import styled from 'styled-components';

const Label = styled.label.attrs({ className: 'form-label' })`
  color: var(--grey700);
  font-weight: var(--font-weight-medium) !important;
`;

const Input = styled.input.attrs({ className: 'form-control' })`
  height: var(--base-height);
  color: var(--grey900) !important;
  border: 1px solid var(--grey300);
  border-radius: var(--radius-m);
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'var(--base-width)')};

  :focus {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }

  &:disabled {
    background-color: #e9ecef;
    color: var(--grey900) !important;
  }
`;

export { Label, Input };
