import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';

import { REQUEST_STATUS_CODE } from 'commons/constant';
import { addPlaceholderOption, handleError, handleInputChange } from 'commons/helper';
import useSelectOptions from 'hooks/useSelectOptions';
import vendorApi from 'service/vendor/info';

import { Flex } from 'styles/components';
import Select from 'components/Select';
import InputLabel from 'components/InputLabel';
import ImageUploader from 'components/ImageUploader';

import * as Styled from '../../styled';

import { PAYMENT_INFO_INPUT_LABELS, PAYMENT_INFO_SELECTS } from './commons/constant';
import { isInputDisabledStatus } from '../../commons/helper';

const PaymentInfoForm = ({ form, setForm, statusCode }) => {
  const selectOptions = useSelectOptions(['paymentMethod', 'paymentDate', 'bank']);
  const isImageUploaderRequired = !(
    statusCode === REQUEST_STATUS_CODE.REJECT ||
    (statusCode === null && form.bankBookVendorImageName)
  );

  const handleImageDownloadClick = async () => {
    try {
      const blob = await vendorApi.getImagePaymentById(form.bankBookVendorImageId);
      saveAs(blob, form.bankBookVendorImageName);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Flex $flexDirection='column'>
      <Styled.Category>결제 정보</Styled.Category>
      <Flex $alignItems='flex-start' $gap='var(--space-m)' $flexWrap='wrap'>
        {PAYMENT_INFO_SELECTS.map(({ name, label: _label }) => (
          <Select
            key={name}
            id={name}
            name={name}
            label={_label}
            value={form[name]}
            onChange={(e) => handleInputChange(e, setForm)}
            options={addPlaceholderOption(selectOptions[name], `${_label} 선택`)}
            disabled={isInputDisabledStatus(statusCode)}
            required
          />
        ))}
        {PAYMENT_INFO_INPUT_LABELS.map(
          ({ name, label: _label, type, placeholder, pattern, helperText }) => (
            <InputLabel
              required
              key={name}
              id={name}
              name={name}
              label={_label}
              placeholder={placeholder}
              value={form[name] || ''}
              onChange={(e) => handleInputChange(e, setForm)}
              disabled={isInputDisabledStatus(statusCode)}
              type={type}
              pattern={pattern}
              title={helperText}
            />
          )
        )}
        <ImageUploader
          id='bankBookVendorImage'
          title='통장 사본'
          label='이미지 선택'
          setState={setForm}
          imageName={form.bankBookVendorImageName}
          imageId={statusCode && form.bankBookVendorImageId}
          onImageDownloadClick={handleImageDownloadClick}
          disabled={isInputDisabledStatus(statusCode)}
          required={isImageUploaderRequired}
        />
      </Flex>
    </Flex>
  );
};

PaymentInfoForm.propTypes = {
  form: PropTypes.shape({
    bankBookVendorImageName: PropTypes.string,
    bankBookVendorImageId: PropTypes.number,
  }).isRequired,
  setForm: PropTypes.func.isRequired,
  statusCode: PropTypes.oneOf([
    REQUEST_STATUS_CODE.REQUEST,
    REQUEST_STATUS_CODE.REJECT,
    REQUEST_STATUS_CODE.APPROVE,
    REQUEST_STATUS_CODE.CANCEL,
    null,
  ]),
};

export default PaymentInfoForm;
