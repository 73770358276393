import { Route } from 'react-router-dom';

import AdminNoticeListPage from './AdminNoticeListPage';
import AdminNoticeRegisterPage from './AdminNoticeRegisterPage';

const NoticeRoute = () => {
  return (
    <>
      <Route path='/admin/notice/list' component={AdminNoticeListPage} />
      <Route
        path={['/admin/notice/register/:noticeId', '/admin/notice/register']}
        component={AdminNoticeRegisterPage}
      />
    </>
  );
};

export default NoticeRoute;
