export const VENDOR_COLUMNS = [
  { Header: '거래처 번호', accessor: 'vendorId' },
  { Header: '거래처 명', accessor: 'vendorDisplayName' },
  { Header: '펫프 담당자', accessor: 'vendorManagerName' },
  { Header: '발주 담당자', accessor: 'supplyManagerName' },
];

export const PRODUCT_COLUMNS = [
  { Header: '품목ID', accessor: 'productItemId' },
  { Header: '품목유형', accessor: 'productType' },
  { Header: '품목명', accessor: 'productName' },
  { Header: '상태', accessor: 'productStatus' },
  { Header: '거래처 차수', accessor: 'degree' },
  { Header: '과세구분', accessor: 'tax' },
  { Header: '기준 공급가(₩)', accessor: 'standardUnitPrice' },
  { Header: '최소주문수량(EA)', accessor: 'minimumOrderQuantity' },
];

export const VENDOR_PRODUCT_LIST = '/admin/vendor/product/list';

export const INITIAL_SEARCH_OPTIONS = { query: '', condition: 'VENDOR_NAME' };

export const SELECT_OPTIONS = ['vendorSearchCondition'];
