/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { PREPAID_LIST } from 'pages/admin/AdminPrepaidPage/commons/constant';
import { VENDOR_PRODUCT_LIST } from 'pages/admin/AdminProductManagePage/commons/constant';
import { SETTLEMENT_COMPLETE, SETTLEMENT_LIST } from 'pages/admin/settlement/constant';

import { DASHBOARD } from 'pages/vendor/DashboardPage/commons/constant';
import { SETTLEMENT_LIST as VENDOR_SETTLEMENT_LIST } from 'pages/vendor/SettlementListPage/commons/constant';

const initialState = {
  // global
  alert: { isOpen: false, info: { title: null, content: '', component: null } },
  activityIndicator: { isOpen: false, info: { title: null, content: '', component: null } },

  // admin
  [SETTLEMENT_LIST]: { detail: false, checkBill: false, card: false, cash: false },
  [SETTLEMENT_COMPLETE]: { detail: false },
  [DASHBOARD]: { detail: false },
  [PREPAID_LIST]: { detail: false },
  [VENDOR_PRODUCT_LIST]: { detail: false },
  '/admin/reverse': { detail: false, confirm: false, register: false },
  '/admin/dashboard': { 발주제한: false, 일자별제한: false },

  // vendor
  [VENDOR_SETTLEMENT_LIST]: { detail: false, checkBill: false, card: false, cash: false },
  '/reverse': { detail: false },
};

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setModalIsOpen: (state, action) => {
      let draft;

      if (action.payload.path) {
        draft = state[action.payload.path][action.payload.modalName];
      } else {
        draft = state[action.payload.modalName];
      }
      if (action.payload.info && draft) {
        draft.info = action.payload.info;
        draft.isOpen = action.payload.isOpen;
      } else if (action.payload.path) {
        state[action.payload.path][action.payload.modalName] = action.payload.isOpen;
      } else {
        state[action.payload.modalName] = action.payload.isOpen;
      }
    },
  },
});

export const { setModalIsOpen } = slice.actions;

export default slice.reducer;
