export const productSearchCondition = [
  {
    id: 'PRODUCT_ITEM_ID',
    label: '품목ID',
    value: 'PRODUCT_ITEM_ID',
  },
  {
    id: 'PRODUCT_NAME',
    label: '품목명',
    value: 'PRODUCT_NAME',
  },
];

export default { productSearchCondition };
