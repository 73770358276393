import TableContainer from './containers/TableContainer';

/**
 * @param {*} props
 */

const SettlementPrepaidPage = ({ ...props }) => {
  return <TableContainer {...props} />;
};

export default SettlementPrepaidPage;
