import { getTotalObject } from 'commons/helper';

export const getProductItemIdCounts = (itemList, selectedProductList) =>
  getTotalObject([...itemList, ...selectedProductList], (item) => item.productItemId);

export const getDuplicatedItems = (productItemIdCounts) =>
  Object.entries(productItemIdCounts).filter(([, count]) => count >= 3);

export const getStringProductItemIds = (duplicatedItems) =>
  duplicatedItems.map((item) => item[0]).join(', ');

export default { getProductItemIdCounts };
