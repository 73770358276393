import { combineReducers } from '@reduxjs/toolkit';

import user from './user';

import settlement from './admin/settlement';
import amendmentRequest from './admin/amendmentRequest';
import notice from './admin/notice';
import prepaid from './admin/prepaid';
import vendorProduct from './admin/vendorProduct';
import supply from './admin/supply';
import reverse from './admin/reverse';

import modals from './modals';

import dashboard from './vendor/dashboard';
import vendorSettlement from './vendor/settlement';
import vendorSupply from './vendor/supply';
import ScReverse from './vendor/reverse';

const reducer = (state, action) => {
  return combineReducers({
    // global
    user,
    modals,
    // admin
    settlement,
    amendmentRequest,
    reverse,
    notice,
    prepaid,
    vendorProduct,
    supply,
    // vendor
    dashboard,
    vendorSettlement,
    vendorSupply,
    ScReverse,
  })(state, action);
};

export default reducer;
