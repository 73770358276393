import { useState } from 'react';
import PropTypes from 'prop-types';

import { handleError } from 'commons/helper';
import prepaidApi from 'service/hq/prepaid';
import toastify from 'commons/toast';

import Card from 'components/Card';
import PaginationTable from 'components/Table/PaginationTable';
import Button from 'components/Button';
import NumericCell from 'components/NumericCell/NumericCell';

import * as Styled from './styled';
import { COLUMNS } from './commons/constant';
import PriceCell from './components/PriceCell';

const PrepaidDetail = ({ prepaidHistoryList, fetchPrepaidDetail }) => {
  const [controlledPageIndex, setControlledPage] = useState(0);

  const tableOptions = {
    initialState: { pageIndex: 0, pageSize: 50 },
    manualPagination: true,
    pageCount: prepaidHistoryList.pageCount,
    useControlledState: (state) => ({
      ...state,
      pageIndex: controlledPageIndex,
    }),
  };

  const handleTablePageChange = ({ state }) => fetchPrepaidDetail({ pageIndex: state.pageIndex });

  const handleRestoreClick = (id) => async () => {
    try {
      await prepaidApi.restorePrepaid(id);
      toastify('선지급금이 복원되었습니다.', { variant: 'success' });
      fetchPrepaidDetail({ pageIndex: controlledPageIndex });
    } catch (error) {
      handleError(error);
    }
  };

  const renderCell = (cell) => {
    const { column, row, value } = cell;
    switch (column.id) {
      case 'price':
        return <PriceCell {...cell} />;
      case 'totalPrice':
        return <NumericCell value={value} />;
      case '복원처리':
        if (row.original.action === '사용') {
          return (
            <Button
              $variant='secondary'
              disabled={row.original.cancel === 'N'}
              onClick={handleRestoreClick(row.original.id)}
            >
              복원
            </Button>
          );
        }
        return null;
      default:
        return cell.value;
    }
  };

  return (
    <Card title='선지급금 상세'>
      <Styled.ResultHeader totalCount={prepaidHistoryList.totalElements} />
      <Styled.TableWrapper>
        <PaginationTable
          columns={COLUMNS}
          data={prepaidHistoryList.content}
          onPageChange={handleTablePageChange}
          tableOptions={tableOptions}
          renderCell={renderCell}
          setControlledPage={setControlledPage}
        />
      </Styled.TableWrapper>
    </Card>
  );
};

PrepaidDetail.propTypes = {
  prepaidHistoryList: PropTypes.shape({
    pageCount: PropTypes.number.isRequired,
    totalElements: PropTypes.number.isRequired,
    content: PropTypes.arrayOf(PropTypes.object),
  }),
  fetchPrepaidDetail: PropTypes.func.isRequired,
};

export default PrepaidDetail;
