import { useRef, useState, useMemo } from 'react';

import toastify from 'commons/toast';
import receivingApi from 'service/hq/receiving';
import { downloadJsonAsExcel } from 'commons/excel';
import { handleError, isEmpty, translateList } from 'commons/helper';
import useFetch from 'hooks/useFetch';

import { Flex } from 'styles/components';
import Card from 'components/Card';

import ResultHeader from 'components/ResultHeader';
import Button from 'components/Button';
import PaginationTable from 'components/Table/PaginationTable';
import { ROW_SELECT_PLUGIN_HOOKS } from 'components/Table/commons/constant';
import NumericCell from 'components/NumericCell/NumericCell';

import { COLUMNS, MAPPING_KEYS } from './commons/constant';
import * as Styled from './styled';
import AdminReceivingFilter from './components/AdminReceivingFilter';

function AdminReceivingManagePage() {
  const [controlledPageIndex, setControlledPage] = useState(0);
  const searchOptionsSnapshot = useRef({});
  const {
    data: { content = [], totalElements = 0, totalPages = 0 } = {},
    execute: fetchReceivingResultList,
  } = useFetch(receivingApi.fetchReceivingResultList, { initialExecute: false });
  const [selectedReceivingResultList, setSelectedReceivingResultList] = useState([]);

  const tableOptions = {
    initialState: { pageSize: 500 },
    manualPagination: true,
    pageCount: totalPages,
    useControlledState: (state) => ({
      ...state,
      pageIndex: controlledPageIndex,
    }),
  };

  const FooterElement =
    (valueKey) =>
    ({ rows }) => {
      const total = useMemo(() => {
        return rows.reduce((acc, cur) => cur.values[valueKey] + acc, 0);
      }, [rows]);
      return <NumericCell value={total} />;
    };

  const [customColumn] = useState(() => {
    const nextFooterColumn = COLUMNS.find((it) => it.Header === '입고 결과 개요').columns.map(
      (it, idx) => (idx <= 3 ? it : { ...it, Footer: FooterElement(it.accessor) })
    );

    return COLUMNS.map((item) => {
      if (item.Header === '입고 결과 개요') {
        return { ...item, columns: nextFooterColumn };
      }
      return item;
    });
  });

  const handleSearchHandler = () => {
    fetchReceivingResultList({
      productSupplyReceivingStatusCode:
        searchOptionsSnapshot.current.productSupplyReceivingStatusCode,
      searchCondition: searchOptionsSnapshot.current.searchCondition,
      searchWord: searchOptionsSnapshot.current.query,
      beforeReceiveAvailableDay: searchOptionsSnapshot.current.receiveAvailableDay[0],
      afterReceiveAvailableDay: searchOptionsSnapshot.current.receiveAvailableDay[1],
      beforeInboundCompletionDay: searchOptionsSnapshot.current.inboundCompletionDay[0],
      afterInboundCompletionDay: searchOptionsSnapshot.current.inboundCompletionDay[1],
    });
    setControlledPage(0);
  };

  const handlePageChange = ({ state }) => {
    const { current } = searchOptionsSnapshot;
    fetchReceivingResultList({
      productSupplyReceivingStatusCode: current.productSupplyReceivingStatusCode,
      searchCondition: current.searchCondition,
      searchWord: current.query,
      beforeReceiveAvailableDay: current.receiveAvailableDay[0],
      afterReceiveAvailableDay: current.receiveAvailableDay[1],
      beforeInboundCompletionDay: current.inboundCompletionDay[0],
      afterInboundCompletionDay: current.inboundCompletionDay[1],
      pageIndex: state.pageIndex,
    });
  };

  const handleProductSelect = ({ selectedFlatRows }) => {
    setSelectedReceivingResultList(selectedFlatRows.map((item) => item.original));
  };

  const isValidList = (list) => {
    return list.every((item) => item.productSupplyReceivingStatusValue === '입고완료');
  };

  const getProductSupplyRequestItemIdList = (list) => {
    return list.map((item) => item.productSupplyRequestItemId);
  };

  const handleReceivingConfirmClick = async () => {
    if (isEmpty(selectedReceivingResultList)) {
      toastify('선택된 항목이 존재하지 않습니다.', { variant: 'warn' });
      return;
    }
    if (!isValidList(selectedReceivingResultList)) {
      toastify('"입고완료"항목만 입고확정 처리가 가능합니다.', { variant: 'warn' });
      return;
    }

    try {
      await receivingApi.confirmReceivingResultList(
        getProductSupplyRequestItemIdList(selectedReceivingResultList)
      );
      toastify('선택한 품목의 입고결과가 확정되었습니다.', { variant: 'success' });
      fetchReceivingResultList({
        productSupplyReceivingStatusCode:
          searchOptionsSnapshot.current.productSupplyReceivingStatusCode,
        searchCondition: searchOptionsSnapshot.current.searchCondition,
        searchWord: searchOptionsSnapshot.current.query,
        beforeReceiveAvailableDay: searchOptionsSnapshot.current.receiveAvailableDay[0],
        afterReceiveAvailableDay: searchOptionsSnapshot.current.receiveAvailableDay[1],
        beforeInboundCompletionDay: searchOptionsSnapshot.current.inboundCompletionDay[0],
        afterInboundCompletionDay: searchOptionsSnapshot.current.inboundCompletionDay[1],
        pageIndex: controlledPageIndex,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleExcelDownloadClick = async () => {
    if (isEmpty(content)) {
      toastify('입고 결과가 존재하지 않습니다.', { variant: 'warn' });
      return;
    }
    try {
      const { current } = searchOptionsSnapshot;
      const { response } = await receivingApi.fetchReceivingResultList({
        productSupplyReceivingStatusCode: current.productSupplyReceivingStatusCode,
        searchCondition: current.searchCondition,
        searchWord: current.query,
        beforeReceiveAvailableDay: current.receiveAvailableDay[0],
        afterReceiveAvailableDay: current.receiveAvailableDay[1],
        beforeInboundCompletionDay: current.inboundCompletionDay[0],
        afterInboundCompletionDay: current.inboundCompletionDay[1],
        pageIndex: 0,
        pageSize: 100000,
      });
      downloadJsonAsExcel({
        data: translateList(response.data.content, MAPPING_KEYS),
        sheetName: '입고 결과',
        fileName: '입고 결과',
      });
    } catch (error) {
      handleError(error);
    }
  };

  const headerButtons = [
    <Button $variant='light' onClick={handleReceivingConfirmClick}>
      입고 확정
    </Button>,
    <Button $variant='light' onClick={handleExcelDownloadClick}>
      엑셀 다운로드
    </Button>,
  ];

  const renderCell = (cell) => {
    switch (cell.column.id) {
      case 'productSupplyUnitPrice':
      case 'availableReceiveQuantity':
      case 'receivedQuantity':
      case 'unreceivedQuantity':
      case 'receivedQuantityPrice':
      case 'unreceivedQuantityPrice':
        return <NumericCell value={cell.value} />;
      default:
        return cell.value;
    }
  };

  const renderFooterTotalSum = (footerGroups) => {
    const [footerArgs] = footerGroups;

    return (
      <tfoot>
        <tr {...footerArgs.getFooterGroupProps()} key={footerArgs.getFooterGroupProps().key}>
          {footerArgs.headers.map((column, index) => (
            <td
              {...column.getFooterProps()}
              key={column.getFooterProps().key}
              className={index > 1 ? 'right' : ''}
            >
              {column.render('Footer')}
            </td>
          ))}
        </tr>
      </tfoot>
    );
  };

  return (
    <Styled.Wrapper>
      <Card title='입고 관리'>
        <Flex $flexDirection='column' $gap='var(--space-m)'>
          <AdminReceivingFilter ref={searchOptionsSnapshot} search={handleSearchHandler} />
          <ResultHeader totalCount={totalElements} buttons={headerButtons} />
          <PaginationTable
            columns={customColumn}
            data={content}
            onPageChange={handlePageChange}
            tableOptions={tableOptions}
            pluginHooks={ROW_SELECT_PLUGIN_HOOKS}
            onSelectChange={handleProductSelect}
            setControlledPage={setControlledPage}
            renderCell={renderCell}
            renderTableFoot={renderFooterTotalSum}
          />
        </Flex>
      </Card>
    </Styled.Wrapper>
  );
}

export default AdminReceivingManagePage;
