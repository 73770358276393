import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import HeaderModal from 'components/modals/HeaderModal';
import { usePageModals, useGlobalModals } from 'hooks/pages';
import { Flex } from 'styles/components';

import Button from 'components/Button';
import useFetch from 'hooks/useFetch';
import toastify from 'commons/toast';
import { Text } from './styled';
import { postReverses } from '../../apis';
import { useAdminReverseContext } from '../../hooks';

function ConfirmContent({ body, setError, setIsFailed, errorMsg }) {
  const history = useHistory();
  const [, setIndicatorModalOpen] = useGlobalModals();
  const [, setModalOpen] = usePageModals();
  const { execute } = useFetch(postReverses);
  const { refresh } = useAdminReverseContext();

  const onClose = () => {
    setModalOpen('confirm', false);
    history.goBack();
  };

  const onComplete = () => {
    toastify('등록이 완료되었습니다.', { variant: 'success' });
    refresh();
    setModalOpen('confirm', false);
    history.replace('/admin/reverse');
  };

  const onFailed = (result) => {
    if ('error' in result) {
      const { message = '' } = result?.error;

      errorMsg.current = message;

      if (message.indexOf('재고부족') !== -1) {
        const messageLength = message.length;
        const strToArr = message.substring(message.indexOf('[') + 1, messageLength - 1).split(',');
        setError(strToArr);
      }

      setIsFailed(() => true);
    }
  };

  const onSubmit = async () => {
    setIndicatorModalOpen('activityIndicator', true);

    try {
      const result = await execute(body);
      if ('error' in result) {
        onFailed(result);
        setIndicatorModalOpen('activityIndicator', false);
        return;
      }
      onComplete();
    } finally {
      setIndicatorModalOpen('activityIndicator', false);
    }
  };

  return (
    <Flex $flexDirection='column' $width='400px'>
      <Flex $margin='25px 0 40px' $alignItems='center' $justifyContent='center'>
        <Text>반품 및 출고전표를 생성합니다.</Text>
      </Flex>
      <Flex $alignItems='center' $justifyContent='center' $columnGap={10}>
        <Button $outline $size='large' onClick={onClose}>
          취소
        </Button>
        <Button $size='large' onClick={onSubmit}>
          확인
        </Button>
      </Flex>
    </Flex>
  );
}

function ConfirmFailedContent({ setIsFailed, errorMsg }) {
  const history = useHistory();
  const [, setModalOpen] = usePageModals();

  const onClose = () => {
    setModalOpen('confirm', false);
    history.goBack();
  };

  const onConfirm = () => {
    setIsFailed(() => false);
    onClose();
  };

  return (
    <Flex $flexDirection='column' $width='400px'>
      <Flex
        $flexDirection='column'
        $margin='25px 0 40px'
        $alignItems='center'
        $justifyContent='center'
        $rowGap='10px'
      >
        <Text>출고전표생성에 실패하였습니다.</Text>
        <Text color='#FF5C5C'>{`사유 : ${errorMsg}`}</Text>
      </Flex>
      <Flex $alignItems='center' $justifyContent='center' $columnGap={10}>
        <Button $size='large' onClick={onConfirm}>
          확인
        </Button>
      </Flex>
    </Flex>
  );
}

function ConfirmModal({ body, setError }) {
  const [isFailed, setIsFailed] = useState(false);
  const [{ confirm }] = usePageModals();
  const errorMsg = useRef('');

  return (
    <HeaderModal isOpen={confirm} title='반품등록'>
      {isFailed ? (
        <ConfirmFailedContent setIsFailed={setIsFailed} errorMsg={errorMsg.current} />
      ) : (
        <ConfirmContent
          body={body}
          setError={setError}
          setIsFailed={setIsFailed}
          errorMsg={errorMsg}
        />
      )}
    </HeaderModal>
  );
}

export default React.memo(ConfirmModal);
