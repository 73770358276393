import instance from 'service/interceptor';
import { formatDateAsYYYYMMDD } from 'commons/date';
import { returnNullIfEmptyString } from 'commons/helper';

const getRequestItemSaveDto = (itemList) =>
  itemList.map((item) => ({
    productSupplyRequestItemId: item.productSupplyRequestItemId,
    vendorId: item.vendorId,
    productId: item.productId,
    productItemId: item.productItemId,
    taxationTypeCode: item.taxationTypeCode,
    isFreeGift: item.isFreeGift,
    productStandardUnitPrice: item.productStandardUnitPrice,
    productSupplyUnitPrice: item.productSupplyUnitPrice,
    productSupplyPrice: item.productSupplyPrice,
    minimumOrderQuantity: item.minimumOrderQuantity,
    requestQuantity: item.requestQuantity,
    petfriendsComment: returnNullIfEmptyString(item.petfriendsComment),
    itemBarcode: item.itemBarcode,
    boxBarcode: item.boxBarcode,
    boxUnit: item.boxUnit,
    cartonUnit: item.cartonUnit,
    pltUnit: item.pltUnit,
    width: item.width,
    length: item.length,
    height: item.height,
    weight: item.weight,
    volume: item.volume,
    storageTemperature: item.storageTemperature,
    storageTemperatureName: item.storageTemperatureName,
    shelfLifeDay: item.shelfLifeDay,
    shelfLifeDays: item.shelfLifeDays,
    active: item.active,
  }));

const getSupplyRequestList = async ({
  pageIndex = 0,
  pageSize = 50,

  centerId = null,
  productSupplyTypeCode = null,
  statusCodes = null,

  searchOption = 'PRODUCT_SUPPLY_REQUEST',
  searchWord = '',
  prepaidSettlement = '',

  beforeReceiveAvailableDay = null,
  afterReceiveAvailableDay = null,
  beforeProductSupplyRequestDay = null,
  afterProductSupplyRequestDay = null,
  beforeTotalReceiveConfirmDay = null,
  afterTotalReceiveConfirmDay = null,
  isExcel = false,
} = {}) => {
  const { data } = await instance.post(
    isExcel
      ? '/api/v2/hq/purchase-orders/items/download'
      : '/api/v2/hq/product/supply/request/list',
    {
      pageNumber: pageIndex,
      pageSize,

      centerId: returnNullIfEmptyString(centerId ? Number(centerId) : centerId),
      productSupplyTypeCode: returnNullIfEmptyString(productSupplyTypeCode),
      statusCodes: statusCodes.includes('') ? null : statusCodes,

      searchOption,
      searchWord,
      prepaidSettlement: prepaidSettlement === '' ? null : prepaidSettlement,

      beforeReceiveAvailableDay: formatDateAsYYYYMMDD(beforeReceiveAvailableDay),
      afterReceiveAvailableDay: formatDateAsYYYYMMDD(afterReceiveAvailableDay),
      beforeProductSupplyRequestDay: formatDateAsYYYYMMDD(beforeProductSupplyRequestDay),
      afterProductSupplyRequestDay: formatDateAsYYYYMMDD(afterProductSupplyRequestDay),
      beforeTotalReceiveConfirmDay: formatDateAsYYYYMMDD(beforeTotalReceiveConfirmDay),
      afterTotalReceiveConfirmDay: formatDateAsYYYYMMDD(afterTotalReceiveConfirmDay),
    }
  );

  return data;
};

const getSupplyRequestDetail = async (productSupplyRequestId) => {
  const { data } = await instance.get(
    `/api/v2/hq/product/supply/requests/${productSupplyRequestId}`
  );

  return data;
};

const saveSupplyRequestDetail = async ({
  productSupplyRequestId,
  supplyRequestDetail,
  statusCode,
  confirmYn = 'Y',
  rejectYn = 'N',
}) => {
  const { vendorInfo, requestInfo, receiveInfo, commentInfo, itemList, updatedAt } =
    supplyRequestDetail;

  const statusCodeEndPoint = {
    CANCEL: `/api/v2/hq/product/supply/requests/${productSupplyRequestId}/cancel`,
  };

  if (statusCodeEndPoint[statusCode]) {
    const { data } = await instance.post(statusCodeEndPoint[statusCode]);
    return data;
  }

  const { data } = await instance.post('/api/v2/hq/product/supply/request/save', {
    productSupplyRequestId: returnNullIfEmptyString(productSupplyRequestId),
    vendorId: vendorInfo.vendorId,
    productSupplyTypeCode: requestInfo.productSupplyTypeCode,
    statusCode,
    centerId: Number(receiveInfo.centerId),
    productSupplyRequestDay: requestInfo.productSupplyRequestDay ?? null,
    receiveRequestDay: receiveInfo.receiveRequestDay,
    receiveAvailableDay: receiveInfo.receiveAvailableDay,
    petfriendsComment: returnNullIfEmptyString(commentInfo.petfriendsComment),
    requestItemSaveDto: getRequestItemSaveDto(itemList),
    requestHistorySaveDto: {
      division: statusCode,
      contents: null,
    },
    updatedAt,
    confirmYn,
    rejectYn,
    prepaidSettlement: requestInfo.prepaidSettlement,
  });

  return data;
};

const getSupplyRequestItems = async ({ productSupplyRequestId, pageIndex = 0, pageSize = 50 }) => {
  const { data } = await instance.post(
    `/api/v2/hq/product/supply/requests/${productSupplyRequestId}/items`,
    {
      pageNumber: pageIndex,
      pageSize,
    }
  );

  return data;
};

const getProductList = async ({
  pageIndex = 0,
  pageSize = 50,
  vendorId,
  searchOption = 'PRODUCT_ITEM_ID',
  searchWord = '',
} = {}) => {
  const { data } = await instance.post('/api/v2/hq/product/supply/register/request/item', {
    pageNumber: pageIndex,
    pageSize,
    vendorId,
    searchOption,
    searchWord,
  });

  return data;
};

const getVendorList = async (searchCondition = 'VENDOR_ID', searchContent = '') => {
  const { data } = await instance.get(
    `/api/v2/hq/request/vendor/list?searchCondition=${searchCondition}&searchContent=${searchContent}`
  );

  return data;
};

const updateStatus = async (list, status) => {
  const statusEndPoint = {
    CANCEL: '/api/v2/hq/product/supply/requests/cancel',
  };

  if (statusEndPoint[status]) {
    const params = {
      purchaseOrderIds: list,
    };

    const { data } = await instance.post(statusEndPoint[status], params);
    return data;
  }

  const { data } = await instance.post('/api/v2/hq/product/supply/requests/status/update', {
    list,
    status,
  });

  return data;
};

const readUploadedSupplyExcel = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const { data } = await instance.post('/api/v2/hq/product/supply/request/excel-upload', formData);

  return data;
};

const saveUploadedSupplyExcel = async (list) => {
  const { data } = await instance.post('/api/v2/hq/product/supply/request/excel-upload/save', list);

  return data;
};

// NOTE: 이거 안씀
const readUploadedReceiveExcel = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const { data } = await instance.post('/hq/product/supply/receive/excel-upload', formData);

  return data;
};

// NOTE: 이거 안씀
const saveUploadedReceiveExcel = async (list) => {
  const { data } = await instance.post('/hq/product/supply/receive/excel-upload/save', list);

  return data;
};

const getSupplyRequestStatements = async (productSupplyRequestId) => {
  const { data } = await instance.get(
    `/api/v2/hq/product/supply/request/statements/${productSupplyRequestId}`
  );

  return data;
};

const getWmsCenterList = async () => {
  const { data } = await instance.get('/api/v2/hq/centers');
  return data;
};

const createInboundData = async ({ requestId }) => {
  const { data } = await instance.post(
    `/api/v2/hq/product/supply/requests/${requestId}/transmission`
  );
  return data;
};

const confirmCancel = async ({ requestId }) => {
  const { data } = await instance.post(
    `/api/v2/hq/product/supply/requests/${requestId}/confirm-cancel`
  );
  return data;
};

export default {
  getSupplyRequestList,
  getSupplyRequestDetail,
  saveSupplyRequestDetail,
  getSupplyRequestItems,
  getProductList,
  getVendorList,
  updateStatus,
  readUploadedSupplyExcel,
  saveUploadedSupplyExcel,
  readUploadedReceiveExcel,
  saveUploadedReceiveExcel,
  getSupplyRequestStatements,
  getWmsCenterList,
  createInboundData,
  confirmCancel,
};
