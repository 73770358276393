import PropTypes from 'prop-types';
import Input from 'components/Input';

const Cell = ({ row }) => {
  return <Input type='checkbox' disabled={row.disabled} {...row.getToggleRowSelectedProps()} />;
};

Cell.propTypes = {
  row: PropTypes.shape({
    disabled: PropTypes.bool,
    getToggleRowSelectedProps: PropTypes.func,
  }).isRequired,
};

export default Cell;
