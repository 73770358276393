import dayjs from 'dayjs';

export const REQUEST_STATUS_CODE = {
  SAVE: 'SAVE',
  CANCEL: 'CANCEL',
  REQUEST: 'REQUEST',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
};

export const REQUEST_STATUS = {
  SAVE: '임시 저장',
  CANCEL: '요청 취소',
  REQUEST: '요청 중',
  APPROVE: '승인 완료',
  REJECT: '요청 반려',
};

export const TAX_OPTIONS = [
  { key: '', value: '과세구분 선택' },
  { key: 'TAX', value: '과세' },
  { key: 'ZERO', value: '영세' },
  { key: 'FREE', value: '면세' },
];

export const VENDOR_SEARCH_CONDITION = [
  {
    id: 'VENDOR_NAME',
    label: '거래처 명',
    value: 'VENDOR_NAME',
  },
  {
    id: 'VENDOR_ID',
    label: '거래처 번호',
    value: 'VENDOR_ID',
  },
];

export const CENTER_ID = [
  {
    value: 'CJ 곤지암',
    key: '1',
  },
  {
    value: '안성 보충',
    key: '2',
  },
  {
    value: '덕평 보충',
    key: '8',
  },
];

export const SUPPLY_STATUS = {
  PETFRIENDS_DRAFT: '발주예정',
  PETFRIENDS_REGISTER: '발주등록',
  MODIFY: '발주수정중',
  VENDOR_REQUEST: '확정요청',
  PETFRIENDS_CONFIRM: '발주확정',
  CANCEL: '발주취소',
};

export const SPECIAL_CHARACTER_REGEX = /[`~!@#$%^&*()_|+\-=?;:'"<>{}[\]\\/.,₩]/g;

export const SUPPLY_REQUEST_LIST_MAPPING_KEYS = {
  productItemId: '품목ID',
  productName: '품목명',
  isFreeGift: '증정여부',
  productSupplyUnitPrice: '적용공급가(₩)',
  requestQuantity: '발주 수량',
  availableReceiveQuantity: '입고예정수량',
  unavailableReceiveQuantity: '입고불가수량',
  restockDay: '재입고일정',
  shelfLifeDay: '유통기한',
  petfriendsComment: '펫프 담당자 메시지',
};

export const SUPPLY_REQUEST_STATEMENTS_INITIAL_VALUES = {
  itemList: [],
  partnerInfo: {},
  receiveInfo: {},
  requestInfo: {},
};

export const REASON_FOR_CHANGE_OPTIONS = [
  { key: '단가인상', value: '단가인상' },
  { key: '단가인하', value: '단가인하' },
  { key: '프로모션', value: '프로모션' },
  { key: '데이터오류', value: '데이터오류' },
];

export const PREPAID_SETTLEMENT_OPTIONS = [
  { id: 'PREPAID_SETTLEMENT_OPTIONS_Y', label: 'Y', value: 'Y' },
  { id: 'PREPAID_SETTLEMENT_OPTIONS_N', label: 'N', value: 'N' },
];

export default { REQUEST_STATUS_CODE, REQUEST_STATUS, TAX_OPTIONS };

export const CONNECT_TO_SELLER_ADMIN_MENU_ID = [28];

export const getSellerCouponRequestInitialState = () => {
  const startDate = new Date(dayjs(new Date()).subtract(1, 'month').toDate());

  return {
    dateCondition: 'COUPON_REQUEST_CREATED_DATE',
    startDate,
    endDate: new Date(),
    requestStatuses: null,
    couponStatuses: null,
    searchCondition: 'COUPON_NAME',
    searchContents: '',
    pageNumber: 1,
    pageSize: 10,
  };
};
