import { configureStore } from '@reduxjs/toolkit';
import modules from './modules';

export default configureStore({
  reducer: modules,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // {
      //   // Ignore these action types
      //   ignoredActions: [
      //     'modals/setModalIsOpen',
      //     'settlement/setSelectedDatas',
      //     'settlement/fetchSettlementCompleteList/pending',
      //   ],
      //   // Ignore these field paths in all actions
      //   ignoredActionPaths: ['settlement', 'payload.timestamp'],
      //   // Ignore these paths in the state
      //   ignoredPaths: ['items.dates'],
      // },
    }),
});
