export const LOGIN_PAGE = '/login';
export const UPDATE_ACCESS_TOKEN_ENDPOINT = '/api/v2/product/supply/user/server/refresh';
export const TARGET_ACCESS_TOKEN = 'partner_tk';
export const TARGET_REFRESH_TOKEN = 'partner_rtk';
export const TARGET_LOCAL_STORAGE_TOKEN = 'partner_user_info';
export const STATUS_CODE = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};

export default {
  STATUS_CODE,
  LOGIN_PAGE,
  UPDATE_ACCESS_TOKEN_ENDPOINT,
  TARGET_ACCESS_TOKEN,
  TARGET_REFRESH_TOKEN,
};
