import { formatDateAsYYYYMMDD, getSubtractedDate } from 'commons/date';
import { returnNullIfEmptyString } from 'commons/helper';
import instance from 'service/interceptor';

async function fetchReceivingResultList({
  productSupplyReceivingStatusCode = null,
  searchCondition = 'PRODUCT_SUPPLY_REQUEST',
  searchWord = '',
  beforeReceiveAvailableDay = null,
  afterReceiveAvailableDay = null,
  beforeInboundCompletionDay = getSubtractedDate(7),
  afterInboundCompletionDay = getSubtractedDate(0),
  pageIndex = 0,
  pageSize = 500,
} = {}) {
  const { data } = await instance.post('/api/v2/hq/product/supply/inbound/list', {
    productSupplyReceivingStatusCode: returnNullIfEmptyString(productSupplyReceivingStatusCode),
    searchCondition,
    searchWord,
    beforeReceiveAvailableDay: formatDateAsYYYYMMDD(beforeReceiveAvailableDay),
    afterReceiveAvailableDay: formatDateAsYYYYMMDD(afterReceiveAvailableDay),
    beforeInboundCompletionDay: formatDateAsYYYYMMDD(beforeInboundCompletionDay),
    afterInboundCompletionDay: formatDateAsYYYYMMDD(afterInboundCompletionDay),
    pageNumber: pageIndex,
    pageSize,
  });

  return data;
}

async function confirmReceivingResultList(inboundConfirmRequestItemIdList) {
  const { data } = await instance.post('/api/v2/hq/product/supply/inbound/result/confirm', {
    inboundConfirmRequestItemIdList,
  });

  return data;
}

// NOTE: 이거 안씀
async function readUploadedExcel(file) {
  const formData = new FormData();
  formData.append('file', file);

  const { data } = await instance.post('/hq/product/supply/inbound/result/excel/upload', formData);

  return data;
}

// NOTE: 이거 안씀
async function saveUploadedList(excelList) {
  const { data } = await instance.post('/hq/product/supply/inbound/result/list', {
    excelList,
  });

  return data;
}

export default {
  fetchReceivingResultList,
  confirmReceivingResultList,
  readUploadedExcel,
  saveUploadedList,
};
