import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-l) calc(var(--space-l) * 2);

  @media (max-width: 768px) {
    flex-direction: column;
    margin: var(--space-l) 0;

    span {
      margin-bottom: var(--space-m);
    }
  }
`;

export const Highlight = styled.strong`
  color: var(--blue500);
`;

export default { Header, Highlight };
