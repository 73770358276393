import PropTypes from 'prop-types';

import { updateNestedObject } from 'commons/helper';

import Card from 'components/Card';
import InputLabel from 'components/InputLabel';

import * as Styled from './styled';

const CommentInfo = ({ commentInfo, setSupplyRequestDetail }) => {
  const handleVendorCommentChange = (e) => {
    setSupplyRequestDetail((prev) =>
      updateNestedObject(prev, ['commentInfo', 'vendorComment'], () => e.target.value)
    );
  };

  return (
    <Card title='메모'>
      <Styled.InputLabelWrapper>
        <InputLabel
          id='petfriendsComment'
          label='펫프담당자 메모'
          value={commentInfo.petfriendsComment ?? ''}
          as='textarea'
          disabled
        />
        <InputLabel
          id='vendorComment'
          label='거래처 메모'
          value={commentInfo.vendorComment ?? ''}
          onChange={handleVendorCommentChange}
          as='textarea'
        />
      </Styled.InputLabelWrapper>
    </Card>
  );
};

CommentInfo.propTypes = {
  commentInfo: PropTypes.shape({
    petfriendsComment: PropTypes.string,
    vendorComment: PropTypes.string,
  }).isRequired,
  setSupplyRequestDetail: PropTypes.func.isRequired,
};

export default CommentInfo;
