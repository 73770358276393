import { thousandSeparator } from 'commons/helper';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const PriceCell = ({ value }) => {
  return <Styled.Wrapper $number={value}>{thousandSeparator(value) || 0}</Styled.Wrapper>;
};

PriceCell.propTypes = {
  value: PropTypes.number.isRequired,
};

export default PriceCell;
