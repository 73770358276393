/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedNotice: {
    date: '',
    category: '',
    title: '',
    paragraph: '',
  },
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setSelectedNotice: (state, action) => {
      state.selectedNotice = action.payload;
    },
  },
});

export const { setSelectedNotice } = slice.actions;

export default slice.reducer;
