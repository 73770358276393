import styled from 'styled-components';
import { priceFluctuationColor } from 'styles/commons';

export const TableWrapper = styled.div`
  table {
    thead {
      th:nth-child(n + 4):nth-child(-n + 5) {
        text-align: right;
      }
    }
    tbody {
      td:nth-child(n + 4):nth-child(-n + 5) {
        text-align: right;
      }
    }
  }
`;

export const PriceCell = styled.span`
  ${priceFluctuationColor}
`;

export default { TableWrapper, PriceCell };
