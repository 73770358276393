export const getAmendmentRequestListParams = (searchOptions, pageNumber) => ({
  requestCode: searchOptions.requestCode,
  statusValue: searchOptions.vendorRequestStatusType,

  searchCondition: searchOptions.requestSearchCondition,
  searchContents: searchOptions.query,

  startRequestDay: searchOptions.requestDay[0],
  endRequestDay: searchOptions.requestDay[1],
  startApproveDay: searchOptions.approveDay[0],
  endApproveDay: searchOptions.approveDay[1],

  pageNumber,
});

export const getProductSupplyItemMasterRequestItem = (productList) =>
  productList.map((item) => ({
    id: item.productSupplyPriceRequestItemId,
    petfriendsComment: item.petfriendsComment,
  }));

export default { getAmendmentRequestListParams };
