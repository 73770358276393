import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reverseId: null,
};

const slice = createSlice({
  name: 'ScReverse',
  initialState,
  reducers: {
    setReverseId: (state, action) => {
      state.reverseId = action.payload;
    },
  },
});

export const { setReverseId } = slice.actions;

export default slice.reducer;
