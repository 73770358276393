import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { SETTLEMENT_COMPLETE, SETTLEMENT_PREPAID_COLUMNS } from 'pages/admin/settlement/constant';
import {
  getSettlementUsedPrepaidList,
  postPrepaidUseExcelUpload,
  postSettlementPrepaidUse,
} from 'service/hq/settlement';
import toastify from 'commons/toast';
import { downloadJsonAsExcel } from 'commons/excel';
import { handleError, translateList, thousandSeparator, isEmpty } from 'commons/helper';
import { SETTLEMENT_STATUS_TYPE_HASH, ACTION_CODE_HASH } from 'commons/hash';
import { Divider, Flex } from 'styles';

import Table from 'components/Table';
import FileUpload from 'components/FileUpload';
import Card from 'components/Card';
import Button from 'components/Button';

import { MAPPING_KEYS } from '../../commons/constant';

import Styled from './styled';

const TableContainer = () => {
  const { selectedDatas } = useSelector((state) => state.settlement[SETTLEMENT_COMPLETE]);
  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    const settlementIdList = selectedDatas.map((item) => item.settlementId);

    const fetchSettlementUsedPrepaidList = async () => {
      try {
        const { data } = await getSettlementUsedPrepaidList({ settlementIdList });

        if (!data?.response.data.length) {
          toastify('대상 정산 내역이 없습니다. 정산 할 내역을 선택해주세요.', { variant: 'warn' });
          return;
        }
        setTableData(data.response.data);
      } catch (err) {
        handleError(err);
      }
    };
    fetchSettlementUsedPrepaidList();
  }, []);

  const handleFileUpload = async (e) => {
    try {
      const file = e.target.files[0];
      const { data } = await postPrepaidUseExcelUpload(file);

      setTableData(data.response.data);
      toastify('액셀 업로드가 되었습니다.', { variant: 'success' });

      if (!data?.response.data.length) {
        toastify('대상 정산 내역이 없습니다.', { variant: 'warn' });
      }
    } catch (err) {
      setTableData(null);
      handleError(err);
    }
  };

  const handleExcelDownloadClick = () => {
    if (isEmpty(selectedDatas) && isEmpty(tableData)) {
      toastify('대상 정산 내역이 존재하지 않습니다.', { variant: 'warn' });
      return;
    }

    try {
      downloadJsonAsExcel({
        data: translateList(tableData || [], MAPPING_KEYS),
        sheetName: '대상 정산 내역',
        fileName: '대상 정산 내역',
      });

      toastify('액셀 파일이 다운로드 되었습니다.', { variant: 'success' });
    } catch (err) {
      toastify('액셀 파일 다운로드에 실패했습니다.', { variant: 'error' });
    }
  };

  const handleSaveButtonClick = async () => {
    try {
      const changedList = tableData.map((data) => {
        return {
          usePrepaidPrice: data.usePrepaidPrice,
          reason: data.memo,
          actionCode: data.actionCode,
          vendorId: data.vendorId,
          settlementId: data.settlementId,
        };
      });

      await postSettlementPrepaidUse(changedList);
      toastify('선지급금 사용 및 복원이 등록되었습니다.', { variant: 'success' });
    } catch (err) {
      if (err.response?.data?.code === 400) {
        toastify(err.response?.data?.message, {
          variant: 'error',
        });
        return;
      }
      toastify('선지급금 사용 및 복원이 등록되지 않았습니다. 파일을 확인해주세요.', {
        variant: 'error',
      });
    }

    try {
      const settlementIdList = tableData.map((item) => item.settlementId);
      const res = await getSettlementUsedPrepaidList({ settlementIdList });

      setTableData(res?.data?.response?.data);
    } catch (err) {
      toastify('알 수 없는 오류가 발생하여 변경 된 목록을 불러 오지 못했습니다.', {
        variant: 'warn',
      });
    }
  };

  const renderGuide = () => {
    return (
      <Styled.List>
        <li>
          1. 대상 내역 다운로드 후 선지급금 사용액을 작성해서 파일 업로드를 하고 &lt;저장&gt; 버튼을
          클릭하면 반영됩니다.
        </li>
      </Styled.List>
    );
  };

  const renderCell = ({ column, value = [], render, row }) => {
    switch (column?.id) {
      case 'settlementStatusType':
        return SETTLEMENT_STATUS_TYPE_HASH[value];
      case 'totalPrepaidPrice':
        // eslint-disable-next-line no-case-declarations
        let color = 'var(--black)';
        if (row.original?.actionCode === 'USE') {
          color = 'var(--blue500)';
        }
        if (row.original?.actionCode === 'CANCEL') {
          color = 'var(--red500)';
        }
        return <Styled.Cell $color={color}>{thousandSeparator(value) || 0}</Styled.Cell>;
      case 'usePrepaidPrice':
        return <div>{thousandSeparator(value) || 0}</div>;
      case 'actionCode':
        return ACTION_CODE_HASH[value];
      default:
        return column.Header.match(/₩/) ? thousandSeparator(value) || 0 : render('Cell');
    }
  };

  return (
    <Card title='선지급금 사용'>
      <Styled.SubTitle>선지급금 사용</Styled.SubTitle>
      {renderGuide()}
      <Styled.SubTitle>액셀 업로드</Styled.SubTitle>
      <FileUpload $width='50%' onChange={handleFileUpload} />
      <Divider />
      <Flex $justifyContent='space-between' $margin-bottom='var(--space-l)'>
        <Styled.SubTitle>대상 정산 내역</Styled.SubTitle>
        <Button $variant='success' onClick={handleExcelDownloadClick}>
          대상 내역 다운로드
        </Button>
      </Flex>
      {tableData && (
        <Table
          columns={SETTLEMENT_PREPAID_COLUMNS}
          data={tableData || []}
          $height='unset'
          $margin-bottom='var(--space-l)'
          renderCell={renderCell}
        />
      )}
      <Flex $justifyContent='flex-start' $margin-bottom='var(--space-l)'>
        <Button $variant='success' onClick={handleSaveButtonClick}>
          저장
        </Button>
      </Flex>
    </Card>
  );
};

TableContainer.propTypes = {};

export default TableContainer;
