export const TRANSPORT_TYPE_OPTIONS = [
  {
    key: '',
    value: '운송 유형 선택',
  },
  {
    key: 'TRUCK',
    value: '차량',
  },
  {
    key: 'PARCEL',
    value: '택배',
  },
  { key: 'CONTAINER', value: '컨테이너' },
];

export const CARGO_TYPE_OPTIONS = [
  {
    key: '',
    value: '화물 유형 선택',
  },
  {
    key: 'BOX',
    value: '박스',
  },
  {
    key: 'PALETTE',
    value: '팔레트',
  },
];
