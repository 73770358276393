import { useState } from 'react';
import PropTypes from 'prop-types';

import toastify from 'commons/toast';
import { isEmpty, thousandSeparator, updateMyDataMaker, updateObject } from 'commons/helper';
import { Flex } from 'styles/components';

import Card from 'components/Card';
import Table from 'components/Table';
import { ROW_SELECT_PLUGIN_HOOKS } from 'components/Table/commons/constant';
import Button from 'components/Button';

import { TOTAL_COLUMNS, ITEM_COLUMNS } from './commons/constant';

import * as Styled from './styled';
import ItemRegisterModal from './components/ItemRegisterModal';
import EditableCell from './components/EditableCell';
import {
  getIsSelectedAddedItemList,
  getSelectedItemList,
  handleSelectedListRemove,
} from './commons/helper';

const ItemAndPriceInfoForm = ({
  totalInfo,
  itemList,
  vendorId,
  setSupplyRequestDetail,
  statusCode,
  taxSettlement,
}) => {
  const [isItemRegisterModalOpen, setIsItemRegisterModalOpen] = useState(false);
  const [isSelectedAddedItemList, setIsSelectedAddedItemList] = useState([]);

  const tableOptions = {
    defaultColumn: {
      Cell: EditableCell,
    },
    updateMyData: updateMyDataMaker(setSupplyRequestDetail, {
      properties: ['itemList'],
    }),
    statusCode,
    taxSettlement,
  };

  const handleItemRegisterClick = () => {
    if (!vendorId) {
      toastify('거래처를 먼저 등록해 주세요.', { variant: 'warn' });
      return;
    }
    setIsItemRegisterModalOpen(true);
  };
  const closeItemRegisterModal = () => setIsItemRegisterModalOpen(false);

  const handleSelectChange = ({ flatRows }) => {
    setIsSelectedAddedItemList(getIsSelectedAddedItemList(flatRows));
  };

  const handleItemRemoveClick = () => {
    if (isEmpty(getSelectedItemList(isSelectedAddedItemList))) {
      toastify('선택된 품목이 존재하지 않습니다.', { variant: 'warn' });
      return;
    }

    setSupplyRequestDetail((prev) =>
      updateObject(prev, 'itemList', () => handleSelectedListRemove(isSelectedAddedItemList))
    );
  };

  const isDisabledStatus = (currentStatusCode) =>
    currentStatusCode === 'VENDOR_REQUEST' ||
    currentStatusCode === 'PETFRIENDS_CONFIRM' ||
    currentStatusCode === 'CANCEL' ||
    currentStatusCode === 'CONFIRM_CANCEL';

  const renderCell = (cell) => thousandSeparator(cell.value) || 0;

  const filterTableRows = (rows) => rows.filter(({ original }) => original.active !== 'N');

  return (
    <>
      <Card
        title='품목 정보 및 금액 정보'
        label='품목 등록'
        onClick={handleItemRegisterClick}
        buttonDisabled={isDisabledStatus(statusCode)}
      >
        <Flex $flexDirection='column' $gap='var(--space-m)'>
          <Styled.TotalTableWrapper>
            <Table
              columns={TOTAL_COLUMNS}
              data={[totalInfo]}
              $height='100px'
              renderCell={renderCell}
            />
          </Styled.TotalTableWrapper>
          <Flex $justifyContent='flex-end'>
            <Button
              $variant='secondary'
              onClick={handleItemRemoveClick}
              disabled={isDisabledStatus(statusCode)}
            >
              대상 품목 삭제
            </Button>
          </Flex>
          <Styled.ItemTableWrapper>
            <Table
              columns={ITEM_COLUMNS}
              data={itemList}
              tableOptions={tableOptions}
              pluginHooks={ROW_SELECT_PLUGIN_HOOKS}
              onSelectChange={handleSelectChange}
              filterRows={filterTableRows}
            />
          </Styled.ItemTableWrapper>
        </Flex>
      </Card>
      <ItemRegisterModal
        isOpen={isItemRegisterModalOpen}
        closeCallback={closeItemRegisterModal}
        itemList={itemList}
        setSupplyRequestDetail={setSupplyRequestDetail}
        vendorId={vendorId}
      />
    </>
  );
};

ItemAndPriceInfoForm.propTypes = {
  totalInfo: PropTypes.shape({
    totalAvailableReceiveQuantity: PropTypes.number,
    totalItemKindCount: PropTypes.number,
    totalItemQuantity: PropTypes.number,
    totalPrice: PropTypes.number,
    totalReceivedPrice: PropTypes.number,
  }),
  itemList: PropTypes.arrayOf(PropTypes.object),
  vendorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setSupplyRequestDetail: PropTypes.func.isRequired,
  statusCode: PropTypes.oneOf([
    'PETFRIENDS_DRAFT',
    'PETFRIENDS_REGISTER',
    'MODIFY',
    'VENDOR_REQUEST',
    'PETFRIENDS_CONFIRM',
    'CONFIRM_CANCEL',
    'CANCEL',
    '',
  ]),
  taxSettlement: PropTypes.oneOf(['Y', 'N']),
};

export default ItemAndPriceInfoForm;
