/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getCheckCapacityValidation } from 'pages/admin/AdminDashboard/apis';
import { getTokens, setTokens } from 'commons/token';

const initialState = () => {
  const [accessToken, refreshToken] = getTokens(['accessToken', 'refreshToken']);

  return {
    userInfo: {
      accessToken,
      refreshToken,
    },
    checkCapacity: false,
  };
};
export const getCheckCapacityValidationThunk = createAsyncThunk(
  'user/check-capacity',
  async (_, { rejectWithValue }) => {
    try {
      const { response } = await getCheckCapacityValidation();

      return {
        checkCapacity: response?.data ?? false,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
const userSlice = createSlice({
  name: 'user',
  initialState: initialState(),
  reducers: {
    setUser: (state, action) => {
      setTokens({ ...action.payload });

      state.userInfo = action.payload;
    },
    setCheckCapacity: (state, action) => {
      state.checkCapacity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCheckCapacityValidationThunk.fulfilled, (state, { payload }) => {
      return {
        ...state,
        checkCapacity: payload.checkCapacity,
      };
    });
    builder.addCase(getCheckCapacityValidationThunk.rejected, (state) => {
      return state;
    });
  },
});

export const { setUser, setCheckCapacity } = userSlice.actions;

export default userSlice.reducer;
