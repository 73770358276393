import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { getSettlementList } from 'service/vendor/settlement';

import { thousandSeparator, excelDownload, getHash } from 'commons/helper';

import {
  fetchSettlementDetail,
  fetchSettlementList,
} from 'commons/store/modules/vendor/settlement';

import { useDispatchModals } from 'hooks/pages';

import PaginationTable from 'components/Table/PaginationTable';
import Card from 'components/Card';
import Button from 'components/Button';

import {
  SETTLEMENT_LIST_TABLE_COLUMNS,
  MAPPING_KEYS,
  TABLE_OPTIONS,
  SETTLEMENT_LIST,
} from '../../commons/constant';
import { translateSettlementList } from '../../commons/helper';

import Styled from './styled';

const TableContainer = ({ match: { path } }) => {
  const dispatch = useDispatch();
  const setModalOpen = useDispatchModals(path);
  const { searchFilter, tableData, totalElements, totalPages } = useSelector(
    (state) => state.vendorSettlement[SETTLEMENT_LIST]
  );
  const [pageIndex, setPageIndex] = useState(0);

  const handleExcelDownloadClick = async () => {
    excelDownload(
      getSettlementList,
      tableData,
      searchFilter,
      MAPPING_KEYS,
      translateSettlementList
    );
  };

  const handlePageChange = ({ state }) => {
    const params = {
      ...searchFilter,
      pageNumber: state.pageIndex,
    };
    dispatch(fetchSettlementList(params));
  };

  const handleDetailModalOpen = (selectedSettlementId) => {
    dispatch(
      fetchSettlementDetail({
        path,
        selectedSettlementId,
      })
    );
    setModalOpen('detail', true);
  };

  const renderCell = ({ column, value, row }) => {
    const { id, Header } = column;

    if (id === 'requestIds' || id === 'reverseNumbers') {
      return value.map((val) => <div key={val}>{val}</div>);
    }

    if (id === 'totalPrepaidPrice' && value === null) {
      return '0';
    }

    return {
      [Header.match(/₩/) && id]: thousandSeparator(value) || 0,
      [getHash(id, value) && id]: getHash(id, value),
      settlementId: (
        <button
          className='btn-link'
          type='button'
          onClick={() => handleDetailModalOpen(row.original.settlementId)}
        >
          {value}
        </button>
      ),
    }[id];
  };

  const resultHeaderButtons = [
    <Button $variant='success' onClick={handleExcelDownloadClick}>
      엑셀 다운로드
    </Button>,
  ];

  return (
    <Card title='정산 내역'>
      <Styled.ResultHeader totalCount={totalElements || 0} buttons={resultHeaderButtons} />
      <PaginationTable
        columns={SETTLEMENT_LIST_TABLE_COLUMNS}
        data={tableData || []}
        setControlledPage={setPageIndex}
        onPageChange={handlePageChange}
        renderCell={renderCell}
        tableOptions={{
          ...TABLE_OPTIONS,
          pageCount: totalPages || 0,
          useControlledState: (state) => ({
            ...state,
            pageIndex,
          }),
        }}
      />
    </Card>
  );
};

export default TableContainer;

TableContainer.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
