import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import amendmentRequestApi from 'service/hq/amendmentRequest';
import toastify from 'commons/toast';
import { setSelectedAmendmentRequest } from 'commons/store/modules/admin/amendmentRequest';

import { handleError, updateMyDataMaker } from 'commons/helper';
import ResultHeader from 'components/ResultHeader';
import Table from 'components/Table';
import Button from 'components/Button';

import Styled from './styled';
import EditableCell from '../../components/EditableCell';
import { getProductSupplyItemMasterRequestItem } from '../../commons/helper';

/**
 * @param {*} props
 */
const ProductPriceTableContainer = ({
  columns,
  productList,
  setRequestDetail,
  statusValue,
  requestInfo,
  requestId,
}) => {
  const dispatch = useDispatch();

  const tableOptions = {
    defaultColumn: {
      Cell: EditableCell,
    },
    updateMyData: updateMyDataMaker(setRequestDetail, {
      properties: ['data', 'requestDetailList'],
    }),
    statusValue,
  };

  const handleModalClose = () =>
    dispatch(
      setSelectedAmendmentRequest({
        code: undefined,
        id: undefined,
      })
    );

  const handleApproveClick = async () => {
    try {
      const response = await amendmentRequestApi.updateProductPriceAmendmentRequestStatus({
        productSupplyPriceRequestId: requestId,
        memo: requestInfo.memo,
        productSupplyRequestItem: getProductSupplyItemMasterRequestItem(productList),
        status: 'approval',
      });
      if (response) {
        toastify('승인되었습니다.', { variant: 'success' });
        handleModalClose();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleRejectClick = async () => {
    try {
      const response = await amendmentRequestApi.updateProductPriceAmendmentRequestStatus({
        productSupplyPriceRequestId: requestId,
        memo: requestInfo.memo,
        productSupplyRequestItem: getProductSupplyItemMasterRequestItem(productList),
        status: 'reject',
      });
      if (response) {
        toastify('반려되었습니다.', { variant: 'success' });
        handleModalClose();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const buttons = statusValue === '요청 중' && [
    <Button onClick={handleApproveClick}>승인</Button>,
    <Button $variant='light' onClick={handleRejectClick}>
      반려
    </Button>,
  ];

  return (
    <Styled.Div>
      <ResultHeader totalCount={productList.length} buttons={buttons || []} />
      <Table columns={columns} data={productList} tableOptions={tableOptions} />
    </Styled.Div>
  );
};

ProductPriceTableContainer.propTypes = {
  productList: PropTypes.arrayOf(PropTypes.object),
  setRequestDetail: PropTypes.func.isRequired,
  statusValue: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  requestInfo: PropTypes.shape({
    vendorId: PropTypes.number,
    displayName: PropTypes.string,
    requestId: PropTypes.number,
    createdAt: PropTypes.string,
    requestAt: PropTypes.string,
    approveAt: PropTypes.string,
    updatedAt: PropTypes.string,
    statusValue: PropTypes.string,
    memo: PropTypes.string,
  }).isRequired,
  requestId: PropTypes.number,
};

export default ProductPriceTableContainer;
