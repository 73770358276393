export const isListLinkButtonDisabled = (statusCode) =>
  statusCode === 'PETFRIENDS_REGISTER' ||
  statusCode === 'MODIFY' ||
  statusCode === 'VENDOR_REQUEST' ||
  statusCode === 'CANCEL' ||
  statusCode === 'CONFIRM_CANCEL';

export const isSaveButtonEnabled = (statusCode) =>
  statusCode !== 'CANCEL' && statusCode !== 'VENDOR_REQUEST' && statusCode !== 'CONFIRM_CANCEL';

export const isConfirmButtonDisabled = (statusCode) =>
  statusCode === 'PETFRIENDS_CONFIRM' ||
  statusCode === 'CANCEL' ||
  statusCode === 'VENDOR_REQUEST' ||
  statusCode === 'CONFIRM_CANCEL';
