import PropTypes from 'prop-types';

import { Flex } from 'styles/components';

import LabelAndText from 'components/LabelAndText';

const RequestInfo = ({ requestInfo }) => {
  const {
    productSupplyPriceRequestId,
    productSupplyItemMasterRequestId,
    createdAt,
    requestAt,
    approveAt,
  } = requestInfo;

  return (
    <Flex $gap='var(--space-l)'>
      <LabelAndText
        label='요청ID'
        text={productSupplyPriceRequestId || productSupplyItemMasterRequestId}
      />
      <LabelAndText label='등록일시' text={createdAt} />
      <LabelAndText label='요청일시' text={requestAt} />
      <LabelAndText label='승인일시' text={approveAt} />
    </Flex>
  );
};

RequestInfo.propTypes = {
  requestInfo: PropTypes.shape({
    productSupplyPriceRequestId: PropTypes.number,
    productSupplyItemMasterRequestId: PropTypes.number,
    createdAt: PropTypes.string,
    requestAt: PropTypes.string,
    approveAt: PropTypes.string,
  }),
};

export default RequestInfo;
