import { Cookie, jwtDecode } from '@teampetfriends/utils';
import { LOGIN_PAGE, TARGET_ACCESS_TOKEN, TARGET_REFRESH_TOKEN } from 'service/commons/constant';

export const TOKENS = {
  accessToken: `${TARGET_ACCESS_TOKEN}-${import.meta.env.VITE_SERVICE_ENV}`,
  refreshToken: `${TARGET_REFRESH_TOKEN}-${import.meta.env.VITE_SERVICE_ENV}`,
};

export const redirectToLoginPage = () => {
  window.location.href = LOGIN_PAGE;
  return false;
};

export const getTokens = (tokens) => {
  return tokens.map((token) => {
    const target = TOKENS[token];
    const currentCookie = Cookie.getCookie(target);

    if (!currentCookie) return null;
    return currentCookie;
  });
};

export const setTokens = (tokens) => {
  const isLocalhost = window.location.host.includes('localhost');
  const domain = isLocalhost ? 'localhost' : '.pet-friends.co.kr';

  Object.entries(tokens).forEach(([key, value]) => {
    const tokenKey = TOKENS[key];
    if (value) Cookie.setCookie(tokenKey, value, { domain });
  });
};

export const removeTokens = () => {
  const isLocalhost = window.location.host.includes('localhost');
  const domain = isLocalhost ? 'localhost' : '.pet-friends.co.kr';

  Object.values(TOKENS).forEach((key) => {
    Cookie.removeCookie(key, { domain });
  });
};

export const getTokenData = () => {
  try {
    const [currentCookie] = getTokens(['accessToken']);

    if (!currentCookie) return null;

    return jwtDecode(currentCookie);
  } catch (err) {
    removeTokens();
    return null;
  }
};
