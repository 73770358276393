import instance from 'service/interceptor';

/**
 *
 * @param {yearMonth, centerId} "2023-01", "1"
 */
export const postPurchaseOrderCalendar = async (params) => {
  const { data } = await instance.post('/api/v2/hq/purchase-order-calendars', params);

  return data;
};

/**
 *
 * @param {searchDate, centerId} "2022-12-12", "1"
 * @returns
 */
export const postPurchaseOrderCalendarDaily = async (params) => {
  const { data } = await instance.post('/api/v2/hq/purchase-order-calendars/daily', params);

  return data;
};

/**
 * 발주금액, 입고금액, 입고정확도
 * @param {yearMonth, centerId} "2022-02", "1"
 * @returns
 */
export const postPurchaseOrderOverview = (params) => {
  return instance.post('/api/v2/hq/purchase-order-overviews', params);
};

/**
 * 선지급금 잔액
 * @param {yearMonth, centerId} "2022-02", "1"
 * @returns
 */
export const getPrepaidOverview = (params) => {
  return instance.get('/api/v2/hq/prepaid-overviews', params);
};

/**
 * 정산금액
 * @param {yearMonth, centerId} "2022-02", "1"
 * @returns
 */
export const postSettlementOverview = ({ yearMonth }) => {
  return instance.post('/api/v2/hq/settlement-overviews', { yearMonth });
};

export const getRequestOverview = (params) => {
  return instance.get('/api/v2/hq/request-overviews', params);
};

/**
 *
 * @param {yearMonth, centerId}"2022-12-12", "1"
 */
export const getOverviewData = async (params) => {
  const result = await Promise.allSettled([
    postPurchaseOrderOverview(params),
    getPrepaidOverview(params),
    postSettlementOverview(params),
    getRequestOverview(params),
  ]);

  const data = result.reduce((acc, cur) => {
    const { value } = cur;
    const { data } = value;
    const { data: item } = data?.response;

    return { ...acc, ...item };
  }, {});

  return data;
};

/**
 *
 * @param {searchDate, centerId} "2022-12-12", "1"
 * @returns
 */
export const postReceiveScheduled = async (params) => {
  const { data } = await instance.post(
    '/api/v2/hq/purchase-order-calendars/receive-scheduled',
    params
  );

  return data;
};

export const getCenterDefaultSku = async () => {
  const { data } = await instance.get('api/v2/hq/capacities/default');

  return data;
};

/**
 *
 * @param {centerId, skuCapacity} 1, 300
 */
export const postCenterDefaultSku = async (params) => {
  const { data } = await instance.post('/api/v2/hq/capacities/default', params);

  return data;
};

export const getCenterDailySku = async () => {
  const { data } = await instance.get('/api/v2/hq/capacities/daily');

  return data;
};

/**
 *
 * @param {centerId, referenceDate, skuCapacity, note} 12, "2023-06-30", 1, "음음"
 * @returns
 */
export const postCenterDailySku = async (params) => {
  const { data } = await instance.post('/api/v2/hq/capacities/daily', params);
  return data;
};

export const getCheckCapacityValidation = async () => {
  const { data } = await instance.get('/api/v2/hq/admin-users/check-capacity');

  return data;
};
