import styled from 'styled-components';

export const Wrapper = styled.div`
  thead {
    tr:nth-child(2) {
      th:nth-child(n + 20) {
        text-align: right;
      }
      th:nth-child(14) {
        text-align: right;
      }
    }
  }
  tbody {
    td:nth-child(n + 20) {
      text-align: right;
    }
    td:nth-child(14) {
      text-align: right;
    }
  }
`;

export default { Wrapper };
