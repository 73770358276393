/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useState, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import ArrowBlackImg from 'icons/png/arrow_black.png';
import { getYearMonthList } from '../../helper';

const SelectWrapper = styled.div`
  position: relative;
`;

const SelectButton = styled.button`
  display: flex;
  align-items: center;
  height: 40px;
  column-gap: 12px;

  & > p {
    font-size: 26px;
    font-weight: 500;
    margin: 0;
  }

  & > div {
    width: 12px;
    transition: transform 0.6s;

    &.tornado {
      transform: rotate(180deg);
    }

    & img {
      width: 100%;
    }
  }
`;

const OptionList = styled.ul`
  display: ${(props) => (props.open ? 'initial' : 'none')};
  position: absolute;
  top: 42px;
  height: 150px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #dbdde2;
  left: 0;
  right: -10px;
  z-index: 100;
  padding: 10px 0px;
  overflow-y: scroll;
  cursor: pointer;

  & > li {
    list-style: none;
    font-size: 14px;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;

    &:hover {
      background: #cce5ff;
    }

    &.active {
      color: #0066cc;
      background: #cce5ff;
    }
  }
`;

function CalendarSelect({ value, onChange }) {
  const select = useRef(null);
  const option = useRef(null);
  const optionItem = useRef([]);
  const [open, setOpen] = useState(false);
  const optionsList = useMemo(() => getYearMonthList(new Date(), 12), []);

  const openHandler = () => {
    setTimeout(() => {
      setOpen((prev) => !prev);
      if (value === dayjs(new Date()).format('YYYY-MM')) {
        option.current.scrollTop = 310;
      }
    }, [100]);
  };

  const onDateClickHandler = (value, index) => {
    onChange(value);
    setOpen(() => false);
    option.current.scrollTop = optionItem.current[index].offsetTop - 60;
  };

  useEffect(() => {
    function outClickHandler(e) {
      if (open && !select.current.contains(e.target)) {
        setOpen(() => false);
      }
    }

    if (open) {
      document.addEventListener('click', outClickHandler);
    }

    return () => {
      if (open) document.removeEventListener('click', outClickHandler);
    };
  }, [open]);

  return (
    <SelectWrapper ref={select}>
      <SelectButton type='button' onClick={openHandler}>
        <p>{value}</p>
        <div className={open ? 'tornado' : ''}>
          <img src={ArrowBlackImg} alt='화살표' />
        </div>
      </SelectButton>
      <OptionList ref={option} open={open}>
        {optionsList.map((item, index) => (
          <li
            key={`${item}-options`}
            ref={(el) => (optionItem.current[index] = el)}
            className={item === value ? 'active' : ''}
            onClick={() => onDateClickHandler(item, index)}
          >
            {item}
          </li>
        ))}
      </OptionList>
    </SelectWrapper>
  );
}

export default CalendarSelect;
