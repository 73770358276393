import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { thousandSeparator } from 'commons/helper';
import { getPrepaidList, setPageIndex } from 'commons/store/modules/admin/prepaid';
import { usePageModals } from 'hooks/pages';

import Button from 'components/Button';
import ResultHeader from 'components/ResultHeader';
import PaginationTable from 'components/Table/PaginationTable';

import Styled from './styled';
import { COLUMNS } from '../../commons/constant';

const ResultContainer = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { 1: setModalOpen } = usePageModals();

  const { tableData, totalElements, totalPages, pageIndex, searchFilter } = useSelector(
    (state) => state.prepaid[pathname]
  );

  const tableOptions = {
    initialState: { pageIndex: 0, pageSize: 50 },
    manualPagination: true,
    pageCount: totalPages,
    useControlledState: (state) => ({
      ...state,
      pageIndex,
    }),
  };

  const handlePrepaidRegisterClick = () => setModalOpen('detail');
  const handleTableRowClick = ({ values }) =>
    history.push(`/admin/prepaid/detail/${values.vendorId}`);

  const resultHeaderButtons = [
    <Button $variant='secondary' onClick={handlePrepaidRegisterClick}>
      선지급금 등록/회수
    </Button>,
  ];

  const setControlledPage = (newPageIndex) =>
    dispatch(setPageIndex({ path: pathname, pageIndex: newPageIndex }));

  const handlePageChange = ({ state }) =>
    dispatch(getPrepaidList({ ...searchFilter, pageNumber: state.pageIndex }));

  const renderCell = ({ column, value }) => {
    if (column.id === 'totalPrice') {
      return <Styled.Underline>{thousandSeparator(value) || 0}</Styled.Underline>;
    }
    return value;
  };

  return (
    <>
      <ResultHeader totalCount={totalElements} buttons={resultHeaderButtons} />
      <PaginationTable
        columns={COLUMNS}
        data={tableData}
        onPageChange={handlePageChange}
        tableOptions={tableOptions}
        onRowClick={handleTableRowClick}
        setControlledPage={setControlledPage}
        renderCell={renderCell}
      />
    </>
  );
};

export default ResultContainer;
