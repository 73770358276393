import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setSelectedAmendmentRequest } from 'commons/store/modules/admin/amendmentRequest';
import amendmentRequestApi from 'service/hq/amendmentRequest';
import useFetch from 'hooks/useFetch';

import HeaderModal from 'components/modals/HeaderModal';
import RequestInfoContainer from '../../containers/RequestInfoContainer';
import ProductMasterTableContainer from '../../containers/ProductMasterTableContainer';

import { PRODUCT_MASTER_TABLE_COLUMNS } from '../../commons/constant';

const ProductMasterModal = () => {
  const {
    data: { requestInfoDto = {}, requestDetailList = [] } = {},
    execute: getProductMasterAmendmentRequestDetail,
    setState: setProductMasterAmendmentRequestDetail,
  } = useFetch(amendmentRequestApi.getProductMasterAmendmentRequestDetail, {
    initialExecute: false,
  });
  const dispatch = useDispatch();
  const { selectedAmendmentRequest } = useSelector((state) => state.amendmentRequest);

  const isProductMasterModalOpen = selectedAmendmentRequest.code === 'PRODUCT_MASTER';

  useEffect(() => {
    if (isProductMasterModalOpen) {
      getProductMasterAmendmentRequestDetail(selectedAmendmentRequest.id);
    }
  }, [isProductMasterModalOpen]);

  const handleModalClose = () =>
    dispatch(
      setSelectedAmendmentRequest({
        code: null,
        id: null,
      })
    );

  return (
    <HeaderModal
      isOpen={isProductMasterModalOpen}
      closeCallback={handleModalClose}
      title='품목 정보 수정 요청'
      $size='xl'
    >
      <RequestInfoContainer
        requestInfo={requestInfoDto}
        setRequestDetail={setProductMasterAmendmentRequestDetail}
      />
      <ProductMasterTableContainer
        columns={PRODUCT_MASTER_TABLE_COLUMNS}
        requestInfo={requestInfoDto}
        productList={requestDetailList}
        setRequestDetail={setProductMasterAmendmentRequestDetail}
        statusValue={requestInfoDto.statusValue}
        requestId={selectedAmendmentRequest.id}
      />
    </HeaderModal>
  );
};

export default ProductMasterModal;
