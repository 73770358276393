import styled from 'styled-components';
import _Input from 'components/Input';

import { PRICE_COLORS } from './commons/constant';

export const Input = styled(_Input)`
  color: ${({ $actionCode }) => PRICE_COLORS[$actionCode] || 'black'};
`;

export default { Input };
