import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: ${({ $marginBottom }) => $marginBottom || 'var(--space-l)'};
`;

export const InputWrapper = styled.div`
  position: relative;

  button {
    display: grid;
    place-items: center;
    position: absolute;
    top: 14px;
    right: 5px;
    background-color: var(--grey700);
    color: #fff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    font-size: 12px;
    line-height: 1;
  }
`;

export const Label = styled.label`
  line-height: 30px;
  text-align: center;
  margin-bottom: 0;
  cursor: pointer;
  padding: var(--space-m);
  border-radius: var(--radius-m);
  background-color: var(--grey200);
`;

export default { Container, InputWrapper, Label };
