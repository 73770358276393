import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { useGlobalModals } from 'hooks/pages';
import useFetch from 'hooks/useFetch';

import { fetchSettlementList } from 'commons/store/modules/admin/settlement';

import { setInitDataList } from '../../containers/TaxInvoiceContainer/helper';

import { useSum } from '../../containers/FinalSettlementInfoContainer/useSum';

export const useSettlementDetail = ({ isOpen = false, onClose, listApiFunc, submitApiFunc }) => {
  const { path } = useRouteMatch();
  const [, setModalOpen] = useGlobalModals();
  const { selectedDatas } = useSelector((state) => state.settlement[path]);
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const { data, execute, isLoading, setState, error } = useFetch(listApiFunc, {
    initialExecute: false,
  });
  const { execute: finalExecute } = useFetch(submitApiFunc, {
    initialExecute: false,
  });

  const addedTaxInvoiceListObj = useRef({});

  const finalSum = useSum(
    data && Object.keys(data).length ? data[index] : {},
    Object.keys(addedTaxInvoiceListObj.current).length ? addedTaxInvoiceListObj.current[index] : []
  );

  useEffect(() => {
    if (isOpen) {
      execute({
        requestIdDiscountRateList: selectedDatas.map((x) => ({
          requestId: x.productSupplyRequestId,
          discountRate: 0,
        })),
        reverseIds: selectedDatas.map((x) => x.reverseId).filter((it) => it),
      });
    }
  }, [isOpen]);

  const handleStateChange = (key) => (list) => {
    const newList = [...data];
    newList[index] = { ...newList[index], [key]: list };

    if (key === 'requestInfoList') newList[index].isDiscountApply = 'N';

    if (key === 'taxInvoiceInfoList') {
      addedTaxInvoiceListObj.current = {
        ...addedTaxInvoiceListObj.current,
        [index]: list,
      };
    }

    setState({ data: newList });
  };

  const convertThousandPointStrToNum = (target) => {
    return typeof target === 'string' ? Number(target.replace(/,/g, '')) : target;
  };

  const afterSaveAction = () => {
    if (data[index + 1] !== undefined) {
      setModalOpen('alert', true, {
        title: `저장 버튼 클릭 - 알림 Message`,
        content: '정산이 등록되었습니다. \n 다음페이지로 넘어갑니다.',
      });
      setIndex(index + 1);
      setDisabled(() => false);
      document.querySelector('.modal-content').scrollTo(0, 0);
    } else {
      setModalOpen('alert', true, {
        title: `저장 버튼 클릭 - 알림 Message`,
        content: '정산이 등록되었습니다.',
      });
      onClose();
    }
  };

  const handleFinalExecute = async () => {
    const params = data[index];
    if (params.isDiscountApply === 'Y') {
      const taxInvoiceInfoList = setInitDataList(
        params.taxInvoiceInfoList,
        addedTaxInvoiceListObj.current[index]
      ).map(({ settlementPrice, supplyCostTotal, taxTotal, ...items }) => ({
        ...items,
        settlementPrice: convertThousandPointStrToNum(settlementPrice),
        supplyCostTotal: convertThousandPointStrToNum(supplyCostTotal),
        taxTotal: convertThousandPointStrToNum(taxTotal),
      }));

      const settlementInfo = {
        ...params.settlementInfo,
        otherCosts: finalSum.기타비용합계,
        taxTotal: finalSum.세액합계,
        supplyCostTotal: finalSum.공급가액합계,
        settlementPrice: finalSum.최종정산금액합계,
      };

      setDisabled(() => true);

      const { data: _data, error } = await finalExecute({
        vendorId: params.vendorInfo.vendorId,
        requestInfoList: params.requestInfoList,
        reverses: params.reverses,
        settlementInfo,
        taxInvoiceInfoList,
      });
      if (_data) {
        handleStateChange('productSupplySettlementId')(_data.productSupplySettlementId);
        dispatch(fetchSettlementList());
        afterSaveAction();
      } else if (error) {
        setModalOpen('alert', true, {
          title: `에러 ${error.response.status}`,
          content: error.message,
        });
        setDisabled(() => false);
      }
    } else {
      setModalOpen('alert', true, {
        content: '할인 적용 계산을 눌러주세요!',
      });
      setDisabled(() => false);
    }
  };

  const handleClose = () => {
    onClose();
    const isChanged = data?.filter(
      ({ productSupplySettlementId }) => productSupplySettlementId
    ).length;
    if (isChanged) dispatch(fetchSettlementList());
  };

  return {
    // list state
    data,
    error,
    prevTaxInvoiceListData: addedTaxInvoiceListObj.current,
    execute,
    isLoading,
    setState,
    // index state
    index,
    setIndex,
    // handler
    handleStateChange,
    handleFinalExecute,
    handleClose,
    disabled,
  };
};

export default { useSettlementDetail };
