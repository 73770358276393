import { NumberCell } from './components/Cells';

export const INITIAL_STATE = {
  status: '',
  reason: '',
  centerId: '',
  createdDay: [null, null],
  completeDay: [null, null],
  searchWord: '',
  searchCondition: 'PURCHASE_ORDER',
};

export const EXCEL_MAPPING_KEYS = {
  reverseNo: '반품번호',
  reverseStatus: '반품상태',
  reverseReason: '반품사유',
  purchaseOrderId: '발주번호',
  inboundWikey: '입고번호',
  centerName: '출고센터',
  wokey: '출고번호',
  availableDay: '출고예정일',
  completeDay: '출고완료일',
  productItemId: '품목코드',
  productName: '품목명',
  reverseAvailableQuantity: '출고예정수량',
  reversedQuantity: '출고결과수량',
  unreversedQuantity: '미출고수량',
  reversedPrice: '출고결과금액(₩)',
  unreversedPrice: '미출고금액(₩)',
};

export const MAIN_COLUMNS = [
  {
    Header: '반품정보',
    columns: [
      {
        Header: '반품번호',
        accessor: 'reverseNo',
      },
      {
        Header: '반품상태',
        accessor: 'reverseStatus',
      },
      {
        Header: '반품사유',
        accessor: 'reverseReason',
      },
      {
        Header: '발주번호',
        accessor: 'purchaseOrderId',
      },
      { Header: '입고번호', accessor: 'inboundWikey' },
    ],
  },
  {
    Header: '출고번호',
    columns: [
      {
        Header: '출고센터',
        accessor: 'centerName',
      },
      {
        Header: '출고번호',
        accessor: 'wokey',
      },
      {
        Header: '출고예정일',
        accessor: 'availableDay',
      },
      {
        Header: '출고완료일',
        accessor: 'completeDay',
      },
      {
        Header: '품목코드',
        accessor: 'productItemId',
      },
      {
        Header: '품목명',
        accessor: 'productName',
      },
      {
        Header: '출고예정수량',
        accessor: 'reverseAvailableQuantity',
        Cell: NumberCell,
      },
      {
        Header: '출고결과수량',
        accessor: 'reversedQuantity',
        Cell: NumberCell,
      },
      {
        Header: '미출고수량',
        accessor: 'unreversedQuantity',
        Cell: NumberCell,
      },
      {
        Header: '출고결과금액(₩)',
        accessor: 'reversedPrice',
        Cell: NumberCell,
      },
      {
        Header: '미출고금액(₩)',
        accessor: 'unreversedPrice',
        Cell: NumberCell,
      },
    ],
  },
];

export const DETAIL_COLUMNS = [
  {
    Header: '품목코드',
    accessor: 'productItemId',
  },
  {
    Header: '품목유형',
    accessor: 'itemType',
  },
  {
    Header: '품목명',
    accessor: 'productName',
  },
  {
    Header: '과세구분',
    accessor: 'taxationType',
  },
  {
    Header: '적용공급가',
    accessor: 'purchaseOrderUnitPrice',
    Cell: NumberCell,
  },
  {
    Header: '발주수량',
    accessor: 'requestQuantity',
    Cell: NumberCell,
  },
  {
    Header: '발주금액',
    accessor: 'requestPrice',
    Cell: NumberCell,
  },
  {
    Header: '입고수량',
    accessor: 'receivedQuantity',
    Cell: NumberCell,
  },
  {
    Header: '입고금액',
    accessor: 'receivedPrice',
    Cell: NumberCell,
  },
];
