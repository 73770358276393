import { REQUEST_STATUS } from 'commons/constant';
import Underline from 'components/Underline';

export const IS_OPEN_INITIAL_VALUES = {
  requestsModal: false,
  requestModifyModal: false,
};

export const REQUEST_INITIAL_VALUES = {
  id: null,
  statusCode: null,
};

export const COLUMNS = [
  {
    Header: '상태',
    accessor: 'status',
    Cell: ({ value }) => REQUEST_STATUS[value],
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: Underline,
  },
  {
    Header: '등록 일시',
    accessor: 'createdAt',
  },
  {
    Header: '승인 일시',
    accessor: 'updatedAt',
  },
  {
    Header: '처리자 (펫프 담당자)',
    accessor: 'workerInfo',
  },
];

export default { IS_OPEN_INITIAL_VALUES, REQUEST_INITIAL_VALUES, COLUMNS };
