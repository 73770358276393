import styled from 'styled-components';
import { useGlobalModals } from 'hooks/pages';

import indicator from 'icons/gif/cupertino_activity_indicator.gif';

import Modal from '../Modal';

const ActivityIndicatorImg = styled.img`
  width: 60px;
  height: 60px;
`;

const ActivityIndicatorModal = () => {
  const [{ activityIndicator }] = useGlobalModals(false);
  const { isOpen } = activityIndicator;

  return (
    <Modal id='activityIndicator' isOpen={isOpen}>
      <ActivityIndicatorImg src={indicator} />
    </Modal>
  );
};

export default ActivityIndicatorModal;
