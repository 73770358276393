import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import supply from 'service/supply/supply';

const initialState = {
  prevVendorSupplyValue: { supplyId: null },
  centerList: [],
};

export const getScCenterList = createAsyncThunk(
  'common/sc/centers',
  async (_, { rejectWithValue }) => {
    try {
      const { response } = await supply.getScCenterList();
      return {
        centerList: response?.data ?? [],
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const slice = createSlice({
  name: 'vendorSupply',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getScCenterList.fulfilled, (state, { payload }) => {
      return { ...state, centerList: payload.centerList };
    });
    builder.addCase(getScCenterList.rejected, (state) => state);
  },
  reducers: {
    setPrevVendorSupplyValue: (state, { payload }) => {
      state.prevVendorSupplyValue = payload;
    },
  },
});

export const { setPrevVendorSupplyValue } = slice.actions;

export default slice.reducer;
