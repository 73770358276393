import styled, { css } from 'styled-components';

const TOOLTIP_STYLES = {
  right: { left: 0 },
  left: { right: 0 },
  center: { left: '-140px', transform: 'translateX(10px)' },
};

export const Wrapper = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  line-height: normal;
  text-align: center;
  border-radius: 50%;
  color: white;
  background-color: var(--grey900);
  cursor: pointer;
  margin: 0 var(--space-s);
  box-sizing: border-box;
  border: 1px solid white;

  :hover {
    color: var(--grey900);
    background-color: white;
    border: 1px solid var(--grey900);
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  top: ${({ $height }) => `-${$height + 15}px`};
  width: 280px;

  color: var(--grey900);
  font-size: var(--font-size-xs);
  text-align: start;
  background-color: white;
  border-radius: var(--radius-m);
  border: 1px solid var(--grey300);
  padding: var(--space-m);
  z-index: 3;

  ${({ $position }) => {
    return css`
      ${TOOLTIP_STYLES[$position]}
    `;
  }}
`;

export default { Wrapper, Tooltip };
