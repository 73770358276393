import styled from 'styled-components';

export const Wrapper = styled.form`
  > button {
    margin-left: calc(var(--space-l) / 2);
    margin-bottom: var(--space-l);
  }
`;

export default { Wrapper };
