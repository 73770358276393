import { isEmpty } from 'commons/helper';

export const isConfirmButtonDisabled = (selectedSupplyRequestList) =>
  isEmpty(selectedSupplyRequestList) ||
  !selectedSupplyRequestList.every(({ statusCodeName }) => statusCodeName === '발주수정중');

export const getSupplyRequestListParams = (options, pageIndex) => ({
  productSupplyTypeCode: options.productSupplyTypeCode,

  beforeProductSupplyRequestDay: options.productSupplyRequestDay[0],
  afterProductSupplyRequestDay: options.productSupplyRequestDay[1],
  beforeReceiveAvailableDay: options.receiveAvailableDay[0],
  afterReceiveAvailableDay: options.receiveAvailableDay[1],

  statusCodes: options.statusCodes,
  searchOption: options.condition,
  searchWord: options.query,

  pageIndex,
});

export default { isConfirmButtonDisabled };
