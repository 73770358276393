import Card from 'components/Card';

import SearchContainer from './containers/SearchContainer';
import ResultContainer from './containers/ResultContainer';

const AdminNoticeListPage = () => {
  return (
    <Card title='공지사항 관리'>
      <SearchContainer />
      <ResultContainer />
    </Card>
  );
};

export default AdminNoticeListPage;
