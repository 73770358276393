import { useDispatch } from 'react-redux';

import { Flex } from 'styles/components';

import useStateKey from 'hooks/useStateKey';
import useSelectOptions from 'hooks/useSelectOptions';

import { addAllOption } from 'commons/helper';
import { fetchSettlementList } from 'commons/store/modules/admin/settlement';
import { formatDateAsYYYYMMDD } from 'commons/date';

import Card from 'components/Card';
import Select from 'components/Select';
import DatePicker from 'components/DatePicker';
import TextareaOrInputSearchbar from 'components/TextareaOrInputSearchbar';

import RadioGroup from 'components/RadioGroup';
import { PREPAID_SETTLEMENT_OPTIONS } from 'commons/constant';
import { SEARCH_FILTER_INIT_VALUES } from '../../commons/constant';

const SearchFilterContainer = () => {
  const dispatch = useDispatch();
  const [filterState, setFilterState, onStateChange] = useStateKey(SEARCH_FILTER_INIT_VALUES);

  const selectOptions = useSelectOptions(['paymentDate', 'productSupplySettlementSearchCondition']);
  const radios = selectOptions.productSupplySettlementSearchCondition.map((x, i) => ({
    id: `${x.key}_${i}`,
    label: x.value,
    value: x.key,
  }));

  const onResetFilter = () => {
    setFilterState(SEARCH_FILTER_INIT_VALUES);
    dispatch(fetchSettlementList(SEARCH_FILTER_INIT_VALUES));
  };

  const onTextareaFormat = (value = '', searchOption) => {
    let newValue = value;
    if (searchOption !== 'VENDOR_NAME')
      newValue = newValue.replace(/\s\s/g, ' ').replace(/\s/g, ', ').replace(/,,/g, ',');
    return newValue;
  };

  const onSearchClick = () => {
    dispatch(fetchSettlementList(filterState));
  };

  const handleDateChange = (dates) => {
    setFilterState({ ...filterState, receiveCompleteDay: dates.map(formatDateAsYYYYMMDD) });
  };

  const getDateFormat = (date) => (date ? new Date(date) : date);

  return (
    <Card title='정산 실행'>
      <Flex $flexWrap='wrap' $spacer>
        <Select
          id='payment-date'
          label='결제일'
          options={addAllOption(selectOptions.paymentDate)}
          value={filterState.paymentDate || ''}
          onChange={onStateChange('paymentDate')}
        />
        <DatePicker
          id='receive-complete-day'
          label='완료일'
          onChange={handleDateChange}
          startDate={getDateFormat(filterState.receiveCompleteDay[0])}
          endDate={getDateFormat(filterState.receiveCompleteDay[1])}
          selectsRange
        />
        <RadioGroup
          name='prepaidSettlement'
          title='선지급금 정산 여부'
          radios={[
            { id: 'PREPAID_SETTLEMENT_OPTIONS_null', label: '전체', value: '' },
            ...PREPAID_SETTLEMENT_OPTIONS,
          ]}
          checkedValue={filterState.prepaidSettlement ?? ''}
          onCheckedValueChange={onStateChange('prepaidSettlement')}
          width='200px'
        />
      </Flex>
      <TextareaOrInputSearchbar
        name='searchOption'
        placeholder='검색어를 입력해 주세요. 구분 문자 (쉼표, 엔터)로 복수 품목을 검색할 수 있습니다.'
        radios={radios}
        type={filterState.searchOption === 'VENDOR_NAME' ? 'input' : 'textarea'}
        textareaValue={filterState.searchWord}
        checkedValue={filterState.searchOption}
        onSearchClick={onSearchClick}
        onResetClick={onResetFilter}
        onTextareaChange={onStateChange('searchWord')}
        onCheckedValueChange={onStateChange('searchOption')}
        onTextareaFormat={onTextareaFormat}
      />
    </Card>
  );
};

export default SearchFilterContainer;
