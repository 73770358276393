import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getHash, thousandSeparator } from 'commons/helper';

import Button from 'components/Button';
import EditableCell from 'components/Table/components/Cell/EditableCell';

import TotalTable from '../../components/TotalTable';

/**
 * @param {*} props
 */
const SupplyInfoContainer = ({ columns, datas = [], index = 0, handleStateChange, execute }) => {
  const { requestInfoList = [], productSupplySettlementId } = datas[index] || {};
  const [state, setState] = useState([]);

  useEffect(() => {
    setState(requestInfoList);
  }, [index]);

  const updateMyDataChange = (rowIndex, columnName, v) => {
    const newData = [...state];
    newData[rowIndex] = { ...newData[rowIndex], [columnName]: v };
    setState(newData);
    handleStateChange(newData);
  };

  const discountExecute = (e) => {
    e.preventDefault();

    const list = [];
    datas.forEach((data) => {
      list.push(...data.requestInfoList);
    });
    execute({
      requestIdDiscountRateList: list.map((x) => {
        return {
          requestId: x.productSupplyRequestId,
          discountRate: x.discountRate,
        };
      }),
      reverseIds: datas[index]?.reverses.map((x) => x.reverseId).filter((it) => it),
    });
  };

  const renderCell = useCallback(
    ({ column, value, row }) => {
      const { id, Header, isEditable } = column;
      const editColumn = isEditable && {
        discountRate: (
          <EditableCell
            type='number'
            column={column}
            value={value}
            row={row}
            updateMyData={updateMyDataChange}
            min={0}
            max={100}
          />
        ),
      };
      return {
        [Header.match(/₩/) && id]: thousandSeparator(value) || 0,
        [getHash(id, value) && id]: getHash(id, value),
        totalReceivedQuantity: thousandSeparator(value) || 0,
        productSupplyRequestId: (
          <a className='text-primary' href={`/admin/supply/registration/${value}`}>
            {value}
          </a>
        ),
        prepaidSettlement: <p style={{ textAlign: 'center', margin: 0 }}>{value}</p>,
        ...editColumn,
      }[id];
    },
    [state]
  );

  return (
    <form onSubmit={discountExecute}>
      <h5 style={{ padding: '1rem 0' }}>
        대상 발주 정보 <b>{requestInfoList.length}</b>건
      </h5>
      <TotalTable
        columns={
          productSupplySettlementId ? columns.map((c) => ({ ...c, isEditable: false })) : columns
        }
        data={state}
        setData={setState}
        renderCell={renderCell}
        $height='unset'
      />
      {!productSupplySettlementId && (
        <div>
          <br />
          <Button $variant='success' type='submit'>
            할인 적용 계산
          </Button>
          &nbsp;&nbsp;&nbsp;
          <span>
            할인 적용 % 입력 후 이 버튼을 클릭하면, 세금계산서에 할인적용 금액에 반영됩니다.
          </span>
        </div>
      )}
    </form>
  );
};
SupplyInfoContainer.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  index: PropTypes.number,
  datas: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  handleStateChange: PropTypes.func,
  execute: PropTypes.func,
};
export default SupplyInfoContainer;
