import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { thousandSeparator, numberExtractor } from 'commons/helper';
import Select from 'components/Select';
import Input from 'components/Input';
import { TAX_TYPE_HASH } from 'commons/hash';

import { formatDateAsYYYYMMDD, formatStringAsDate } from 'commons/date';
import DatePicker from 'components/DatePicker';
import { REASON_FOR_CHANGE_OPTIONS } from 'commons/constant';
import { TAX_OPTIONS } from './commons/constant';

const PriceEditableCell = ({
  value: initialValue,
  row,
  column: { id: columnName },
  updateMyData,
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e) => setValue(e.target.value);

  const handleBlur = () => updateMyData(row, columnName, value);
  const handleNumberInputChange = (e) => setValue(numberExtractor(e.target.value));
  const handleDateChange = (date) => {
    setValue(formatDateAsYYYYMMDD(date));
    updateMyData(row, columnName, formatDateAsYYYYMMDD(date));
  };

  if (columnName === 'afterTax') {
    return (
      <Select
        id='afterTax'
        value={value || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        options={TAX_OPTIONS}
        required
      />
    );
  }

  if (
    columnName === 'afterProductStandardUnitPrice' ||
    columnName === 'afterMinimumOrderQuantity'
  ) {
    return (
      <Input
        type='text'
        value={thousandSeparator(value)}
        onChange={handleNumberInputChange}
        onBlur={handleBlur}
        required
        maxLength={10}
      />
    );
  }

  if (columnName === 'reasonForChange') {
    return (
      <Select
        id='reasonForChange'
        placeholder='변경사유 선택'
        value={value || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        options={REASON_FOR_CHANGE_OPTIONS}
        required
      />
    );
  }

  if (columnName === 'beforeTax') {
    return TAX_TYPE_HASH[value] ?? value;
  }

  if (columnName === 'effectiveDate') {
    return (
      <div css='min-width: 150px'>
        <DatePicker
          id='effectiveDateChange'
          selected={formatStringAsDate(value) ?? undefined}
          onChange={handleDateChange}
          minDate={new Date()}
        />
      </div>
    );
  }

  return value;
};

PriceEditableCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  row: PropTypes.shape({ index: PropTypes.number }).isRequired,
  column: PropTypes.shape({ id: PropTypes.string }).isRequired,
  updateMyData: PropTypes.func.isRequired,
};

export default PriceEditableCell;
