import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getRadioOption, setObjectState } from 'commons/helper';
import { getPrepaidList, setPageIndex, setSearchFilter } from 'commons/store/modules/admin/prepaid';
import useSelectOptions from 'hooks/useSelectOptions';

import TextareaSearchbar from 'components/TextareaSearchbar';

import { INITIAL_SEARCH_OPTIONS, SELECT_OPTIONS } from '../../commons/constant';

const SearchContainer = () => {
  const [searchOptions, setSearchOptions] = useState(INITIAL_SEARCH_OPTIONS);
  const { productSupplyPrepaidSearchCondition } = useSelectOptions(SELECT_OPTIONS);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const handleSearchQueryChange = (e) => setObjectState(setSearchOptions, 'query', e.target.value);
  const handleCheckedValueChange = (e) =>
    setObjectState(setSearchOptions, 'condition', e.target.value);

  useEffect(() => {
    dispatch(getPrepaidList(searchOptions));
  }, []);

  const handleSearchClick = () => {
    dispatch(setSearchFilter({ path: pathname, searchFilter: searchOptions }));
    dispatch(getPrepaidList(searchOptions));
    dispatch(setPageIndex({ path: pathname, pageIndex: 0 }));
  };

  const handleResetClick = () => {
    dispatch(setSearchFilter({ path: pathname, searchFilter: INITIAL_SEARCH_OPTIONS }));
    dispatch(getPrepaidList(INITIAL_SEARCH_OPTIONS));
    dispatch(setPageIndex({ path: pathname, pageIndex: 0 }));
    setSearchOptions(INITIAL_SEARCH_OPTIONS);
  };

  return (
    <TextareaSearchbar
      name='vendorSearchCondition'
      textareaValue={searchOptions.query}
      onTextareaChange={handleSearchQueryChange}
      onCheckedValueChange={handleCheckedValueChange}
      radios={getRadioOption(productSupplyPrepaidSearchCondition)}
      onSearchClick={handleSearchClick}
      onResetClick={handleResetClick}
      checkedValue={searchOptions.condition}
    />
  );
};

export default SearchContainer;
