import styled from 'styled-components';

export const Title = styled.div.attrs({ className: 'modal-title' })`
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: var(--space-m);
`;

export default { Title, ButtonWrapper };
