import styled from 'styled-components';

const Wrapper = styled.input`
  width: ${({ $width }) => $width || 'auto'};
  display: ${({ $display }) => $display || 'inline-block'};
  padding: var(--space-m);
  color: var(--grey900);
  border: 1px solid var(--grey300);
  border-radius: var(--radius-m);
  &:focus {
    border-color: #80bdff;
    outline: none;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition-property: border-color, box-shadow;
    transition-duration: 0.15s, 0.15s;
    transition-timing-function: ease-in-out, ease-in-out;
    transition-delay: 0s, 0s;
  }

  &:disabled {
    background-color: #e9ecef;
    cursor: default;
  }

  &.error {
    border-color: #f33f46;
    outline: none;
    box-shadow: 0 0 0.4rem 0.05rem rgb(243 63 70 / 25%);
  }
`;

export default { Wrapper };
