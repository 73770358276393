import { useState, useEffect } from 'react';
import { REQUEST_INITIAL_VALUES } from '../commons/constant';

const useRequest = (isOpen) => {
  const { requestsModal, requestModifyModal } = isOpen;
  const [request, setRequest] = useState(REQUEST_INITIAL_VALUES);

  useEffect(() => {
    if (!requestsModal && !requestModifyModal) {
      setRequest({ id: null, statusCode: null });
    }
  }, [isOpen]);

  return [request, setRequest];
};

export default useRequest;
