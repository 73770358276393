import PropTypes from 'prop-types';

import Card from 'components/Card';
import Table from 'components/Table';

import { COLUMNS } from './commons/constant';

const HistoryList = ({ historyList = [] }) => {
  return (
    <Card title='변경 이력'>
      <Table columns={COLUMNS} data={historyList} $height='225px' />
    </Card>
  );
};

HistoryList.propTypes = {
  historyList: PropTypes.arrayOf(PropTypes.object),
};

export default HistoryList;
