import { useRowSelect } from 'react-table';
import Header from '../components/Header';
import Cell from '../components/Cell';

export const ROW_SELECT_PLUGIN_HOOKS = [
  useRowSelect,
  (hooks) => {
    hooks.visibleColumns.push((columns) => [
      {
        id: 'selection',
        Header,
        Cell,
      },
      ...columns,
    ]);
  },
];

export default { ROW_SELECT_PLUGIN_HOOKS };
