import { useRef } from 'react';
import PropTypes from 'prop-types';
import { setDuplicatePreventClick } from 'commons/helper';
import Styled from './styled';

const Button = ({
  children,
  $size,
  $outline,
  $variant = 'primary',
  type = 'button',
  onClick,
  ...props
}) => {
  const clickDisabled = useRef(false);

  const onclickHandler = () => {
    setDuplicatePreventClick(clickDisabled, onClick, 700);
  };

  return (
    <Styled.Wrapper
      type={type}
      $variant={$variant}
      $size={$size}
      $outline={$outline}
      onClick={onclickHandler}
      {...props}
    >
      {children}
    </Styled.Wrapper>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  $size: PropTypes.oneOf(['small', 'large']),
  $outline: PropTypes.bool,
  $variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    'link',
  ]),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

export default Button;
