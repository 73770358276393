import { useCallback } from 'react';

import { thousandSeparator } from 'commons/helper';

import { REVERSE_INFO_COLUMNS } from '../../commons/constant';
import TotalTable from '../TotalTable';

function ReverseContainer({ data = [] }) {
  const renderCell = useCallback(({ column, value }) => {
    const { id } = column;
    return {
      totalReversedItemCount: thousandSeparator(value) || 0,
      totalReversedQuantity: thousandSeparator(value) || 0,
      reversedPrice: thousandSeparator(value) || 0,
      reverseAvailablePrice: thousandSeparator(value) || 0,
      ...column,
    }[id];
  }, []);

  return (
    <div>
      <br />
      <h5 style={{ padding: '1rem 0' }}>
        대상 반품 정보 <b>{data ? data.length : 0}</b>건
      </h5>
      <TotalTable
        columns={REVERSE_INFO_COLUMNS}
        renderCell={renderCell}
        data={data}
        $height='unset'
      />
    </div>
  );
}

export default ReverseContainer;
