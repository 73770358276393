import styled from 'styled-components';

export const Wrapper = styled.div``;

export const TextareaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const Textarea = styled.textarea`
  border: 1px solid var(--grey300);
  border-radius: var(--radius-s);
  padding: var(--space-m);
  width: 100%;

  ::placeholder {
    color: var(--grey400);
  }
`;

export default { Wrapper, TextareaWrapper, Textarea };
