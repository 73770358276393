import { memo } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';

import * as StyledPage from 'styles';
import { Flex } from 'styles';

import LabelAndText from 'components/LabelAndText';
import ImageUploader from 'components/ImageUploader';
import { handleError } from 'commons/helper';

import vendorApi from 'service/vendor/info';

const BusinessmanInfo = ({
  business: {
    companyName = '',
    businessNumber = '',
    ceoName = '',
    contractVendorImageName = '',
    contractVendorImageId,
  } = {},
}) => {
  const handleImageDownloadClick = async () => {
    try {
      const blob = await vendorApi.getImageById(contractVendorImageId);
      saveAs(blob, contractVendorImageName);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <StyledPage.Card title='사업자 정보'>
      <Flex>
        <LabelAndText label='법인명' text={companyName} />
        <LabelAndText label='사업자 등록번호' text={businessNumber} />
      </Flex>
      <Flex>
        <LabelAndText label='대표자 성명' text={ceoName} />
        <ImageUploader
          id='contractVendorImageName'
          title='계약서'
          imageName={contractVendorImageName || '파일이 없습니다.'}
          imageId={contractVendorImageId}
          onImageDownloadClick={handleImageDownloadClick}
          disabled
        />
      </Flex>
    </StyledPage.Card>
  );
};

BusinessmanInfo.propTypes = {
  business: PropTypes.shape({
    companyName: PropTypes.string,
    businessNumber: PropTypes.string,
    ceoName: PropTypes.string,
    contractVendorImageName: PropTypes.string,
    contractVendorImageId: PropTypes.number,
  }),
};

export default memo(BusinessmanInfo);
