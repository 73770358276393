import instance from 'service/interceptor';

export const getSettlementList = async (params) => {
  const { data } = await instance.post('/api/v2/product/supply/settlement/list', params);
  return data.response;
};

export const getSettlementDetail = async (id) => {
  const res = await instance.get(`/api/v2/product/supply/settlement/info/${id}`);
  return res;
};

export default {
  getSettlementList,
  getSettlementDetail,
};
