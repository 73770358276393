import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import supplyApi from 'service/hq/supply';
import { thousandSeparator } from 'commons/helper';
import useFetch from 'hooks/useFetch';

import { Flex } from 'styles/components';
import HeaderModal from 'components/modals/HeaderModal';
import PaginationTable from 'components/Table/PaginationTable';
import { COLUMNS } from './commons/constant';

const ProductListModal = ({ isOpen, closeCallback, productSupplyRequestId }) => {
  const [controlledPage, setControlledPage] = useState(0);
  const { data: { content = [], totalPages = 0 } = {}, execute: getSupplyRequestItems } = useFetch(
    supplyApi.getSupplyRequestItems,
    {
      initialExecute: false,
    }
  );

  const tableOptions = {
    initialState: { pageSize: 50, pageIndex: 0 },
    manualPagination: true,
    pageCount: totalPages,
    useControlledState: (state) => ({
      ...state,
      pageIndex: controlledPage,
    }),
  };

  useEffect(() => {
    if (isOpen) {
      getSupplyRequestItems({ productSupplyRequestId });
    } else {
      setControlledPage(0);
    }
  }, [isOpen, productSupplyRequestId]);

  const handlePageChange = ({ state }) => {
    getSupplyRequestItems({
      productSupplyRequestId,
      pageIndex: state.pageIndex,
    });
  };

  const modalTitle = `품목 내역 - 발주번호 (${productSupplyRequestId})`;

  const renderCell = (cell) => {
    switch (cell.column.id) {
      case 'productSupplyUnitPrice':
      case 'requestQuantity':
      case 'minimumOrderQuantity':
        return thousandSeparator(cell.value) || 0;
      default:
        return cell.value;
    }
  };

  return (
    <HeaderModal isOpen={isOpen} closeCallback={closeCallback} title={modalTitle} $size='xl'>
      <Flex $flexDirection='column' $gap='var(--space-m)'>
        <span>참고 : 증정 품목은 별도 행으로 표시됩니다.</span>
        <PaginationTable
          columns={COLUMNS}
          data={content}
          onPageChange={handlePageChange}
          tableOptions={tableOptions}
          renderCell={renderCell}
          setControlledPage={setControlledPage}
        />
      </Flex>
    </HeaderModal>
  );
};

ProductListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  productSupplyRequestId: PropTypes.string.isRequired,
};

export default ProductListModal;
