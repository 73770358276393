import { getHash } from 'commons/helper';

export const translateSettlementList = (list) =>
  list.map((item) => ({
    ...item,
    requestIds: item.requestIds.length ? item.requestIds.join('') : '',
    reverseNumbers: item.reverseNumbers ? item.reverseNumbers.join(',') : '',
    settlementStatusType: getHash('settlementStatusType', item.settlementStatusType),
    taxInvoiceType: getHash('taxInvoiceType', item.taxInvoiceType),
  }));

export default {};
