import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import vendorInfo from 'service/vendor/info';
import { updateMyDataMaker, handleError } from 'commons/helper';
import toastify from 'commons/toast';

import { Flex } from 'styles/components';
import * as StyledPage from 'styles';
import Button from 'components/Button';
import Table from 'components/Table';
import { Label } from 'components/LabelAndText/styled';

import { MANAGER_INFO_COLUMNS, TARGET_INDEX, VIEW_MANAGE_TABLE_INFO } from './commons/constant';

import EditableCell from './components/EditableCell';

const ManagerInfo = ({ manager: updatedManager }) => {
  const [manager, setManager] = useState({
    vendor: [],
    petfriends: [],
  });

  useEffect(() => {
    if (!updatedManager) {
      return;
    }

    setManager({
      vendor: updatedManager.filter((_, idx) => idx <= TARGET_INDEX),
      petfriends: updatedManager.filter((_, idx) => idx > TARGET_INDEX),
    });
  }, [updatedManager]);

  const validationFunc = (key, func, args = manager.vendor) =>
    args.map((data) => data[key]).some(func);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationChecks = [
      validationFunc('manager', (item) => item.length > 100),
      validationFunc('telNo', (item) => item.length > 20),
      validationFunc('email', (item) => item.length > 100),
    ];

    if (validationChecks.some((validation) => typeof validation === 'boolean' && validation)) {
      toastify('이름과 연락처 및 이메일을 확인해주세요.', { variant: 'error' });
      return;
    }

    try {
      await vendorInfo.modifyManagerInfo(manager.vendor);
      toastify('담당자 정보가 저장되었습니다.', { variant: 'success' });
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <StyledPage.Card title='담당자 정보'>
      <form onSubmit={handleSubmit}>
        {VIEW_MANAGE_TABLE_INFO.map((item, index) => (
          <Flex
            $flexDirection='column'
            $gap='var(--space-m)'
            $marginBottom={!index ? 40 : null}
            key={`${item.key}-table`}
          >
            <Flex $justifyContent='space-between' $alignItems='center' $height={36}>
              <Label>{item.label}</Label>
              {!index ? (
                <Button $variant='secondary' type='submit'>
                  저장
                </Button>
              ) : null}
            </Flex>
            <Table
              columns={MANAGER_INFO_COLUMNS}
              data={manager[item.key]}
              tableOptions={{
                defaultColumn: {
                  Cell: EditableCell,
                },
                isVendorTable: item.isVendorTable,
                updateMyData: updateMyDataMaker(setManager, { properties: [item.key] }),
              }}
              $height='unset'
            />
          </Flex>
        ))}
      </form>
    </StyledPage.Card>
  );
};

ManagerInfo.propTypes = {
  manager: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      manager: PropTypes.string,
      position: PropTypes.string,
      telNo: PropTypes.string,
    })
  ),
};

export default memo(ManagerInfo);
