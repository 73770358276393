/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  NOTICE_LIST,
  INITIAL_SEARCH_OPTIONS,
} from 'pages/admin/notice/AdminNoticeListPage/commons/constant';
import noticeApi from 'service/hq/notice';

const initialState = {
  [NOTICE_LIST]: {
    searchFilter: INITIAL_SEARCH_OPTIONS,
    tableData: [],
    totalPages: 0,
    totalElements: 0,
    pageIndex: 0,
  },
};

export const getNoticeList = createAsyncThunk('notice/getNoticeList', async (params) => {
  const { response } = await noticeApi.getNoticeList({
    ...params,
    categoryCode: params.noticeCategoryCode,
    startCreatedDay: params.createdDay[0],
    endCreatedDay: params.createdDay[1],
    searchContents: params.query,
  });

  return {
    searchFilter: params,
    content: response?.data?.content,
    totalElements: response?.data?.totalElements,
    totalPages: response?.data?.totalPages,
  };
});

const slice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    setSearchFilter: (state, action) => {
      state[action.payload.path].searchFilter = action.payload.searchFilter;
    },
    setPageIndex: (state, action) => {
      state[action.payload.path].pageIndex = action.payload.pageIndex;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNoticeList.fulfilled, (state, action) => {
      state[NOTICE_LIST].searchFilter = action.payload.searchFilter;
      state[NOTICE_LIST].tableData = action.payload.content;
      state[NOTICE_LIST].totalElements = action.payload.totalElements;
      state[NOTICE_LIST].totalPages = action.payload.totalPages;
    });
    builder.addCase(getNoticeList.rejected, (state) => {
      state[NOTICE_LIST].tableData = [];
    });
  },
});

export const { setSearchFilter, setPageIndex } = slice.actions;

export default slice.reducer;
