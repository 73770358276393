import AlertModal from 'components/modals/AlertModal';
import ActivityIndicatorModal from 'components/modals/ActivityIndicatorModal';

const ModalContainer = () => {
  return (
    <>
      <AlertModal />
      <ActivityIndicatorModal />
    </>
  );
};

export default ModalContainer;
