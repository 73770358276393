export const HELPER_TEXTS = [
  '세부내용을 검토한 후 <입고예정수량>과 상품의 <유통기한>, <검토 메시지>를 입력하고 <저장>을 클릭해 주세요.',
  '발주수량보다 입고예정수량이 부족한 경우 <재입고 일정> 입력하고 <저장>을 클릭해 주세요.',
  '발주에 대해 검토가 완료되면  발주 업무 처리 메뉴에서 <발주확정>을 클릭해 주세요.',
];

export const TOTAL_COLUMNS = [
  { Header: '품목 수', accessor: 'totalItemKindCount' },
  { Header: '총 발주수량', accessor: 'totalItemQuantity' },
  { Header: '총 입고예정수량', accessor: 'totalAvailableReceiveQuantity' },
  { Header: '총 발주금액(₩)', accessor: 'totalPrice' },
  { Header: '입고결과금액(₩)', accessor: 'totalReceivedPrice' },
];

export const ITEM_COLUMNS = [
  { Header: '품목ID', accessor: 'productItemId' },
  { Header: '품목유형', accessor: 'productTypeName' },
  { Header: '품목명', accessor: 'productName' },
  { Header: '증정여부', accessor: 'isFreeGiftName' },

  { Header: '과세구분', accessor: 'taxationTypeCodeName' },
  { Header: '기준 공급가(₩)', accessor: 'productStandardUnitPrice' },
  { Header: '적용 공급가(₩)', accessor: 'productSupplyUnitPrice' },
  { Header: '최소주문수량', accessor: 'minimumOrderQuantity' },
  { Header: '발주수량', accessor: 'requestQuantity' },
  { Header: '입고예정수량', accessor: 'availableReceiveQuantity' },
  { Header: '입고불가수량', accessor: 'unavailableReceiveQuantity' },
  { Header: '재입고일정', accessor: 'restockDay' },
  { Header: '유통기한', accessor: 'shelfLifeDay' },
  { Header: '발주금액', accessor: 'productSupplyPrice' },
  { Header: '실제 입고 수량', accessor: 'receivedQuantity' },
  { Header: '펫프 담당자 메시지', accessor: 'petfriendsComment' },
  { Header: '담당자 메시지', accessor: 'vendorComment' },
];
