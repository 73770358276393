export const MAPPING_KEYS = {
  productSupplyRequestId: '발주번호',
  productSupplyTypeCodeName: '발주유형',
  statusCodeName: '발주상태',
  prepaidSettlement: '선지급금 정산 여부',
  inboundWikey: '입고번호',

  vendorId: '거래처 번호',
  displayName: '거래처명',

  purchaseManager: '발주 담당자',
  totalItemKindCount: '품목',
  totalItemQuantity: '발주수량',

  totalPrice: '발주금액',
  totalReceivedPrice: '입고결과금액',
  centerName: '입고센터',

  receiveRequestDay: '입고요청일자',
  receiveAvailableDay: '입고예정일',

  requestWorkerName: '등록자',
  createDate: '등록일시',
  updateDate: '수정일시',
};

export const MAPPING_KEYS_V2 = {
  id: '발주번호',
  purchaseOrderType: '발주유형',
  purchaseOrderStatus: '발주상태',
  prepaidSettlement: '선지급금 여부',
  centerName: '입고센터',
  vendorId: '거래처번호',
  vendorName: '거래처명',
  productItemId: '품목코드',
  productItemName: '품목명',
  standardUnitPrice: '기준공급가',
  unitPrice: '적용공급가',
  requestQuantity: '발주수량',
  purchaseOrderPrice: '발주금액',
  receivingStatus: '입고상태',
  availableReceiveQuantity: '입고예정수량',
  receivedQuantity: '입고완료수량',
  unavailableReceiveQuantity: '입고불가수량',
  receivedQuantityPrice: '입고완료금액',
  restockDay: '재입고일정',
  purchaseOrderRequestDay: '발주요청일',
  receiveRequestDay: '입고요청일',
  receiveAvailableDay: '입고예정일',
  requestWorkerName: '등록자',
  supplyManager: '발주담당자',
};
