import PropTypes from 'prop-types';
import * as Styled from './styled';

const PageItem = ({ onClick, $isCurrentPage, disabled, item }) => {
  return (
    <Styled.Wrapper>
      <Styled.PageLink onClick={onClick} $isCurrentPage={$isCurrentPage} disabled={disabled}>
        {item}
      </Styled.PageLink>
    </Styled.Wrapper>
  );
};

PageItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  $isCurrentPage: PropTypes.bool,
  disabled: PropTypes.bool,
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default PageItem;
