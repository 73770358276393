import Card from 'components/Card';

import { viewOverviewData } from '../../helper';
import { OverviewWrapper, OverviewItem } from './Overview.style';

function Overview({ right, currentDate, overviewData = {} }) {
  const {
    supplyAmounts,
    receivedAmounts,
    accuracy,
    prepaidAmounts,
    settlementAmounts,
    requestCount,
  } = overviewData;

  return (
    <Card title={currentDate} right={right}>
      <OverviewWrapper>
        <OverviewItem>
          <p>발주금액</p>
          <span>{viewOverviewData(supplyAmounts)}원</span>
        </OverviewItem>
        <OverviewItem>
          <p>입고금액</p>
          <span>{viewOverviewData(receivedAmounts)}원</span>
        </OverviewItem>
        <OverviewItem>
          <p>계산서 발행금액</p>
          <span>{viewOverviewData(settlementAmounts)}원</span>
        </OverviewItem>
        <OverviewItem>
          <p>입고정확도</p>
          <span>{viewOverviewData(accuracy)}%</span>
        </OverviewItem>
        <OverviewItem>
          <p>선지급금 잔액</p>
          <span>{viewOverviewData(prepaidAmounts)}원</span>
        </OverviewItem>
        <OverviewItem>
          <p>거래처 요청 사항</p>
          <span>{requestCount ?? 0}건</span>
        </OverviewItem>
      </OverviewWrapper>
    </Card>
  );
}

export default Overview;
