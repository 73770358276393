export const COLUMNS = [
  {
    Header: '입고지 정보',
    columns: [{ Header: '입고 센터', accessor: 'centerName', Footer: '합계 :' }],
  },
  {
    Header: '발주 정보',
    columns: [
      { Header: '발주번호', accessor: 'productSupplyRequestId' },
      { Header: '입고번호', accessor: 'inboundWikey' },
      { Header: '발주유형', accessor: 'productSupplyTypeValue' },
      { Header: '발주상태', accessor: 'productSupplyRequestStatusValue' },
      { Header: '발주요청일', accessor: 'productSupplyRequestDay' },
      { Header: '발주담당자', accessor: 'supplyManagerName' },
    ],
  },
  {
    Header: '거래처 정보',
    columns: [
      { Header: '거래처번호', accessor: 'vendorId' },
      { Header: '거래처명', accessor: 'displayName' },
      { Header: '발주 담당자', accessor: 'purchaseManager' },
    ],
  },
  {
    Header: '품목 정보',
    columns: [
      { Header: '품목번호', accessor: 'productItemId' },
      { Header: '품목명', accessor: 'productName' },
      {
        Header: '적용공급가(₩)',
        accessor: 'productSupplyUnitPrice',
      },
      { Header: '증정여부', accessor: 'isFreeGift' },
    ],
  },
  {
    Header: '입고 결과 개요',
    columns: [
      { Header: '입고 상태', accessor: 'productSupplyReceivingStatusValue' },
      { Header: '입고요청일', accessor: 'receiveRequestDay' },
      { Header: '입고예정일', accessor: 'receiveAvailableDay' },
      { Header: '입고완료일', accessor: 'inboundCompletionDay' },
      {
        Header: '입고예정수량',
        accessor: 'availableReceiveQuantity',
      },
      {
        Header: '입고결과수량',
        accessor: 'receivedQuantity',
      },
      {
        Header: '미입고수량',
        accessor: 'unreceivedQuantity',
      },
      {
        Header: '입고결과금액(₩)',
        accessor: 'receivedQuantityPrice',
      },
      {
        Header: '미입고금액(₩)',
        accessor: 'unreceivedQuantityPrice',
      },
    ],
  },
];

export const SEARCH_OPTIONS_INITIAL_VALUE = {
  productSupplyReceivingStatusCode: '',
  receiveAvailableDay: [null, null],
  inboundCompletionDay: [null, null],
  query: '',
  searchCondition: 'PRODUCT_SUPPLY_REQUEST',
};

export const SEARCH_CONDITIONS = [
  {
    id: 'PRODUCT_SUPPLY_REQUEST',
    label: '발주 번호',
    value: 'PRODUCT_SUPPLY_REQUEST',
  },
  {
    id: 'INBOUND',
    label: '입고번호',
    value: 'INBOUND',
  },
  {
    id: 'PRODUCT_ITEM',
    label: '품목 번호',
    value: 'PRODUCT_ITEM',
  },
  {
    id: 'PRODUCT_NAME',
    label: '품목 명',
    value: 'PRODUCT_NAME',
  },
  {
    id: 'VENDOR_NAME',
    label: '거래처명',
    value: 'VENDOR_NAME',
  },
  {
    id: 'VENDOR_ID',
    label: '거래처번호',
    value: 'VENDOR_ID',
  },
  {
    id: 'SUPPLY',
    label: '발주담당자',
    value: 'SUPPLY',
  },
];

export const MAPPING_KEYS = {
  centerName: '입고 센터',
  productSupplyRequestId: '발주번호',
  inboundWikey: '입고번호',
  productSupplyTypeValue: '발주유형',
  productSupplyRequestStatusValue: '발주상태',
  productSupplyRequestDay: '발주요청일',
  vendorId: '거래처번호',
  displayName: '거래처명',
  purchaseManager: '발주 담당자',
  productItemId: '품목번호',
  productName: '품목명',
  productSupplyUnitPrice: '적용공급가(₩)',
  isFreeGift: '증정여부',
  productSupplyReceivingStatusValue: '입고 상태',
  receiveRequestDay: '입고요청일',
  receiveAvailableDay: '입고예정일',
  inboundCompletionDay: '입고완료일',
  availableReceiveQuantity: '입고예정수량',
  receivedQuantity: '입고결과수량',
  unreceivedQuantity: '미입고수량',
  receivedQuantityPrice: '입고결과금액(₩)',
  unreceivedQuantityPrice: '미입고금액(₩)',
};

export default { COLUMNS };
