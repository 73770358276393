// import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { usePageModals } from 'hooks/pages';

import { thousandSeparator } from 'commons/helper';
import { blueUnderline } from 'styles/commons';
import { setReverseId } from 'commons/store/modules/vendor/reverse';

const BlueUnderLineButton = styled.button`
  ${blueUnderline}
`;

export function NumberCell({ value }) {
  return thousandSeparator(value) || 0;
}

export function ReverseIdCell({ value, row }) {
  const dispatch = useDispatch();
  const [, setModalOpen] = usePageModals();

  const openModal = () => {
    dispatch(setReverseId(row?.original?.reverseId || null));
    setModalOpen('detail', true);
  };

  return <BlueUnderLineButton onClick={openModal}>{value}</BlueUnderLineButton>;
}
