import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import Spinner from 'components/Spinner';
import * as Styled from './styled';

/**
 * https://react-table.tanstack.com/docs/api/useTable#usetable
 */
const Table = ({
  columns,
  data,
  onRowClick,

  headerComponent,
  footerComponent,

  onSelectChange,
  noResultMessage,

  tableOptions = {},
  pluginHooks = [],
  $height,
  renderCell,
  renderTableFoot,
  isRowSelected,
  isLoading,
  filterRows = (rows) => rows,
  ...props
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    rows,
    ...tableInstance
  } = useTable(
    {
      columns,
      data,
      ...tableOptions,
    },
    ...pluginHooks
  );

  const rowList = page || rows;

  useEffect(() => {
    if (!tableInstance.selectedFlatRows) {
      return;
    }

    if (onSelectChange) {
      onSelectChange(tableInstance);
    }
  }, [tableInstance.selectedFlatRows?.length]);

  return (
    <>
      {headerComponent && headerComponent(tableInstance)}

      <Styled.TableWrapper $height={$height}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Styled.Table $useHover={Boolean(onRowClick)} {...getTableProps()} {...props}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={headerGroup.getHeaderGroupProps().key}
                >
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} key={column.id}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {filterRows(rowList).map((row) => {
                prepareRow(row);
                return (
                  <Styled.Tr
                    {...row.getRowProps()}
                    key={row.getRowProps().key}
                    onClick={() => onRowClick && onRowClick(row)}
                    $isSelected={isRowSelected && isRowSelected(row.values)}
                  >
                    {row.cells.map((cell) => {
                      const Cell = cell.render('Cell');
                      return (
                        <td {...cell.getCellProps()} key={cell.getCellProps().key}>
                          {renderCell ? renderCell(cell) || Cell : Cell}
                        </td>
                      );
                    })}
                  </Styled.Tr>
                );
              })}
            </tbody>
            {renderTableFoot && renderTableFoot(footerGroups)}
          </Styled.Table>
        )}
        {data.length === 0 && noResultMessage && (
          <Styled.NoResultMessageWrapper>
            <span>{noResultMessage}</span>
          </Styled.NoResultMessageWrapper>
        )}
      </Styled.TableWrapper>

      {footerComponent && footerComponent(tableInstance)}
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,

  onRowClick: PropTypes.func,
  tableOptions: PropTypes.shape({
    initialState: PropTypes.shape({
      hiddenColumns: PropTypes.arrayOf(PropTypes.string),
    }),
    autoResetHiddenColumns: PropTypes.bool,
    stateReducer: PropTypes.func,
    useControlledState: PropTypes.func,
    defaultColumn: PropTypes.shape({
      Cell: PropTypes.elementType,
    }),
    getSubRows: PropTypes.func,
    getRowId: PropTypes.func,
  }),
  pluginHooks: PropTypes.arrayOf(PropTypes.func),

  headerComponent: PropTypes.elementType,
  footerComponent: PropTypes.elementType,
  onSelectChange: PropTypes.func,
  noResultMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  $height: PropTypes.string,

  renderCell: PropTypes.func,
  renderTableFoot: PropTypes.func,
  isRowSelected: PropTypes.func,
  isLoading: PropTypes.bool,
  filterRows: PropTypes.func,
};

export default memo(Table);
