import PropTypes from 'prop-types';
import { thousandSeparator } from 'commons/helper';

const NumericCell = ({ value }) => {
  return <span>{thousandSeparator(value) || 0}</span>;
};

NumericCell.propTypes = {
  value: PropTypes.number,
};

export default NumericCell;
