import styled from 'styled-components';

const Wrapper = styled.div.attrs({
  className: 'main-header navbar navbar-expand',
})`
  background-color: white !important;
  border-bottom: 1px solid var(--border-color);
`;

const LeftSide = styled.ul.attrs({ className: 'navbar-nav' })``;

const NavItem = styled.li.attrs({ className: 'nav-item' })``;

const NavLink = styled.a.attrs({ className: 'nav-link' })``;

const MenuIcon = styled.i.attrs({ className: 'fa fa-bars' })`
  color: var(--grey900);
`;

const RightSide = styled.div`
  margin-left: auto;
`;

const DisplayName = styled.span`
  margin-right: var(--space-l);
  color: var(--grey900);
`;

export { Wrapper, LeftSide, NavItem, NavLink, MenuIcon, RightSide, DisplayName };
