import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ROW_SELECT_PLUGIN_HOOKS } from 'components/Table/commons/constant';
import toastify from 'commons/toast';
import { isEmpty, setObjectState, updateObject } from 'commons/helper';
import supplyApi from 'service/hq/supply';
import useFetch from 'hooks/useFetch';
import { Flex } from 'styles/components';

import HeaderModal from 'components/modals/HeaderModal';
import Searchbar from 'components/Searchbar';
import Button from 'components/Button';
import RadioGroup from 'components/RadioGroup';
import PaginationTable from 'components/Table/PaginationTable';
import NumericCell from 'components/NumericCell/NumericCell';

import { SEARCH_OPTIONS_INITIAL_VALUES, SEARCH_CONDITION, COLUMNS } from './commons/constant';
import {
  getDuplicatedItems,
  getProductItemIdCounts,
  getStringProductItemIds,
} from './commons/helper';

import * as Styled from './styled';

const ItemRegisterModal = ({
  isOpen,
  closeCallback,
  itemList,
  setSupplyRequestDetail,
  vendorId,
}) => {
  const [searchOptions, setSearchOptions] = useState(SEARCH_OPTIONS_INITIAL_VALUES);
  const searchOptionsSnapshot = useRef(SEARCH_OPTIONS_INITIAL_VALUES);
  const [controlledPageIndex, setControlledPage] = useState(0);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const { data: { content = [], totalPages = 0 } = {}, execute: fetchProductList } = useFetch(
    supplyApi.getProductList,
    { initialExecute: false }
  );

  const tableOptions = {
    initialState: { pageSize: 50 },
    manualPagination: true,
    pageCount: totalPages,
    useControlledState: (state) => ({
      ...state,
      pageIndex: controlledPageIndex,
    }),
  };

  useEffect(() => {
    if (!isOpen) {
      setSearchOptions(SEARCH_OPTIONS_INITIAL_VALUES);
      setControlledPage(0);
      searchOptionsSnapshot.current = SEARCH_OPTIONS_INITIAL_VALUES;
      return;
    }
    fetchProductList({ vendorId });
  }, [isOpen]);

  const handleCheckedValueChange = (e) =>
    setObjectState(setSearchOptions, 'condition', e.target.value);
  const handleSearchQueryChange = (e) => setObjectState(setSearchOptions, 'query', e.target.value);

  const handleSearch = () => {
    searchOptionsSnapshot.current = searchOptions;
    setControlledPage(0);
    fetchProductList({
      searchOption: searchOptions.condition,
      searchWord: searchOptions.query,
      vendorId,
    });
  };

  const handleResetClick = () => {
    setControlledPage(0);
    setSearchOptions(SEARCH_OPTIONS_INITIAL_VALUES);
    searchOptionsSnapshot.current = SEARCH_OPTIONS_INITIAL_VALUES;
    fetchProductList({ vendorId });
  };

  const handlePageChange = ({ state }) => {
    fetchProductList({
      pageIndex: state.pageIndex,
      searchOption: searchOptionsSnapshot.current.condition,
      searchWord: searchOptionsSnapshot.current.query,
      vendorId,
    });
  };

  const addDefaultColumns = (list) =>
    list.map((item) => ({
      ...item,
      isFreeGift: 'N',
      productSupplyUnitPrice: 0,
      requestQuantity: 0,
      petfriendsComment: '',
    }));

  const handleSelectChange = ({ selectedFlatRows }) =>
    setSelectedProductList(selectedFlatRows.map((item) => item.original));

  const handleProductAddClick = () => {
    if (isEmpty(selectedProductList)) {
      toastify('선택된 품목이 존재하지 않습니다.', { variant: 'warn' });
      return;
    }
    const productItemIdCounts = getProductItemIdCounts(
      itemList.filter(({ active }) => active !== 'N'),
      selectedProductList
    );
    const duplicatedItems = getDuplicatedItems(productItemIdCounts);
    if (!isEmpty(duplicatedItems)) {
      toastify(
        `이미 등록된 품목이 존재합니다. 
        품목ID: ${getStringProductItemIds(duplicatedItems)}`,
        { variant: 'warn' }
      );
      return;
    }

    setSupplyRequestDetail((prev) =>
      updateObject(prev, 'itemList', (value) => {
        return [...addDefaultColumns(selectedProductList), ...value];
      })
    );
    toastify('선택된 품목이 등록되었습니다.', { variant: 'success' });
    closeCallback();
  };

  const renderCell = (cell) => {
    switch (cell.column.id) {
      case 'productStandardUnitPrice':
      case 'minimumOrderQuantity':
        return <NumericCell value={cell.value} />;
      default:
        return cell.value;
    }
  };

  return (
    <HeaderModal isOpen={isOpen} closeCallback={closeCallback} title='품목 등록' $size='xl'>
      <Flex $flexDirection='column' $gap='var(--space-m)'>
        <RadioGroup
          title='검색 조건'
          radios={SEARCH_CONDITION}
          name='searchCondition'
          checkedValue={searchOptions.condition}
          onCheckedValueChange={handleCheckedValueChange}
        />
        <Styled.SearchbarWrapper>
          <Searchbar
            name='itemRegister'
            value={searchOptions.query}
            onChange={handleSearchQueryChange}
            onClick={handleSearch}
            onReset={handleResetClick}
          />
          <Button $variant='secondary' onClick={handleProductAddClick}>
            대상 품목 추가 &gt;
          </Button>
        </Styled.SearchbarWrapper>
        <Styled.TableWrapper>
          <PaginationTable
            columns={COLUMNS}
            data={content}
            onPageChange={handlePageChange}
            tableOptions={tableOptions}
            pluginHooks={ROW_SELECT_PLUGIN_HOOKS}
            onSelectChange={handleSelectChange}
            setControlledPage={setControlledPage}
            renderCell={renderCell}
          />
        </Styled.TableWrapper>
      </Flex>
    </HeaderModal>
  );
};

ItemRegisterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  itemList: PropTypes.arrayOf(PropTypes.object),
  setSupplyRequestDetail: PropTypes.func.isRequired,
  vendorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ItemRegisterModal;
