export const MAPPING_KEYS = {
  vendorId: '거래처번호',
  vendorDisplayName: '거래처명',
  settlementName: '정산명',
  settlementId: '정산번호',
  settlementDay: '정산일',
  settlementStatusType: '정산 상태',
  totalSettlementPrice: '최종 정산금액 합계 (₩)',
  balance: '선지급금 잔액 (₩)',
  usePrepaidPrice: '선지급금 사용액 (₩)',
  totalPrepaidPrice: '기존 선지급금 사용액 (₩)',
  actionCode: '사용 구분',
  memo: '사유',
};

export default {
  MAPPING_KEYS,
};
