import PropTypes from 'prop-types';
import Input from 'components/Input';

const Header = ({ getToggleAllRowsSelectedProps }) => {
  return <Input type='checkbox' {...getToggleAllRowsSelectedProps()} />;
};

Header.propTypes = {
  getToggleAllRowsSelectedProps: PropTypes.func.isRequired,
};

export default Header;
