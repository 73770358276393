import styled from 'styled-components';
import { flexRowCenter } from 'styles/commons';

export const OverviewWrapper = styled.ul`
  ${flexRowCenter}
  list-style: none;
  justify-content: start;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 100px;
`;

export const OverviewItem = styled.li`
  & > p {
    margin: 0;
    font-size: 13px;
    color: #8b95a1;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 10px;
  }

  & > span {
    font-size: 15px;
    font-weight: 500;
    line-height: 120%;
  }
`;
