import styled from 'styled-components';

import { Flex } from 'styles/components';
import Card from 'components/Card';

export const TransitionWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  height: 100%;

  & > div {
    width: 200%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    position: relative;
    top: 0;
    left: ${({ step }) => (step ? `-${(step - 1) * 100}%` : 0)};
    transition: left 0.4s ease-in-out;
  }
`;

export const TransitionContainer = styled(Flex)`
  width: 50%;
  height: 100%;
`;

export const CardContainer = styled(Card)`
  flex-grow: ${({ grow }) => grow};
  min-width: 320px;
  height: 100%;
`;

export const Text = styled.p`
  font-size: 15px;
  color: ${({ color }) => color ?? '#4e5968'};
  margin: 0;
  font-weight: 500;
`;

export const SkipCheckBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  & div {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  & input {
    width: 22px;
    height: 22px;
  }

  & label {
    font-size: 15px;
    line-height: 22px;
    color: #4e5968;
    margin: 0;
  }
`;
