import { REQUEST_STATUS_CODE } from 'commons/constant';

export const MODAL_LEFT_BUTTON_LABELS = {
  null: '수정 요청',
  [REQUEST_STATUS_CODE.REQUEST]: '요청 취소',
  [REQUEST_STATUS_CODE.REJECT]: '재요청',
};

export const MODAL_RIGHT_BUTTON_LABELS = {
  [REQUEST_STATUS_CODE.REJECT]: '요청 취소',
};

export default {
  MODAL_LEFT_BUTTON_LABELS,
  MODAL_RIGHT_BUTTON_LABELS,
};
