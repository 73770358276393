import instance from 'service/interceptor';

export const getSettlementList = async (params) => {
  const { data } = await instance.post('/api/v2/hq/product/supply/settlement/list', params);
  return data.response;
};

export const postRequestSettlementList = async ({
  receiveCompleteDay,
  prepaidSettlement,
  ...params
}) => {
  const { data } = await instance.post('/api/v2/hq/product/supply/request/settlement/list', {
    ...params,
    startCompleteDay: receiveCompleteDay[0],
    endCompleteDay: receiveCompleteDay[1],
    prepaidSettlement: prepaidSettlement === '' ? null : prepaidSettlement,
  });
  return data.response;
};

export const postSupplySettlementByVendorList = (type) => async (list) => {
  const ext = type ? `/${type}` : '';
  const { data } = await instance.post(
    `/api/v2/hq/product/supply/settlement-by-vendor${ext}/list`,
    list
  );
  return data;
};

export const postSupplyTaxInvoiceIssue = (type) => async (params) => {
  const ext = type ? `/settlement-${type}` : '/tax-invoice/issue';
  const { data } = await instance.post(`/api/v2/hq/product/supply${ext}`, params);
  return data;
};

export const postSettlementListComplete = async (params) => {
  const res = await instance.post('/api/v2/hq/product/supply/settlements/complete', params);
  return res;
};

export const postSettlementComplete = async (id) => {
  const res = await instance.post(`/api/v2/hq/product/supply/settlement/complete/${id}`);
  return res;
};

export const postSettlementCancel = async (id) => {
  const res = await instance.post(`/api/v2/hq/product/supply/settlement/cancel/${id}`);
  return res;
};

export const getSettlementDetail = async (id) => {
  const { data } = await instance.get(`/api/v2/hq/product/supply/settlement/info/${id}`);
  return data;
};

export const getSettlementUsedPrepaidList = async (params) => {
  const data = await instance.post(
    '/api/v2/hq/product/supply/settlement-used-prepaid/list',
    params
  );
  return data;
};

export const postPrepaidUseExcelUpload = async (file) => {
  const formData = new FormData();
  formData.append('file1', file);
  const data = await instance.post('/api/v2/hq/product/supply/prepaid/use/excel-upload', formData);

  return data;
};

export const postSettlementPrepaidUse = async (list) => {
  const data = await instance.post('/api/v2/hq/product/supply/settlement-prepaid/use', list);
  return data;
};

export default {
  getSettlementList,
  postRequestSettlementList,
  postSupplySettlementByVendorList,
  postSupplyTaxInvoiceIssue,
  postSettlementListComplete,
  postSettlementComplete,
  postSettlementCancel,
  getSettlementUsedPrepaidList,
  postPrepaidUseExcelUpload,
  postSettlementPrepaidUse,
};
