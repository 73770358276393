import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { getHash, thousandSeparator } from 'commons/helper';

import Table from 'components/Table';

import Styled from './styled';

/**
 * @param {*} props
 */
const TaxInvoiceContainer = ({ labelText, columns, taxInvoiceInfoList = [], isTitle = true }) => {
  const renderCell = useCallback(
    (cell) => {
      const { column, value, row } = cell;
      const { id, Header } = column;

      if (id === 'settlementTaxationTypeCode') {
        return value;
      }

      const lastValue =
        thousandSeparator(parseInt(value, 10)) === ''
          ? '0'
          : thousandSeparator(parseInt(value, 10));

      return {
        [Header.match(/₩/) && id]: lastValue,
        [getHash(id, value) && id]: getHash(id, value),
        index: row.index + 1,
        supplyCostTotal: lastValue,
        settlementPrice: lastValue,
        TaxInvoiceContainer: lastValue,
        taxTotal: lastValue,
      }[id];
    },
    [taxInvoiceInfoList]
  );

  return (
    <Styled.Div>
      {isTitle && <h5 style={{ padding: '1rem 0' }}>{labelText || '세금계산서 금액 계산'}</h5>}
      <Table
        columns={columns}
        data={taxInvoiceInfoList}
        $height='unset'
        $width='unset'
        renderCell={renderCell}
      />
    </Styled.Div>
  );
};

TaxInvoiceContainer.propTypes = {
  labelText: PropTypes.string,
  columns: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  taxInvoiceInfoList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default TaxInvoiceContainer;
