import PropTypes from 'prop-types';
import * as Styled from './styled';

const Choice = ({ title, onClick, value, options = [] }) => {
  return (
    <div>
      <Styled.TitleWrapper>
        <Styled.Title>{title}</Styled.Title>
      </Styled.TitleWrapper>
      <Styled.OptionWrapper>
        {options.map((option) => (
          <Styled.Option
            onClick={onClick}
            $isSelected={Array.isArray(value) ? value.includes(option.key) : value === option.key}
            key={option.value}
            data-key={option.key}
          >
            {option.value}
          </Styled.Option>
        ))}
      </Styled.OptionWrapper>
    </div>
  );
};

Choice.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ),
};

export default Choice;
