import PropTypes from 'prop-types';
import DatePickerComponent, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import * as Styled from './styled';

registerLocale('ko', ko);

const DatePicker = ({
  id,
  label,
  startDate,
  endDate,
  dateFormat = 'yyyy-MM-dd',
  isClearable = true,
  onChange = () => {},
  // eslint-disable-next-line no-return-assign
  onChangeRaw = (e) => (e.target.value = ''),
  selectsRange,
  disabled,
  ...props
}) => {
  const handleClickOutside = () => {
    if (selectsRange && startDate && endDate === null) {
      onChange([null, null]);
    }
  };

  return (
    <Styled.Wrapper selectsRange={selectsRange}>
      <Styled.Label htmlFor={id}>{label}</Styled.Label>
      <DatePickerComponent
        locale='ko'
        id={id}
        startDate={startDate}
        endDate={endDate}
        dateFormat={dateFormat}
        isClearable={!disabled && isClearable}
        onChange={onChange}
        onChangeRaw={onChangeRaw}
        onClickOutside={handleClickOutside}
        selectsRange={selectsRange}
        autoComplete='off'
        disabled={disabled}
        {...props}
      />
    </Styled.Wrapper>
  );
};

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  dateFormat: PropTypes.string,
  isClearable: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  selectsRange: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DatePicker;
