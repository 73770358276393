export const RECEIVE_DELAY_OPTIONS = [
  {
    id: 'N',
    label: '미지연',
    value: 'N',
  },
  {
    id: 'Y',
    label: '입고 지연',
    value: 'Y',
  },
];

export default { RECEIVE_DELAY_OPTIONS };
