import PropTypes from 'prop-types';

import { setObjectState } from 'commons/helper';

import { Flex } from 'styles/components';
import Card from 'components/Card';
import InputLabel from 'components/InputLabel';

/**
 * @param {*} props
 */
const NoticeContentContainer = ({ noticeInfo, setNoticeInfo, isLoading }) => {
  const handleInputChange = (e) => setObjectState(setNoticeInfo, e.target.name, e.target.value);

  return (
    <Card title='게시물 내용' isLoading={isLoading}>
      <Flex $flexDirection='column' $gap='var(--space-m)'>
        <InputLabel
          id='title'
          name='title'
          label='제목'
          value={noticeInfo.title}
          onChange={handleInputChange}
          placeholder='제목을 입력해 주세요.'
          maxLength={100}
          $fullWidth
          required
        />
        <InputLabel
          id='contents'
          name='contents'
          label='내용'
          value={noticeInfo.contents}
          onChange={handleInputChange}
          as='textarea'
          placeholder='내용을 입력해 주세요.'
          $fullWidth
          required
        />
      </Flex>
    </Card>
  );
};

NoticeContentContainer.propTypes = {
  noticeInfo: PropTypes.shape({
    title: PropTypes.string,
    contents: PropTypes.string,
    category: PropTypes.string,
    active: PropTypes.string,
  }).isRequired,
  setNoticeInfo: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default NoticeContentContainer;
