import instance from 'service/interceptor';

async function getVendorList({
  searchCondition,
  searchContent,
  pageNumber = 0,
  pageSize = 50,
} = {}) {
  const { data } = await instance.post('/api/v2/hq/vendor/list', {
    searchCondition,
    searchContent,
    pageNumber,
    pageSize,
  });

  return data;
}

export default { getVendorList };
