import ThousandSeperator from 'components/ThousandSeperator';

export const COLUMNS = [
  {
    Header: '발주 정보',
    columns: [
      {
        Header: '발주번호',
        accessor: 'productSupplyRequestId',
      },
      {
        Header: '발주유형',
        accessor: 'productSupplyTypeCodeName',
      },
      {
        Header: '발주상태',
        accessor: 'statusCodeName',
      },
      {
        Header: '선지급금',
        accessor: 'prepaidSettlement',
      },
      {
        Header: '입고번호',
        accessor: 'inboundWikey',
      },
    ],
  },

  {
    Header: '거래처 정보',
    columns: [
      { Header: '거래처 번호', accessor: 'vendorId' },
      {
        Header: '거래처명',
        accessor: 'displayName',
      },
      {
        Header: '발주 담당자',
        accessor: 'purchaseManager',
      },
    ],
  },

  {
    Header: '발주 개요',
    columns: [
      {
        Header: '품목',
        accessor: 'totalItemKindCount',
        Cell: ThousandSeperator,
      },
      {
        Header: '발주수량',
        accessor: 'totalItemQuantity',
        Cell: ThousandSeperator,
      },
      {
        Header: '발주금액(₩)',
        accessor: 'totalPrice',
        Cell: ThousandSeperator,
      },
      {
        Header: '입고예정수량',
        accessor: 'availableItemQuantity',
        Cell: ThousandSeperator,
      },
      {
        Header: '입고예정금액',
        accessor: 'availableItemPrice',
        Cell: ThousandSeperator,
      },
      {
        Header: '입고결과금액(₩)',
        accessor: 'totalReceivedPrice',
        Cell: ThousandSeperator,
      },
    ],
  },

  {
    Header: '입고 정보',
    columns: [
      { Header: '입고센터', accessor: 'centerName' },
      {
        Header: '입고요청일자',
        accessor: 'receiveRequestDay',
      },
      {
        Header: '입고예정일',
        accessor: 'receiveAvailableDay',
      },
      {
        Header: '입고완료일',
        accessor: 'receiveCompleteDay',
      },
    ],
  },

  {
    Header: '등록정보',
    columns: [
      { Header: '등록자', accessor: 'requestWorkerName' },
      { Header: '발주담당자', accessor: 'supplyManagerName' },
      {
        Header: '등록일시',
        accessor: 'createDate',
      },
      {
        Header: '수정일시',
        accessor: 'updateDate',
      },
    ],
  },
];

export const SEARCH_CONDITION = [
  {
    id: 'PRODUCT_SUPPLY_REQUEST',
    label: '발주번호',
    value: 'PRODUCT_SUPPLY_REQUEST',
  },
  {
    id: 'VENDOR_ID',
    label: '거래처 번호',
    value: 'VENDOR_ID',
  },
  {
    id: 'PRODUCT_ITEM',
    label: '품목 ID',
    value: 'PRODUCT_ITEM',
  },
  {
    id: 'INBOUND',
    label: '입고번호',
    value: 'INBOUND',
  },
  {
    id: 'VENDOR_NAME',
    label: '거래처명',
    value: 'VENDOR_NAME',
  },
  {
    id: 'CREATE',
    label: '등록자',
    value: 'CREATE',
  },
  {
    id: 'SUPPLY',
    label: '발주담당자',
    value: 'SUPPLY',
  },
];

export const SEARCH_OPTIONS_INITIAL_VALUES = {
  centerId: '',
  productSupplyTypeCode: '',
  statusCodes: [''],
  productSupplyRequestDay: [null, null],
  totalReceiveConfirmDay: [null, null],
  receiveAvailableDay: [null, null],
  prepaidSettlement: '',

  searchOption: 'PRODUCT_SUPPLY_REQUEST',
  query: '',
};

export default { COLUMNS };
