import styled from 'styled-components';

export const InnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: calc(var(--space-l) * 2);
    flex: none;
  }

  span {
    width: 95px;
  }

  p {
    margin-bottom: 0;
  }
`;

export default { InnerWrapper };
