import instance from 'service/interceptor';
import { formatDateAsYYYYMMDD } from 'commons/date';
import { returnNullIfEmptyString } from 'commons/helper';

const getAmendmentRequestList = async ({
  requestCode = null,
  statusValue = 'REQUEST',

  searchCondition = 'VENDOR_ID',
  searchContents = '',

  startRequestDay = null,
  endRequestDay = null,
  startApproveDay = null,
  endApproveDay = null,

  pageNumber = 0,
  pageSize = 100,
} = {}) => {
  const { data } = await instance.post(
    '/api/v2/hq/product/supply/amendment/request/approval/list',
    {
      requestCode: returnNullIfEmptyString(requestCode),
      statusValue: returnNullIfEmptyString(statusValue),

      searchCondition,
      searchContents,

      startRequestDay: formatDateAsYYYYMMDD(startRequestDay),
      endRequestDay: formatDateAsYYYYMMDD(endRequestDay),
      startApproveDay: formatDateAsYYYYMMDD(startApproveDay),
      endApproveDay: formatDateAsYYYYMMDD(endApproveDay),

      pageNumber,
      pageSize,
    }
  );

  return data;
};

const getProductMasterAmendmentRequestDetail = async (requestId) => {
  const { data } = await instance.get(
    `/api/v2/hq/product/supply/amendment/request/master/${requestId}`
  );

  return data;
};

/**
 *
 * @param {*} status = "approval" | "reject"
 * @returns
 */
const updateProductMasterAmendmentRequestStatus = async ({
  productSupplyItemMasterRequestId,
  memo,
  productSupplyItemMasterRequestItem,
  status,
}) => {
  const { data } = await instance.post(
    `/api/v2/hq/product/supply/amendment/request/master/${status}`,
    {
      productSupplyItemMasterRequestId,
      memo,
      productSupplyItemMasterRequestItem,
    }
  );

  return data;
};

const getVendorAmendmentRequestDetail = async (requestId) => {
  const { data } = await instance.get(
    `/api/v2/hq/product/supply/amendment/request/vendor/${requestId}`
  );

  return data;
};

/**
 *
 * @param {*} status = "approval" | "reject"
 * @returns
 */
const updateVendorAmendmentRequestStatus = async ({ id, reason, status }) => {
  const { data } = await instance.post(
    `/api/v2/hq/product/supply/amendment/request/vendor/${status}`,
    {
      id,
      reason,
    }
  );

  return data;
};

const getProductPriceAmendmentRequestDetail = async (requestId) => {
  const { data } = await instance.get(
    `/api/v2/hq/product/supply/amendment/request/price/${requestId}`
  );

  return data;
};

/**
 *
 * @param {*} status = "approval" | "reject"
 * @returns
 */
const updateProductPriceAmendmentRequestStatus = async ({
  productSupplyPriceRequestId,
  memo,
  productSupplyRequestItem,
  status,
}) => {
  const { data } = await instance.post(
    `/api/v2/hq/product/supply/amendment/request/price/${status}`,
    {
      productSupplyPriceRequestId,
      memo,
      productSupplyRequestItem,
    }
  );

  return data;
};

const getImageById = async (id) => {
  const { data } = await instance.get(`/api/v2/hq/product/supply/image/download/${id}`, {
    responseType: 'blob',
  });

  return data;
};

export default {
  getAmendmentRequestList,
  getProductMasterAmendmentRequestDetail,
  updateProductMasterAmendmentRequestStatus,
  getVendorAmendmentRequestDetail,
  updateVendorAmendmentRequestStatus,
  getProductPriceAmendmentRequestDetail,
  updateProductPriceAmendmentRequestStatus,
  getImageById,
};
