export const PAYMENT_DATE_HASH = {
  NEXT_5: '익월 5일',
  NEXT_15: '익월 15일',
  NEXT_10: '익월 10일',
  NEXT_20: '익월 20일',
  NEXT_25: '익월 25일',
  NEXT_30: '익월 30일',
  NEXT_LAST: '익월 말일',
  CUR_LAST: '당월 말일',
  NOW: '바로결제',
  RECEIVE_3: '입고 후 3일 이내',
  RECEIVE_7: '입고 후 7일 이내',
  RECEIVE_15: '입고 후 15일 이내',
  '익월 5일': 'NEXT_5',
  '익월 15일': 'NEXT_15',
  '익월 10일': 'NEXT_10',
  '익월 20일': 'NEXT_20',
  '익월 25일': 'NEXT_25',
  '익월 30일': 'NEXT_30',
  '익월 말일': 'NEXT_LAST',
  '당월 말일': 'CUR_LAST',
  바로결제: 'NOW',
  '입고 후 3일 이내': 'RECEIVE_3',
  '입고 후 7일 이내': 'RECEIVE_7',
  '입고 후 15일 이내': 'RECEIVE_15',
};

export const PAYMENT_METHOD_HASH = {
  PREPAID: '선 결제',
  POSTPAID: '후 정산',
  BOTH: '선 결제 후 차감',
  HALF: '선 50 후 50',
  '선 결제': 'PREPAID',
  '후 정산': 'POSTPAID',
  '선 결제 후 차감': 'BOTH',
  '선 50 후 50': 'HALF',
};

export const SETTLEMENT_TAXATION_TYPE_CODE_HASH = {
  NONE: '없음',
  TAX: '과세',
  ZERO: '영세',
  FREE: '면세',
  없음: 'NONE',
  과세: 'TAX',
  영세: 'ZERO',
  면세: 'FREE',
};

export const PRODUCT_SUPPLY_TYPE_CODE_HASH = {
  RE_ORDER: '리오더',
  NEW_ORDER: '신규',
  PROMOTION: '프로모션',
  RETAIL_SALE: '소매',
  REPLACE_ORDER: '대체발주',
  PREPARE_SOLD_OUT: '품절대비',
  PREPARE_INCREASE: '인상대비',
  PREPARE_EVENT: '행사대비',
  ADVERTISEMENT: '광고대체',
  신규: 'NEW_ORDER',
  리오더: 'RE_ORDER',
  프로모션: 'PROMOTION',
  소매: 'RETAIL_SALE',
  품절대비: 'PREPARE_SOLD_OUT',
  인상대비: 'PREPARE_INCREASE',
  행사대비: 'PREPARE_EVENT',
  대체발주: 'REPLACE_ORDER',
  광고대체: 'ADVERTISEMENT',
};

export const PRODUCT_SUPPLY_REQUEST_STATUS_CODE_HASH = {
  PETFRIENDS_DRAFT: '발주예정',
  PETFRIENDS_REGISTER: '발주등록',
  MODIFY: '발주수정중',
  VENDOR_REQUEST: '확정요청',
  PETFRIENDS_CONFIRM: '발주확정',
  CANCEL: '발주취소',
  발주예정: 'PETFRIENDS_DRAFT',
  발주등록: 'PETFRIENDS_REGISTER',
  발주수정중: 'MODIFY',
  확정요청: 'VENDOR_REQUEST',
  발주확정: 'PETFRIENDS_CONFIRM',
  발주취소: 'CANCEL',
};

export const SETTLEMENT_PURPOSE_TYPE_CODE_HASH = {
  NONE: '없음',
  RECEIPT: '영수',
  CLAIM: '청구',
  없음: 'NONE',
  영수: 'RECEIPT',
  청구: 'CLAIM',
};

export const TAX_INVOICE_TYPE_HASH = {
  TAX_INVOICE: '세금계산서',
  CARD: '카드',
  CASH: '현금영수증',
  CHECK_BILL: '지류 세금계산서',
  세금계산서: 'TAX_INVOICE',
  카드: 'CARD',
  현금영수증: 'CASH',
  '지류 세금계산서': 'CHECK_BILL',
};

export const SETTLEMENT_STATUS_TYPE_HASH = {
  REGISTER: '정산등록',
  COMPLETE: '정산완료',
  NORMAL_CANCEL: '정상취소',
  ABNORMAL_CANCEL: '비정상취소',
  정산등록: 'REGISTER',
  정산완료: 'COMPLETE',
  정상취소: 'NORMAL_CANCEL',
  비정상취소: 'ABNORMAL_CANCEL',
};

export const TAX_INVOICE_STATUS_CODE_HASH = {
  REQUEST: '발행요청',
  REFUSE: '발행거부',
  COMPLETE: '발행완료',
  CANCEL: '발행취소',
  NTS_REQUEST: '국세청 전송중',
  NTS_COMPLETE: '국세청 전송완료',
  NTS_FAILURE: '국세청 전송실패',
  발행요청: 'REQUEST',
  발행거부: 'REFUSE',
  발행완료: 'COMPLETE',
  발행취소: 'CANCEL',
  '국세청 전송중': 'NTS_REQUEST',
  '국세청 전송완료': 'NTS_COMPLETE',
  '국세청 전송실패': 'NTS_FAILURE',
};

export const ACTION_CODE_HASH = {
  SAVE: '등록',
  REVOKE: '회수',
  USE: '사용',
  CANCEL: '복원',
  등록: 'SAVE',
  회수: 'REVOKE',
  사용: 'USE',
  복원: 'CANCEL',
};

export const TAX_TYPE_HASH = {
  TAX: '과세',
  ZERO: '영세',
  FREE: '면세',
};

export const STORAGE_TEMPERATURE_TYPE_HASH = {
  AMBIENT: '상온',
  FRIDGE: '냉장',
  FROZEN: '냉동',
};

export default {
  paymentDate: PAYMENT_DATE_HASH,
  paymentMethod: PAYMENT_METHOD_HASH,
  settlementTaxationTypeCode: SETTLEMENT_TAXATION_TYPE_CODE_HASH,
  productSupplyTypeCode: PRODUCT_SUPPLY_TYPE_CODE_HASH,
  productSupplyRequestStatusCode: PRODUCT_SUPPLY_REQUEST_STATUS_CODE_HASH,
  settlementPurposeTypeCode: SETTLEMENT_PURPOSE_TYPE_CODE_HASH,
  taxInvoiceType: TAX_INVOICE_TYPE_HASH,
  settlementStatusType: SETTLEMENT_STATUS_TYPE_HASH,
  settlementPrice: TAX_INVOICE_STATUS_CODE_HASH,
  actionCode: ACTION_CODE_HASH,
};
