import { getTotalObject } from 'commons/helper';

export const hasInvalidColumn = (uploadResultList) =>
  uploadResultList.some(
    (result) => result.errorMessage !== null && result.errorMessage.length >= 1
  );

export const getDuplicatedProductItemIdList = (uploadResultList) => {
  const productItemIdTotals = getTotalObject(uploadResultList, (result) => result.productItemId);

  return Object.entries(productItemIdTotals)
    .filter(([, count]) => count >= 2)
    .map(([productItemId]) => productItemId);
};

export default { getDuplicatedProductItemIdList };
