import { Flex } from 'styles/components';
import Button from 'components/Button';
import Spinner from 'components/Spinner';

import * as Styled from './styled';

const Card = ({
  title,
  description,
  label,
  onClick,
  children,
  buttonDisabled,
  isLoading,
  right,
  ...props
}) => {
  const isHeader = title || description || label;
  return (
    <Styled.Wrapper {...props}>
      {isHeader && (
        <Styled.Header>
          <Flex $alignItems='center'>
            <Styled.Title>{title}</Styled.Title>
            <Styled.Description>{description}</Styled.Description>
          </Flex>

          {label && (
            <Button onClick={onClick} disabled={buttonDisabled} $variant='secondary'>
              {label}
            </Button>
          )}

          {right && right()}
        </Styled.Header>
      )}

      <Styled.Body>{isLoading ? <Spinner /> : children}</Styled.Body>
    </Styled.Wrapper>
  );
};

export default Card;
