import instance from 'service';

/**
 *
 * @param {yearMonth} "2023-01"
 */
export const postVendorPurchaseOrderCalendar = async (params) => {
  const { data } = await instance.post('/api/v2/product/supply/purchase-order-calendars', params);

  return data;
};

/**
 * 발주금액, 입고금액
 * @param {yearMonth} "2023-03"
 * @returns
 */
export const postVendorPurchaseOrderOverview = async (params) => {
  return instance.post('/api/v2/product/supply/purchase-order-overviews', params);
};

/**
 * 정산대상금액
 * @param {yearMonth} "2023-03"
 * @returns
 */
export const getVendorPurchaseSettlementOverview = async (params) => {
  return instance.post('/api/v2/product/supply/settlement-overviews', params);
};

/**
 *
 * @param {yearMonth} "2022-23"
 */
export const getVendorOverviewData = async (params) => {
  const result = await Promise.allSettled([
    postVendorPurchaseOrderOverview(params),
    getVendorPurchaseSettlementOverview(params),
  ]);

  const data = result.reduce((acc, cur) => {
    const { value } = cur;
    const { data } = value;
    const { data: item } = data?.response;

    return { ...acc, ...item };
  }, {});

  return data;
};
