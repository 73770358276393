import styled from 'styled-components';

const Wrapper = styled.div.attrs({ className: 'input-group' })`
  display: flex;
  align-items: flex-end;

  select {
    border-radius: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  > .form-control {
    position: static;
    height: var(--base-height);
  }

  button {
    height: var(--base-height);
    border-radius: 0;

    &:last-child {
      border-top-right-radius: var(--radius-m);
      border-bottom-right-radius: var(--radius-m);
    }
  }
`;

const Input = styled.input.attrs({ className: 'form-control' })`
  height: 58px;
  border: 1px solid var(--grey300);
  color: var(--grey900) !important;

  ::placeholder {
    color: var(--grey400);
  }
`;

export { Wrapper, Input };
