import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { getHash, thousandSeparator } from 'commons/helper';

import Table from 'components/Table';
import Select from 'components/Select';
import DateCell from 'components/Table/components/Cell/DateCell';
import EditableCell from 'components/Table/components/Cell/EditableCell';

import Input from 'components/Input';
import Button from 'components/Button';
import Styled from './styled';
import { setInitDataList } from './helper';

/**
 * @param {*} props
 */
const TaxInvoiceContainer = ({
  prevTaxInvoiceListData,
  labelText,
  columns,
  datas = [],
  index = 0,
  handleStateChange,
}) => {
  const {
    taxInvoiceInfoList = [],
    productSupplySettlementId,
    requestInfoList = [],
    reverses = [],
  } = datas[index] || {};

  const isEditableSkipLine = useMemo(() => {
    if (requestInfoList.length && reverses.length) return 1;
    return 0;
  }, [JSON.stringify(requestInfoList), JSON.stringify(reverses)]);

  const [state, setState] = useState(
    setInitDataList(taxInvoiceInfoList, prevTaxInvoiceListData[index])
  );

  useEffect(() => {
    const isSaleButtonTrigger =
      prevTaxInvoiceListData[index] &&
      taxInvoiceInfoList.length !== prevTaxInvoiceListData[index].length;

    setState(
      setInitDataList(taxInvoiceInfoList, isSaleButtonTrigger ? prevTaxInvoiceListData[index] : [])
    );
  }, [index]);

  const setStateHandler = (data) => {
    setState(data);
    handleStateChange(data);
  };

  const updateMyDataChange = (rowIndex, columnName, v) => {
    const newData = [...state];
    newData[rowIndex] = { ...newData[rowIndex], [columnName]: v };
    setStateHandler(newData);
  };

  const handleTaxInvoiceItemNameChange = (e, index) => {
    if (e.target.value.length > 100) return;
    updateMyDataChange(index, 'taxInvoiceItemName', e.target.value);
  };

  const handleTaxInvoiceTransFormDay = (columnName, v) => {
    const newState = [...state].map((it) => ({ ...it, [columnName]: v }));
    setStateHandler(newState);
  };

  const deleteTaxInvoiceList = (rowIndex) => {
    const newState = [...state].filter((_, index) => rowIndex !== index);
    setStateHandler(newState);
  };

  const addTaxInvoiceList = () => {
    const [defaultRow] = state;
    const { taxInvoiceTransformDay, settlementTaxationTypeCode } = defaultRow;

    const taxObj = {
      approvalNumber: null,
      ntsConfirmNum: null,
      settlementPrice: 0,
      settlementPurposeTypeCode: null,
      settlementTaxationTypeCode,
      supplyCostTotal: 0,
      taxInvoiceId: null,
      taxInvoiceItemName: '',
      taxInvoiceStatusCode: null,
      taxInvoiceTransformDay,
      taxTotal: 0,
    };

    const newState = [...state, taxObj];

    setStateHandler(newState);
  };

  const setCalculateCost = (e, row) => {
    const rows = [...state];

    const isTaxationTypeCodeFree = row.original?.settlementTaxationTypeCode === 'FREE';
    const valueNumber = Number(e.target.value);

    if (isTaxationTypeCodeFree) {
      rows[row.index] = {
        ...rows[row.index],
        supplyCostTotal: thousandSeparator(valueNumber),
        taxTotal: 0,
        settlementPrice: thousandSeparator(valueNumber),
      };
    } else {
      const IsNotNumber = Number.isNaN(e.target.value);
      const supplyCostTotalValue = IsNotNumber ? 0 : Math.floor((valueNumber / 11) * 10);
      const taxTotalValue = IsNotNumber ? 0 : valueNumber - supplyCostTotalValue;

      rows[row.index] = {
        ...rows[row.index],
        supplyCostTotal: thousandSeparator(supplyCostTotalValue),
        taxTotal: thousandSeparator(taxTotalValue),
        settlementPrice: thousandSeparator(valueNumber),
      };
    }

    setStateHandler(rows);
  };

  const setUnitValue = (e, row) => {
    const rows = [...state];

    rows[row.index] = {
      ...rows[row.index],
      settlementPrice: e.target.value.replace(/,/g, ''),
    };

    setStateHandler(rows);
  };

  const renderCell = (cell) => {
    const { column, value, row } = cell;
    const { id, Header, isEditable } = column;

    const editColumn = isEditable && {
      [id === 'approvalNumber' && id]: (
        <EditableCell {...cell} value={value || ''} updateMyData={updateMyDataChange} required />
      ),
      [(id === 'supplyCostTotal' || id === 'taxTotal') && id]: (
        <EditableCell
          {...cell}
          value={parseInt(value, 10)}
          type='number'
          updateMyData={updateMyDataChange}
        />
      ),
      [id === 'taxInvoiceTransformDay' && id]: (
        <DateCell
          row={row}
          value={value}
          onChange={(e) => handleTaxInvoiceTransFormDay('taxInvoiceTransformDay', e.target.value)}
          disabled={!!row.index}
          required
        />
      ),
      [id === 'settlementPurposeTypeCode' && id]: (
        <Select
          id='settlement-purpose-type-code'
          value={value}
          onChange={(e) =>
            updateMyDataChange(row.index, 'settlementPurposeTypeCode', e.target.value)
          }
          options={[
            { key: 'NONE', value: '없음' },
            { key: 'RECEIPT', value: '영수' },
            { key: 'CLAIM', value: '청구' },
          ]}
        />
      ),
      [id === 'taxInvoiceItemName' && id]: (
        <Input
          id='taxInvoiceItemName'
          value={value ?? ''}
          onChange={(e) => handleTaxInvoiceItemNameChange(e, row.index)}
        />
      ),
      [id === 'settlementPrice' && id]:
        row.index > isEditableSkipLine ? (
          <Input
            type='text'
            id='settlementPrice'
            value={value}
            onChange={(e) => updateMyDataChange(row.index, 'settlementPrice', e.target.value)}
            onFocus={(e) => setUnitValue(e, row)}
            onBlur={(e) => setCalculateCost(e, row)}
          />
        ) : (
          thousandSeparator(value)
        ),
      [id === 'delete' && id]: (
        <Button
          $outline
          $variant={row.index <= isEditableSkipLine ? 'secondary' : 'primary'}
          disabled={row.index <= isEditableSkipLine}
          onClick={() => deleteTaxInvoiceList(row.index)}
        >
          삭제
        </Button>
      ),
    };

    const lastValue = thousandSeparator(value) === '' ? '0' : thousandSeparator(value);

    return {
      [Header.match(/₩/) && id]: lastValue,
      [getHash(id, value) && id]: getHash(id, value),
      index: row.index + 1,
      supplyCostTotal: lastValue,
      taxTotal: lastValue,
      ...editColumn,
    }[id];
  };

  return (
    <Styled.Div>
      <h5 style={{ padding: '1rem 0' }}>{labelText || '세금계산서 금액 계산'}</h5>
      <Table
        columns={
          productSupplySettlementId ? columns.map((c) => ({ ...c, isEditable: false })) : columns
        }
        data={state}
        setData={setState}
        $height='unset'
        $width='unset'
        renderCell={renderCell}
      />
      {!labelText && (
        <div>
          <br />
          <Button $variant='success' onClick={addTaxInvoiceList}>
            세금계산서 내역 추가
          </Button>
        </div>
      )}
    </Styled.Div>
  );
};

TaxInvoiceContainer.propTypes = {
  index: PropTypes.number,
  labelText: PropTypes.string,
  columns: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  datas: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  prevTaxInvoiceListData: PropTypes.object,
  handleStateChange: PropTypes.func,
};

export default TaxInvoiceContainer;
