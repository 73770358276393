import { REQUEST_STATUS_CODE } from 'commons/constant';

export function isOnlyTargetProductsEnabled(statusCode) {
  return (
    statusCode === REQUEST_STATUS_CODE.REQUEST ||
    statusCode === REQUEST_STATUS_CODE.APPROVE ||
    statusCode === REQUEST_STATUS_CODE.CANCEL
  );
}

export function getProductSupplyRequestItem(targetProducts) {
  return targetProducts.map(
    ({
      active,
      productSupplyPriceRequestItemId: id,
      productItemId,
      beforeProductStandardUnitPrice,
      afterProductStandardUnitPrice,
      beforeMinimumOrderQuantity,
      afterMinimumOrderQuantity,
      beforeTax,
      reasonForChange,
      petfriendsComment,
      effectiveDate,
    }) => ({
      active,
      id,
      productItemId,
      beforeProductStandardUnitPrice,
      afterProductStandardUnitPrice,
      beforeMinimumOrderQuantity,
      afterMinimumOrderQuantity,
      beforeTax,
      reasonForChange,
      petfriendsComment,
      effectiveDate: effectiveDate ?? null,
    })
  );
}

export function getProductSupplyItemMasterRequestItem(targetProducts) {
  return targetProducts.map(
    ({
      active,
      productSupplyPriceRequestItemId: id,
      productItemId,

      itemBarcode,
      boxBarcode,
      boxUnit,
      pltUnit,
      width,
      length,
      height,
      weight,
      volume,
      storageTemperature,
      effectiveDate,
      reasonForChange,
    }) => ({
      active,
      id,
      productItemId,

      itemBarcode: itemBarcode || null,
      boxBarcode: boxBarcode || null,
      boxUnit: boxUnit || null,
      pltUnit: pltUnit || null,
      width: width || null,
      length: length || null,
      height: height || null,
      weight: weight || null,
      volume: volume || null,
      storageTemperature: storageTemperature || null,
      effectiveDate: effectiveDate || null,
      reasonForChange: reasonForChange || null,
    })
  );
}

export const isNoProductsSelected = (products) =>
  products.filter(({ isSelected }) => isSelected).length === 0;

export default {
  isOnlyTargetProductsEnabled,
  getProductSupplyRequestItem,
  getProductSupplyItemMasterRequestItem,
  isNothingSelected: isNoProductsSelected,
};
