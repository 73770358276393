import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import Card from 'components/Card';
import SupplyRequestStatus from 'components/SupplyRequestStatus';

const SupplyDetail = ({ statusCode }) => {
  const { productSupplyRequestId = '' } = useParams();
  const cardTitle = `발주 상세 - ${productSupplyRequestId}`;

  return (
    <Card title={cardTitle}>
      <SupplyRequestStatus currentStatusCode={statusCode} hideDraft />
    </Card>
  );
};

SupplyDetail.propTypes = {
  statusCode: PropTypes.oneOf([
    'PETFRIENDS_DRAFT',
    'PETFRIENDS_REGISTER',
    'MODIFY',
    'VENDOR_REQUEST',
    'PETFRIENDS_CONFIRM',
    'CONFIRM_CANCEL',
    'CANCEL',
    '',
  ]),
};

export default SupplyDetail;
