import styled from 'styled-components';
import { flexRowCenter } from 'styles/commons';

export const Wrapper = styled.div`
  ${flexRowCenter}
`;

export const Date = styled.time`
  color: var(--grey500);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-medium) !important;
  white-space: nowrap;
  min-width: 145px;
  margin-right: var(--space-m);
`;

export const Category = styled.span`
  border: 1px solid var(--grey900);
  padding: calc(var(--space-s) / 2) var(--space-s);
  border-radius: var(--radius-s);
  white-space: nowrap;

  border: 1px solid var(--red50);
  background-color: var(--red50);
  color: var(--red500);
`;

export default {
  Wrapper,
  Date,
  Category,
};
