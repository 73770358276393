import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import supplyApi from 'service/hq/supply';
import { setObject } from 'commons/helper';
import useFetch from 'hooks/useFetch';

import { Flex } from 'styles/components';
import TextareaSearchbar from 'components/TextareaSearchbar';
import ResultHeader from 'components/ResultHeader';
import Card from 'components/Card';
import PaginationTable from 'components/Table/PaginationTable';
import { ROW_SELECT_PLUGIN_HOOKS } from 'components/Table/commons/constant';

import useSearchParams from 'hooks/useSearchParams';
import useSearchParamsState from 'hooks/useSearchParamsState';

import { setPrevAdminSupplyValue } from 'commons/store/modules/admin/supply';
import { SEARCH_CONDITION, COLUMNS, SEARCH_OPTIONS_INITIAL_VALUES } from './commons/constant';

import ProductCell from './components/ProductCell';
import SupplyRequestCell from './components/SupplyRequestCell';
import HeaderButtons from './components/HeaderButtons';
import SearchOptions from './components/SearchOptions';
import { getFetchSupplyRequestListParams } from './commons/helper';

const AdminSupplyManagePage = () => {
  const dispatch = useDispatch();
  const { prevAdminSupplyValue } = useSelector((state) => state.supply);

  const [searchOptions, setSearchOptions] = useSearchParamsState(SEARCH_OPTIONS_INITIAL_VALUES);
  const [searchParams, setQueryParams] = useSearchParams();

  const [columns, setColumns] = useState(COLUMNS);
  const [controlledPageIndex, setControlledPage] = useState(() =>
    Number(searchParams.get('pageNumber'))
  );
  const [selectedProductSupplyRequestList, setSelectedProductSupplyRequestList] = useState([]);

  const searchOptionsSnapshot = useRef(SEARCH_OPTIONS_INITIAL_VALUES);

  const {
    data: { content = [], totalElements = 0, totalPages = 0 } = {},
    execute: fetchSupplyRequestList,
  } = useFetch(supplyApi.getSupplyRequestList, { initialExecute: false });

  const tableOptions = {
    initialState: { pageSize: 50 },
    manualPagination: true,
    pageCount: totalPages,
    useControlledState: (state) => ({
      ...state,
      pageIndex: Number.isNaN(Number(searchParams.get('pageNumber'))) ? 0 : controlledPageIndex,
    }),
  };

  const setState = (field) => (value) => {
    setSearchOptions((prev) => ({
      ...prev,
      [field]: typeof value === 'function' ? value(prev[field]) : value,
    }));
  };

  const handleSearchClick = () => {
    const query = searchOptions.query.replaceAll('\n', '|');

    setQueryParams({ ...searchOptions, query });
    searchOptionsSnapshot.current = searchOptions;
    setControlledPage(0);
    fetchSupplyRequestList(getFetchSupplyRequestListParams(searchOptions));
  };

  const handleSelectChange = (e) => setState(e.target.name)(e.target.value);

  const handleSearchQueryChange = (e) => setState('query')(e.target.value);

  const handleResetClick = () => {
    setQueryParams(SEARCH_OPTIONS_INITIAL_VALUES);
    searchOptionsSnapshot.current = SEARCH_OPTIONS_INITIAL_VALUES;
    setControlledPage(0);
    setSearchOptions(SEARCH_OPTIONS_INITIAL_VALUES);
    fetchSupplyRequestList();
  };

  const handlePageChange = ({ state }) => {
    const pageNumber = state.pageIndex;
    setQueryParams({ ...searchOptions, pageNumber });
    fetchSupplyRequestList(getFetchSupplyRequestListParams(searchOptions, pageNumber));
  };

  const handleTableSelectChange = ({ selectedFlatRows }) =>
    setSelectedProductSupplyRequestList(selectedFlatRows.map((item) => item.original));

  useEffect(() => {
    if (prevAdminSupplyValue.supplyId) {
      handlePageChange({ state: controlledPageIndex });
      dispatch(setPrevAdminSupplyValue({ supplyId: null }));
    }

    const newColumns = [...columns];
    newColumns[0].columns[0] = setObject(newColumns[0].columns[0], 'Cell', SupplyRequestCell);
    newColumns[2].columns[0] = setObject(newColumns[2].columns[0], 'Cell', ProductCell);
    setColumns(newColumns);
  }, []);

  return (
    <Card title='발주 관리'>
      <Flex $flexDirection='column' $gap='var(--space-m)'>
        <SearchOptions searchOptions={searchOptions} setState={setState} />
        <TextareaSearchbar
          name='searchOption'
          textareaValue={searchOptions.query}
          onTextareaChange={handleSearchQueryChange}
          radios={SEARCH_CONDITION}
          onSearchClick={handleSearchClick}
          onResetClick={handleResetClick}
          checkedValue={searchOptions.searchOption}
          onCheckedValueChange={handleSelectChange}
          placeholder='검색어를 입력해 주세요. 구분 문자 (쉼표, 엔터)로 복수 품목을 검색할 수 있습니다.'
        />
        <HeaderButtons
          searchOptionsSnapshot={searchOptionsSnapshot}
          selectedProductSupplyRequestList={selectedProductSupplyRequestList}
          fetchSupplyRequestList={fetchSupplyRequestList}
          controlledPageIndex={controlledPageIndex}
        />
      </Flex>
      <ResultHeader totalCount={totalElements} />
      <PaginationTable
        columns={columns}
        data={content}
        onPageChange={handlePageChange}
        tableOptions={tableOptions}
        pluginHooks={ROW_SELECT_PLUGIN_HOOKS}
        onSelectChange={handleTableSelectChange}
        setControlledPage={setControlledPage}
      />
    </Card>
  );
};

export default AdminSupplyManagePage;
