import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import prepaidApi from 'service/hq/prepaid';
import { handleError, isEmpty, updateMyDataMaker } from 'commons/helper';
import toastify from 'commons/toast';
import { usePageModals } from 'hooks/pages';
import { getPrepaidList } from 'commons/store/modules/admin/prepaid';

import HeaderModal from 'components/modals/HeaderModal';
import Button from 'components/Button';
import ResultHeader from 'components/ResultHeader';
import Table from 'components/Table';
import { ROW_SELECT_PLUGIN_HOOKS } from 'components/Table/commons/constant';
import RegistTextArea from './components/RegistTextArea';

import { COLUMNS } from './commons/constant';
import { addAdditionalColumns, filterParameters } from './commons/helper';

import * as Styled from './styled';
import EditableCell from './components/EditableCell';

const PrepaidRegisterModal = () => {
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendorIndexes, setSelectedVendorIndexes] = useState([]);
  const autoResetSelectedRows = useRef();

  const { pathname } = useLocation();
  const { pageIndex: prepaidListPageIndex, searchFilter } = useSelector(
    (state) => state.prepaid[pathname]
  );
  const dispatch = useDispatch();

  const [{ detail }, setModalOpen] = usePageModals();

  const tableOptions = {
    defaultColumn: {
      Cell: EditableCell,
    },
    autoResetSelectedRows: autoResetSelectedRows.current,
    updateMyData: updateMyDataMaker(setVendorList, { autoResetSelectedRows }),
  };

  const getVendorList = async ({ searchCondition = 'VENDOR_NAME', searchContent = '' } = {}) => {
    try {
      const { response } = await prepaidApi.getVendorList({
        searchCondition,
        searchContent,
      });
      const searchedVendorList = response.data;
      setVendorList(addAdditionalColumns(searchedVendorList));
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    autoResetSelectedRows.current = true;
  }, [vendorList]);

  useEffect(() => {
    if (!detail) setVendorList([]);
  }, [detail]);

  async function handleSubmit(e) {
    e.preventDefault();
    if (isEmpty(selectedVendorIndexes)) {
      toastify('선택된 거래처가 존재하지 않습니다.', { variant: 'error' });
      return;
    }
    const selectedVendorList = vendorList.filter((_, index) =>
      selectedVendorIndexes.includes(index)
    );
    try {
      await prepaidApi.registerPrepaid(filterParameters(selectedVendorList));
      toastify('저장되었습니다.', { variant: 'success' });
      dispatch(getPrepaidList({ ...searchFilter, pageNumber: prepaidListPageIndex }));
      setModalOpen('detail');
    } catch (error) {
      handleError(error);
    }
  }

  const handleTableSelectChange = ({ selectedFlatRows }) => {
    const selectedIndexes = selectedFlatRows.map(({ index }) => index);
    setSelectedVendorIndexes(selectedIndexes);
  };

  const resultHeaderButtons = [
    <Button type='submit' $variant='secondary'>
      선택된 거래처 저장
    </Button>,
  ];
  return (
    <HeaderModal
      isOpen={detail}
      closeCallback={() => setModalOpen('detail')}
      title='선지급금 등록/회수'
      $size='xl'
    >
      <RegistTextArea detail={detail} search={getVendorList} />
      <form onSubmit={handleSubmit}>
        <ResultHeader totalCount={vendorList.length} buttons={resultHeaderButtons} />
        <Styled.TableWrapper>
          <Table
            columns={COLUMNS}
            data={vendorList}
            tableOptions={tableOptions}
            noResultMessage='선지급금을 등록할 거래처를 먼저 검색해 주세요.'
            pluginHooks={ROW_SELECT_PLUGIN_HOOKS}
            onSelectChange={handleTableSelectChange}
          />
        </Styled.TableWrapper>
      </form>
    </HeaderModal>
  );
};

export default PrepaidRegisterModal;
