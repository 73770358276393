export function addDefaultValues(data) {
  return {
    ...data,
    content: data?.content.map((item) => ({
      ...item,
      itemBarcode: item.itemBarcode || null,
      boxBarcode: item.boxBarcode || null,
      boxUnit: item.boxUnit || null,
      cartonUnit: item.cartonUnit || null,
      pltUnit: item.pltUnit || null,
      width: item.width || null,
      length: item.length || null,
      height: item.height || null,
      weight: item.weight || null,
      volume: item.volume || null,
      storageTemperature: item.storageTemperature || null,
      shelfLifeDays: item.shelfLifeDays || null,
    })),
  };
}

export function getUpdateItemList(content) {
  return content.map((item) => ({
    productSupplyItemMasterId: item.productSupplyItemMasterId,
    productItemId: item.productItemId,
    itemBarcode: item.itemBarcode,
    boxBarcode: item.boxBarcode,
    boxUnit: item.boxUnit,
    pltUnit: item.pltUnit,
    width: item.width,
    length: item.length,
    height: item.height,
    weight: item.weight,
    volume: item.volume,
    storageTemperature: item.storageTemperature,
    outboundDivisionUnit: item.outboundDivisionUnit,
    noShippingConversionDays: item.noShippingConversionDays,
    imminentStockConversionDays: item.imminentStockConversionDays,
  }));
}

export default { addDefaultValues };
