import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko');

export const formatDate = (date, form) => dayjs(date).format(form);

const manipulateDate = ({ date, method, number, unit }) => dayjs(date)[method](number, unit);

export const formatDateAsYYYYMMDD = (date) =>
  date === null ? null : formatDate(date, 'YYYY-MM-DD');

export const getSubtractedDate = (number, date) =>
  manipulateDate({ date, method: 'subtract', number, unit: 'day' });

export const formatStringAsDate = (stringDate) => stringDate && new Date(stringDate);

export const getThisMonthStartEndDate = () => {
  const date = new Date();
  return {
    firstDay: new Date(date.getFullYear(), date.getMonth(), 1),
    today: date,
  };
};
