import styled, { createGlobalStyle } from 'styled-components';

export const CalendarStyle = createGlobalStyle`

  .fc {
    width: 70%;
  }

  .fc-day-sat, .fc-day-sun {
    background: #F2F4F6;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 20px;
    padding: 0;
  }

  .fc-col-header-cell-cushion {
    font-weight: 500;
    font-size: 14px;
  }

  .fc .fc-daygrid-day-frame {
    height: 110px;
  }

  .fc .fc-daygrid-day-number {
    padding-right: 7px;
  }

  .fc .fc-bg-event {
    background: #F8DCDC;
    opacity: unset;
  }

  .fc .fc-h-event {
    background: transparent;
    border-color: transparent;
  }

  .fc .fc-daygrid-day-number {
    width: 100%;
  }

  .calendar-wrapper {
    display: flex;
    column-gap: 10px;
    overflow: hidden;
    position: relative;

    &.vendor {
      .fc {
        width: 100%;
      }
    }
  }
`;

export const CustomHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => props.width || 'calc(70% - 10px)'};
`;
