import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { REQUEST_STATUS_CODE } from 'commons/constant';
import { Flex } from 'styles/components';

import HeaderModal from 'components/modals/HeaderModal/HeaderModal';

import Notice from './components/Notice';
import RequestInfo from './components/RequestInfo';
import ProcessStatus from './components/ProcessStatus/ProcessStatus';
import ProductsSelection from './components/ProductsSelection';
import TargetProducts from './components/TargetProducts';
import { isProductsSelectionEnabled } from './commons/helper';

const ProductManageModal = ({ isOpen, setModalOpen, request, setRequest }) => {
  const { target, productSupplyPriceRequestInfo } = request;
  const { status: statusCode } = productSupplyPriceRequestInfo;
  const [products, setProducts] = useState({ pageCount: 0, pageResult: [] });
  const [targetProducts, setTargetProducts] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      setTargetProducts([]);
    }
  }, [isOpen]);

  return (
    <HeaderModal title={target} $size='xl' isOpen={isOpen} closeCallback={setModalOpen}>
      <Flex $flexDirection='column' $gap='var(--space-l)'>
        <Notice target={target} />
        {statusCode !== null && (
          <Flex $flexDirection='column' $gap='var(--space-l)'>
            <RequestInfo requestInfo={productSupplyPriceRequestInfo} />
            <ProcessStatus requestInfo={productSupplyPriceRequestInfo} />
          </Flex>
        )}
        <Flex $justifyContent='space-between'>
          {isProductsSelectionEnabled(statusCode) && (
            <ProductsSelection
              products={products}
              setProducts={setProducts}
              targetProducts={targetProducts}
              setTargetProducts={setTargetProducts}
              request={request}
            />
          )}
          <TargetProducts
            targetProducts={targetProducts}
            setTargetProducts={setTargetProducts}
            request={request}
            setRequest={setRequest}
            setModalOpen={setModalOpen}
          />
        </Flex>
      </Flex>
    </HeaderModal>
  );
};

ProductManageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  request: PropTypes.shape({
    target: PropTypes.string.isRequired,
    productSupplyPriceRequestInfo: PropTypes.shape({
      status: PropTypes.oneOf([
        REQUEST_STATUS_CODE.SAVE,
        REQUEST_STATUS_CODE.REQUEST,
        REQUEST_STATUS_CODE.REJECT,
        REQUEST_STATUS_CODE.APPROVE,
        REQUEST_STATUS_CODE.CANCEL,
        null,
      ]),
    }),
  }).isRequired,
  setRequest: PropTypes.func.isRequired,
};

export default ProductManageModal;
