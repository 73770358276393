import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import productMasterApi from 'service/hq/master';
import { downloadJsonAsExcel } from 'commons/excel';
import { handleError, isEmpty } from 'commons/helper';

import toastify from 'commons/toast';
import { Flex } from 'styles/components';
import Card from 'components/Card';
import Button from 'components/Button';
import Table from 'components/Table';
import FileUpload from 'components/FileUpload';

import { SAMPLE_DATA, COLUMNS } from './commons/constant';

function AdminReceivingUploadPage() {
  const [uploadedList, setUploadedList] = useState([]);
  const history = useHistory();

  const handleSampleDownloadClick = () => {
    downloadJsonAsExcel({
      data: SAMPLE_DATA,
      sheetName: '품목 마스터 대량 등록 및 수정 샘플',
      fileName: '품목 마스터 대량 등록 및 수정 샘플',
    });
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];

    try {
      const { response } = await productMasterApi.readUploadedExcel(file);
      setUploadedList(response.data);
    } catch (error) {
      handleError(error);
    }
  };

  const isInvalid = (itemList) => itemList.some((item) => item.errorMessage !== '');

  const handleSaveClick = async () => {
    if (isEmpty(uploadedList)) {
      toastify('업로드된 엑셀 파일이 존재하지 않습니다.', { variant: 'warn' });
      return;
    }
    if (isInvalid(uploadedList)) {
      toastify('형식에 맞지 않는 데이터가 존재합니다. 비고 항목을 참고해주세요.', {
        variant: 'warn',
      });
      return;
    }

    try {
      await productMasterApi.saveUploadedExcel(uploadedList);
      toastify(`품목 마스터 ${uploadedList.length}건이 저장되었습니다.`, { variant: 'success' });
      history.push('/admin/product/list');
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Card title='품목 마스터 대량 등록/수정'>
      <Flex $flexDirection='column' $gap='var(--space-m)'>
        <b>엑셀 업로드</b>
        <Flex $gap='var(--space-m)'>
          <Button $variant='secondary' onClick={handleSampleDownloadClick}>
            샘플 다운로드
          </Button>
          <FileUpload onChange={handleFileInputChange} />
        </Flex>
        <b>엑셀 업로드 결과</b>
        <Table columns={COLUMNS} data={uploadedList} />
        <Flex>
          <Button onClick={handleSaveClick}>저장</Button>
        </Flex>
      </Flex>
    </Card>
  );
}

export default AdminReceivingUploadPage;
