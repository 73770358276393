import PropTypes from 'prop-types';

import { REQUEST_STATUS, REQUEST_STATUS_CODE } from 'commons/constant';
import * as StyledPage from 'styles';
import { Flex } from 'styles/components';

import * as Styled from './styled';

const ProcessStatus = ({ requestInfo }) => {
  const { status, updatedAt } = requestInfo;
  const memo = requestInfo.memo ?? '';

  return (
    <Flex $flexDirection='column'>
      <StyledPage.SectionTitle>처리 상태</StyledPage.SectionTitle>
      <Flex $flexDirection='column' $gap='var(--space-s)'>
        <Flex $alignItems='center' $gap='var(--space-m)'>
          <StyledPage.Status $status={status}>{REQUEST_STATUS[status]}</StyledPage.Status>
          <span>
            {updatedAt} {REQUEST_STATUS[status]} 되었습니다.
          </span>
        </Flex>
        <Styled.Input value={memo} disabled />
      </Flex>
    </Flex>
  );
};

ProcessStatus.propTypes = {
  requestInfo: PropTypes.shape({
    status: PropTypes.oneOf([
      REQUEST_STATUS_CODE.SAVE,
      REQUEST_STATUS_CODE.REQUEST,
      REQUEST_STATUS_CODE.REJECT,
      REQUEST_STATUS_CODE.APPROVE,
      REQUEST_STATUS_CODE.CANCEL,
    ]),
    updatedAt: PropTypes.string,
    memo: PropTypes.string,
  }),
};

export default ProcessStatus;
