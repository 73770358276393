import instance from 'service/interceptor';

async function getRequests({ pageNumber = 0, pageSize = 10 } = {}) {
  const { data } = await instance.post(`/api/v2/product/supply/item/master/request/list`, {
    pageNumber,
    pageSize,
  });

  return data;
}

async function getRequestDetail(requestId) {
  const { data } = await instance.get(
    `/api/v2/product/supply/item/master/request/info?productSupplyItemMasterRequestId=${requestId}`
  );

  return data;
}

async function isIncludedInTargetProducts(productItemIdList) {
  const { data } = await instance.post('/api/v2/product/supply/item/master/target/item/check', {
    productItemIdList,
  });

  return data;
}

async function saveProducts(
  productSupplyItemMasterRequestItem,
  productSupplyItemMasterRequestId = null
) {
  const { data } = await instance.post('/api/v2/product/supply/item/master/save/request', {
    productSupplyItemMasterRequestId,
    productSupplyItemMasterRequestItem,
  });

  return data;
}

async function modifyProducts(
  productSupplyItemMasterRequestItem,
  productSupplyItemMasterRequestId = null
) {
  const { data } = await instance.post('/api/v2/product/supply/item/master/change/request', {
    productSupplyItemMasterRequestId,
    productSupplyItemMasterRequestItem,
  });

  return data;
}

async function cancelModifyProducts(productSupplyItemMasterRequestId) {
  const { data } = await instance.get(
    `/api/v2/product/supply/item/master/cancel/request?productSupplyItemMasterRequestId=${productSupplyItemMasterRequestId}`
  );

  return data;
}

async function getProducts({ pageNumber = 0, pageSize = 100, query = '', requestId } = {}) {
  const { data } = await instance.post(`/api/v2/product/supply/item/master/product/item/list`, {
    pageNumber,
    pageSize,
    productName: query,
    requestId,
  });

  return data;
}

export default {
  getRequests,
  getRequestDetail,
  isIncludedInTargetProducts,
  saveProducts,
  modifyProducts,
  cancelModifyProducts,
  getProducts,
};
