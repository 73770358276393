/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';

const setSum = (list, key) =>
  list
    .map((it) => it[key])
    .reduce((acc, cur) => {
      const current = String(cur).indexOf(',') !== -1 ? cur.replaceAll(',', '') : cur;
      acc += Number(current);
      return acc;
    }, 0);

export const useSum = (datas = {}, prevDatas = []) => {
  const { settlementInfo = {}, taxInvoiceInfoList, requestInfoList = [], reverses = [] } = datas;
  const prevTaxInvoiceList = [...prevDatas];
  const targetList = !prevTaxInvoiceList.length ? taxInvoiceInfoList : prevTaxInvoiceList;

  const checkIndex = useMemo(() => {
    if (requestInfoList.length && reverses.length) return 2;
    return 1;
  }, [JSON.stringify(requestInfoList), JSON.stringify(reverses)]);

  const 입고결과금액합계 = useMemo(
    () => settlementInfo.receivedQuantityPrice,
    [settlementInfo.receivedQuantityPrice]
  );

  const 기타비용합계 = useMemo(() => {
    if (!Object.keys(datas).length) return 0;
    return setSum(
      targetList.filter((_, index) => index >= checkIndex),
      'settlementPrice'
    );
  }, [checkIndex, JSON.stringify(prevTaxInvoiceList), JSON.stringify(taxInvoiceInfoList)]);

  const 할인적용금액합계 = useMemo(
    () => settlementInfo.discountApplyPrice,
    [settlementInfo.discountApplyPrice]
  );

  const 반품금액합계 = useMemo(() => {
    if (!Object.keys(datas).length) return 0;
    return settlementInfo.reversedPrice;
  }, [settlementInfo.reversedPrice]);

  const 공급가액합계 = useMemo(() => {
    if (!Object.keys(datas).length) return 0;
    return Math.floor(setSum(targetList, 'supplyCostTotal'));
  }, [JSON.stringify(prevTaxInvoiceList), JSON.stringify(taxInvoiceInfoList)]);

  const 세액합계 = useMemo(() => {
    if (!Object.keys(datas).length) return 0;
    return setSum(targetList, 'taxTotal');
  }, [JSON.stringify(prevTaxInvoiceList), JSON.stringify(taxInvoiceInfoList)]);

  const 최종정산금액합계 = useMemo(
    () => 입고결과금액합계 + 기타비용합계 - 할인적용금액합계 - 반품금액합계,
    [입고결과금액합계, 할인적용금액합계, 기타비용합계, 반품금액합계]
  );

  return {
    입고결과금액합계,
    기타비용합계,
    할인적용금액합계,
    최종정산금액합계,
    공급가액합계,
    세액합계,
    반품금액합계,
  };
};
