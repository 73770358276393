import PropTypes from 'prop-types';

import { updateNestedObject } from 'commons/helper';

import Card from 'components/Card';
import InputLabel from 'components/InputLabel';

import * as Styled from './styled';

const CommentInfoForm = ({ commentInfo, setSupplyRequestDetail, statusCode }) => {
  const handleInputChange = (e) =>
    setSupplyRequestDetail((prev) =>
      updateNestedObject(prev, ['commentInfo', e.target.name], () => e.target.value)
    );

  return (
    <Card title='발주 메모'>
      <Styled.TextareaLabelWrapper>
        <InputLabel
          name='petfriendsComment'
          id='receiveDelayReason'
          label='펫프담당자 메모'
          value={commentInfo.petfriendsComment ?? ''}
          onChange={handleInputChange}
          as='textarea'
          disabled={statusCode === 'CANCEL'}
        />
        <InputLabel
          id='receiveDelayReason'
          label='거래처담당자 메모'
          value={commentInfo.vendorComment ?? ''}
          disabled
          as='textarea'
        />
      </Styled.TextareaLabelWrapper>
    </Card>
  );
};

CommentInfoForm.propTypes = {
  commentInfo: PropTypes.shape({
    petfriendsComment: PropTypes.string,
    vendorComment: PropTypes.string,
  }),
  setSupplyRequestDetail: PropTypes.func.isRequired,
  statusCode: PropTypes.oneOf([
    'PETFRIENDS_DRAFT',
    'PETFRIENDS_REGISTER',
    'MODIFY',
    'VENDOR_REQUEST',
    'PETFRIENDS_CONFIRM',
    'CONFIRM_CANCEL',
    'CANCEL',
    '',
  ]),
};

export default CommentInfoForm;
