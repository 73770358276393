import styled from 'styled-components';

export const TableWrapper = styled.div`
  thead {
    tr {
      &:first-child {
        text-align: center;
      }
      &:nth-child(2) {
        th:nth-child(n + 6):nth-child(-n + 9) {
          text-align: right;
        }
      }
    }
  }

  tbody {
    td:nth-child(n + 6):nth-child(-n + 9) {
      text-align: right;
    }
  }
`;

export default { TableWrapper };
