import { useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import styled from 'styled-components';
import { CustomHeaderWrapper, CalendarStyle } from './styled';
import CalendarSelect from './components/CalendarSelect';
import EventContent from './components/EventContent';
import DayCellContent from './components/DayCellContent';
import DayHeader from './components/DayHeader';

const EmptyBox = styled.div`
  min-width: 200px;
`;

function DashboardCalendar({
  width,
  className,
  calendarEvents,
  currentDate,
  setCurrentDate,
  dayCellProps,
  children,
  leftTopHeaderNode,
  rightTopHeaderNode,
}) {
  const calendar = useRef(null);

  const onSelectChangeHandler = (value) => {
    const calendarApi = calendar.current.getApi();
    calendarApi.gotoDate(new Date(value));
    setCurrentDate(value);
  };

  return (
    <>
      <CustomHeaderWrapper width={width}>
        {leftTopHeaderNode || <EmptyBox />}
        <CalendarSelect value={currentDate} onChange={onSelectChangeHandler} />
        {rightTopHeaderNode || <EmptyBox />}
      </CustomHeaderWrapper>
      <div className={`calendar-wrapper ${className}`}>
        <FullCalendar
          ref={calendar}
          selectable
          initialDate={currentDate}
          events={calendarEvents}
          eventContent={EventContent}
          dayCellContent={(props) => DayCellContent({ ...props, ...dayCellProps })}
          plugins={[dayGridPlugin, interactionPlugin]}
          locale='ko'
          initialView='dayGridMonth'
          headerToolbar={{
            left: '',
            center: '',
            right: '',
          }}
          dayHeaderContent={DayHeader}
          aspectRatio={1}
        />
        {children}
      </div>
      <CalendarStyle />
    </>
  );
}

export default DashboardCalendar;
