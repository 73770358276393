import instance from 'service/interceptor';

async function getRequestForm(id) {
  const { data } = await instance.get(`/api/v2/product/supply/vendor/info/request/${id}`);

  return data;
}

async function getAllVendorInfo() {
  const { data } = await instance.get('/api/v2/product/supply/vendor/info');

  return data;
}

async function modifyManagerInfo(managerInfo) {
  const { data } = await instance.post('/api/v2/product/supply/vendor/manager/info', {
    purchaseManager: managerInfo[0].manager,
    purchaseManagerTelNo: managerInfo[0].telNo,
    purchaseManagerEmail: managerInfo[0].email,
    moneyManager: managerInfo[1].manager,
    moneyManagerTelNo: managerInfo[1].telNo,
    moneyManagerEmail: managerInfo[1].email,
  });

  return data;
}

async function getModifyRequests({ pageNumber = 0, pageSize = 10 } = {}) {
  const { data } = await instance.post('/api/v2/product/supply/vendor/info/request/history', {
    pageNumber,
    pageSize,
  });

  return data;
}

async function getProcessingRequest() {
  const { data } = await instance.get('/api/v2/product/supply/vendor/request/check');

  return data;
}

async function modifyRequest(form) {
  const formData = new FormData();

  if (form.id) {
    formData.append('id', form.id);
  }
  if (!form.bankBookVendorImage) {
    formData.append('bankBookVendorImageId', form.bankBookVendorImageId);
  }
  if (form.bankBookVendorImage) {
    formData.append('bankBookVendorImage', form.bankBookVendorImage);
  }

  formData.append('companyName', form.companyName);
  formData.append('ceoName', form.ceoName);
  formData.append('businessNumber', form.businessNumber);
  formData.append('paymentMethod', form.paymentMethod);
  formData.append('paymentDate', form.paymentDate);
  formData.append('bank', form.bank);
  formData.append('accountNumber', form.accountNumber);
  formData.append('depositor', form.depositor);
  formData.append('receiveMethod', form.receiveMethod);

  const { data } = await instance.post('/api/v2/product/supply/vendor/info/request', formData);

  return data;
}

async function cancelModifyRequest(id) {
  const { data } = await instance.post(`/api/v2/product/supply/vendor/info/request/cancel/${id}`);

  return data;
}

// NOTE: 이거 안씀
async function checkIfProcessingRequestExist() {
  const { data } = await instance.get('/product/supply/vendor/request/check');

  return data;
}

const getImageById = async (id) => {
  const { data } = await instance.get(`/api/v2/product/supply/image/download/${id}`, {
    responseType: 'blob',
  });
  return data;
};

const getImagePaymentById = async (id) => {
  const { data } = await instance.get(
    `/api/v2/product/supply/vendor/info/request/image/download/${id}`,
    {
      responseType: 'blob',
    }
  );
  return data;
};

export default {
  getRequestForm,
  getAllVendorInfo,
  modifyManagerInfo,
  getModifyRequests,
  getProcessingRequest,
  modifyRequest,
  cancelModifyRequest,
  checkIfProcessingRequestExist,
  getImageById,
  getImagePaymentById,
};
