import PropTypes from 'prop-types';

import toastify from 'commons/toast';

import { Flex } from 'styles';
import Button from 'components/Button';
import RadioGroup from 'components/RadioGroup';

import * as Styled from './styled';

const TextareaOrInputSearchbar = ({
  textareaValue,
  onTextareaChange,
  onTextareaFormat = (v) => v,
  onSearchClick,
  onResetClick,
  title = '검색 조건',
  checkedValue,
  placeholder = '검색어를 입력해 주세요. 거래처 번호인 경우, 복수 품목을 검색 할 수 있습니다. (Enter 또는 ","로 구분, 최대 50개)',
  type = 'textarea',
  ...props
}) => {
  const handleTextareaChange = (e) => {
    const value = onTextareaFormat(e.target.value, checkedValue);
    onTextareaChange(value);
  };

  const handleSearchClick = () => {
    if (['VENDOR_ID'].includes(checkedValue) && /[^\d, \n]/g.test(textareaValue)) {
      toastify('숫자만 입력할 수 있습니다.', { variant: 'error' });
      return;
    }

    onSearchClick();
  };

  return (
    <Styled.Wrapper>
      <Flex $gap='var(--space-m)' $flexDirection='column'>
        <Flex $justifyContent='space-between' $alignItems='flex-end'>
          <RadioGroup title={title} checkedValue={checkedValue} {...props} />
          <Flex $gap='var(--space-s)'>
            <Button onClick={handleSearchClick}>검색</Button>
            {onResetClick && (
              <Button onClick={onResetClick} $variant='light'>
                초기화
              </Button>
            )}
          </Flex>
        </Flex>
        <Styled.TextareaWrapper>
          {type === 'textarea' ? (
            <Styled.Textarea
              placeholder={placeholder}
              value={textareaValue}
              onChange={handleTextareaChange}
            />
          ) : (
            <Styled.Input
              placeholder={placeholder}
              value={textareaValue}
              onChange={handleTextareaChange}
            />
          )}
        </Styled.TextareaWrapper>
      </Flex>
    </Styled.Wrapper>
  );
};

TextareaOrInputSearchbar.propTypes = {
  textareaValue: PropTypes.string.isRequired,
  checkedValue: PropTypes.string,
  onTextareaChange: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  onResetClick: PropTypes.func,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onTextareaFormat: PropTypes.func,
};

export default TextareaOrInputSearchbar;
