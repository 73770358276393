import Card from 'components/Card';
import { thousandSeparator } from 'commons/helper';
import { OverviewWrapper, OverviewItem } from './Overview.style';

export const viewOverviewData = (value) => {
  return thousandSeparator(value) || 0;
};

function Overview({ currentDate, overviewData = {} }) {
  const { supplyAmounts, receivedAmounts, settlementAmounts } = overviewData;

  return (
    <Card title={currentDate}>
      <OverviewWrapper>
        <OverviewItem>
          <p>발주금액</p>
          <span>{viewOverviewData(supplyAmounts)}원</span>
        </OverviewItem>
        <OverviewItem>
          <p>입고금액</p>
          <span>{viewOverviewData(receivedAmounts)}원</span>
        </OverviewItem>
        <OverviewItem>
          <p>정산 대상 금액</p>
          <span>{viewOverviewData(settlementAmounts)}원</span>
        </OverviewItem>
      </OverviewWrapper>
    </Card>
  );
}

export default Overview;
