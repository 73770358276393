import { formatDateAsYYYYMMDD } from 'commons/date';
import { returnNullIfEmptyString } from 'commons/helper';
import instance from 'service';

const getSupplyRequestList = async ({
  pageIndex = 0,
  pageSize = 50,

  productSupplyTypeCode = null,

  beforeProductSupplyRequestDay = null,
  afterProductSupplyRequestDay = null,
  beforeReceiveAvailableDay = null,
  afterReceiveAvailableDay = null,

  statusCodes = null,

  searchOption = 'PRODUCT_SUPPLY_REQUEST',
  searchWord = '',
} = {}) => {
  const { data } = await instance.post('/api/v2/product/supply/request/list', {
    pageNumber: pageIndex,
    pageSize,
    productSupplyTypeCode: returnNullIfEmptyString(productSupplyTypeCode),
    beforeProductSupplyRequestDay: formatDateAsYYYYMMDD(beforeProductSupplyRequestDay),
    afterProductSupplyRequestDay: formatDateAsYYYYMMDD(afterProductSupplyRequestDay),
    beforeReceiveAvailableDay: formatDateAsYYYYMMDD(beforeReceiveAvailableDay),
    afterReceiveAvailableDay: formatDateAsYYYYMMDD(afterReceiveAvailableDay),
    statusCodes: statusCodes.includes('') ? null : statusCodes,
    searchOption,
    searchWord,
  });

  return data;
};

const getSupplyRequestItems = async ({ productSupplyRequestId, pageIndex = 0, pageSize = 50 }) => {
  const { data } = await instance.post(
    `/api/v2/product/supply/requests/${productSupplyRequestId}/items`,
    {
      pageNumber: pageIndex,
      pageSize,
    }
  );

  return data;
};

const updateStatus = async (list, status) => {
  const { data } = await instance.post('/api/v2/product/supply/requests/status/update', {
    list,
    status,
  });

  return data;
};

const getSupplyRequestDetail = async (productSupplyRequestId) => {
  const { data } = await instance.get(`/api/v2/product/supply/requests/${productSupplyRequestId}`);

  return data;
};

const getRequestItemSaveDto = (itemList) =>
  itemList.map((item) => ({
    availableReceiveQuantity: item.availableReceiveQuantity,
    restockDay: item.restockDay,
    shelfLifeDay: item.shelfLifeDay,
    vendorComment: returnNullIfEmptyString(item.vendorComment),
    productSupplyRequestItemId: item.productSupplyRequestItemId,
  }));

const updateSupplyRequestDetail = async ({
  productSupplyRequestId,
  statusCode,
  supplyRequestDetail,
  confirmYn,
}) => {
  const { data } = await instance.post('/api/v2/product/supply/request/update', {
    productSupplyRequestId,
    statusCode,
    receiveDelay: supplyRequestDetail.delayInfo.receiveDelay,
    receiveDelayAvailableDay: supplyRequestDetail.delayInfo.receiveDelayAvailableDay,
    receiveDelayReason: returnNullIfEmptyString(supplyRequestDetail.delayInfo.receiveDelayReason),
    receiveAvailableDay: supplyRequestDetail.receiveInfo.receiveAvailableDay,
    transportTypeCode: returnNullIfEmptyString(supplyRequestDetail.transportInfo.transportTypeCode),
    transportCargoTypeCode: returnNullIfEmptyString(
      supplyRequestDetail.transportInfo.transportCargoTypeCode
    ),
    transportQuantity: returnNullIfEmptyString(supplyRequestDetail.transportInfo.transportQuantity),
    transportInfoComment: returnNullIfEmptyString(
      supplyRequestDetail.transportInfo.transportInfoComment
    ),
    vendorComment: returnNullIfEmptyString(supplyRequestDetail.commentInfo.vendorComment),
    requestItemSaveDto: getRequestItemSaveDto(supplyRequestDetail.itemList),
    requestHistorySaveDto: {
      division: statusCode,
      contents: null,
    },
    updatedAt: supplyRequestDetail.updatedAt,
    confirmYn,
  });

  return data;
};

const getSupplyRequestStatements = async (productSupplyRequestId) => {
  const { data } = await instance.get(
    `/api/v2/product/supply/request/statements/${productSupplyRequestId}`
  );

  return data;
};

const getScCenterList = async () => {
  const { data } = await instance.get('/api/v2/product/supply/centers');
  return data;
};

export default {
  getSupplyRequestList,
  getSupplyRequestItems,
  updateStatus,
  getSupplyRequestDetail,
  updateSupplyRequestDetail,
  getSupplyRequestStatements,
  getScCenterList,
};
