import { useHistory } from 'react-router-dom';

import dashboardApi from 'service/dashboard';
import useFetch from 'hooks/useFetch';

import Card from 'components/Card';

import Styled from './styled';
import LabelAndCount from '../../components/LabelAndCount';

const TotalStatusContainer = () => {
  const { data = {} } = useFetch(dashboardApi.getTotalStatus);
  const history = useHistory();

  const navigateToSupplyListPage = () => history.push('/supply/list');
  const navigateToReceivingListPage = () => history.push('/receiving/list');
  const navigateToSettlementListPage = () => history.push('/settlement/list');

  return (
    <Styled.Div>
      <Card title='발주 현황' label='바로가기' onClick={navigateToSupplyListPage}>
        <Styled.LabelCountWrapper>
          <LabelAndCount label='발주 등록' count={data.supplyRegisterCnt || 0} />
          <LabelAndCount label='발주 수정 중' count={data.supplyModifyCnt || 0} />
          <LabelAndCount label='확정 요청' count={data.supplyRequestCnt || 0} />
          <LabelAndCount label='발주 확정' count={data.supplyConfirmCnt || 0} />
        </Styled.LabelCountWrapper>
      </Card>
      <Card title='입고 현황' label='바로가기' onClick={navigateToReceivingListPage}>
        <Styled.LabelCountWrapper>
          <LabelAndCount label='입고 대기' count={data.inboundWaitingCnt || 0} />
          <LabelAndCount label='입고 완료' count={data.inboundCompleteCnt || 0} />
          <LabelAndCount label='미입고' count={data.inboundDelayCnt || 0} />
        </Styled.LabelCountWrapper>
      </Card>
      <Card title='정산 현황' label='바로가기' onClick={navigateToSettlementListPage}>
        <Styled.LabelCountWrapper>
          <LabelAndCount label='정산 등록' count={data.settlementRegisterCnt || 0} />
          <LabelAndCount label='정산 완료' count={data.settlementCompleteCnt || 0} />
        </Styled.LabelCountWrapper>
      </Card>
    </Styled.Div>
  );
};

export default TotalStatusContainer;
