import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import LabelAndText from 'components/LabelAndText';
import HeaderModal from 'components/modals/HeaderModal';
import Table from 'components/Table';
import { usePageModals } from 'hooks/pages';
import { Flex } from 'styles/components';
import { thousandSeparator } from 'commons/helper';
import { Text } from '../ReverseRegisterModal/styled';
import { DETAIL_COLUMNS } from '../../constant';
import { useReverseDetail } from '../../apis';

const InfoWrapper = styled.div`
  margin-bottom: 25px;
  border-bottom: 1px solid #e9ecef;

  & > h4,
  & > h5 {
    margin-bottom: 20px;
  }
`;

function ReverseDetailModal() {
  const [{ detail }, setModalOpen] = usePageModals();
  const { reverseId } = useSelector((state) => state.reverse);
  const { executeHandler, info, totalSum } = useReverseDetail(reverseId);
  const { reverse, purchaseOrder, outbound, reverseItems } = info;

  const onClose = () => setModalOpen('detail', false);

  useEffect(() => {
    if (detail) executeHandler();
  }, [detail]);

  return (
    <HeaderModal isOpen={detail} title='반품 상세내역' closeCallback={onClose} $size='lg'>
      {!reverse?.reverseNo ? (
        <>
          <div />
        </>
      ) : (
        <>
          <InfoWrapper>
            <h4>{reverse?.reverseNo}</h4>
            <Flex>
              <LabelAndText label='등록일시' text={reverse?.createdAt} />
              <LabelAndText label='확정일시' text={reverse?.confirmedAt} />
            </Flex>
          </InfoWrapper>
          <InfoWrapper>
            <h5>발주정보</h5>
            <Flex>
              <LabelAndText label='발주번호' text={purchaseOrder?.purchaseOrderId} />
              <LabelAndText label='입고번호' text={purchaseOrder?.inboundWikey} />
            </Flex>
            <Flex $flexWrap='wrap'>
              <LabelAndText label='거래처번호' text={purchaseOrder?.vendorId} />
              <LabelAndText label='거래처명' text={purchaseOrder?.vendorName} />
              <LabelAndText label='입고센터' text={purchaseOrder?.centerName} />
              <LabelAndText
                label='발주금액'
                text={thousandSeparator(purchaseOrder?.purchaseOrderPrice) || 0}
              />
              <LabelAndText label='발주요청일' text={purchaseOrder?.purchaseOrderRequestDay} />
            </Flex>
          </InfoWrapper>
          <InfoWrapper>
            <h5>출고정보</h5>
            <Flex $flexWrap='wrap'>
              <LabelAndText label='출고번호' text={outbound?.wokey} />
              <LabelAndText label='출고센터' text={outbound?.centerName} />
              <LabelAndText label='출고예정일' text={outbound?.availableDay} />
              <LabelAndText label='배송유형' text={outbound?.deliveryType} />
            </Flex>
          </InfoWrapper>
          <Flex $gap='var(--space-m)' $flexDirection='column'>
            <Text>품목정보</Text>
            <Table columns={DETAIL_COLUMNS} data={reverseItems} $height='auto' />
            <Text style={{ textAlign: 'right' }}>{totalSum}원</Text>
          </Flex>
        </>
      )}
    </HeaderModal>
  );
}

export default ReverseDetailModal;
