import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { numberExtractor, thousandSeparator } from 'commons/helper';
import { TAX_TYPE_HASH } from 'commons/hash';
import { SPECIAL_CHARACTER_REGEX } from 'commons/constant';

import Select from 'components/Select';
import Input from 'components/Input';

const STORAGE_TEMPERATURE_OPTIONS = [
  { key: '', value: '보관온도 선택' },
  { key: 'AMBIENT', value: '상온' },
  { key: 'FRIDGE', value: '냉장' },
  { key: 'FROZEN', value: '냉동' },
];

const NUMBER_TYPE_CELLS = ['width', 'length', 'height', 'weight', 'volume'];
const THOUSAND_SEPARATOR_TEXT_CELLS = [
  'boxUnit',
  'outboundDivisionUnit',
  'cartonUnit',
  'pltUnit',
  'shelfLifeDays',
];

const EditableCell = ({
  value: initialValue,
  row: {
    index: rowIndex,
    isSelected,
    values: { degree },
  },
  column: { id: columnName },
  updateMyData,
}) => {
  const [value, setValue] = useState(initialValue ?? '');
  useEffect(() => {
    setValue(initialValue ?? '');
  }, [initialValue]);

  const isNumberInputTypeCell = NUMBER_TYPE_CELLS.includes(columnName);
  const isThousandSeparatorTypeCell = THOUSAND_SEPARATOR_TEXT_CELLS.includes(columnName);

  const isEnabled = degree === null || degree === 1;

  const handleChange = (e) => setValue(e.target.value);
  const handleBarcodeChange = (e) => setValue(e.target.value.replace(SPECIAL_CHARACTER_REGEX, ''));
  const handleBlur = () => updateMyData(rowIndex, columnName, value);
  const handleNumberInputChange = (e) => setValue(numberExtractor(e.target.value));

  if (columnName === 'itemBarcode' || columnName === 'boxBarcode') {
    return (
      <Input
        type='text'
        value={value}
        onChange={handleBarcodeChange}
        onBlur={handleBlur}
        required={isSelected && columnName === 'itemBarcode'}
        maxLength={20}
        disabled={!isEnabled}
      />
    );
  }

  if (isThousandSeparatorTypeCell) {
    return (
      <Input
        type='text'
        value={thousandSeparator(value)}
        onChange={handleNumberInputChange}
        onBlur={handleBlur}
        required={isSelected && columnName === 'boxUnit'}
        maxLength={12}
        disabled={!isEnabled}
      />
    );
  }

  if (isNumberInputTypeCell) {
    return (
      <Input
        type='number'
        value={value || ''}
        onChange={(e) => {
          const [, decimal] = e.target.value.split('.');
          if (decimal?.length > 1) {
            return;
          }

          setValue(Number(e.target.value));
        }}
        onBlur={handleBlur}
        disabled={!isEnabled}
      />
    );
  }

  if (columnName === 'imminentStockConversionDays' || columnName === 'noShippingConversionDays') {
    return (
      <Input
        type='number'
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        required={isSelected}
        disabled={!isEnabled}
      />
    );
  }

  if (columnName === 'storageTemperature') {
    return (
      <Select
        id='storageTemperature'
        name='storageTemperature'
        value={value}
        onChange={handleChange}
        required={isSelected}
        onBlur={handleBlur}
        options={STORAGE_TEMPERATURE_OPTIONS}
        disabled={!isEnabled}
      />
    );
  }

  if (columnName === 'standardUnitPrice' || columnName === 'minimumOrderQuantity') {
    return thousandSeparator(value);
  }

  if (value && columnName === 'tax') {
    return TAX_TYPE_HASH[value];
  }

  return value ?? '';
};

EditableCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  row: PropTypes.shape({
    index: PropTypes.number,
    isSelected: PropTypes.bool,
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  updateMyData: PropTypes.func.isRequired,
};

export default EditableCell;
