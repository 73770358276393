import { useMemo, createContext, useContext } from 'react';
import useSelectOptions from 'hooks/useSelectOptions';

const setRadioOption = (arr) =>
  arr.map(({ key, value }) => ({ id: key, label: value, value: key }));

export const useReverseOptions = () => {
  const { reverseSearchCondition, ...options } = useSelectOptions([
    'reverseStatus',
    'reverseReason',
    'reverseSearchCondition',
    'purchaseOrderReverseSearchCondition',
    'deliveryType',
  ]);

  const mappingReverseSearchCondition = useMemo(() => {
    return setRadioOption(reverseSearchCondition);
  }, [reverseSearchCondition]);

  const mappingPurchaseOrderReverseSearchCondition = useMemo(() => {
    return setRadioOption(options.purchaseOrderReverseSearchCondition ?? []);
  }, [options.purchaseOrderReverseSearchCondition]);

  return {
    ...options,
    reverseSearchCondition: mappingReverseSearchCondition,
    purchaseOrderReverseSearchCondition: mappingPurchaseOrderReverseSearchCondition,
  };
};

export const AdminReverseContext = createContext(null);

export const useAdminReverseContext = () => {
  const context = useContext(AdminReverseContext);
  if (!context) {
    throw new Error('반품 안에 있어야 합니다!');
  }
  return context;
};
