import { signInWithPopup, GoogleAuthProvider, signOut, deleteUser } from 'firebase/auth';
import { handleAdminLogin } from 'service/auth';
import { auth } from './config';

export const logout = async () => signOut(auth);

export const signInWithGooglePopup = async () => {
  const signIn = await signInWithPopup(auth, new GoogleAuthProvider());
  const firebaseUid = signIn?.user?.uid;

  try {
    const { accessToken, refreshToken } = await handleAdminLogin({
      firebaseUid,
      originDomain: 'SUPPLY',
    });

    return { accessToken, refreshToken };
  } catch (err) {
    const error = new Error('가입된 계정이 아닙니다.', 400);

    logout();
    deleteUser(signIn?.user);

    throw error;
  }
};
