import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: calc(var(--space-s) / 2);

  input:focus {
    box-shadow: none;
  }
`;

export const Label = styled.label`
  margin: 0;
  color: var(--grey900);
  font-weight: var(--font-weight-medium) !important;
  line-height: 100%;
`;

export default { Wrapper };
