import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setSelectedAmendmentRequest } from 'commons/store/modules/admin/amendmentRequest';
import amendmentRequestApi from 'service/hq/amendmentRequest';
import useFetch from 'hooks/useFetch';

import HeaderModal from 'components/modals/HeaderModal';
import RequestInfoContainer from '../../containers/RequestInfoContainer';
import ProductPriceTableContainer from '../../containers/ProductPriceTableContainer';

import { PRODUCT_PRICE_TABLE_COLUMNS } from '../../commons/constant';

const ProductPriceModal = () => {
  const {
    data: { requestInfoDto = {}, requestDetailList = [] } = {},
    execute: getProductPriceAmendmentRequestDetail,
    setState: setProductPriceAmendmentRequestDetail,
  } = useFetch(amendmentRequestApi.getProductPriceAmendmentRequestDetail, {
    initialExecute: false,
  });
  const dispatch = useDispatch();
  const { selectedAmendmentRequest } = useSelector((state) => state.amendmentRequest);

  const isProductMasterModalOpen = selectedAmendmentRequest.code === 'PRODUCT_PRICE';

  useEffect(() => {
    if (isProductMasterModalOpen) {
      getProductPriceAmendmentRequestDetail(selectedAmendmentRequest.id);
    }
  }, [isProductMasterModalOpen]);

  const handleModalClose = () =>
    dispatch(
      setSelectedAmendmentRequest({
        code: null,
        id: null,
      })
    );

  return (
    <HeaderModal
      isOpen={isProductMasterModalOpen}
      closeCallback={handleModalClose}
      title='가격 정보 수정 요청'
      $size='xl'
    >
      <RequestInfoContainer
        requestInfo={requestInfoDto}
        setRequestDetail={setProductPriceAmendmentRequestDetail}
      />
      <ProductPriceTableContainer
        columns={PRODUCT_PRICE_TABLE_COLUMNS}
        requestInfo={requestInfoDto}
        productList={requestDetailList}
        setRequestDetail={setProductPriceAmendmentRequestDetail}
        statusValue={requestInfoDto.statusValue}
        requestId={selectedAmendmentRequest.id}
      />
    </HeaderModal>
  );
};

export default ProductPriceModal;
