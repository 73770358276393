import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  text-align: ${({ $textAlign }) => $textAlign || 'left'};
  margin-bottom: ${({ $marginBottom }) => $marginBottom || 'var(--space-l)'};
`;

export const SubTitle = styled.h6`
  color: ${({ $color }) => $color || 'var(--grey600)'};
`;

export const Cell = styled.div`
  color: ${({ $color }) => $color || 'var(--black)'};
`;

export default { List, SubTitle, Cell };
