import PropTypes from 'prop-types';

import { PAYMENT_DATE_HASH, PAYMENT_METHOD_HASH } from 'commons/hash';

import Styled from './styled';

const SettlementDetailHeader = ({ index = 0, datas = [], setIndex }) => {
  const data = datas[index];
  const onIndexChange = (newIndex) => () => {
    if (newIndex >= 0 && newIndex < datas.length) setIndex(newIndex);
  };
  return (
    <Styled.Header>
      <span className='pagenation'>
        <Styled.Box type='button' onClick={onIndexChange(index - 1)}>
          <span>{'<'}</span>
        </Styled.Box>
        <span className='currunt-index'>
          {index + 1}/{datas.length}
        </span>
        <Styled.Box type='button' onClick={onIndexChange(index + 1)}>
          <span>{'>'}</span>
        </Styled.Box>
      </span>
      <Styled.Infos>
        <span>
          <b>{data?.vendorInfo?.displayName}</b>
        </span>
        <span>
          <b>{data?.settlementInfo?.settlementName}</b>
        </span>
        <span>정산 담당자: {data?.vendorInfo?.moneyManagerFormat}</span>
        <span>결제방법: {PAYMENT_METHOD_HASH[data?.vendorInfo?.paymentMethod]}</span>
        <span>결제일: {PAYMENT_DATE_HASH[data?.vendorInfo?.paymentDate]}</span>
      </Styled.Infos>
    </Styled.Header>
  );
};

SettlementDetailHeader.propTypes = {
  index: PropTypes.number,
  datas: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setIndex: PropTypes.func,
};

export default SettlementDetailHeader;
