import { useState, useCallback, useEffect, useRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { SETTLEMENT_TAXATION_TYPE_CODE_HASH } from 'commons/hash';
import { handleError, isEmpty } from 'commons/helper';
import productPrice from 'service/product/price';
import productItem from 'service/product/item';
import toastify from 'commons/toast';

import { PRODUCT_SERVICES } from 'pages/vendor/ProductManagePage/commons/constant';
import { ROW_SELECT_PLUGIN_HOOKS } from 'components/Table/commons/constant';

import * as StyledPage from 'styles';
import { Flex } from 'styles/components';
import Searchbar from 'components/Searchbar/Searchbar';
import Button from 'components/Button';
import PaginationTable from 'components/Table/PaginationTable';

import { COLUMNS } from './commons/constant';
import {
  getDuplicatedItemsMessage,
  transformIntoItemTargetProducts,
  transformIntoPriceTargetProducts,
} from './commons/helper';
import * as Styled from './styled';

function ProductsSelection({ products, setProducts, targetProducts, setTargetProducts, request }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [controlledPageIndex, setControlledPage] = useState(0);
  const currentSearchQuery = useRef('');
  const autoResetSelectedRows = useRef();
  const activeTargetProducts = targetProducts.filter(({ active }) => active !== 'N');

  const tableOptions = useMemo(
    () => ({
      initialState: { pageIndex: 0, pageSize: 100 },
      manualPagination: true,
      pageCount: products.pageCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: controlledPageIndex,
      }),
      autoResetSelectedRows: autoResetSelectedRows.current,
    }),
    [products.pageCount, controlledPageIndex, autoResetSelectedRows.current]
  );

  const fetchProductsApi = {
    '가격 수정 요청': productPrice,
    '품목 정보 수정 요청': productItem,
  };

  const requestId = {
    '품목 정보 수정 요청': request.productSupplyPriceRequestInfo?.productSupplyItemMasterRequestId,
    '가격 수정 요청': request.productSupplyPriceRequestInfo?.productSupplyPriceRequestId,
  };

  const fetchProducts = useCallback(async ({ query = '', pageSize = 100, pageIndex = 0 } = {}) => {
    try {
      const { response } = await fetchProductsApi[request.target].getProducts({
        query,
        pageSize,
        pageNumber: pageIndex,
        requestId: requestId[request.target],
      });
      const { totalPages, content } = response.data;
      setProducts({
        pageCount: totalPages,
        pageResult: content,
      });
    } catch (error) {
      handleError(error);
    }
  }, []);

  const handlePageChange = useCallback(
    ({ state }) => {
      fetchProducts({ pageIndex: state.pageIndex, query: currentSearchQuery.current });
    },
    [fetchProducts]
  );

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    autoResetSelectedRows.current = true;
  }, [products]);

  const handleRowSelectionChange = useCallback(({ flatRows }) => {
    autoResetSelectedRows.current = false;
    const updatedProducts = flatRows.map(({ isSelected, original }) => ({
      ...original,
      isSelected,
    }));
    setProducts((prev) => ({ ...prev, pageResult: updatedProducts }));
  }, []);

  const handleTargetProductsAdd = async () => {
    const selectedProducts = products.pageResult.filter(({ isSelected }) => isSelected);
    if (isEmpty(selectedProducts)) {
      toastify('품목을 먼저 선택해 주십시오.', { variant: 'warning' });
      return;
    }
    if (activeTargetProducts.length + selectedProducts.length > 100) {
      toastify(
        `하나의 요청에는 최대 100개의 품목까지 대상으로 지정할 수 있습니다.
        현재: ${activeTargetProducts.length}개`,
        { variant: 'warning' }
      );
      return;
    }

    const duplicatedProducts = activeTargetProducts.filter(({ productItemId }) =>
      selectedProducts.some((selectedProduct) => selectedProduct.productItemId === productItemId)
    );
    if (!isEmpty(duplicatedProducts)) {
      toastify(
        `이미 대상 품목에 존재하는 품목이 포함되어 있습니다.
          품목ID: ${duplicatedProducts.map(({ productItemId }) => productItemId).join(', ')}`,
        { variant: 'warning' }
      );

      return;
    }

    const productItemIds = selectedProducts.map(({ productItemId }) => productItemId);
    const { response } = await PRODUCT_SERVICES[request.target]
      .isIncludedInTargetProducts(productItemIds)
      .catch(handleError);
    const duplicateProductItems = response.data;
    if (!isEmpty(duplicateProductItems)) {
      toastify(
        `기존에 요청에서 처리 중인 품목이 포함되어 있습니다.

        ${getDuplicatedItemsMessage(duplicateProductItems)}`,
        { variant: 'warning', autoClose: 5000 }
      );
      return;
    }

    if (request.target === '가격 수정 요청') {
      setTargetProducts((prev) => [...transformIntoPriceTargetProducts(selectedProducts), ...prev]);
    } else if (request.target === '품목 정보 수정 요청') {
      setTargetProducts((prev) => [...transformIntoItemTargetProducts(selectedProducts), ...prev]);
    }
  };

  const changeSearchQuery = (e) => setSearchQuery(e.target.value);

  const handleResetButtonClick = () => {
    setSearchQuery('');
    currentSearchQuery.current = '';
    setControlledPage(0);
    fetchProducts();
  };

  const handleSearchButtonClick = () => {
    currentSearchQuery.current = searchQuery;
    setControlledPage(0);
    fetchProducts({ query: currentSearchQuery.current });
  };

  const renderCell = (cell) => {
    if (cell.column.id === 'tax') {
      return SETTLEMENT_TAXATION_TYPE_CODE_HASH[cell.value];
    }

    return cell.value;
  };

  return (
    <Styled.Wrapper>
      <StyledPage.SectionTitle>수정할 품목 선택</StyledPage.SectionTitle>
      <Flex $spacer>
        <Searchbar
          name='수정할 품목'
          value={searchQuery}
          onChange={changeSearchQuery}
          onReset={handleResetButtonClick}
          onClick={handleSearchButtonClick}
          placeholder='수정할 품목명을 검색해 주세요.'
        />
        <Button onClick={handleTargetProductsAdd} $variant='secondary' $size='small'>
          대상 품목 추가 &gt;
        </Button>
      </Flex>
      <PaginationTable
        columns={COLUMNS[request.target]}
        data={products.pageResult}
        onSelectChange={handleRowSelectionChange}
        onPageChange={handlePageChange}
        tableOptions={tableOptions}
        setControlledPage={setControlledPage}
        pluginHooks={ROW_SELECT_PLUGIN_HOOKS}
        renderCell={renderCell}
      />
    </Styled.Wrapper>
  );
}

ProductsSelection.propTypes = {
  products: PropTypes.shape({
    pageCount: PropTypes.number,
    pageResult: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  setProducts: PropTypes.func.isRequired,
  targetProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  setTargetProducts: PropTypes.func.isRequired,
  request: PropTypes.shape({
    target: PropTypes.string,
    productSupplyPriceRequestInfo: PropTypes.shape({
      productSupplyPriceRequestId: PropTypes.number,
      productSupplyItemMasterRequestId: PropTypes.number,
    }),
  }).isRequired,
};

export default memo(ProductsSelection);
