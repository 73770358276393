import styled from 'styled-components';

export const Div = styled.div`
  margin-top: var(--space-l);

  table {
    thead {
      th:nth-child(n + 6):nth-child(-n + 9) {
        text-align: right;
      }
    }

    tbody {
      td:last-child {
        min-width: 250px;
      }
      td:nth-child(n + 6):nth-child(-n + 9) {
        text-align: right;
      }
    }
  }
`;

export default { Div };
