import { useDispatch, useSelector } from 'react-redux';

import {
  getVendorList,
  setPageIndex,
  setSelectedVendor,
} from 'commons/store/modules/admin/vendorProduct';

import ResultHeader from 'components/ResultHeader';
import PaginationTable from 'components/Table/PaginationTable';

import { VENDOR_COLUMNS, VENDOR_PRODUCT_LIST } from '../../commons/constant';

const VendorResultContainer = () => {
  const { searchFilter, tableData, totalElements, totalPages, pageIndex, selectedVendor } =
    useSelector(({ vendorProduct }) => vendorProduct[VENDOR_PRODUCT_LIST]);
  const dispatch = useDispatch();

  const isRowSelected = ({ vendorId }) => vendorId === selectedVendor?.id;

  const tableOptions = {
    initialState: { pageIndex: 0, pageSize: 50 },
    manualPagination: true,
    pageCount: totalPages,
    useControlledState: (state) => ({
      ...state,
      pageIndex,
    }),
  };

  const handlePageChange = ({ state }) =>
    dispatch(getVendorList({ ...searchFilter, pageNumber: state.pageIndex }));

  const setControlledPage = (newPageIndex) =>
    dispatch(setPageIndex({ path: VENDOR_PRODUCT_LIST, pageIndex: newPageIndex }));

  const handleRowClick = ({ values }) =>
    dispatch(
      setSelectedVendor({
        path: VENDOR_PRODUCT_LIST,
        selectedVendor: {
          id: values.vendorId,
          displayName: values.vendorDisplayName,
        },
      })
    );

  return (
    <>
      <ResultHeader totalCount={totalElements} />
      <PaginationTable
        columns={VENDOR_COLUMNS}
        data={tableData}
        onPageChange={handlePageChange}
        tableOptions={tableOptions}
        setControlledPage={setControlledPage}
        onRowClick={handleRowClick}
        isRowSelected={isRowSelected}
      />
    </>
  );
};

export default VendorResultContainer;
