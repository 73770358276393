import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setPrevAdminSupplyValue } from 'commons/store/modules/admin/supply';

import useRouter from 'hooks/useRouter';
import * as Styled from './styled';

const SupplyRequestCell = ({ value }) => {
  const dispatch = useDispatch();
  const { pushWithSearchParams } = useRouter();

  const handleClick = () => {
    dispatch(setPrevAdminSupplyValue({ supplyId: value }));
    pushWithSearchParams(`/admin/supply/registration/${value}`);
  };

  return (
    <Styled.Wrapper type='button' onClick={handleClick}>
      {value}
    </Styled.Wrapper>
  );
};

SupplyRequestCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default SupplyRequestCell;
