import PropTypes from 'prop-types';
import Table from 'components/Table';

import { thousandSeparator } from 'commons/helper';

import Styled from './styled';

/**
 * @param {*} props
 */
const TotalTable = ({ columns, data, setData, ...props }) => {
  const getTotalColumns = () => {
    return columns.map((col, index) => {
      const { isSum, accessor } = col;
      const newRow = { ...col };
      if (index === 0) newRow.value = '합계';
      else if (isSum) {
        let sum = 0;
        data.forEach((x) => {
          sum += parseInt(x[accessor], 10);
        });
        newRow.value = sum;
      }
      return newRow;
    });
  };

  const renderTableFoot = () => {
    const totalColumns = getTotalColumns();
    return (
      <tfoot>
        <tr>
          {totalColumns.map((col, index) => {
            return (
              <td key={`total-cols-${col.accessor}`} className={index ? 'left' : 'right'}>
                {thousandSeparator(col.value) ?? ''}
              </td>
            );
          })}
        </tr>
      </tfoot>
    );
  };
  return (
    <Styled.Div>
      <Table {...props} columns={columns} data={data} renderTableFoot={renderTableFoot} />
    </Styled.Div>
  );
};

TotalTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,

  setData: PropTypes.func.isRequired,
  tableOptions: PropTypes.shape({
    autoResetSelectedRows: PropTypes.bool,
  }),
};

export default TotalTable;
