export const COLUMNS = [
  {
    Header: '품목 정보',
    columns: [
      { Header: '품목ID', accessor: 'productItemId' },
      { Header: '품목유형', accessor: 'productType' },
      { Header: '품목명', accessor: 'productName' },
      { Header: '상태', accessor: 'productStatus' },
      { Header: '대분류', accessor: 'productGroup1Name' },
      { Header: '중분류', accessor: 'productGroup2Name' },
      { Header: '소분류', accessor: 'productGroup3Name' },
      { Header: '세분류', accessor: 'productDetailGroup' },
    ],
  },
  {
    Header: '거래처 정보',
    columns: [
      { Header: '거래처 번호', accessor: 'vendorId' },
      { Header: '거래처명', accessor: 'vendorName' },
      { Header: '거래처 차수', accessor: 'degree' },
    ],
  },
  {
    Header: '가격 정보',
    columns: [
      { Header: '과세구분', accessor: 'tax' },
      { Header: '기준 공급가(₩)', accessor: 'standardUnitPrice' },
      { Header: '최소주문수량(EA)', accessor: 'minimumOrderQuantity' },
    ],
  },
  {
    Header: '바코드 정보',
    columns: [
      { Header: '품목 바코드', accessor: 'itemBarcode' },
      { Header: '박스 바코드', accessor: 'boxBarcode' },
    ],
  },
  {
    Header: '입수 정보',
    columns: [
      { Header: '박스(EA)', accessor: 'boxUnit' },
      { Header: '팔레트(EA)', accessor: 'pltUnit' },
      { Header: '단수/단포(EA)', accessor: 'outboundDivisionUnit' },
    ],
  },
  {
    Header: '규격 정보',
    columns: [
      { Header: '가로(cm)', accessor: 'width' },
      { Header: '세로(cm)', accessor: 'length' },
      { Header: '높이(cm)', accessor: 'height' },
      { Header: '중량(kg)', accessor: 'weight' },
      { Header: '용량(ml)', accessor: 'volume' },
    ],
  },
  {
    Header: '보관 정보',
    columns: [
      { Header: '보관온도', accessor: 'storageTemperature' },
      { Header: '임박전환(일)', accessor: 'imminentStockConversionDays' },
      { Header: '출고불가(일)', accessor: 'noShippingConversionDays' },
    ],
  },
];

export const SEARCH_OPTIONS_INITIAL_VALUES = {
  itemTypeCode: '',
  stockProductStatus: '',
  query: '',
  searchCondition: 'PRODUCT_ITEM_ID',
};

export const MAPPING_KEYS = {
  productItemId: '품목ID',
  productType: '품목유형',
  productName: '품목명',
  productStatus: '상태',
  productGroup1Name: '대분류',
  productGroup2Name: '중분류',
  productGroup3Name: '소분류',
  productDetailGroup: '세분류',

  vendorId: '거래처 번호',
  vendorName: '거래처명',
  degree: '거래처 차수',

  tax: '과세구분',
  standardUnitPrice: '기준 공급가(₩)',
  minimumOrderQuantity: '최소주문수량(EA)',

  itemBarcode: '품목 바코드',
  boxBarcode: '박스 바코드',
  boxUnit: '박스(EA)',
  pltUnit: '팔레트(EA)',
  outboundDivisionUnit: '단수/단포(EA)',

  width: '가로(cm)',
  length: '세로(cm)',
  height: '높이(cm)',
  weight: '중량(kg)',
  volume: '용량(ml)',

  storageTemperature: '보관온도',
  imminentStockConversionDays: '임박전환(일)',
  noShippingConversionDays: '출고불가(일)',
};

export const SEARCH_CONDITIONS = [
  {
    id: 'PRODUCT_ITEM_ID',
    label: '품목ID',
    value: 'PRODUCT_ITEM_ID',
  },
  {
    id: 'PRODUCT_NAME',
    label: '품목명',
    value: 'PRODUCT_NAME',
  },
  {
    id: 'VENDOR_NAME',
    label: '거래처명',
    value: 'VENDOR_NAME',
  },
];

export default { COLUMNS };
