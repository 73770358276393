import styled from 'styled-components';

const Wrapper = styled.div.attrs({
  className: 'form-floating',
})`
  min-width: var(--base-width);
`;

const FormSelect = styled.select.attrs({
  className: 'form-select',
})`
  border-radius: var(--radius-m);
  border-color: var(--grey300);
  cursor: pointer;
  color: var(--grey900);
  width: 100%;
  padding: var(--space-m);
  height: var(--base-height);
  opacity: 1 !important;

  &:focus {
    border-color: #80bdff;
    outline: none;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition-property: border-color, box-shadow;
    transition-duration: 0.15s, 0.15s;
    transition-timing-function: ease-in-out, ease-in-out;
    transition-delay: 0s, 0s;
  }

  &:disabled {
    background-color: #e9ecef;
    cursor: default;
  }
`;

const Label = styled.label`
  opacity: 1 !important;
  color: var(--grey700) !important;
  font-weight: var(--font-weight-medium) !important;
  cursor: pointer;
`;

export { Wrapper, FormSelect, Label };
