import styled from 'styled-components';

export const Wrapper = styled.div`
  .card-title {
    color: var(--red500);
  }

  textarea {
    width: 100%;
  }
`;

export default { Wrapper };
