import { css } from 'styled-components';
import { SUPPLY_REQUEST_LIST_MAPPING_KEYS } from './constant';
import { formatDate } from './date';
import { downloadJsonAsExcel } from './excel';
import hash from './hash';

import toastify from './toast';

export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error(error);

  if (error instanceof TypeError) return;
  if (typeof error === 'string') {
    toastify(error, { variant: 'error' });
    return;
  }

  toastify(error?.message, { variant: 'error' });
}

export function handleInputChange(e, setState) {
  const { name, value } = e.target;

  setState((state) => ({
    ...state,
    [name]: value,
  }));
}

export const setObjectState = (setState, field, value) =>
  setState((prev) => ({ ...prev, [field]: value }));

export const isEmpty = (array) => array.length === 0;

export const thousandSeparator = (number) => {
  if (number === 0) return '';
  return number ? number.toLocaleString() : '';
};

export const numberExtractor = (string) => Number(string.replace(/[^0-9]/g, ''));

export function getStyleProps(props) {
  const styles = {};
  Object.keys(props).forEach((key) => {
    if (key.match(/\$/)) styles[key.replace('$', '')] = props[key];
  });
  return styles;
}

export const styleSelector = (props) => css(getStyleProps(props));

export const translateObjectKeys = (object, mappingKeys) => {
  const newObject = {};
  Object.keys(object).forEach((key) => {
    if (mappingKeys[key]) {
      newObject[mappingKeys[key]] = object[key];
    }
  });

  return newObject;
};

export const translateList = (list, mappingKeys) => {
  return list.map((item) => translateObjectKeys(item, mappingKeys));
};

export function returnNullIfEmptyString(value) {
  return value === '' ? null : value;
}

export const withArrayCopy = (array, mutator) => {
  const newArray = [...array];
  mutator(newArray);
  return newArray;
};

const addFirstElement = (array, element) =>
  withArrayCopy(array, (newArray) => {
    newArray.unshift(element);
  });

const dropFirstElement = (array) =>
  withArrayCopy(array, (newArray) => {
    newArray.shift();
  });

export const addAllOption = (options) => addFirstElement(options, { key: '', value: '전체' });
export const addPlaceholderOption = (options, placeholder) =>
  addFirstElement(options, { key: '', value: placeholder });

export const setObject = (object, key, value) => {
  const newObject = { ...object };
  newObject[key] = value;
  return newObject;
};

export const updateObject = (object, key, modifier) => {
  const value = object[key];
  const newValue = modifier(value);
  return setObject(object, key, newValue);
};

export const updateNestedObject = (object, keys, modifier) => {
  if (keys.length === 0) {
    return modifier(object);
  }

  const firstKey = keys[0];
  const restOfKeys = dropFirstElement(keys);
  return updateObject(object, firstKey, (nestedObject) =>
    updateNestedObject(nestedObject, restOfKeys, modifier)
  );
};

export const getTotalObject = (array, keyExtractor) =>
  array.reduce((acc, element) => {
    if (acc[keyExtractor(element)]) {
      acc[keyExtractor(element)] += 1;
    } else {
      acc[keyExtractor(element)] = 1;
    }

    return acc;
  }, {});

export const getType = (obj) => {
  return Object.prototype.toString
    .call(obj)
    .replace(/^\[object (.+)\]$/, '$1')
    .toLowerCase();
};

export const getRadioOption = (selectOption) =>
  selectOption.map(({ key, value }, index) => ({
    id: `${key}_${index}`,
    label: value,
    value: key,
  }));

export const getHash = (key, value) => {
  if (hash[key]) return hash[key][value];
  return undefined;
};

export const translateFieldsByHash = (list) =>
  list.map((item) =>
    Object.keys(item).reduce((prev, key) => {
      prev[key] = getHash(key, item[key]) || item[key];
      return prev;
    }, {})
  );

/**
 * excelDownload
 * @param { function } apiFunc
 * @param { array } list
 * @param { object } filter
 * @param { array } keys
 */
export const excelDownload = async (
  apiFunc,
  list,
  filter,
  keys,
  translateFunc = (prevContent) => prevContent
) => {
  if (isEmpty(list)) {
    toastify('검색결과가 존재하지 않습니다.', { variant: 'warn' });
    return;
  }

  try {
    const response = await apiFunc({
      ...filter,
      pageNumber: 0,
      pageSize: 10000,
    });

    downloadJsonAsExcel({
      data: translateList(translateFunc(response.data?.content) || [], keys),
      sheetName: '정산 완료 검색 결과',
      fileName: '정산 완료 검색 결과',
    });
    toastify('액셀 파일이 다운로드 되었습니다.', { variant: 'success' });
  } catch (error) {
    handleError(error);
  }
};

const editCell = (rowIndex, columnId, value) => (row, index) => {
  if (index === rowIndex) {
    return {
      ...row,
      [columnId]: value,
    };
  }
  return row;
};

/**
 * updateMyDataMaker
 * @param { Function } setState - state 변경 함수
 * @param { object } option - optional 객체
 */
export const updateMyDataMaker =
  (setState, { properties, autoResetSelectedRows } = {}) =>
  (rowIndex, columnId, value) => {
    if (autoResetSelectedRows) {
      autoResetSelectedRows.current = false;
    }
    if (properties) {
      setState((prevState) =>
        updateNestedObject(prevState, properties, (prevCells) =>
          prevCells.map(editCell(rowIndex, columnId, value))
        )
      );
      return;
    }

    setState((prevCells) => prevCells.map(editCell(rowIndex, columnId, value)));
  };

export const downloadSupplyRequestList = (productSupplyRequestId, supplyRequestList) => {
  downloadJsonAsExcel({
    header: [['발주번호', productSupplyRequestId]],
    data: translateList(supplyRequestList, SUPPLY_REQUEST_LIST_MAPPING_KEYS),
    options: {
      origin: 'A2',
    },
    sheetName: '발주 품목 내역',
    fileName: `${productSupplyRequestId}_${formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss')}`,
  });
};

export const setDuplicatePreventClick = (ref, fn, time) => {
  if (ref.current) return;

  ref.current = true;

  if (fn) {
    fn();
    setTimeout(() => {
      ref.current = false;
    }, [time]);
  }
};

/**
 * 서버에서 받은 전화번호를 포맷팅 해주는 함수
 * @param {string} phone
 */
export const phoneFormatter = (phone) => {
  let formatNum = '';

  if (phone.length === 11) {
    formatNum = phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (phone.length === 8) {
    formatNum = phone.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else if (phone.indexOf('02') === 0) {
    formatNum = phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
  } else {
    formatNum = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  return formatNum;
};

export const formattingPhoneNumberWithHypen = (phone) => {
  if (!phone) return '';

  const phoneNum = phone.replace(/[^0-9]/g, '');

  const result = [];
  let restNumber = '';

  if (phoneNum.startsWith('02')) {
    result.push(phone.substr(0, 2));
    restNumber = phoneNum.substring(2);
  } else if (phoneNum.startsWith('1')) {
    restNumber = phoneNum;
  } else {
    result.push(phoneNum.substr(0, 3));
    restNumber = phoneNum.substring(3);
  }

  if (restNumber.length === 7) {
    result.push(restNumber.substring(0, 3));
    result.push(restNumber.substring(3));
  } else {
    result.push(restNumber.substring(0, 4));
    result.push(restNumber.substring(4));
  }

  return result.filter((val) => val).join('-');
};

/**
 *
 * @param {object} body
 */
export const setQueries = (body) =>
  Object.entries(body)
    .reduce((acc, [key, value]) => {
      if (value) acc.push(`${key}=${value}`);
      return acc;
    }, [])
    .join('&');
