import styled from 'styled-components';

export const EventContentWrapper = styled.button`
  width: 100%;
  min-height: 40px;
  text-align: right;
  padding-right: 4px;
  position: relative;

  &:hover {
    background: rgba(189, 189, 189, 0.1);
  }

  & > p {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & > p:nth-child(1) {
    color: #8b95a1;
    font-size: var(--font-size-s);
    font-weight: 500;
  }

  & > p:nth-child(2) {
    color: #8b95a1;
    font-size: var(--font-size-s);
    font-weight: 500;
  }

  & > p:nth-child(3) {
    color: #3182f6;
    font-size: var(--font-size-s);
    margin-bottom: 8px;
  }

  & > p:nth-child(4) {
    font-size: var(--font-size-s);
    color: #f33f46;
    font-weight: 700;
  }
`;

function EventContent({ event }) {
  const { extendedProps } = event;

  return (
    <EventContentWrapper
      onClick={() =>
        extendedProps.onClick?.(extendedProps.receiveAvailableDay, extendedProps.centerId ?? null)
      }
    >
      {Object.keys(extendedProps).length ? (
        <>
          <p>{extendedProps.skuCount} sku</p>
          <p>{Number(extendedProps.pcsCount).toLocaleString('ko')}pcs</p>
          <p>{Number(extendedProps.amount).toLocaleString('ko')} 원</p>
          <p>미입고 : {extendedProps.unreceivedSkuCount} sku</p>
        </>
      ) : null}
    </EventContentWrapper>
  );
}

export default EventContent;
