import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getHash, thousandSeparator } from 'commons/helper';

import TotalTable from '../../components/TotalTable';

import Styled from './styled';

/**
 * @param {*} props
 */
const SupplyInfoContainer = ({ columns, requestInfoList = [] }) => {
  const renderCell = useCallback(
    ({ column, value }) => {
      const { id, Header } = column;
      return {
        [Header.match(/₩/) && id]: thousandSeparator(value) || 0,
        [getHash(id, value) && id]: getHash(id, value),
        totalReceivedQuantity: thousandSeparator(value) || 0,
        productSupplyRequestId: (
          <Link className='text-primary' to={`/supply/register/${value}`}>
            {value}
          </Link>
        ),
        prepaidSettlement: <p style={{ textAlign: 'center', margin: 0 }}>{value}</p>,
      }[id];
    },
    [requestInfoList]
  );

  return (
    <Styled.Div>
      <h5 style={{ padding: '1rem 0' }}>
        대상 발주 정보 <b>{requestInfoList.length}</b>건
      </h5>
      <TotalTable
        columns={columns}
        data={requestInfoList}
        renderCell={renderCell}
        $height='unset'
      />
    </Styled.Div>
  );
};
SupplyInfoContainer.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  requestInfoList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
export default SupplyInfoContainer;
