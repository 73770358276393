import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Card from 'components/Card';
import Select from 'components/Select';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import TextareaSearchbar from 'components/TextareaSearchbar';
import ResultHeader from 'components/ResultHeader';
import { ROW_SELECT_PLUGIN_HOOKS } from 'components/Table/commons/constant';
import Table from 'components/Table';
import toastify from 'commons/toast';
import { downloadJsonAsExcel } from 'commons/excel';

import { Flex } from 'styles/components';

import { usePageModals } from 'hooks/pages';

import useFetch from 'hooks/useFetch';
import { translateObjectKeys } from 'commons/helper';
import ReverseRegisterModal from './components/ReverseRegisterModal';
import ReverseDetailModal from './components/ReverseDetailModal';

import { MAIN_COLUMNS, INITIAL_STATE, EXCEL_MAPPING_KEYS } from './constant';

import { useReverseOptions, AdminReverseContext } from './hooks';
import { putReverseCancel, useReverseList } from './apis';

function AdminReverse() {
  const history = useHistory();
  const [, setModalOpen] = usePageModals();
  const { wmsCenterList } = useSelector((state) => state.supply);
  const options = useReverseOptions();

  const [forms, setForms] = useState(INITIAL_STATE);

  const { execute } = useFetch(putReverseCancel, { initialExecute: false });
  const { reverseList, total, executeHandler } = useReverseList(forms);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelectionChange = ({ selectedFlatRows }) => {
    setSelectedRows(selectedFlatRows);
  };

  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    setForms((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangeSearchHandler = (key) => (e) => {
    onChangeHandler({ target: { name: key, value: e.target.value } });
  };

  const onChangeDatePickerHandler = (key) => (date) => {
    onChangeHandler({ target: { name: key, value: date } });
  };

  const search = () => executeHandler();

  const reset = () => {
    setForms(INITIAL_STATE);
  };

  const openRegisterModal = () => {
    history.push('/admin/reverse?register_step=1');
    setModalOpen('register');
  };

  const setReverseCancel = async () => {
    if (selectedRows.length !== 1) {
      toastify('반품취소는 1건씩만 가능합니다.', { variant: 'error' });
      return false;
    }

    if (window.confirm('취소된 반품은 되돌릴 수 없습니다. 취소하시겠습니까?')) {
      const [{ original }] = selectedRows;
      const { data, error } = await execute(original?.reverseId);

      if (error) {
        toastify(error.message, { variant: 'error' });
      } else if (data) {
        toastify('반품 취소가 완료되었습니다.', { variant: 'success' });
      }
      search();
    }
  };

  const translateList = (list) => {
    return list.map((item) => translateObjectKeys(item, EXCEL_MAPPING_KEYS));
  };

  const downloadExcelList = () => {
    if (!reverseList.length) {
      toastify('조회된 내역이 없습니다.', { variant: 'error' });
      return;
    }

    downloadJsonAsExcel({
      data: translateList(reverseList),
      sheetName: '반품 검색 결과',
      fileName: '반품 검색 결과',
    });
  };

  const renderButtons = [
    <Button type='button' onClick={openRegisterModal}>
      반품 등록
    </Button>,
    <Button type='button' $variant='danger' onClick={setReverseCancel}>
      반품 취소
    </Button>,
    <Button type='button' $variant='success' onClick={downloadExcelList}>
      엑셀 다운로드
    </Button>,
  ];

  return (
    <AdminReverseContext.Provider value={{ refresh: executeHandler }}>
      <section>
        <Card title='반품관리'>
          <Flex $flexDirection='column' $gap='var(--space-l)'>
            <Flex $gap='var(--space-m)'>
              <Select
                id='status'
                name='status'
                value={forms.status}
                label='반품상태'
                options={[{ key: '', value: '전체' }, ...options.reverseStatus]}
                onChange={onChangeHandler}
              />
              <Select
                id='reason'
                name='reason'
                label='반품사유'
                value={forms.reason}
                options={[{ key: '', value: '전체' }, ...options.reverseReason]}
                onChange={onChangeHandler}
              />
              <Select
                id='centerId'
                name='centerId'
                label='출고센터'
                value={forms.centerId}
                options={[{ key: '', value: '전체' }, ...wmsCenterList]}
                onChange={onChangeHandler}
              />
            </Flex>
            <Flex $gap='var(--space-m)'>
              <DatePicker
                label='반품등록일'
                id='createdDay'
                startDate={forms.createdDay[0]}
                endDate={forms.createdDay[1]}
                onChange={onChangeDatePickerHandler('createdDay')}
                selectsRange
              />
              <DatePicker
                label='출고완료일'
                id='completeDay'
                startDate={forms.completeDay[0]}
                endDate={forms.completeDay[1]}
                onChange={onChangeDatePickerHandler('completeDay')}
                selectsRange
              />
            </Flex>
            <TextareaSearchbar
              name='searchCondition'
              textareaValue={forms.searchWord}
              onTextareaChange={onChangeSearchHandler('searchWord')}
              radios={options.reverseSearchCondition || []}
              checkedValue={forms.searchCondition}
              onCheckedValueChange={onChangeHandler}
              onSearchClick={search}
              onResetClick={reset}
            />
          </Flex>
        </Card>
        <Card title='반품내역'>
          <ResultHeader totalCount={total} buttons={renderButtons} />
          <Table
            columns={MAIN_COLUMNS}
            data={reverseList}
            pluginHooks={ROW_SELECT_PLUGIN_HOOKS}
            onSelectChange={handleRowSelectionChange}
          />
        </Card>
      </section>
      <ReverseRegisterModal
        deliveryType={options.deliveryType}
        purchaseOrderReverseSearchCondition={options.purchaseOrderReverseSearchCondition}
        reverseReason={options.reverseReason}
      />
      <ReverseDetailModal />
    </AdminReverseContext.Provider>
  );
}

export default AdminReverse;
