import { useState } from 'react';
import PropTypes from 'prop-types';

import { addAllOption, getRadioOption, setObjectState } from 'commons/helper';
import useSelectOptions from 'hooks/useSelectOptions';
import { Flex } from 'styles';

import DatePicker from 'components/DatePicker';
import Select from 'components/Select';
import TextareaSearchbar from 'components/TextareaSearchbar';

import { getAmendmentRequestListParams } from '../../commons/helper';
import { INITIAL_SEARCH_OPTIONS, SELECT_OPTIONS } from '../../commons/constant';

/**
 * @param {*} props
 */
const SearchContainer = ({
  setControlledPage,
  getAmendmentRequestList,
  setSearchOptionsSnapshot,
}) => {
  const [searchOptions, setSearchOptions] = useState(INITIAL_SEARCH_OPTIONS);
  const { requestCode, vendorRequestStatusType, requestSearchCondition } =
    useSelectOptions(SELECT_OPTIONS);

  const handleChange = (e) => setObjectState(setSearchOptions, e.target.name, e.target.value);

  const handleDateChange = (field) => (dates) => setObjectState(setSearchOptions, field, dates);

  const handleTextareaChange = (e) => setObjectState(setSearchOptions, 'query', e.target.value);

  const handleSearchClick = () => {
    setSearchOptionsSnapshot(searchOptions);
    getAmendmentRequestList(getAmendmentRequestListParams(searchOptions));
    setControlledPage(0);
  };

  const handleResetClick = () => {
    setControlledPage(0);
    setSearchOptions(INITIAL_SEARCH_OPTIONS);
    setSearchOptionsSnapshot(INITIAL_SEARCH_OPTIONS);
    getAmendmentRequestList();
  };

  return (
    <Flex $flexDirection='column' $gap='var(--space-m)'>
      <Flex $gap='var(--space-m)' $flexWrap='wrap'>
        <Select
          id='requestCode'
          name='requestCode'
          label='요청 구분'
          value={searchOptions.requestCode}
          onChange={handleChange}
          options={addAllOption(requestCode)}
        />
        <Select
          id='vendorRequestStatusType'
          name='vendorRequestStatusType'
          label='상태'
          value={searchOptions.vendorRequestStatusType}
          onChange={handleChange}
          options={addAllOption(vendorRequestStatusType)}
        />
        <DatePicker
          id='requestDay'
          label='요청일'
          onChange={handleDateChange('requestDay')}
          startDate={searchOptions.requestDay[0]}
          endDate={searchOptions.requestDay[1]}
          selectsRange
        />
        <DatePicker
          id='approveDay'
          label='승인일'
          onChange={handleDateChange('approveDay')}
          startDate={searchOptions.approveDay[0]}
          endDate={searchOptions.approveDay[1]}
          selectsRange
        />
      </Flex>
      <TextareaSearchbar
        name='requestSearchCondition'
        textareaValue={searchOptions.query}
        onTextareaChange={handleTextareaChange}
        radios={getRadioOption(requestSearchCondition)}
        checkedValue={searchOptions.requestSearchCondition}
        onCheckedValueChange={handleChange}
        onSearchClick={handleSearchClick}
        onResetClick={handleResetClick}
        placeholder='검색어를 입력해 주세요. 구분 문자 (쉼표, 엔터)로 복수 품목을 검색할 수 있습니다.'
      />
    </Flex>
  );
};

SearchContainer.propTypes = {
  setControlledPage: PropTypes.func.isRequired,
  getAmendmentRequestList: PropTypes.func.isRequired,
  setSearchOptionsSnapshot: PropTypes.func.isRequired,
};

export default SearchContainer;
