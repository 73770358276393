import instance from 'service/interceptor';

const getNoticeList = async ({ pageNumber = 0, pageSize = 20 } = {}) => {
  const { data } = await instance.post('/api/v2/product/supply/notice/list', {
    pageNumber,
    pageSize,
  });

  return data;
};

const getTotalStatus = async () => {
  const { data } = await instance.get('/api/v2/product/supply/total-status');

  return data;
};

export default { getNoticeList, getTotalStatus };
