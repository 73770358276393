import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';

import * as Styled from './styled';

const Modal = ({
  isOpen,
  closeCallback,
  children,
  header,
  footer,
  $size,
  isLoading = false,
  ...props
}) => {
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    function handleKeydown(e) {
      if (e.key === 'Escape') {
        closeCallback?.();
      }
    }

    document.body.style.overflowY = 'hidden';
    window.addEventListener('keydown', handleKeydown);
    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('keydown', handleKeydown);
      document.body.style.overflowY = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <Styled.Wrapper onClick={closeCallback} {...props}>
      <Styled.Dialog $size={$size}>
        <Styled.Content $size={$size} onClick={(e) => e.stopPropagation()}>
          {!isLoading && header && <Styled.Header>{header}</Styled.Header>}
          <Styled.Body>{isLoading ? <Spinner /> : children}</Styled.Body>
          {!isLoading && footer && <Styled.Footer>{footer}</Styled.Footer>}
        </Styled.Content>
      </Styled.Dialog>
    </Styled.Wrapper>,
    document.getElementById('modal')
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  footer: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  $size: PropTypes.oneOf(['sm', 'lg', 'xl']),
  isLoading: PropTypes.bool,
};

export default Modal;
