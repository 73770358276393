import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
      --border-color:#dbdde2;
      --brand-color:hsl(340,100%,63%);
      --brand-deep-color:hsl(340,86%,53%);
      --brand-light-color:hsl(340,100%,73%);
      --brand-background-color:#fff2f6;
      --grey50: #f9fafb;
      --grey100: #f2f4f6;
      --grey200: #e5e8eb;
      --grey300: #d1d6db;
      --grey400: #b0b8c1;
      --grey500: #8b95a1;
      --grey600: #6b7684;
      --grey700: #4e5968;
      --grey800: #333d4b;
      --grey900: #191f28;
      --greyOpacity50: rgba(0,23,51,0.02);
      --greyOpacity100: rgba(2,32,71,0.05);
      --greyOpacity200: rgba(0,27,55,0.1);
      --greyOpacity300: rgba(0,29,58,0.18);
      --greyOpacity400: rgba(0,29,54,0.31);
      --greyOpacity500: rgba(3,24,50,0.46);
      --greyOpacity600: rgba(0,19,43,0.58);
      --greyOpacity700: rgba(3,18,40,0.7);
      --greyOpacity800: rgba(0,12,30,0.8);
      --greyOpacity900: rgba(2,9,19,0.91);
      --white: #fff;
      --black: #000;
      --blue50: #e8f3ff;
      --blue200: #90c2ff;
      --blue100: #c9e2ff;
      --blue300: #64a8ff;
      --blue400: #4593fc;
      --blue500: #3182f6;
      --blue600: #2272eb;
      --blue700: #1b64da;
      --blue800: #1957c2;
      --blue900: #194aa6;
      --red50: #ffebee;
      --red100: #ffcdd2;
      --red200: #ef9a9a;
      --red300: #e57373;
      --red400: #ef5350;
      --red500: #f44336;
      --red600: #e53935;
      --red700: #d32f2f;
      --red800: #c62828;
      --red900: #b71c1c;
      --orange50: #fff3e0;
      --orange100: #ffe0b2;
      --orange200: #ffcc80;
      --orange300: #ffb74d;
      --orange400: #ffa726;
      --orange500: #ff9800;
      --orange600: #fb8c00;
      --orange700: #f57c00;
      --orange800: #ef6c00;
      --orange900: #e65100;
      --yellow50: #fffde7;
      --yellow100: #fff9c4;
      --yellow200: #fff59d;
      --yellow300: #fff176;
      --yellow400: #ffee58;
      --yellow500: #ffeb3b;
      --yellow600: #fdd835;
      --yellow700: #fbc02d;
      --yellow800: #f9a825;
      --yellow900: #f57f17;
      --purple50: #f3e5f5;
      --purple100: #e1bee7;
      --purple200: #ce93d8;
      --purple300: #ba68c8;
      --purple400: #ab47bc;
      --purple500: #9c27b0;
      --purple600: #8e24aa;
      --purple700: #7b1fa2;
      --purple800: #6a1b9a;
      --purple900: #4a148c;
      --teal50: #e1f0f2;
      --teal100: #b1dade;
      --teal200: #81c7cc;
      --teal300: #4caeb5;
      --teal400: #269da6;
      --teal500: #008c96;
      --teal600: #00818a;
      --teal700: #007278;
      --teal800: #006369;
      --teal900: #004d4d;
      --green50: #e5fff4;
      --green100: #abf2d4;
      --green200: #5ae9ad;
      --green300: #1cd98a;
      --green400: #05c072;
      --green500: #00a661;
      --green600: #009959;
      --green700: #008a50;
      --green800: #007544;
      --green900: #005c36;
      --whiteOpacity50: rgba(209,209,253,0.05);
      --whiteOpacity100: rgba(217,217,255,0.11);
      --whiteOpacity200: rgba(222,222,255,0.19);
      --whiteOpacity300: rgba(224,224,255,0.27);
      --whiteOpacity400: rgba(232,232,253,0.36);
      --whiteOpacity500: rgba(242,242,255,0.47);
      --whiteOpacity600: rgba(248,248,255,0.6);
      --whiteOpacity700: rgba(253,253,255,0.75);
      --whiteOpacity800: rgba(253,253,254,0.89);
      --whiteOpacity900: #fff;
      --inverseGrey50: #202027;
      --inverseGrey100: #2c2c35;
      --inverseGrey200: #3c3c47;
      --inverseGrey300: #4d4d59;
      --inverseGrey400: #62626d;
      --inverseGrey500: #7e7e87;

      --radius-s: 4px;
      --radius-m: 8px;
      --radius-l: 16px;

      --space-s: 5px;
      --space-m: 10px;
      --space-l: 20px;

      --base-height:48px;
      --base-width:200px;

      --font-weight-bold: bold;
      --font-weight-semibold: 600;
      --font-weight-medium: 500;
      --font-weight-regular: normal;

      --font-size-xxs:10px;
      --font-size-xs:12px;
      --font-size-s:14px;
      --font-size-m:15px;
      --font-size-l:18px;
      --font-size-xl:20px;
      --font-size-xxl:22px;
      --font-size-xxxl:24px;
  }
  * {
    box-sizing: border-box;
    font-family: TDSiOSsubset,SF Pro KR,SF Pro Display,SF Pro Icons,-apple-system,BlinkMacSystemFont,Basier Square,Apple SD Gothic Neo,Roboto,Noto Sans KR,Noto Sans,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  }
  html {
    font-size: var(--font-size-m);
  }
  html, body {
    height: 100%;
  }
  body {
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    color:var(--grey900);
  }
  main{
    padding:15px;

    @media (max-width: 766px) {
    padding:0;
  }
  }
  button {
    outline: none;
    background: none;
    margin: 0;
    border: none;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
  }
  a {
    color: inherit;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
  }
  ul,
  li {
    padding: 0;
    margin: 0;
  }
  ol {
    padding-left:20px;
    margin:0;
  }
  input {
    border: 1px solid var(--grey200);
    background: transparent;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  textarea {
    resize: vertical;
    min-height: 120px;
    border: none;
    outline: none;
    padding: 0;
    -webkit-appearance: none;
  }
  img {
    pointer-events: none;
  }
  input::-ms-clear,
  input::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  svg {
    display: block;
    box-sizing: content-box;
  }
  // 부트스트랩 커스텀
  .btn-primary,.badge-primary{
    background-color: var(--blue500);
    border-color: var(--blue500) !important;

    &:hover,&:active{
      border-color: var(--blue700) !important;
      background-color: var(--blue700) !important;
    }
    &:focus{
      background-color: var(--blue500);
    }
    &:disabled{
      background-color: var(--blue300);
      border-color: var(--blue300) !important;
    }
  }
  .btn-secondary{
    background-color: var(--blue50);
    border-color: var(--blue50);
    color: var(--blue500);

    &:hover,&:active{
      background-color: var(--blue100) !important;
      border-color: var(--blue100) !important;
      color: var(--blue500) !important;
    }
    &:focus{
      background-color: var(--blue50);
      border-color: var(--blue50);
      color: var(--blue500);
    }

    &:disabled{
      background-color: var(--blue50);
      border-color: var(--blue50);
      color: var(--blue200);
    }
  }
  .btn-danger{
    background-color: var(--red500);
    border-color: var(--red500) !important;

    &:hover,&:active{
      border-color: var(--red700) !important;
      background-color: var(--red700) !important;
    }
    &:focus{
      background-color: var(--red500);
    }
    &:disabled{
      background-color: var(--red300);
      border-color: var(--red300) !important;
    }
  }
  .btn-light{
    background-color: var(--grey100) !important;
    border-color: var(--grey100) !important;

    &:hover{
      background-color: var(--grey200) !important;
    }
  }
  .card-primary.card-outline{
    border-color: var(--blue500);
  }
  .btn-link{
    color:var(--blue500);
    border-color: var(--blue500);

    :hover{
      color:var(--blue700);
      border-color: var(--blue700);
    }

    :disabled{
      border:1px solid var(--blue50);
      color: var(--blue200);
      cursor: not-allowed;
    }
  }
  .btn-light{
    background-color: var(--grey50);
    color:var(--grey700);
  }
  .btn-success{
    background-color: var(--green50);
    border-color: var(--green50);
    color:var(--green500);

    &:hover,&:active{
      background-color: var(--green100) !important;
      border-color: var(--green100) !important;
      color: var(--green500) !important;
    }
    &:focus{
      background-color: var(--green50);
      border-color: var(--green50);
      color: var(--green500);
    }

    &:disabled{
      background-color: var(--green50);
      border-color: var(--green50);
      color: var(--green200);
    }
  }
  .content-wrapper{
    background-color: white;

    @media (max-width: 766px) {
    background-color: var(--grey100);
  }
  }
  .modal-xl{
    max-width:100%;
    margin:0px calc(var(--space-l) * 2);

    @media (max-width:766px){
    margin:0;
    }
  }
  .Toastify__toast-body{
    white-space: pre-line;
  }

  dl {
    & > div {
      display:flex;
      gap:var(--space-s)
    }
  }
  
  dt:after {
    content: ": ";
  }
`;

export default GlobalStyle;
