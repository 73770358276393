const Remark = () => {
  return (
    <div
      css={`
        margin-bottom: 50px;
      `}
    >
      <h3
        css={`
          font-size: 15px;
          color: var(--grey700);
          font-weight: 400;
        `}
      >
        Remark
      </h3>
      <ul
        css={`
          border-radius: var(--radius-m);
          border: 1px solid var(--border-color);
          padding: 20px 20px 20px 40px;
          list-style-position: outside;
        `}
      >
        <li>
          재고 관련 지표는 직전 영업일 마감재고 기준입니다.
          <br />
          (매일 데이터 업데이트 됩니다)
        </li>
        <li>
          담당자가 지정되지 않은 품목은 모든 유저에게 노출됩니다.
          <br />
          마스터 정보를 보완해주세요.
        </li>
      </ul>
    </div>
  );
};

export default Remark;
