import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { thousandSeparator, numberExtractor } from 'commons/helper';
import { REASON_FOR_CHANGE_OPTIONS, SPECIAL_CHARACTER_REGEX } from 'commons/constant';
import { formatDateAsYYYYMMDD, formatStringAsDate } from 'commons/date';

import Select from 'components/Select';
import Input from 'components/Input';

import DatePicker from 'components/DatePicker';
import { STORAGE_TEMPERATURE_OPTIONS } from './commons/constant';

const ItemEditableCell = ({
  value: initialValue,
  row,
  column: { id: columnName },
  updateMyData,
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e) => setValue(e.target.value);
  const handleThousandSeparatorInputChange = (e) => setValue(numberExtractor(e.target.value));
  const handleBarcodeChange = (e) => setValue(e.target.value.replace(SPECIAL_CHARACTER_REGEX, ''));
  const handleNumberInputChange = (e) => {
    const [, decimal] = e.target.value.split('.');
    if (decimal?.length > 1) {
      return;
    }

    setValue(Number(e.target.value));
  };
  const handleBlur = () => updateMyData(row, columnName, value);
  const handleDateChange = (date) => {
    setValue(formatDateAsYYYYMMDD(date));
    updateMyData(row, columnName, formatDateAsYYYYMMDD(date));
  };

  if (columnName === 'itemBarcode' || columnName === 'boxBarcode') {
    return (
      <Input
        type='text'
        value={value || ''}
        onChange={handleBarcodeChange}
        onBlur={handleBlur}
        required={columnName === 'itemBarcode'}
      />
    );
  }

  if (columnName === 'boxUnit' || columnName === 'pltUnit') {
    return (
      <Input
        type='text'
        value={thousandSeparator(value)}
        onChange={handleThousandSeparatorInputChange}
        onBlur={handleBlur}
        required={columnName === 'boxUnit'}
        maxLength={10}
      />
    );
  }

  if (
    columnName === 'width' ||
    columnName === 'length' ||
    columnName === 'height' ||
    columnName === 'weight' ||
    columnName === 'volume'
  ) {
    return (
      <Input
        type='number'
        value={value || ''}
        onChange={handleNumberInputChange}
        onBlur={handleBlur}
      />
    );
  }

  if (columnName === 'storageTemperature') {
    return (
      <Select
        required
        id='storageTemperature'
        value={value || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        options={STORAGE_TEMPERATURE_OPTIONS}
      />
    );
  }

  if (columnName === 'reasonForChange') {
    return (
      <Select
        id='reasonForChange'
        placeholder='변경사유 선택'
        value={value || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        options={REASON_FOR_CHANGE_OPTIONS}
        required
      />
    );
  }

  if (columnName === 'effectiveDate') {
    return (
      <div css='min-width: 150px'>
        <DatePicker
          id='effectiveDate'
          selected={formatStringAsDate(value) ?? undefined}
          onChange={handleDateChange}
          minDate={new Date()}
        />
      </div>
    );
  }

  return value;
};

ItemEditableCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  row: PropTypes.shape({ index: PropTypes.number }).isRequired,
  column: PropTypes.shape({ id: PropTypes.string }).isRequired,
  updateMyData: PropTypes.func.isRequired,
};

export default ItemEditableCell;
