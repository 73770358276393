import Card from 'components/Card';

import PrepaidRegisterModal from './components/PrepaidRegisterModal';
import SearchContainer from './containers/SearchContainer';
import ResultContainer from './containers/ResultContainer';

const PrepaidPage = () => {
  return (
    <>
      <Card title='선지급금 내역'>
        <SearchContainer />
        <ResultContainer />
      </Card>
      <PrepaidRegisterModal />
    </>
  );
};

export default PrepaidPage;
