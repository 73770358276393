import PropTypes from 'prop-types';

import Modal from 'components/modals/Modal';

import * as Styled from './styled';

const HeaderModal = ({
  isOpen,
  closeCallback,
  children,
  title,
  leftButton,
  rightButton,
  ...props
}) => {
  function renderHeader() {
    return (
      <>
        <Styled.Title>{title}</Styled.Title>
        <Styled.ButtonWrapper>
          {leftButton?.props.children && leftButton}
          {rightButton?.props.children && rightButton}
        </Styled.ButtonWrapper>
      </>
    );
  }
  return (
    <Modal isOpen={isOpen} closeCallback={closeCallback} header={renderHeader()} {...props}>
      {children}
    </Modal>
  );
};

HeaderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.string.isRequired,
  leftButton: PropTypes.element,
  rightButton: PropTypes.element,
};

export default HeaderModal;
