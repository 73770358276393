import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import supplyApi from 'service/hq/supply';

const initialState = {
  wmsCenterList: [],
  prevAdminSupplyValue: { supplyId: null },
};

export const getWmsCenterList = createAsyncThunk(
  'common/centers',
  async (_, { rejectWithValue }) => {
    try {
      const { response } = await supplyApi.getWmsCenterList();
      return {
        centerList: response?.data ?? [],
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const slice = createSlice({
  name: 'supplySlice',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getWmsCenterList.fulfilled, (state, { payload }) => {
      return {
        ...state,
        wmsCenterList: payload.centerList,
      };
    });
    builder.addCase(getWmsCenterList.rejected, (state) => {
      return state;
    });
  },
  reducers: {
    setPrevAdminSupplyValue: (state, { payload }) => {
      state.prevAdminSupplyValue = payload;
    },
  },
});

export const { setPrevAdminSupplyValue } = slice.actions;

export default slice.reducer;
