import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import { usePageModals } from 'hooks/pages';

import { thousandSeparator, numberExtractor } from 'commons/helper';

import { blueUnderline } from 'styles/commons';
import Input from 'components/Input';
import Button from 'components/Button';
import { setReverseId, setPurchaseOrderId } from 'commons/store/modules/admin/reverse';

const BlueUnderLineButton = styled.button`
  ${blueUnderline}
`;

export function ReverseIdCell({ value, row }) {
  const dispatch = useDispatch();
  const [, setModalOpen] = usePageModals();

  const openModal = () => {
    dispatch(setReverseId(row?.original?.reverseId || null));
    setModalOpen('detail', true);
  };

  return <BlueUnderLineButton onClick={openModal}>{value}</BlueUnderLineButton>;
}

export function NumberCell({ value }) {
  return thousandSeparator(value) || 0;
}

export function DisabledInputCell({ value }) {
  return <Input disabled value={thousandSeparator(value) || '0'} />;
}

export function VendorIdChoiceButton({ row }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const nextStep = () => {
    dispatch(setPurchaseOrderId(row?.original.purchaseOrderId));
    history.push('/admin/reverse?register_step=2');
  };

  return (
    <Button $outline onClick={nextStep}>
      선택
    </Button>
  );
}

export function ReverseCountCell({
  value: initialValue,
  row: { index: rowIndex, values, original },
  column: { id: columnName },
  updateMyData,
  etcOption, // collect(회수) or dispose(폐기)
}) {
  const [value, setValue] = useState(initialValue);
  const [initError, setInitError] = useState(false);
  const isDispose = etcOption === 'dispose';

  const onChangeHandler = (e) => {
    if (initError) setInitError(false);

    setValue(numberExtractor(e.target.value));
  };
  const onBlurHandler = () => {
    const sum = value * values.purchaseOrderUnitPrice;
    updateMyData(rowIndex, columnName, value);

    if (isDispose) return;
    updateMyData(rowIndex, 'reverseAvailablePrice', sum);
  };

  useEffect(() => {
    if (initialValue === '') {
      setValue(0);
      return;
    }
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setInitError(original?.isError ?? false);
  }, [original?.isError]);

  useEffect(() => {
    onBlurHandler();
  }, [isDispose]);

  return (
    <Input
      className={initError ? 'error' : ''}
      value={value ?? ''}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
    />
  );
}
