export const COLUMNS = [
  {
    Header: '화물 정보',
    columns: [
      { Header: '운송유형', accessor: 'transportTypeCodeName' },
      { Header: '화물 수량', accessor: 'transportQuantity' },
      { Header: '화물 구분', accessor: 'transportCargoTypeCodeName' },
      { Header: '비고', accessor: 'transportInfoComment' },
    ],
  },
];

export default { COLUMNS };
