import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Styled from './styled';

const TitleCell = ({ value, row }) => {
  const history = useHistory();

  const handleNoticeDetailModalOpen = (id) => () => history.push(`/admin/notice/register/${id}`);

  return <Styled.Div onClick={handleNoticeDetailModalOpen(row.original.id)}>{value}</Styled.Div>;
};

TitleCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default TitleCell;
