import styled from 'styled-components';

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-l);

  > div {
    flex: 1;
    height: 100%;
  }

  .card {
    margin-bottom: 0;
  }
`;

export const LabelCountWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 100%;
  align-items: center;
`;

export default { Div, LabelCountWrapper };
