import PropTypes from 'prop-types';

import * as StyledPage from 'styles';
import { TITLES, SUB_TITLES, TEXTS } from './commons/constant';

const Notice = ({ target }) => {
  return (
    <div>
      <StyledPage.SectionTitle>{TITLES[target]}</StyledPage.SectionTitle>
      <ol>
        <ul>
          <li>{SUB_TITLES[target]}</li>
        </ul>
        {TEXTS[target].map((text) => (
          <li key={text}>{text}</li>
        ))}
      </ol>
    </div>
  );
};

Notice.propTypes = {
  target: PropTypes.string.isRequired,
};

export default Notice;
