import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { setPrevVendorSupplyValue } from 'commons/store/modules/vendor/supply';

import useRouter from 'hooks/useRouter';
import * as Styled from './styled';

const SupplyRequestCell = ({ value }) => {
  const dispatch = useDispatch();
  const { pushWithSearchParams } = useRouter();

  const handleClick = () => {
    dispatch(setPrevVendorSupplyValue({ supplyId: value }));
    pushWithSearchParams(`/supply/register/${value}`);
  };

  return (
    <Styled.Wrapper type='button' onClick={handleClick}>
      {value}
    </Styled.Wrapper>
  );
};

SupplyRequestCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default SupplyRequestCell;
