import { useState, useEffect } from 'react';
import useFetch from 'hooks/useFetch';

import { getVendorOverviewData } from '../apis';

export const useOverview = ({ yearMonth }) => {
  const [overviewData, setOverviewData] = useState({});
  const { execute } = useFetch(getVendorOverviewData, { initialExecute: false });

  const reloadingOverview = async (params) => {
    const { data } = await execute(params);
    setOverviewData(data);
  };

  useEffect(() => {
    reloadingOverview({ yearMonth });
  }, [yearMonth]);

  return { overviewData };
};
