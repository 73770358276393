import PropTypes from 'prop-types';

import { addPlaceholderOption, handleInputChange } from 'commons/helper';
import useSelectOptions from 'hooks/useSelectOptions';
import { Flex } from 'styles/components';

import Select from 'components/Select';

import * as Styled from '../../styled';

const SupplyInfoForm = ({ form, setForm, inputDisabled }) => {
  const selectOptions = useSelectOptions(['receiveMethod']);

  return (
    <Flex $flexDirection='column'>
      <Styled.Category>발주 정보</Styled.Category>
      <Flex $gap='var(--space-m)'>
        <Select
          id='receiveMethod'
          name='receiveMethod'
          label='기본 입고 방법'
          value={form.receiveMethod}
          onChange={(e) => handleInputChange(e, setForm)}
          options={addPlaceholderOption(selectOptions.receiveMethod, '기본 입고 방법 선택')}
          disabled={inputDisabled}
          required
        />
      </Flex>
    </Flex>
  );
};

SupplyInfoForm.propTypes = {
  form: PropTypes.shape({
    receiveMethod: PropTypes.string,
  }).isRequired,
  setForm: PropTypes.func.isRequired,
  inputDisabled: PropTypes.bool,
};

export default SupplyInfoForm;
