import { useSelector } from 'react-redux';

import { usePageModals } from 'hooks/pages';

import Modal from 'components/modals/Modal';

import Styled from './styled';
import DateCategory from '../../components/DateCategory';

const NoticeModalContainer = () => {
  const [{ detail }, setModalOpen] = usePageModals();
  const { selectedNotice } = useSelector((state) => state.dashboard);

  const handleDetailModalOpen = () => setModalOpen('detail');

  const modalHeader = (
    <Styled.ModalHeader>
      <DateCategory date={selectedNotice.date} category={selectedNotice.category} />
      <Styled.ModalTitle>{selectedNotice.title}</Styled.ModalTitle>
    </Styled.ModalHeader>
  );

  return (
    <Modal isOpen={detail} closeCallback={handleDetailModalOpen} header={modalHeader} $size='lg'>
      <Styled.Textarea readOnly defaultValue={selectedNotice.paragraph} />
    </Modal>
  );
};

export default NoticeModalContainer;
