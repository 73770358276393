import styled from 'styled-components';

export const RadioGroupWrapper = styled.div`
  width: 200px;
`;

export const TextareaLabelWrapper = styled.div`
  textarea {
    width: 100%;
  }
`;

export default { RadioGroupWrapper };
