export const getIsSelectedAddedItemList = (prevItemList) =>
  prevItemList.map(({ original, isSelected }) => ({ ...original, isSelected }));

export const getSelectedItemList = (list) => list.filter((flatRaw) => flatRaw.isSelected);

export const handleSelectedListRemove = (list) =>
  list
    .filter((item) => {
      if (item.isSelected && !item.productSupplyRequestItemId) {
        return false;
      }
      return true;
    })
    .map((item) => {
      if (item.isSelected && item.productSupplyRequestItemId) {
        return { ...item, active: 'N' };
      }
      return item;
    });
