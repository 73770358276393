import PropTypes from 'prop-types';

import { updateNestedObject } from 'commons/helper';

import * as StyledPage from 'styles';
import { Flex } from 'styles';
import LabelAndText from 'components/LabelAndText';
import Input from 'components/Input';

import { MAPPING_REQUEST_STATUS, STATUS_VALUES_FOR_MESSAGE } from '../../commons/constant';

/**
 * @param {*} props
 */
const RequestInfoContainer = ({
  requestInfo: {
    requestAt,
    updatedAt,
    approveAt,
    createdAt,
    statusValue,
    vendorId,
    displayName,
    requestId,
    memo,
  },
  setRequestDetail,
}) => {
  const handleMemoChange = (e) => {
    setRequestDetail((prev) =>
      updateNestedObject(prev, ['data', 'requestInfoDto', 'memo'], () => e.target.value)
    );
  };

  const processedAt = {
    '요청 중': requestAt,
    '요청 취소': updatedAt,
    '승인 완료': approveAt,
    '요청 반려': updatedAt,
  };

  const processStatusMessage =
    statusValue &&
    `${processedAt[statusValue]} ${
      STATUS_VALUES_FOR_MESSAGE[statusValue] || statusValue
    } 되었습니다.`;

  const isMemoDisabled = statusValue !== '요청 중';

  const labelAndTexts = [
    { label: '거래처ID', text: vendorId },
    { label: '거래처명', text: displayName },
    { label: '요청 ID', text: requestId },
    { label: '등록 일시', text: createdAt },
    { label: '요청 일시', text: requestAt },
    { label: '승인 일시', text: approveAt },
  ];

  return (
    <Flex $flexDirection='column' $gap='var(--space-m)'>
      <Flex $gap='var(--space-m)' $flexWrap='wrap'>
        {labelAndTexts.map(({ label, text }) => (
          <LabelAndText key={label} label={label} text={text} />
        ))}
      </Flex>
      <StyledPage.SectionTitle>처리 상태</StyledPage.SectionTitle>
      <Flex $alignItems='center' $gap='var(--space-m)'>
        <StyledPage.Status $status={MAPPING_REQUEST_STATUS[statusValue]}>
          {statusValue}
        </StyledPage.Status>
        <span>{processStatusMessage}</span>
      </Flex>
      <Input value={memo || ''} onChange={handleMemoChange} disabled={isMemoDisabled} />
    </Flex>
  );
};

RequestInfoContainer.propTypes = {
  requestInfo: PropTypes.shape({
    vendorId: PropTypes.number,
    displayName: PropTypes.string,
    requestId: PropTypes.number,
    createdAt: PropTypes.string,
    requestAt: PropTypes.string,
    approveAt: PropTypes.string,
    updatedAt: PropTypes.string,
    statusValue: PropTypes.string,
    memo: PropTypes.string,
  }).isRequired,
  setRequestDetail: PropTypes.func,
};

export default RequestInfoContainer;
