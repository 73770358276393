import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setSelectedAmendmentRequest } from 'commons/store/modules/admin/amendmentRequest';
import amendmentRequestApi from 'service/hq/amendmentRequest';
import useFetch from 'hooks/useFetch';

import HeaderModal from 'components/modals/HeaderModal';
import VendorInfoContainer from './VendorInfoContainer';
import RequestInfoContainer from '../../containers/RequestInfoContainer';

const VendorModal = () => {
  const {
    data: { requestInfoDto = {}, requestDetailList = [] } = {},
    execute: getVendorAmendmentRequestDetail,
    setState: setVendorAmendmentRequestDetail,
  } = useFetch(amendmentRequestApi.getVendorAmendmentRequestDetail, {
    initialExecute: false,
  });
  const dispatch = useDispatch();
  const { selectedAmendmentRequest } = useSelector((state) => state.amendmentRequest);

  const isProductMasterModalOpen = selectedAmendmentRequest.code === 'VENDOR';

  useEffect(() => {
    if (isProductMasterModalOpen) {
      getVendorAmendmentRequestDetail(selectedAmendmentRequest.id);
    }
  }, [isProductMasterModalOpen]);

  const handleModalClose = () =>
    dispatch(
      setSelectedAmendmentRequest({
        code: null,
        id: null,
      })
    );

  return (
    <HeaderModal
      isOpen={isProductMasterModalOpen}
      closeCallback={handleModalClose}
      title='거래처 정보 수정 요청'
      $size='xl'
    >
      <RequestInfoContainer
        requestInfo={requestInfoDto}
        setRequestDetail={setVendorAmendmentRequestDetail}
      />
      <VendorInfoContainer
        vendorInfo={requestDetailList[0]}
        reason={requestInfoDto.memo}
        statusValue={requestInfoDto.statusValue}
        requestId={selectedAmendmentRequest.id}
      />
    </HeaderModal>
  );
};

export default VendorModal;
