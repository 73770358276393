import PropTypes from 'prop-types';

import Button from 'components/Button';

import * as Styled from './styled';

const HelperButtons = ({ handleListClick, handleSaveClick, statusCode }) => {
  return (
    <Styled.Wrapper>
      <Button $variant='link' onClick={handleListClick}>
        목록
      </Button>
      {statusCode !== 'CANCEL' && (
        <Button type='submit' onClick={handleSaveClick}>
          저장
        </Button>
      )}
    </Styled.Wrapper>
  );
};

HelperButtons.propTypes = {
  handleListClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  statusCode: PropTypes.oneOf([
    'PETFRIENDS_DRAFT',
    'PETFRIENDS_REGISTER',
    'MODIFY',
    'VENDOR_REQUEST',
    'PETFRIENDS_CONFIRM',
    'CONFIRM_CANCEL',
    'CANCEL',
    '',
  ]),
};

export default HelperButtons;
