export const COLUMNS = [
  {
    Header: '발주 정보',
    columns: [
      { Header: '발주번호', accessor: 'productSupplyRequestId' },
      { Header: '발주유형', accessor: 'productSupplyTypeCodeName' },
      { Header: '발주상태', accessor: 'statusCodeName' },
      { Header: '입고번호', accessor: 'inboundWikey' },
    ],
  },
  {
    Header: '발주 개요',
    columns: [
      { Header: '품목', accessor: 'totalItemKindCount' },
      { Header: '발주수량', accessor: 'totalItemQuantity' },
      { Header: '발주금액', accessor: 'totalPrice' },
      { Header: '입고결과 금액(₩)', accessor: 'totalReceivedPrice' },
    ],
  },
  {
    Header: '입고 정보',
    columns: [
      { Header: '입고센터', accessor: 'centerName' },
      { Header: '입고예정일', accessor: 'receiveAvailableDay' },
      { Header: '입고완료일', accessor: 'receiveCompleteDay' },
    ],
  },
  {
    Header: '등록 정보',
    columns: [
      { Header: '등록자', accessor: 'requestWorkerName' },
      { Header: '등록일시', accessor: 'createDate' },
      { Header: '수정일시', accessor: 'updateDate' },
    ],
  },
];

export const SEARCH_OPTIONS_INITIAL_VALUES = {
  productSupplyTypeCode: '',

  productSupplyRequestDay: [null, null],
  receiveAvailableDay: [null, null],

  statusCodes: [''],

  condition: 'PRODUCT_SUPPLY_REQUEST',
  query: '',
};

// jsx 파싱 에러 발생
// export const SUPPLY_CONFIRM_MODAL_OPTIONS = {
//   title: '발주 확정 요청 안내',
//   content: (
//     <div>
//       <h4>
//         발주 확정 후에는 발주를 더 이상 수정할 수 없습니다.
//         <br />
//         확정 요청 전에 품목, 금액, 수량이 알맞게 입력됐는 지 꼭 확인해 주세요.
//       </h4>
//       <p>
//         발주가 확정된 대로 입고 및 정산이 진행되며, 특히 정산은 입고 결과에 따라 진행됩니다.
//         <br />
//         따라서 확정 요청하시기 전에 품목 별로 금액과 수량이 알맞게 입력됐는 지 반드시 확인하시기
//         바랍니다.
//       </p>
//       <p>발주 확정 요청을 진행하시겠습니까?</p>
//     </div>
//   ),
// };

export default { COLUMNS };
