import styled from 'styled-components';

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--space-m) calc(var(--space-l) * 2);
  margin-top: var(--space-l);

  > div {
    display: flex;
    align-items: center;
    gap: var(--space-s);

    span {
      color: var(--grey900);
      font-weight: var(--font-weight-semibold);
    }
  }
`;

export default { HeaderButtons };
