import { useRef, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { handleError, isEmpty, setObjectState } from 'commons/helper';
import supplyApi from 'service/supply/supply';
import toastify from 'commons/toast';
import useFetch from 'hooks/useFetch';
import pdf from 'files/팀프레시_물류센터_약도.pdf';
import { useGlobalModals } from 'hooks/pages';

import Card from 'components/Card';
import Searchbar from 'components/Searchbar';
import Button from 'components/Button';
import ResultHeader from 'components/ResultHeader';
import PaginationTable from 'components/Table/PaginationTable';
import { ROW_SELECT_PLUGIN_HOOKS } from 'components/Table/commons/constant';
import QuestionMark from 'components/QuestionMark';
import NumericCell from 'components/NumericCell/NumericCell';

import { Flex } from 'styles/components';

import useSearchParams from 'hooks/useSearchParams';

import useSearchParamsState from 'hooks/useSearchParamsState';
import { setPrevVendorSupplyValue } from 'commons/store/modules/vendor/supply';
import { COLUMNS, SEARCH_OPTIONS_INITIAL_VALUES } from './commons/constant';
import ProductCell from './components/ProductCell';
import SupplyRequestCell from './components/SupplyRequestCell';
import { getSupplyRequestListParams, isConfirmButtonDisabled } from './commons/helper';
import SearchOptions from './components/SearchOptions';

import * as Styled from './styled';

const SupplyManagePage = () => {
  const dispatch = useDispatch();
  const { prevVendorSupplyValue } = useSelector((state) => state.vendorSupply);

  const [searchOptions, setSearchOptions] = useSearchParamsState(SEARCH_OPTIONS_INITIAL_VALUES);
  const [searchParams, setQueryParams] = useSearchParams();

  const [controlledPageIndex, setControlledPage] = useState(
    Number(searchParams.get('pageNumber')) || 0
  );
  const [selectedSupplyRequestList, setSelectedSupplyRequestList] = useState([]);
  const searchOptionsSnapshot = useRef(SEARCH_OPTIONS_INITIAL_VALUES);
  const [, setModalOpen] = useGlobalModals();

  const {
    data: { content: supplyRequestList = [], totalElements = 0, totalPages = 0 } = {},
    execute: getSupplyRequestList,
  } = useFetch(supplyApi.getSupplyRequestList);

  const tableOptions = {
    initialState: { pageSize: 50 },
    manualPagination: true,
    pageCount: totalPages,
    useControlledState: (state) => ({
      ...state,
      pageIndex: controlledPageIndex,
    }),
  };

  const handleSearchQueryChange = (e) => setObjectState(setSearchOptions, 'query', e.target.value);

  const handleSearch = () => {
    setQueryParams(searchOptions);
    setControlledPage(0);
    searchOptionsSnapshot.current = searchOptions;
    getSupplyRequestList(getSupplyRequestListParams(searchOptions));
  };

  const handleResetClick = () => {
    setQueryParams(SEARCH_OPTIONS_INITIAL_VALUES);
    setControlledPage(0);
    setSearchOptions(SEARCH_OPTIONS_INITIAL_VALUES);
    searchOptionsSnapshot.current = SEARCH_OPTIONS_INITIAL_VALUES;
    getSupplyRequestList();
  };

  const handlePageChange = ({ state }) => {
    const pageNumber = state.pageIndex;
    setQueryParams({ ...searchOptions, pageNumber });
    getSupplyRequestList(getSupplyRequestListParams(searchOptions, pageNumber));
  };

  const handleTableSelectChange = ({ selectedFlatRows }) =>
    setSelectedSupplyRequestList(selectedFlatRows.map((row) => row.original));

  const getProductSupplyRequestIdList = (list) =>
    list.map((request) => request.productSupplyRequestId);

  const handleYesClick = async () => {
    try {
      await supplyApi.updateStatus(
        getProductSupplyRequestIdList(selectedSupplyRequestList),
        'VENDOR_REQUEST'
      );
      toastify(`${selectedSupplyRequestList.length}건의 발주를 확정 요청하였습니다.`, {
        variant: 'success',
      });
      getSupplyRequestList(
        getSupplyRequestListParams(searchOptionsSnapshot.current, controlledPageIndex)
      );
      setModalOpen('alert', false, {});
    } catch (error) {
      handleError(error);
    }
  };

  const handleConfirmClick = async () => {
    if (isEmpty(selectedSupplyRequestList)) {
      toastify('선택된 발주가 존재하지 않습니다.', { variant: 'warn' });
      return;
    }

    setModalOpen('alert', true, {
      title: '발주 확정 요청 안내',
      content: (
        <div>
          <h4>
            발주 확정 후에는 발주를 더 이상 수정할 수 없습니다.
            <br />
            확정 요청 전에 품목, 금액, 수량이 알맞게 입력됐는 지 꼭 확인해 주세요.
          </h4>
          <p>
            발주가 확정된 대로 입고 및 정산이 진행되며, 특히 정산은 입고 결과에 따라 진행됩니다.
            <br />
            따라서 확정 요청하시기 전에 품목 별로 금액과 수량이 알맞게 입력됐는 지 반드시 확인하시기
            바랍니다.
          </p>
          <p>발주 확정 요청을 진행하시겠습니까?</p>
        </div>
      ),
      buttons: [
        { text: '예', onClick: handleYesClick },
        {
          text: '아니오',
          onClick: () => setModalOpen('alert', false, {}),
          variant: 'secondary',
        },
      ],
    });
  };

  useEffect(() => {
    if (prevVendorSupplyValue.supplyId) {
      handlePageChange({ state: controlledPageIndex });
      dispatch(setPrevVendorSupplyValue({ supplyId: null }));
    }
  }, []);

  const renderCell = (cell) => {
    switch (cell.column.id) {
      case 'productSupplyRequestId':
        return <SupplyRequestCell {...cell} />;
      case 'totalItemKindCount':
        return <ProductCell {...cell} />;
      case 'totalItemQuantity':
      case 'totalPrice':
      case 'estimatedTaxSettlementPrice':
      case 'totalReceivedPrice':
        return <NumericCell {...cell} />;
      default:
        return cell.value;
    }
  };

  const resultHeaderButtons = [
    <Button $variant='light' as='a' href={pdf} target='blank'>
      입고센터 약도 다운로드
    </Button>,
    <Flex $alignItems='center'>
      <Button
        $variant='secondary'
        disabled={isConfirmButtonDisabled(selectedSupplyRequestList)}
        onClick={handleConfirmClick}
      >
        발주 확정 일괄 요청
      </Button>
      <QuestionMark
        $position='left'
        helperText='수정을 마친 발주에 대해서 펫프렌즈 발주 담당자에게 발주 확정을 요청합니다.'
      />
    </Flex>,
  ];

  return (
    <Card title='발주 관리'>
      <Flex $flexDirection='column' $gap='var(--space-m)'>
        <SearchOptions searchOptions={searchOptions} setSearchOptions={setSearchOptions} />
        <Searchbar
          name='searchbar'
          value={searchOptions.query}
          onChange={handleSearchQueryChange}
          onClick={handleSearch}
          onReset={handleResetClick}
        />
      </Flex>
      <ResultHeader totalCount={totalElements} buttons={resultHeaderButtons} />
      <Styled.TableWrapper>
        <PaginationTable
          columns={COLUMNS}
          data={supplyRequestList}
          tableOptions={tableOptions}
          onPageChange={handlePageChange}
          onSelectChange={handleTableSelectChange}
          pluginHooks={ROW_SELECT_PLUGIN_HOOKS}
          setControlledPage={setControlledPage}
          renderCell={renderCell}
        />
      </Styled.TableWrapper>
    </Card>
  );
};

export default SupplyManagePage;
