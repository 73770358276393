import { useState, useCallback, lazy, Suspense } from 'react';
import dayjs from 'dayjs';
import Card from 'components/Card';
import DashboardCalendar, { useCalendarList } from 'components/DashboardCalendar';
import Select from 'components/Select';
import { addAllOption, returnNullIfEmptyString } from 'commons/helper';
import { useSelector } from 'react-redux';

import { usePageModals } from 'hooks/pages';

import { Flex } from 'styles';
import Button from 'components/Button';
import Overview from './components/Overview';
import SideBar from './components/SideBar';
import { useDaily, useOverview } from './hooks';
import { postPurchaseOrderCalendar } from './apis';

const SupplyLimitModal = lazy(() => import('./components/SupplyLimitModal'));
const DailyLimitModal = lazy(() => import('./components/DailyLimitModal'));

function AdminDashboard() {
  const [, setModalOpen] = usePageModals();
  const { wmsCenterList } = useSelector((state) => state.supply);
  const { checkCapacity } = useSelector((state) => state.user);

  const [currentDate, setCurrentDate] = useState(dayjs(new Date()).format('YYYY-MM'));
  const [centerId, setCenterId] = useState('12');

  const [{ visible, sidebarTitle }, setSidebarData] = useState({
    visible: false,
    sidebarTitle: '',
  });
  const { overviewData } = useOverview({ yearMonth: currentDate, centerId });
  const { dailyData, executeHandler } = useDaily();

  const onDailyClickHandler = (searchDate, id) => {
    executeHandler({ searchDate, centerId: id }, () => {
      setSidebarData(() => ({ visible: true, sidebarTitle: searchDate }));
    });
  };

  const setModalHandler = (modalName) => {
    setModalOpen(modalName);
  };

  const { calendarEvents, executeHandler: calendarReload } = useCalendarList({
    yearMonth: currentDate,
    centerId,
    onClick: onDailyClickHandler,
    api: postPurchaseOrderCalendar,
  });

  const renderSelectCenter = useCallback(() => {
    return (
      <Select
        options={addAllOption(wmsCenterList)}
        id='centerId'
        value={centerId ?? ''}
        onChange={(e) => setCenterId(returnNullIfEmptyString(e.target.value))}
      />
    );
  }, [centerId, wmsCenterList]);

  return (
    <section>
      <Overview currentDate={currentDate} overviewData={overviewData} right={renderSelectCenter} />
      <Card title=''>
        <DashboardCalendar
          calendarEvents={calendarEvents}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          dayCellProps={{ events: calendarEvents }}
          {...(checkCapacity
            ? {
                leftTopHeaderNode: (
                  <Flex $columnGap='8px'>
                    <Button
                      $outline
                      $variant='dark'
                      $size='small'
                      onClick={() => setModalHandler('발주제한')}
                    >
                      발주제한 설정
                    </Button>
                    <Button
                      $outline
                      $variant='dark'
                      $size='small'
                      onClick={() => setModalHandler('일자별제한')}
                    >
                      일자별 제한 설정
                    </Button>
                  </Flex>
                ),
              }
            : {})}
        >
          <SideBar
            title={sidebarTitle}
            centerId={centerId}
            onClose={() => {
              setSidebarData((prev) => ({ ...prev, visible: false }));
            }}
            visible={visible}
            dailyData={dailyData}
          />
        </DashboardCalendar>
      </Card>
      <Suspense fallback={null}>
        <SupplyLimitModal refresh={calendarReload} />
      </Suspense>
      <Suspense fallback={null}>
        <DailyLimitModal refresh={calendarReload} />
      </Suspense>
    </section>
  );
}

export default AdminDashboard;
