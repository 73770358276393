import { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { usePagination } from 'react-table';

import Table from 'components/Table';
import Pagination from '../components/Pagination';

/**
 * https://react-table.tanstack.com/docs/api/usePagination#usepagination
 */
const PaginationTable = ({
  columns,
  data,
  onPageChange,
  tableOptions,
  setControlledPage,
  pluginHooks: addedPluginHooks = [],
  ...props
}) => {
  const [pluginHooks] = useState([usePagination, ...addedPluginHooks]);
  const footerComponent = useCallback(
    (tableInstance) => {
      return Pagination({ tableInstance, onPageChange, setControlledPage });
    },
    [setControlledPage, onPageChange]
  );

  return (
    <Table
      columns={columns}
      data={data}
      pluginHooks={pluginHooks}
      tableOptions={tableOptions}
      footerComponent={footerComponent}
      {...props}
    />
  );
};

PaginationTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,

  onPageChange: PropTypes.func.isRequired,
  tableOptions: PropTypes.shape({
    initialState: PropTypes.shape({ pageIndex: PropTypes.number, pageSize: PropTypes.number })
      .isRequired,
    manualPagination: PropTypes.bool,
    pageCount: PropTypes.number.isRequired,
  }).isRequired,
  setControlledPage: PropTypes.func,
  pluginHooks: PropTypes.arrayOf(PropTypes.func),
};

export default memo(PaginationTable);
