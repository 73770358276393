import PropTypes from 'prop-types';

import { thousandSeparator } from 'commons/helper';

import Styled from './styled';

/**
 * @param {*} props
 */
const FinalSettlementInfoContainer = ({ settlementInfo = {} }) => {
  const renderLabalPrice = (label, price, color) => {
    if (!price && typeof price !== 'number') return null;

    return (
      <Styled.LabalPrice $color={color}>
        <label>{label}</label>
        <div>
          <b>₩{thousandSeparator(price) || 0}</b>
        </div>
      </Styled.LabalPrice>
    );
  };

  return (
    <Styled.Div>
      <h5 style={{ padding: '1rem 0' }}>최종 정산내역</h5>
      <div>
        <Styled.Flex>
          {renderLabalPrice('입고결과 금액 합계', settlementInfo.receivedQuantityPrice, 'red')}
          {renderLabalPrice('기타비용 합계', settlementInfo.otherCosts, 'red')}
          {renderLabalPrice('할인 적용금액 합계', settlementInfo.discountApplyPrice, 'blue')}
          {renderLabalPrice('반품금액 합계', settlementInfo.reversedPrice, 'blue')}
          {renderLabalPrice('선지급금 사용 합계', settlementInfo.usedPrepaidPrice || 0, 'blue')}
          {renderLabalPrice('최종 정산금액 합계', settlementInfo.settlementPrice, 'red')}
          {renderLabalPrice('공급가액 합계', settlementInfo.supplyCostTotal)}
          {renderLabalPrice('세액 합계', settlementInfo.taxTotal)}
        </Styled.Flex>
      </div>
    </Styled.Div>
  );
};

FinalSettlementInfoContainer.propTypes = {
  settlementInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default FinalSettlementInfoContainer;
