import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import supplyApi from 'service/hq/supply';
import useFetch from 'hooks/useFetch';
import { VENDOR_SEARCH_CONDITION } from 'commons/constant';
import { setObjectState } from 'commons/helper';
import toastify from 'commons/toast';
import { Flex } from 'styles/components';

import RadioGroup from 'components/RadioGroup';
import Searchbar from 'components/Searchbar';
import HeaderModal from 'components/modals/HeaderModal';
import Table from 'components/Table';

import { COLUMNS } from './commons/constant';

const VendorRegisterModal = ({ isOpen, closeCallback, setSupplyRequestDetail }) => {
  const [searchOptions, setSearchOptions] = useState({
    condition: 'VENDOR_ID',
    query: '',
  });
  const { data: vendorList = [], execute: getVendorList } = useFetch(supplyApi.getVendorList, {
    initialExecute: false,
  });

  useEffect(() => {
    if (isOpen) {
      getVendorList();
      setSearchOptions({
        condition: 'VENDOR_ID',
        query: '',
      });
    }
  }, [isOpen]);

  const setState = (e, name) => setObjectState(setSearchOptions, name, e.target.value);
  const handleCheckedValueChange = (e) => setState(e, 'condition');
  const searchQueryChange = (e) => setState(e, 'query');
  const handleSearch = () => {
    if (searchOptions.condition === 'VENDOR_ID' && Number.isNaN(Number(searchOptions.query))) {
      toastify('숫자만 입력할 수 있습니다.', { variant: 'error' });
      return;
    }
    getVendorList(searchOptions.condition, searchOptions.query);
  };

  const handleRowClick = ({ original }) => {
    setSupplyRequestDetail((prev) => ({
      ...prev,
      vendorInfo: original,
      itemList: prev.vendorInfo.vendorId === original.vendorId ? prev.itemList : [],
    }));

    toastify('거래처가 선택되었습니다.', { variant: 'success' });
    closeCallback();
  };

  return (
    <HeaderModal isOpen={isOpen} closeCallback={closeCallback} title='거래처 등록' $size='xl'>
      <Flex $flexDirection='column' $gap='var(--space-m)'>
        <RadioGroup
          title='거래처 검색'
          radios={VENDOR_SEARCH_CONDITION}
          name='condition'
          checkedValue={searchOptions.condition}
          onCheckedValueChange={handleCheckedValueChange}
        />
        <Searchbar
          name='query'
          value={searchOptions.query}
          onChange={searchQueryChange}
          onClick={handleSearch}
        />
        <Table
          columns={COLUMNS}
          data={vendorList}
          onRowClick={handleRowClick}
          $height='calc(95vh - 210px)'
        />
      </Flex>
    </HeaderModal>
  );
};

VendorRegisterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  setSupplyRequestDetail: PropTypes.func.isRequired,
};

export default VendorRegisterModal;
