import instance from 'service/interceptor';

async function getPrepaidList({
  searchCondition,
  searchContent,
  pageNumber = 0,
  pageSize = 50,
} = {}) {
  const { data } = await instance.post('/api/v2/hq/product/supply/prepaid/list', {
    searchCondition,
    searchContent,
    pageNumber,
    pageSize,
  });

  return data;
}

async function registerPrepaid(prepaidList) {
  const { data } = await instance.post('/api/v2/hq/product/supply/prepaid', prepaidList);

  return data;
}

async function getPrepaidDetail(vendorId, { pageNumber, pageSize }) {
  const { data } = await instance.post(`/api/v2/hq/product/supply/prepaid/history/${vendorId}`, {
    pageNumber,
    pageSize,
  });

  return data;
}

const restorePrepaid = async (id) => {
  const { data } = await instance.post('/api/v2/hq/product/supply/prepaid/cancel', [{ id }]);

  return data;
};

const getVendorList = async ({ searchCondition = 'VENDOR_NAME', searchContent = '' } = {}) => {
  const { data } = await instance.post('/api/v2/hq/product/supply/prepaid/vendor/list', {
    searchCondition,
    searchContent,
  });

  return data;
};

export default { getPrepaidList, registerPrepaid, getPrepaidDetail, restorePrepaid, getVendorList };
