import { REQUEST_STATUS_CODE } from 'commons/constant';

export function isMainButtonsEnabled(statusCode) {
  return (
    statusCode === null ||
    statusCode === REQUEST_STATUS_CODE.SAVE ||
    statusCode === REQUEST_STATUS_CODE.REJECT
  );
}

export const isSaveButtonsEnabled = (statusCode) =>
  statusCode === null || statusCode === REQUEST_STATUS_CODE.SAVE;

export function isRejected(statusCode) {
  return statusCode === REQUEST_STATUS_CODE.REJECT;
}

export function isRequestCancelButtonEnabled(statusCode) {
  return statusCode === REQUEST_STATUS_CODE.REQUEST || statusCode === REQUEST_STATUS_CODE.REJECT;
}

export default { isMainButtonsEnabled, isRejected, isRequestCancelButtonEnabled };
