import { usePageModals } from 'hooks/pages';

import SettlementCompleteDetail from 'pages/admin/settlement/containers/SettlementCompleteDetail';

import Modal from 'components/modals/Modal';

/**
 * @param {*} props
 */
const ModalContainer = ({ ...props }) => {
  const [{ detail }, setModalOpen] = usePageModals();

  const handleDetailModalOpen = () => setModalOpen('detail');

  return (
    <>
      <Modal isOpen={detail} closeCallback={handleDetailModalOpen} $size='xl'>
        <SettlementCompleteDetail isOpen={detail} onClose={handleDetailModalOpen} {...props} />
      </Modal>
    </>
  );
};

export default ModalContainer;
