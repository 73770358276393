export const AMENDMENT_REQUEST_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'requestId',
  },
  { Header: '거래처 번호', accessor: 'vendorId' },
  {
    Header: '거래처명',
    accessor: 'displayName',
  },

  { Header: '요청 구분', accessor: 'requestCodeValue' },
  {
    Header: '상태',
    accessor: 'statusTypeValue',
  },
  {
    Header: '등록 일시',
    accessor: 'createdAt',
  },
  {
    Header: '요청 일시',
    accessor: 'updatedAt',
  },
  {
    Header: '승인 일시',
    accessor: 'approvedAt',
  },
  {
    Header: '처리자 (펫프 담당자)',
    accessor: 'workerInfo',
  },
  {
    Header: 'MD1',
    accessor: 'md1ManagerName',
  },
  {
    Header: '발주담당자',
    accessor: 'supplyManagerName',
  },
];

export const INITIAL_SEARCH_OPTIONS = {
  requestCode: '',
  vendorRequestStatusType: 'REQUEST',
  requestDay: [null, null],
  approveDay: [null, null],

  requestSearchCondition: 'VENDOR_ID',
  query: '',
};

export const MAPPING_REQUEST_STATUS = {
  '임시 저장': 'SAVE',
  '요청 취소': 'CANCEL',
  '요청 중': 'REQUEST',
  '승인 완료': 'APPROVE',
  '요청 반려': 'REJECT',
};

export const PRODUCT_PRICE_TABLE_COLUMNS = [
  { Header: '품목ID', accessor: 'productItemId' },
  { Header: '품목명', accessor: 'productName' },
  { Header: '상태', accessor: 'productStatus' },

  { Header: '과세 구분', accessor: 'beforeTaxValue' },
  { Header: '기준 공급가(₩)', accessor: 'beforeProductStandardUnitPrice' },
  { Header: '기준 공급가 수정(₩)', accessor: 'afterProductStandardUnitPrice' },
  { Header: '최소 주문 수량(EA)', accessor: 'beforeMinimumOrderQuantity' },
  { Header: '최소 주문 수량 수정(EA)', accessor: 'afterMinimumOrderQuantity' },

  { Header: '변경 사유', accessor: 'reasonForChange' },
  { Header: '반영일자', accessor: 'effectiveDate' },
  { Header: '펫프 담당자 코멘트', accessor: 'petfriendsComment' },
];

export const PRODUCT_MASTER_TABLE_COLUMNS = [
  { Header: '품목ID', accessor: 'productItemId' },
  { Header: '품목명', accessor: 'productName' },
  { Header: '상태', accessor: 'productStatus' },

  { Header: '품목 바코드', accessor: 'itemBarcode' },
  { Header: '박스 바코드', accessor: 'boxBarcode' },
  { Header: '박스 내입수', accessor: 'boxUnit' },
  { Header: '팔레트 내입수', accessor: 'pltUnit' },
  { Header: '가로(cm)', accessor: 'width' },
  { Header: '세로(cm)', accessor: 'length' },
  { Header: '높이(cm)', accessor: 'height' },
  { Header: '중량(kg)', accessor: 'weight' },
  { Header: '용량(ml)', accessor: 'volume' },
  { Header: '보관온도', accessor: 'storageTemperatureValue' },

  { Header: '변경 사유', accessor: 'reasonForChange' },
  { Header: '반영일자', accessor: 'effectiveDate' },
  { Header: '펫프 담당자 코멘트', accessor: 'petfriendsComment' },
];

export const STATUS_VALUES_FOR_MESSAGE = {
  '요청 중': '요청',
  '승인 완료': '승인',
};

export const NUMERIC_COLUMN_NAMES = [
  'beforeProductStandardUnitPrice',
  'afterProductStandardUnitPrice',
  'beforeMinimumOrderQuantity',
  'afterMinimumOrderQuantity',
];

export const SELECT_OPTIONS = ['requestCode', 'vendorRequestStatusType', 'requestSearchCondition'];
