import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import DefaultLayout from 'layouts/DefaultLayout';
import { useAuthInfo } from 'hooks/useSyncAuth';

function PrivateRoute({ render, component: Component, ...rest }) {
  const { cookie } = useAuthInfo(['accessToken']);
  const [userToken] = cookie;

  if (!userToken) return <Redirect to='/login' />;

  return (
    <DefaultLayout>
      <Route {...rest} render={render || ((props) => <Component {...props} />)} />
    </DefaultLayout>
  );
}

PrivateRoute.propTypes = {
  render: PropTypes.func,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

export default PrivateRoute;
