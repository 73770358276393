import { useEffect, useRef, useState } from 'react';

import supplyApi from 'service/supply/supply';
import { downloadSupplyRequestList, handleError } from 'commons/helper';
import toastify from 'commons/toast';
import { useGlobalModals } from 'hooks/pages';
import useRouter from 'hooks/useRouter';

import Button from 'components/Button';

import * as Styled from './styled';
import HistoryList from './components/HistoryList';
import StatusChangeButtons from './components/StatusChangeButtons';
import RequestInfo from './components/RequestInfo';
import ReceiveInfo from './components/ReceiveInfo';
import CommentInfo from './components/CommentInfo';
import SupplyProduct from './components/SupplyProduct';
import { NEXT_STATUS_CODE, SUPPLY_REQUEST_DETAIL_INITIAL_VALUES } from './commons/constant';
import DelayInfo from './components/DelayInfo';
import TransportInfo from './components/TransportInfo';
import SupplyDetail from './components/SupplyDetail';
import { isSaveButtonEnabledStatus } from './commons/helper';

const SupplyRegisterPage = () => {
  const [supplyRequestDetail, setSupplyRequestDetail] = useState(
    SUPPLY_REQUEST_DETAIL_INITIAL_VALUES
  );
  const [hasError, setHasError] = useState(false);
  const {
    pushWithSearchParams,
    params: { productSupplyRequestId = '' },
  } = useRouter();
  const targetButtonRef = useRef();
  const [, setModalOpen] = useGlobalModals();

  const getDefaultValues = (itemList) =>
    itemList.map((item) => ({
      ...item,
      availableReceiveQuantity: item.availableReceiveQuantity || 0,
    }));

  const getSupplyRequestDetail = async (id) => {
    try {
      const { response } = await supplyApi.getSupplyRequestDetail(id);
      setSupplyRequestDetail({
        ...response?.data,
        itemList: getDefaultValues(response?.data?.itemList),
      });
    } catch (error) {
      setHasError(true);
    }
  };

  useEffect(() => {
    if (productSupplyRequestId) {
      getSupplyRequestDetail(productSupplyRequestId);
    }
  }, [productSupplyRequestId]);

  const setTargetButtonRef = (value) => {
    targetButtonRef.current = value;
  };
  const handleSaveClick = () => setTargetButtonRef('전체 저장');

  const handleListClick = () => pushWithSearchParams('/supply/list');

  const handleExcelDownloadClick = () => {
    setModalOpen('activityIndicator', true);
    try {
      downloadSupplyRequestList(productSupplyRequestId, supplyRequestDetail.itemList);
    } finally {
      setModalOpen('activityIndicator', false);
    }
  };

  const confirmSupply = async (statusCodeParam, successMessage, confirmYn) => {
    try {
      const data = await supplyApi.updateSupplyRequestDetail({
        productSupplyRequestId,
        statusCode: statusCodeParam,
        supplyRequestDetail,
        confirmYn,
      });
      if (!data) {
        return;
      }
      setSupplyRequestDetail(data.response.data);
      toastify(successMessage, { variant: 'success' });
      setModalOpen('alert', false, {});
    } catch (error) {
      handleError(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (targetButtonRef.current === '전체 저장') {
      confirmSupply(
        NEXT_STATUS_CODE[supplyRequestDetail.statusCode] || supplyRequestDetail.statusCode,
        '저장되었습니다.',
        'N'
      );
      return;
    }
    if (targetButtonRef.current === '발주 확정요청') {
      setModalOpen('alert', true, {
        title: '발주 확정 요청 안내',
        content: (
          <div>
            <h4>
              발주 확정 후에는 발주를 더 이상 수정할 수 없습니다.
              <br />
              확정 요청 전에 품목, 금액, 수량이 알맞게 입력됐는 지 꼭 확인해 주세요.
            </h4>
            <p>
              발주가 확정된 대로 입고 및 정산이 진행되며, 특히 정산은 입고 결과에 따라 진행됩니다.
              <br />
              따라서 확정 요청하시기 전에 품목 별로 금액과 수량이 알맞게 입력됐는 지 반드시
              확인하시기 바랍니다.
            </p>
            <p>발주 확정 요청을 진행하시겠습니까?</p>
          </div>
        ),
        buttons: [
          {
            text: '예',
            onClick: () => confirmSupply('VENDOR_REQUEST', '발주 확정요청 되었습니다.', 'Y'),
          },
          {
            text: '아니오',
            onClick: () => setModalOpen('alert', false, {}),
            variant: 'secondary',
          },
        ],
      });
    }
  };

  if (hasError) {
    return null;
  }

  return (
    <Styled.Wrapper onSubmit={handleSubmit}>
      <Button $variant='link' onClick={handleListClick}>
        &lt; 목록
      </Button>
      <SupplyDetail statusCode={supplyRequestDetail.statusCode} />

      <HistoryList historyList={supplyRequestDetail.historyList} />
      <StatusChangeButtons
        statusCode={supplyRequestDetail.statusCode}
        setTargetButtonRef={setTargetButtonRef}
        onExcelDownloadClick={handleExcelDownloadClick}
      />

      <RequestInfo
        requestInfo={supplyRequestDetail.requestInfo}
        productSupplyRequestId={productSupplyRequestId}
      />
      <ReceiveInfo
        receiveInfo={supplyRequestDetail.receiveInfo}
        setSupplyRequestDetail={setSupplyRequestDetail}
        statusCode={supplyRequestDetail.statusCode}
      />
      <CommentInfo
        commentInfo={supplyRequestDetail.commentInfo}
        setSupplyRequestDetail={setSupplyRequestDetail}
      />
      <SupplyProduct
        totalInfo={supplyRequestDetail.totalInfo}
        itemList={supplyRequestDetail.itemList}
        setSupplyRequestDetail={setSupplyRequestDetail}
        statusCode={supplyRequestDetail.statusCode}
      />
      <TransportInfo
        transportInfo={supplyRequestDetail.transportInfo}
        setSupplyRequestDetail={setSupplyRequestDetail}
        statusCode={supplyRequestDetail.statusCode}
      />
      <DelayInfo
        delayInfo={supplyRequestDetail.delayInfo}
        setSupplyRequestDetail={setSupplyRequestDetail}
        inboundWikey={supplyRequestDetail.receiveInfo.inboundWikey}
      />

      {isSaveButtonEnabledStatus(supplyRequestDetail.statusCode) && (
        <Button type='submit' onClick={handleSaveClick}>
          전체저장
        </Button>
      )}
    </Styled.Wrapper>
  );
};

export default SupplyRegisterPage;
