import PropTypes from 'prop-types';
import * as Styled from './styled';

const Underline = ({ value }) => {
  return <Styled.Wrapper>{value}</Styled.Wrapper>;
};

Underline.propTypes = {
  value: PropTypes.number.isRequired,
};

export default Underline;
