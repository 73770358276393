import styled from 'styled-components';

export const TotalTableWrapper = styled.div`
  thead,
  tbody {
    th,
    td {
      text-align: right;
    }
  }
`;

export const ItemTableWrapper = styled.div`
  thead {
    th:nth-child(9) {
      text-align: right;
    }
    th:nth-child(10) {
      text-align: right;
    }
    th:nth-child(11) {
      text-align: right;
    }
    th:nth-child(12) {
      text-align: right;
    }
    th:nth-child(13) {
      text-align: right;
    }
    th:nth-child(14) {
      text-align: right;
    }
    th:nth-child(17) {
      text-align: right;
    }
    th:nth-child(18) {
      text-align: right;
    }
  }
  tbody {
    td:nth-child(9) {
      text-align: right;
    }
    td:nth-child(10) {
      input {
        text-align: right;
      }
    }
    td:nth-child(11) {
      text-align: right;
    }
    td:nth-child(12) {
      input {
        text-align: right;
      }
    }
    td:nth-child(13) {
      text-align: right;
    }
    td:nth-child(14) {
      text-align: right;
    }
    td:nth-child(17) {
      text-align: right;
    }
    td:nth-child(18) {
      text-align: right;
    }
    td:nth-child(19) {
      input {
        min-width: 250px;
      }
    }
  }
`;

export default { TotalTableWrapper, ItemTableWrapper };
