import { useEffect, useState } from 'react';

import Card from 'components/Card';
import dashboardApi from 'service/dashboard';
import useFetch from 'hooks/useFetch';

import Pagination from 'components/Pagination';

import Notice from '../../components/Notice';
import { NoticeTable } from './styled';

const NoticeContainer = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const { data: { content = [], totalPages = 0 } = {}, execute: getNoticeList } = useFetch(
    dashboardApi.getNoticeList,
    { initialExecute: false }
  );

  useEffect(() => {
    getNoticeList({ pageNumber: pageIndex });
  }, [pageIndex]);

  const handlePageIndexChange = (newPageIndex) => setPageIndex(newPageIndex);

  return (
    <Card title='펫프렌즈 공지사항'>
      <NoticeTable>
        <colgroup>
          <col width='10%' />
          <col width='70%' />
          <col width='20%' />
        </colgroup>
        <thead>
          <tr>
            <th>분류</th>
            <th>제목</th>
            <th>등록일</th>
          </tr>
        </thead>
        <tbody>
          {content.map((notice) => {
            return (
              <Notice
                key={notice.id}
                date={notice.createdAt}
                category={notice.noticeCategoryCodeValue}
                title={notice.title}
                paragraph={notice.contents}
              />
            );
          })}
        </tbody>
      </NoticeTable>
      <Pagination
        pageCount={totalPages}
        pageIndex={pageIndex}
        onPageIndexChange={handlePageIndexChange}
      />
    </Card>
  );
};

export default NoticeContainer;
