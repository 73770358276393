import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setSelectedAmendmentRequest } from 'commons/store/modules/admin/amendmentRequest';

import Styled from './styled';

/**
 * @param {*} props
 */
const RequestIdCell = ({ value, row }) => {
  const { original } = row;
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      setSelectedAmendmentRequest({
        code: original.requestCode,
        id: original.requestId,
      })
    );
  };

  return <Styled.Div onClick={handleClick}>{value}</Styled.Div>;
};

RequestIdCell.propTypes = {
  value: PropTypes.number.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      approvedAt: PropTypes.string,
      createdAt: PropTypes.string,
      displayName: PropTypes.string,
      masterRequestStatusType: PropTypes.string,
      priceRequestStatusType: PropTypes.string,
      requestCode: PropTypes.string,
      requestCodeValue: PropTypes.string,
      requestId: PropTypes.number,
      statusType: PropTypes.string,
      statusTypeValue: PropTypes.string,
      updatedAt: PropTypes.string,
      vendorId: PropTypes.number,
      vendorRequestStatusType: PropTypes.string,
      workerInfo: PropTypes.string,
    }),
  }),
};

export default RequestIdCell;
