import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const InputLabel = forwardRef(({ id, label, $fullWidth = false, ...props }, ref) => (
  <div>
    <Styled.Label htmlFor={id}>{label}</Styled.Label>
    <Styled.Input ref={ref} type='text' id={id} $fullWidth={$fullWidth} {...props} />
  </div>
));

InputLabel.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  $fullWidth: PropTypes.bool,
};

export default InputLabel;
