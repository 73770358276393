import styled, { css } from 'styled-components';

import { styleSelector } from 'commons/helper';

export const Flex = styled.div`
  display: flex;
  ${styleSelector}
  ${({ $spacer }) =>
    $spacer &&
    css`
      gap: var(--space-l);
      margin-bottom: var(--space-l);
    `};
`;

export default { Flex };
