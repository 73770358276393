import { returnNullIfEmptyString } from 'commons/helper';
import instance from 'service/interceptor';

const getNoticeList = async ({
  active = null,
  categoryCode = null,
  startCreatedDay = null,
  endCreatedDay = null,
  noticeSearchCondition = 'TITLE',
  searchContents = '',

  pageNumber = 0,
  pageSize = 20,
}) => {
  const { data } = await instance.post('/api/v2/hq/product/supply/notice/list', {
    active: returnNullIfEmptyString(active),
    categoryCode: returnNullIfEmptyString(categoryCode),
    startCreatedDay,
    endCreatedDay,
    noticeSearchCondition,
    searchContents,

    pageNumber,
    pageSize,
  });

  return data;
};

const getNoticeDetailById = async (id) => {
  const { data } = await instance.get(`/api/v2/hq/product/supply/notice/${id}`);

  return data;
};

const updateNotice = async ({ id, title, contents, noticeCategoryCode, active }) => {
  const { data } = await instance.post('/api/v2/hq/product/supply/notice', {
    id,
    title,
    contents,
    noticeCategoryCode,
    active,
  });

  return data;
};

export default { getNoticeList, getNoticeDetailById, updateNotice };
