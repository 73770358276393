import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import noticeApi from 'service/hq/notice';
import useFetch from 'hooks/useFetch';

import { handleError } from 'commons/helper';
import toastify from 'commons/toast';

import { INITIAL_NOTICE_INFO } from './commons/constant';

import Styled from './styled';
import NoticeContentContainer from './containers/NoticeContentContainer';
import NoticeInfoContainer from './containers/NoticeInfoContainer';
import ButtonContainer from './containers/ButtonContainer';

const AdminNoticeRegisterPage = () => {
  const history = useHistory();
  const { noticeId } = useParams();
  const [noticeInfo, setNoticeInfo] = useState(INITIAL_NOTICE_INFO);
  const { execute: getNoticeDetailById, isLoading } = useFetch(noticeApi.getNoticeDetailById, {
    initialExecute: false,
  });

  useEffect(() => {
    if (!noticeId) {
      return;
    }
    const getNoticeDetail = async (id) => {
      const { data } = await getNoticeDetailById(id);
      setNoticeInfo(data);
    };
    getNoticeDetail(noticeId);
  }, [noticeId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { response } = await noticeApi.updateNotice({
        id: noticeId,
        title: noticeInfo.title,
        contents: noticeInfo.contents,
        noticeCategoryCode: noticeInfo.noticeCategoryCode,
        active: noticeInfo.active,
      });
      if (response) {
        toastify('게시물이 저장되었습니다.', { variant: 'success' });
        history.push('/admin/notice/list');
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Styled.Form onSubmit={handleSubmit}>
      <ButtonContainer />
      <NoticeContentContainer
        noticeInfo={noticeInfo}
        setNoticeInfo={setNoticeInfo}
        isLoading={isLoading}
      />
      <NoticeInfoContainer
        noticeInfo={noticeInfo}
        setNoticeInfo={setNoticeInfo}
        isLoading={isLoading}
      />
      <ButtonContainer />
    </Styled.Form>
  );
};

export default AdminNoticeRegisterPage;
