import PropTypes from 'prop-types';

import { REQUEST_STATUS_CODE } from 'commons/constant';

import Input from 'components/Input';

import { Flex } from 'styles/components';

import * as Styled from '../../styled';

const FormFooter = ({
  request: { statusCode, statusCodeValue, reason, approveDate, updateDate, requestDate },
}) => {
  const hasProcessingRequest = statusCode !== null;
  if (!hasProcessingRequest) {
    return null;
  }

  const processMessage = {
    '승인 완료': `${approveDate} 승인 되었습니다.`,
    '요청 취소': `${updateDate} 요청 취소 되었습니다.`,
    '요청 반려': `${updateDate} 요청 반려 되었습니다.`,
    '요청 중': `${requestDate} 요청 되었습니다.`,
  };

  return (
    <>
      <Flex $flexDirection='column'>
        <Styled.Category>처리 상태</Styled.Category>
        <Flex $alignItems='center' $gap='var(--space-m)'>
          <Styled.Status $statusCode={statusCode}>{statusCodeValue}</Styled.Status>
          <span>{processMessage[statusCodeValue]}</span>
        </Flex>
      </Flex>
      <Flex $flexDirection='column'>
        <Styled.Category>펫프 담당자 메시지</Styled.Category>
        <Flex>
          <Input value={reason || ''} disabled />
        </Flex>
      </Flex>
    </>
  );
};

FormFooter.propTypes = {
  request: PropTypes.shape({
    reason: PropTypes.string,
    statusCode: PropTypes.oneOf([
      REQUEST_STATUS_CODE.REQUEST,
      REQUEST_STATUS_CODE.REJECT,
      REQUEST_STATUS_CODE.APPROVE,
      REQUEST_STATUS_CODE.CANCEL,
      null,
    ]),
    statusCodeValue: PropTypes.oneOf([
      '임시 저장',
      '요청 취소',
      '요청 중',
      '승인 완료',
      '요청 반려',
    ]),
    approveDate: PropTypes.string,
    updateDate: PropTypes.string,
    requestDate: PropTypes.string,
  }).isRequired,
};

export default FormFooter;
