import styled from 'styled-components';

export const SearchbarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-l);

  button {
    height: 48px;
    white-space: nowrap;
  }
`;

export const TableWrapper = styled.div`
  thead {
    th:nth-child(n + 6) {
      text-align: right;
    }
  }
  tbody {
    td:nth-child(n + 6) {
      text-align: right;
    }
  }
`;

export default { SearchbarWrapper };
