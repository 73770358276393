import ThousandSeperator from 'components/ThousandSeperator';

export const TOTAL_COLUMNS = [
  { Header: '품목 수', accessor: 'totalItemKindCount' },
  { Header: '총 발주수량', accessor: 'totalItemQuantity' },
  { Header: '총 입고예정수량', accessor: 'totalAvailableReceiveQuantity' },
  { Header: '총 발주금액(₩)', accessor: 'totalPrice' },
  { Header: '입고결과금액(₩)', accessor: 'totalReceivedPrice' },
];

export const ITEM_COLUMNS = [
  { Header: '품목ID', accessor: 'productItemId' },
  { Header: '품목유형', accessor: 'productTypeName' },
  { Header: '품목명', accessor: 'productName' },
  { Header: '증정여부', accessor: 'isFreeGift' },
  { Header: '상태', accessor: 'productStatusName' },
  { Header: '분류', accessor: 'productGroupName' },
  { Header: '과세구분', accessor: 'taxationTypeCodeName' },
  { Header: '기준 공급가(₩)', accessor: 'productStandardUnitPrice', Cell: ThousandSeperator },
  { Header: '적용 공급가(₩)', accessor: 'productSupplyUnitPrice' },

  { Header: '최소주문수량', accessor: 'minimumOrderQuantity', Cell: ThousandSeperator },
  { Header: '발주수량', accessor: 'requestQuantity' },
  { Header: '입고예정수량', accessor: 'availableReceiveQuantity' },
  { Header: '입고불가수량', accessor: 'unavailableReceiveQuantity' },
  { Header: '재입고일정', accessor: 'restockDay' },
  { Header: '유통기한', accessor: 'shelfLifeDay' },
  { Header: '발주금액', accessor: 'productSupplyPrice' },
  { Header: '실제 입고 수량', accessor: 'receivedQuantity' },
  { Header: '펫프 담당자 메시지', accessor: 'petfriendsComment' },
  { Header: '담당자 메시지', accessor: 'vendorComment' },
];

export default { TOTAL_COLUMNS };
