export const PREPAID_INFO_COLUMNS = [
  { Header: 'ID', accessor: 'id' },
  { Header: '구분', accessor: 'action' },
  { Header: '정산번호', accessor: 'productSupplySettlementId' },
  { Header: '변동 금액(₩)', accessor: 'price' },
  { Header: '선지급금 잔고(₩)', accessor: 'totalPrice' },
  { Header: '사유', accessor: 'reason' },
  { Header: '등록일시', accessor: 'createdAt' },
  { Header: '수정일시', accessor: 'updatedAt' },
  { Header: '처리자', accessor: 'workerName' },
];

export default { PREPAID_INFO_COLUMNS };
