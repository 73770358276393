import Table from 'components/Table';
import { TAX_INVOICE_STATUS_CODE_HASH } from 'commons/hash';
import Styled from '../styled';
import TaxInvoiceDetailContainer from '../TaxInvoiceContainer';

const COLUMNS = [
  { Header: '작성일자 ', accessor: 'taxInvoiceTransformDay' },
  { Header: '세금계산서 번호', accessor: 'taxInvoiceId' },
  { Header: '국세청승인번호', accessor: 'ntsConfirmNum' },
  { Header: '과세형태', accessor: 'settlementTaxationTypeCode' },
  { Header: '상태', accessor: 'taxInvoiceStatusCode' },
];

const VendorSettlementCompleteTaxArea = ({
  columns,
  taxInvoiceInfoList = [],
  taxInvoiceItemInfos = [],
}) => {
  const renderTopCell = ({ column, value = [], render }) => {
    switch (column?.id) {
      case 'taxInvoiceStatusCode':
        return TAX_INVOICE_STATUS_CODE_HASH[value];
      default:
        return render('Cell');
    }
  };

  return (
    <Styled.Div>
      <h5 style={{ padding: '1rem 0' }}>세금계산서 금액 계산</h5>
      <Table
        columns={COLUMNS}
        data={taxInvoiceInfoList}
        $height='unset'
        $width='unset'
        renderCell={renderTopCell}
      />
      <br />
      <TaxInvoiceDetailContainer
        columns={columns}
        taxInvoiceInfoList={taxInvoiceItemInfos}
        isTitle={false}
      />
    </Styled.Div>
  );
};

export default VendorSettlementCompleteTaxArea;
