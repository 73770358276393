import PropTypes from 'prop-types';

import { handleInputChange } from 'commons/helper';
import { Flex } from 'styles/components';

import InputLabel from 'components/InputLabel';

import { BUSINESSMAN_INFO_INPUT_LABELS } from './commons/constant';

import * as Styled from '../../styled';

const BusinessmanInfoForm = ({ form, setForm, inputDisabled }) => {
  return (
    <Flex $flexDirection='column'>
      <Styled.Category>사업자 정보</Styled.Category>
      <Flex $alignItems='center' $flexWrap='wrap' $gap='var(--space-m)'>
        {BUSINESSMAN_INFO_INPUT_LABELS.map(
          ({ name, label: _label, placeholder, pattern, maxLength, helperText }) => (
            <InputLabel
              required
              key={name}
              id={name}
              name={name}
              label={_label}
              placeholder={placeholder}
              value={form[name] || ''}
              onChange={(e) => handleInputChange(e, setForm)}
              disabled={inputDisabled}
              pattern={pattern}
              maxLength={maxLength}
              title={helperText}
            />
          )
        )}
      </Flex>
    </Flex>
  );
};

BusinessmanInfoForm.propTypes = {
  form: PropTypes.shape({
    companyName: PropTypes.string,
    ceoName: PropTypes.string,
    businessNumber: PropTypes.string,

    paymentMethod: PropTypes.string,
    paymentDate: PropTypes.string,
    bank: PropTypes.string,
    accountNumber: PropTypes.string,
    depositor: PropTypes.string,

    receiveMethod: PropTypes.string,
    reason: PropTypes.string,
  }).isRequired,
  setForm: PropTypes.func.isRequired,
  inputDisabled: PropTypes.bool,
};

export default BusinessmanInfoForm;
