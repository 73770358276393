import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import HeaderModal from 'components/modals/HeaderModal';
import { usePageModals } from 'hooks/pages';
import { TransitionWrapper } from './styled';
import Step1 from './Step1';
import Step2 from './Step2';

function ReverseRegisterModal({
  deliveryType,
  purchaseOrderReverseSearchCondition,
  reverseReason,
}) {
  const history = useHistory();
  const [{ register }, setModalOpen] = usePageModals();

  const query = new URL(window.location).searchParams;

  useEffect(() => {
    if (!query.get('register_step')) {
      setModalOpen('register', false);
    }

    if (query.get('register_step') !== '3') {
      setModalOpen('confirm', false);
    }
  }, [query.get('register_step')]);

  const onClose = () => {
    setModalOpen('register', false);
    setModalOpen('confirm', false);
    history.replace('/admin/reverse');
  };

  return (
    <HeaderModal isOpen={register} title='반품 등록' $size='xl' closeCallback={onClose}>
      <TransitionWrapper
        step={Number(query.get('register_step')) >= 3 ? '2' : query.get('register_step')}
      >
        <div>
          <Step1 purchaseOrderReverseSearchCondition={purchaseOrderReverseSearchCondition} />
          <Step2 reverseReason={reverseReason} deliveryType={deliveryType} />
        </div>
      </TransitionWrapper>
    </HeaderModal>
  );
}

export default ReverseRegisterModal;
