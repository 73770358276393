import Input from 'components/Input';

const DateCell = ({ row, disabled, onChange, value = '', required }) => {
  return (
    <Input
      type='date'
      value={value}
      disabled={disabled || row.original?.disabled}
      onChange={onChange}
      required={required}
    />
  );
};

export default DateCell;
