import styled from 'styled-components';
import { flexRowCenter } from 'styles/commons';

export const SideBarWrapper = styled.aside`
  margin-top: 25px;
  max-width: 30%;
  width: 30%;
  height: inherit;
  position: relative;
  left: ${({ visible }) => (visible ? '0%' : '30%')};
  transition: left 0.3s;
  position: relative;

  & ul,
  & li {
    list-style: none;
  }

  & > .card {
    height: 100%;
    margin: 0;
  }
`;

export const SidebarTitle = styled.p`
  margin-bottom: 5px;
  font-size: 15px;
  line-height: 22px;
  color: #000;
  font-weight: 700;
`;

export const SideTableWrapper = styled.table`
  margin: 0;
  width: 100%;

  & > thead {
    border-bottom: 1px solid #dbdde2;

    & td {
      width: ${(props) => props.width || 'auto'};
      padding-bottom: 7px;
      font-size: 10px;
      color: #8b95a1;
    }
  }

  & > tbody {
    border-bottom: 1px solid #dbdde2;

    & > tr:first-child {
      & td {
        padding-top: 10px;
      }
    }

    & > tr:last-child {
      & td {
        padding-bottom: 10px;
      }
    }

    & td {
      font-size: 12px;
      line-height: 22px;
      padding-top: ${(props) => props.height || 'auto'};

      &:nth-of-type(4) {
        color: #6078e4;
      }
    }
  }

  & > tfoot {
    & > tr:first-child {
      & td {
        padding: 10px 0 6px;
      }
    }

    & > tr:last-child {
      & td {
        color: #f33f46;
      }
    }

    & td {
      font-size: 12px;
      line-height: 22px;

      &:nth-of-type(4) {
        color: #6078e4;
      }
    }
  }
`;

export const CloseButton = styled.button.attrs({ type: 'button' })`
  position: absolute;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 19px;
  right: 18px;

  & > img {
    width: 100%;
    height: auto;
  }
`;

export const SideBarText = styled.p`
  font-weight: 700;
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
`;

export const ButtonWrapper = styled.div`
  ${flexRowCenter};
  margin: 10px 0 47px;
  justify-content: flex-end;
`;
