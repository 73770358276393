export const getFetchSupplyRequestListParams = (options, pageIndex, pageSize) => ({
  centerId: options.centerId,
  productSupplyTypeCode: options.productSupplyTypeCode,
  statusCodes: options.statusCodes,

  searchOption: options.searchOption,
  searchWord: options.query,

  beforeReceiveAvailableDay: options.receiveAvailableDay[0],
  afterReceiveAvailableDay: options.receiveAvailableDay[1],
  beforeProductSupplyRequestDay: options.productSupplyRequestDay[0],
  afterProductSupplyRequestDay: options.productSupplyRequestDay[1],
  beforeTotalReceiveConfirmDay: options.totalReceiveConfirmDay[0],
  afterTotalReceiveConfirmDay: options.totalReceiveConfirmDay[1],
  prepaidSettlement: options.prepaidSettlement,

  pageIndex,
  pageSize,
});

export default { getFetchSupplyRequestListParams };
