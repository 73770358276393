import { usePageModals } from 'hooks/pages';

import Modal from 'components/modals/Modal';

import SettlementListDetail from '../SettlementListDetail';
import SettlementCheckBillDetail from '../SettlementCheckBillDetail';
import SettlementCardDetail from '../SettlementCardDetail';
import SettlementCashDetail from '../SettlementCashDetail';

/**
 * 기본적인 기능을 가진 확장 모달을 이 변수에 추가 시켜주세요
 */
const modalsComponent = {
  detail: SettlementListDetail,
  checkBill: SettlementCheckBillDetail,
  card: SettlementCardDetail,
  cash: SettlementCashDetail,
};

const modalList = Object.keys(modalsComponent);

/**
 * @param {*} props
 */

const ModalContainer = () => {
  const [modals, setModalOpen] = usePageModals();

  const handleDetailModalOpen = (modalName) => () => setModalOpen(modalName);

  return (
    <>
      {modalList.map((modalName) => {
        const ModalComponent = modalsComponent[modalName];
        return (
          <Modal key={`modal-container-${modalName}`} $size='xl' isOpen={modals[modalName]}>
            <ModalComponent isOpen={modals[modalName]} onClose={handleDetailModalOpen(modalName)} />
          </Modal>
        );
      })}
    </>
  );
};
export default ModalContainer;
