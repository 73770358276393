export const SEARCH_OPTIONS_INITIAL_VALUES = {
  condition: 'PRODUCT_ITEM_ID',
  query: '',
};

export const SEARCH_CONDITION = [
  {
    id: 'PRODUCT_ITEM_ID',
    label: '품목ID',
    value: 'PRODUCT_ITEM_ID',
  },
  {
    id: 'PRODUCT_NAME',
    label: '품목명',
    value: 'PRODUCT_NAME',
  },
];

export const COLUMNS = [
  {
    Header: '품목ID',
    accessor: 'productItemId',
  },
  {
    Header: '품목명',
    accessor: 'productName',
  },
  {
    Header: '상태',
    accessor: 'productStatusName',
  },
  {
    Header: '과세구분',
    accessor: 'taxationTypeCodeName',
  },
  {
    Header: '기준 공급가(₩)',
    accessor: 'productStandardUnitPrice',
  },
  {
    Header: '최소주문수량(EA)',
    accessor: 'minimumOrderQuantity',
  },
];

export default { SEARCH_OPTIONS_INITIAL_VALUES };
