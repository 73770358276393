import styled from 'styled-components';
import { ellipsis } from 'styles/commons';

const Title = styled.span`
  color: var(--grey700);
  font-weight: var(--font-weight-medium) !important;
  margin-bottom: 7.5px;
`;

const Input = styled.input.attrs({ className: 'form-control' })`
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
`;

const LabelWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: var(--base-width);
  height: var(--base-height);
  border: 1px solid var(--grey300);
  border-radius: var(--radius-m);

  background-color: ${({ $disabled }) => $disabled && '#e9ecef'};
`;

const Label = styled.label.attrs({ className: 'form-label' })`
  flex: 1;
  text-align: center;
  color: var(--grey700);
  font-size: var(--font-size-xxs);
  font-weight: var(--font-weight-medium) !important;
  margin-bottom: 0;
  padding: var(--space-m);
  ${ellipsis}
`;

const ImageWrapper = styled.div`
  width: 20%;
  height: 100%;
  padding: var(--space-s);
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: calc(var(--radius-s) / 2);
`;

export { Title, Input, LabelWrapper, Label, ImageWrapper, Image };
