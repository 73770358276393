export const PRICE_COLUMNS = [
  { Header: '품목ID', accessor: 'productItemId' },
  { Header: '품목명', accessor: 'productName' },
  { Header: '상태', accessor: 'productStatus' },
  { Header: '과세 구분', accessor: 'tax' },
  { Header: '기준 공급가(₩)', accessor: 'productStandardUnitPrice' },
  { Header: '최소주문수량(EA)', accessor: 'minimumOrderQuantity' },
];

export const ITEM_COLUMNS = [
  { Header: '품목ID', accessor: 'productItemId' },
  { Header: '품목명', accessor: 'productName' },
  { Header: '상태', accessor: 'productStatus' },
];

export const COLUMNS = {
  '가격 수정 요청': PRICE_COLUMNS,
  '품목 정보 수정 요청': ITEM_COLUMNS,
};

export default { PRICE_COLUMNS, ITEM_COLUMNS, COLUMNS };
