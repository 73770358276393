import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { SPECIAL_CHARACTER_REGEX } from 'commons/constant';
import { handleError, updateNestedObject } from 'commons/helper';
import { formatDateAsYYYYMMDD, formatStringAsDate } from 'commons/date';
import { Flex } from 'styles/components';

import toastify from 'commons/toast';
import Card from 'components/Card';
import DatePicker from 'components/DatePicker';
import InputLabel from 'components/InputLabel';
import RadioGroup from 'components/RadioGroup';
import Select from 'components/Select';
import { useGlobalModals } from 'hooks/pages';
import useRouter from 'hooks/useRouter';

import supplyApi from 'service/hq/supply';

import { RECEIVE_DELAY_OPTIONS } from './commons/constant';

import * as Styled from './styled';

const ReceiveInfoForm = ({ receiveInfo, setSupplyRequestDetail, statusCode }) => {
  const {
    params: { productSupplyRequestId = '' },
  } = useRouter();

  const { wmsCenterList } = useSelector((state) => state.supply);
  const [, setModalOpen] = useGlobalModals();
  const minDate = new Date();

  const updateReceiveInfo = (object, name, value) =>
    updateNestedObject(object, ['receiveInfo', name], () => value);

  const handleReceiveRequestDayChange = (date) =>
    setSupplyRequestDetail((prev) =>
      updateReceiveInfo(prev, 'receiveRequestDay', formatDateAsYYYYMMDD(date))
    );
  const handleReceiveAvailableDayChange = (date) =>
    setSupplyRequestDetail((prev) =>
      updateReceiveInfo(prev, 'receiveAvailableDay', formatDateAsYYYYMMDD(date))
    );

  const handleInputChange = (e) =>
    setSupplyRequestDetail((prev) => updateReceiveInfo(prev, e.target.name, e.target.value));

  const handleInboundWikeyChange = (e) =>
    setSupplyRequestDetail((prev) =>
      updateReceiveInfo(prev, e.target.name, e.target.value.replace(SPECIAL_CHARACTER_REGEX, ''))
    );

  const createdInbound = async () => {
    const params = { requestId: productSupplyRequestId };
    if (!productSupplyRequestId) return;
    try {
      await supplyApi.createInboundData(params);
      toastify('생성되었습니다.', { variant: 'success' });
    } catch (err) {
      handleError(err);
    } finally {
      setModalOpen('alert', false);
    }
  };

  const createInboundHandler = () => {
    setModalOpen('alert', true, {
      title: '입고생성',
      content: (
        <div>
          <span>입고를 생성&전송합니다.</span>
          <p>입고예정일이 정확한지 한번 더 확인해주세요.</p>
        </div>
      ),
      buttons: [
        { text: '실행', onClick: createdInbound },
        { text: '취소', onClick: setModalOpen('alert', false) },
      ],
    });
  };

  return (
    <Card
      title='입고 정보'
      label='입고생성'
      onClick={createInboundHandler}
      buttonDisabled={statusCode !== 'PETFRIENDS_CONFIRM'}
    >
      <Flex $flexDirection='column' $gap='var(--space-m)'>
        <Flex $gap='var(--space-m)' $flexWrap='wrap'>
          <Select
            id='centerId'
            name='centerId'
            label='입고센터'
            value={receiveInfo.centerId}
            onChange={handleInputChange}
            options={wmsCenterList}
            disabled={statusCode !== '' && statusCode === 'PETFRIENDS_CONFIRM'}
            required
          />
          <InputLabel
            name='inboundWikey'
            id='inboundWikey'
            label='입고번호'
            value={receiveInfo.inboundWikey ?? ''}
            onChange={handleInboundWikeyChange}
            disabled
          />
          <DatePicker
            id='receiveRequestDay'
            label='입고요청일'
            onChange={handleReceiveRequestDayChange}
            selected={formatStringAsDate(receiveInfo.receiveRequestDay)}
            disabled={statusCode !== '' && statusCode !== 'PETFRIENDS_DRAFT'}
            minDate={minDate}
            required
          />
          <DatePicker
            id='receiveAvailableDay'
            label='입고예정일'
            onChange={handleReceiveAvailableDayChange}
            selected={formatStringAsDate(receiveInfo.receiveAvailableDay)}
            disabled={
              statusCode !== 'PETFRIENDS_REGISTER' &&
              statusCode !== 'MODIFY' &&
              statusCode !== 'CONFIRM_CANCEL'
            }
            minDate={minDate}
            required
          />
          <DatePicker
            id='totalReceiveConfirmDay'
            label='입고확정일'
            selected={formatStringAsDate(receiveInfo.totalReceiveConfirmDay)}
            minDate={minDate}
            disabled
          />
          <Styled.RadioGroupWrapper>
            <RadioGroup
              name='receiveDelay'
              title='입고 지연'
              radios={RECEIVE_DELAY_OPTIONS}
              checkedValue={receiveInfo.receiveDelay ?? ''}
              disabled
              width='200px'
            />
          </Styled.RadioGroupWrapper>
          <DatePicker
            id='receiveDelayAvailableDay'
            label='지연  후 입고가능일'
            selected={formatStringAsDate(receiveInfo.receiveDelayAvailableDay)}
            disabled
          />
        </Flex>
        <Styled.TextareaLabelWrapper>
          <InputLabel
            id='receiveDelayReason'
            label='입고지연 사유'
            value={receiveInfo.receiveDelayReason ?? ''}
            disabled
            as='textarea'
          />
        </Styled.TextareaLabelWrapper>
      </Flex>
    </Card>
  );
};

ReceiveInfoForm.propTypes = {
  receiveInfo: PropTypes.shape({
    centerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    centerName: PropTypes.string,
    inboundWikey: PropTypes.string,
    receiveAvailableDay: PropTypes.string,
    receiveDelay: PropTypes.string,
    receiveDelayAvailableDay: PropTypes.string,
    receiveDelayReason: PropTypes.string,
    receiveRequestDay: PropTypes.string,
    totalReceiveConfirmDay: PropTypes.string,
  }),
  setSupplyRequestDetail: PropTypes.func.isRequired,
  statusCode: PropTypes.oneOf([
    'PETFRIENDS_DRAFT',
    'PETFRIENDS_REGISTER',
    'MODIFY',
    'VENDOR_REQUEST',
    'PETFRIENDS_CONFIRM',
    'CANCEL',
    'CONFIRM_CANCEL',
    '',
  ]),
};

export default ReceiveInfoForm;
