import { mapObject } from 'commons/object';
import dayjs from 'dayjs';
import { useState } from 'react';
import useSearchParams from './useSearchParams';

const useSearchParamsState = (initialState) => {
  const [searchParams] = useSearchParams();
  const [state, setState] = useState(() => {
    return mapObject(initialState, (key, value) => {
      const queryString = searchParams.get(key);
      if (!queryString) return value;

      if (key === 'query') return queryString.replaceAll('|', '\n');

      return Array.isArray(value)
        ? queryString.split(',').map((item) => {
            if (dayjs(item, 'YYYY-MM-DD').isValid()) {
              return new Date(item);
            }

            return item || null;
          })
        : queryString;
    });
  });

  return [state, setState];
};

export default useSearchParamsState;
