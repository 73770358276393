import styled from 'styled-components';

export const TableWrapper = styled.div`
  thead {
    th:nth-child(n + 5) {
      text-align: right;
    }
  }
  tbody {
    td:nth-child(n + 5) {
      text-align: right;
    }
  }
`;

export default { TableWrapper };
