import PropTypes from 'prop-types';

import { PAYMENT_DATE_HASH, PAYMENT_METHOD_HASH } from 'commons/hash';

import Styled from './styled';

const SettlementCompleteDetailHeader = ({ data = {} }) => {
  return (
    <Styled.Header>
      <Styled.Infos>
        <span>
          <b>{data?.vendorInfo?.displayName}</b>
        </span>
        <span>
          <b>{data?.settlementInfo?.settlementName}</b>
        </span>
        <span>정산 담당자: {data?.vendorInfo?.moneyManagerFormat}</span>
        <span>결제방법: {PAYMENT_METHOD_HASH[data?.vendorInfo?.paymentMethod]}</span>
        <span>결제일: {PAYMENT_DATE_HASH[data?.vendorInfo?.paymentDate]}</span>
      </Styled.Infos>
    </Styled.Header>
  );
};

SettlementCompleteDetailHeader.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default SettlementCompleteDetailHeader;
