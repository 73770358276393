import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { postSupplySettlementByVendorList, postSupplyTaxInvoiceIssue } from 'service/hq/settlement';
import { Divider } from 'styles';

import {
  SUPPLY_INFO_COLUMNS,
  TAX_INVOICE_COLUMNS_V2,
  REVERSE_INFO_COLUMNS,
} from 'pages/admin/settlement/constant';
import useSelectOptions from 'hooks/useSelectOptions';

import SupplyInfoContainer from 'pages/admin/settlement/containers/SupplyInfoContainer';
import TaxInvoiceContainer from 'pages/admin/settlement/containers/TaxInvoiceContainer';
import FinalSettlementInfoContainer from 'pages/admin/settlement/containers/FinalSettlementInfoContainer';

import SettlementDetailHeader from 'pages/admin/settlement/components/SettlementDetailHeader';
import SettlementDetailFooter from 'pages/admin/settlement/components/SettlementDetailFooter';

import ReverseContainer from 'pages/admin/settlement/containers/ReverseContainer';

import toastify from 'commons/toast';
import Styled from './styled';
import { useSettlementDetail } from '../../commons/hooks';

/**
 * @param {*} props - { isOpen = false, onClose = () => {} }
 */
const SettlementListDetail = ({ isOpen = false, onClose = () => {} }) => {
  const {
    /// list state
    data,
    error,
    execute,
    isLoading,
    /// 추가한 세금계산서 내역 리스트
    prevTaxInvoiceListData,
    /// index state
    index,
    setIndex,
    /// event handler
    handleStateChange,
    handleFinalExecute,
    handleClose,
    disabled,
  } = useSettlementDetail({
    isOpen,
    onClose,
    listApiFunc: postSupplySettlementByVendorList(),
    submitApiFunc: postSupplyTaxInvoiceIssue(),
  });

  const { reverseStatus, reverseReason } = useSelectOptions(['reverseStatus', 'reverseReason']);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleFinalExecute();
  };

  useEffect(() => {
    if (error && error instanceof Error) {
      toastify(`${error}`, { variant: 'error' });
    }
  }, [error]);

  return (
    <Styled.Div>
      <SettlementDetailHeader
        columns={SUPPLY_INFO_COLUMNS}
        datas={data}
        index={index}
        setIndex={setIndex}
      />
      {isOpen && !isLoading && data && (
        <>
          <SupplyInfoContainer
            columns={SUPPLY_INFO_COLUMNS}
            datas={data}
            index={index}
            handleStateChange={handleStateChange('requestInfoList')}
            execute={execute}
          />
          <ReverseContainer
            columns={REVERSE_INFO_COLUMNS}
            datas={data}
            index={index}
            reverseStatus={reverseStatus}
            reverseReason={reverseReason}
          />
          <Divider $backgroundColor='#B1B1B1' />
          <form onSubmit={handleSubmit}>
            <TaxInvoiceContainer
              columns={TAX_INVOICE_COLUMNS_V2}
              datas={data}
              prevTaxInvoiceListData={prevTaxInvoiceListData}
              index={index}
              handleStateChange={handleStateChange('taxInvoiceInfoList')}
            />
            <Divider $backgroundColor='#B1B1B1' />
            <FinalSettlementInfoContainer
              datas={data}
              prevTaxInvoiceListData={prevTaxInvoiceListData}
              index={index}
              disabled={disabled}
              finalText='세금계산서 발행 요청'
            />
          </form>
        </>
      )}

      <Divider $backgroundColor='#B1B1B1' />
      <SettlementDetailFooter
        noticeList={[
          '세금계산서는 품목의 과세구분에 따라 구별되어 각각 1장의 세금계산서로 발급됩니다.',
          '거래처가 세금계산서 발행을 1건이라도 거절하는 경우에는, 모든 세금계산서 발행요청이 취소되며, 다시 처음부터 정산을 진행해야 합니다.',
          '정산을 실행하면, 정산 대상 목록에서 사라집니다.',
        ]}
      />
      <Styled.Button onClick={handleClose}>닫기</Styled.Button>
    </Styled.Div>
  );
};

SettlementListDetail.propTypes = { isOpen: PropTypes.bool, onClose: PropTypes.func };

export default SettlementListDetail;
