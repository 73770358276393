import { useState, useEffect } from 'react';

import TextareaSearchbar from 'components/TextareaSearchbar';

import { VENDOR_SEARCH_CONDITION } from 'commons/constant';

function RegistTextArea({ detail, search }) {
  const [searchOptions, setSearchOptions] = useState({ query: '', condition: 'VENDOR_NAME' });

  const handleSearchQueryChange = (e) =>
    setSearchOptions((prev) => ({ ...prev, query: e.target.value }));

  const handleCheckedValueChange = (e) =>
    setSearchOptions((prev) => ({ ...prev, condition: e.target.value }));

  const handleSearchClick = () => {
    search({
      searchCondition: searchOptions.condition,
      searchContent: searchOptions.query,
    });
  };

  useEffect(() => {
    if (!detail) setSearchOptions({ query: '', condition: 'VENDOR_NAME' });
  }, [detail]);

  return (
    <TextareaSearchbar
      name='prepaidRegisterSearchCondition'
      textareaValue={searchOptions.query}
      onTextareaChange={handleSearchQueryChange}
      radios={VENDOR_SEARCH_CONDITION}
      onSearchClick={handleSearchClick}
      checkedValue={searchOptions.condition}
      onCheckedValueChange={handleCheckedValueChange}
    />
  );
}

export default RegistTextArea;
