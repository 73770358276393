import PropTypes from 'prop-types';
import Select from 'components/Select';
import Button from '../Button';
import * as Styled from './styled';

const Searchbar = ({
  name,
  options,
  selected,
  onSelect,
  value,
  onChange,
  onClick,
  onReset,
  placeholder = '검색어를 입력해 주세요',
}) => {
  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  return (
    <Styled.Wrapper>
      {options && selected && onSelect && (
        <Select
          id={name}
          label='검색 조건'
          name={name}
          options={options}
          value={selected}
          onChange={onSelect}
        />
      )}
      <Styled.Input
        type='search'
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyUp={handleKeyUp}
      />
      <Button onClick={onClick}>검색</Button>

      {onReset && (
        <Button $variant='light' $size='small' onClick={onReset}>
          초기화
        </Button>
      )}
    </Styled.Wrapper>
  );
};

Searchbar.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  selected: PropTypes.string,
  onSelect: PropTypes.func,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  placeholder: PropTypes.string,
};

export default Searchbar;
