import styled from 'styled-components';
import { MODAL_SIZES } from './commons/constant';

const Wrapper = styled.div.attrs({ className: 'modal' })`
  width: 100vw;
  height: 100vh;
  display: block;
  background-color: var(--greyOpacity500);
  display: grid;
  place-items: center;
`;

const Dialog = styled.div.attrs({
  className: `modal-dialog modal-dialog-centered`,
})`
  max-width: none;
`;

const Content = styled.div.attrs({ className: 'modal-content' })`
  box-shadow: none;
  border-radius: var(--radius-s);
  border: none;
  height: ${({ $size }) => $size && `${MODAL_SIZES[$size]}vh`};
  width: ${({ $size }) => $size && `${MODAL_SIZES[$size]}vw`};
  overflow-y: auto;
  box-shadow: rgb(0 0 0 / 12%) 0.1rem 0.1rem 0.4rem 0px;

  #activityIndicator & {
    background: transparent;
    box-shadow: none;
  }
`;

const Header = styled.div.attrs({ className: 'modal-header' })`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div.attrs({ className: 'modal-title' })`
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
`;

const Body = styled.div.attrs({ className: 'modal-body' })``;

const Footer = styled.div.attrs({ className: 'modal-footer' })``;

export { Wrapper, Dialog, Content, Header, Title, Body, Footer };
