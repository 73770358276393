import { Wrapper, Date, Category } from './styled';

/**
 * @param {*} props
 */
const DateCategory = ({ date, category }) => {
  return (
    <Wrapper>
      <Date>{date}</Date>
      <Category>{category}</Category>
    </Wrapper>
  );
};

export default DateCategory;
