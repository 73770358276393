import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Input from 'components/Input';

const EditableCell = ({
  type,
  value: initialValue,
  row: { index: rowIndex },
  column: { id: columnName },
  format = (v) => {
    return v;
  },
  updateMyData,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleBlur = () => updateMyData(rowIndex, columnName, value);
  const handleNumberInputChange = (e) => setValue(e.target.value);

  return (
    <Input
      type={type || 'text'}
      value={format(value)}
      onChange={handleNumberInputChange}
      onBlur={handleBlur}
      required
      maxLength={12}
      {...rest}
    />
  );
};

EditableCell.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  row: PropTypes.shape({
    index: PropTypes.number,
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  updateMyData: PropTypes.func.isRequired,
  format: PropTypes.func,
};

export default EditableCell;
