import dayjs from 'dayjs';

export const setInitDataList = (current, prev = []) => {
  const newList = current.map(({ taxInvoiceTransformDay, ...item }) => ({
    ...item,
    taxInvoiceTransformDay: taxInvoiceTransformDay ?? dayjs().format('YYYY-MM-DD'),
  }));
  const customList = prev.filter((_, index) => !newList[index]);

  return [...newList, ...customList];
};

export default { setInitDataList };
