import { thousandSeparator } from 'commons/helper';
import PropTypes from 'prop-types';

const ThousandSeperator = ({ value }) => {
  return <div>{thousandSeparator(value) || 0}</div>;
};

ThousandSeperator.propTypes = {
  value: PropTypes.number,
};

export default ThousandSeperator;
