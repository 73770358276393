import { formatDateAsYYYYMMDD } from 'commons/date';
import useRouter from './useRouter';

const useSearchParams = () => {
  const {
    location: { pathname },
    push,
    query,
  } = useRouter();

  const setQueryParams = (searchParams) => {
    const search =
      typeof searchParams === 'string'
        ? searchParams
        : Object.entries(searchParams)
            .map(([key, value]) => {
              return `${key}=${
                Array.isArray(value)
                  ? value
                      .map((date) => (date instanceof Date ? formatDateAsYYYYMMDD(date) : date))
                      .join(',')
                  : value
              }`;
            })
            .join('&');

    push({
      pathname,
      search,
    });
  };

  return [query, setQueryParams];
};

export default useSearchParams;
