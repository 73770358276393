import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import productApi from 'service/hq/product';
import { handleError, isEmpty, updateMyDataMaker } from 'commons/helper';
import toastify from 'commons/toast';
import useFetch from 'hooks/useFetch';
import { useGlobalModals, usePageModals } from 'hooks/pages';

import Button from 'components/Button';
import Card from 'components/Card';
import LabelAndText from 'components/LabelAndText';
import ResultHeader from 'components/ResultHeader';
import PaginationTable from 'components/Table/PaginationTable';
import { ROW_SELECT_PLUGIN_HOOKS } from 'components/Table/commons/constant';

import Styled from './styled';

import { PRODUCT_COLUMNS, VENDOR_PRODUCT_LIST } from '../../commons/constant';
import EditableCell from '../../components/EditableCell';
import { getUpdateProductSupplyVendorStandardList } from '../../commons/helper';

const VendorProductListContainer = () => {
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [controlledPageIndex, setControlledPage] = useState(0);
  const { selectedVendor } = useSelector(({ vendorProduct }) => vendorProduct[VENDOR_PRODUCT_LIST]);
  const {
    data: { content = [], totalElements = 0, totalPages = 0 } = {},
    setState,
    execute: getProductList,
    isLoading,
  } = useFetch(productApi.getProductList, {
    initialExecute: false,
  });
  const autoResetSelectedRows = useRef();
  const [{ detail }, setModalOpen] = usePageModals();
  const { 1: setGlobalModalOpen } = useGlobalModals();

  const tableOptions = {
    initialState: { pageIndex: 0, pageSize: 50 },
    manualPagination: true,
    pageCount: totalPages,
    defaultColumn: {
      Cell: EditableCell,
    },
    updateMyData: updateMyDataMaker(setState, {
      properties: ['data', 'content'],
      autoResetSelectedRows,
    }),
    autoResetSelectedRows: autoResetSelectedRows.current,
    useControlledState: (state) => ({
      ...state,
      pageIndex: controlledPageIndex,
    }),
  };

  useEffect(() => {
    if (!selectedVendor || detail) {
      return;
    }
    getProductList({ vendorId: selectedVendor.id });
    setControlledPage(0);
  }, [selectedVendor, detail]);

  useEffect(() => {
    autoResetSelectedRows.current = true;
  }, [content]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmpty(content)) {
      toastify('품목 내역이 존재하지 않습니다.', { variant: 'warn' });
      return;
    }

    try {
      await productApi.updateProductList(
        selectedVendor.id,
        getUpdateProductSupplyVendorStandardList(content)
      );
      toastify('저장되었습니다.', { variant: 'success' });
    } catch (error) {
      handleError(error);
    }
  };

  const handleProductSelect = ({ selectedFlatRows }) =>
    setSelectedProductList(selectedFlatRows.map((item) => item.original));

  const handlePageChange = ({ state }) => {
    getProductList({
      vendorId: selectedVendor.id,
      pageNumber: state.pageIndex,
    });
  };

  const handleProductConnectionClick = () => {
    if (!selectedVendor) {
      toastify('거래처 목록에서 거래처를 먼저 선택해 주세요.', { variant: 'warn' });
      return;
    }
    setModalOpen('detail');
  };

  const handleConfirmClick = async () => {
    try {
      await productApi.removeProductListFromVendor(
        selectedProductList.map(
          ({ productSupplyVendorStandardId }) => productSupplyVendorStandardId
        )
      );
      toastify('품목 연결이 삭제되었습니다.', { variant: 'success' });
      setGlobalModalOpen('alert', false);
      getProductList({ vendorId: selectedVendor.id, pageNumber: controlledPageIndex });
    } catch (error) {
      handleError(error);
    }
  };

  const handleCancelClick = () => setGlobalModalOpen('alert', false);

  const handleProductRemoveClick = () => {
    if (isEmpty(selectedProductList)) {
      toastify('선택된 품목이 존재하지 않습니다.', { variant: 'warn' });
      return;
    }

    setGlobalModalOpen('alert', true, {
      title: '연결 삭제',
      content: (
        <div>
          선택한 품목의 품목 연결을 삭제하시겠습니까?
          <br />
          해당 품목의 가격, 품목 정보 수정 요청은 취소 처리 됩니다.
        </div>
      ),
      buttons: [
        { text: '예', onClick: handleConfirmClick },
        { text: '아니오', onClick: handleCancelClick, variant: 'secondary' },
      ],
    });
  };

  const productListHeaderButtons = [
    <Button onClick={handleProductConnectionClick} $variant='light'>
      품목 연결
    </Button>,
    <Button onClick={handleProductRemoveClick} $variant='light'>
      연결 삭제
    </Button>,
    <Button type='submit' $variant='secondary'>
      저장
    </Button>,
  ];

  const noResultMessage =
    !isLoading &&
    isEmpty(content) &&
    selectedVendor &&
    '연결된 품목이 없습니다. 먼저 품목 연결을 진행해 주세요.';

  return (
    <Card title='거래처 별 품목 내역'>
      <form onSubmit={handleSubmit}>
        <Styled.SelectedVendorInfo>
          {selectedVendor ? (
            <>
              <LabelAndText label='거래처 번호' text={selectedVendor.id} />
              <LabelAndText label='거래처 명' text={selectedVendor.displayName} />
            </>
          ) : (
            <span>거래처 목록에서 거래처를 먼저 선택해 주세요.</span>
          )}
        </Styled.SelectedVendorInfo>

        <ResultHeader totalCount={totalElements} buttons={productListHeaderButtons} />
        <PaginationTable
          columns={PRODUCT_COLUMNS}
          data={content}
          onPageChange={handlePageChange}
          tableOptions={tableOptions}
          pluginHooks={ROW_SELECT_PLUGIN_HOOKS}
          onSelectChange={handleProductSelect}
          setControlledPage={setControlledPage}
          noResultMessage={noResultMessage}
        />
      </form>
    </Card>
  );
};

export default VendorProductListContainer;
