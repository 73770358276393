import SearchFilterContainer from './containers/SearchFilterContainer';
import TableContainer from './containers/TableContainer';
import ModalContainer from './containers/ModalContainer';

import Styled from './styled';

/**
 * @param {*} props
 */
const SettlementListPage = (props = {}) => {
  return (
    <Styled.Div>
      <SearchFilterContainer {...props} />
      <TableContainer {...props} />
      <ModalContainer {...props} />
    </Styled.Div>
  );
};

export default SettlementListPage;
