import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { numberExtractor, thousandSeparator } from 'commons/helper';
import Select from 'components/Select';
import Input from 'components/Input';

import { ACTION_CODE_OPTIONS } from './commons/constant';

import Styled from './styled';

const EditableCell = ({
  value: initialValue,
  row: { index: rowIndex, isSelected, values },
  column: { id: columnName },
  updateMyData,
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e) => setValue(e.target.value);
  const handleBlur = () => updateMyData(rowIndex, columnName, value);
  const handlePriceChange = (e) => {
    if (values.actionCode === 'REVOKE' && numberExtractor(e.target.value) > values.totalPrice) {
      return;
    }

    setValue(numberExtractor(e.target.value));
  };

  if (columnName === 'actionCode') {
    return (
      <Select
        id='actionCode'
        name='actionCode'
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        options={ACTION_CODE_OPTIONS}
        required={isSelected}
      />
    );
  }

  if (columnName === 'price') {
    return (
      <Styled.Input
        type='text'
        value={thousandSeparator(value)}
        onChange={handlePriceChange}
        onBlur={handleBlur}
        required={isSelected}
        maxLength={12}
        $actionCode={values.actionCode}
      />
    );
  }

  if (columnName === 'reason') {
    return (
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => updateMyData(rowIndex, columnName, value)}
      />
    );
  }

  if (columnName === 'totalPrice') {
    return thousandSeparator(value) || 0;
  }

  return value;
};

EditableCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  row: PropTypes.shape({
    index: PropTypes.number,
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  updateMyData: PropTypes.func.isRequired,
};

export default EditableCell;
