import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--space-m) 0;
  height: 35px;

  strong {
    color: var(--green400);
  }
`;

export const Buttons = styled.div`
  display: flex;
  gap: var(--space-s);
`;

export default { Wrapper, Buttons };
