export function transformIntoPriceTargetProducts(products) {
  return products.map((product) => ({
    ...product,
    isSelected: undefined,
    id: null,

    beforeProductStandardUnitPrice: product.productStandardUnitPrice,
    beforeMinimumOrderQuantity: product.minimumOrderQuantity,
    beforeTax: product.tax,
    afterProductStandardUnitPrice: '',
    afterMinimumOrderQuantity: '',
    afterTax: product.tax ?? 'ZERO',

    reasonForChange: '',
    petfriendsComment: '',
  }));
}

export function transformIntoItemTargetProducts(products) {
  return products.map(({ storageTemperature, ...selectedProduct }) => ({
    ...selectedProduct,
    isSelected: undefined,
    id: null,
    reasonForChange: '',
    petfriendsComment: '',
    storageTemperature,
  }));
}

export const getDuplicatedItemsMessage = (duplicateProductItems) =>
  duplicateProductItems
    .map(({ requestId, duplicateProductItemIdList }) => {
      return `요청ID: ${requestId}
품목ID: ${duplicateProductItemIdList.join(', ')}

`;
    })
    .join('');
