import styled from 'styled-components';

const TitleWrapper = styled.div`
  height: 30px;
`;

const Title = styled.span`
  color: var(--grey700);
  font-weight: var(--font-weight-medium);
`;

const OptionWrapper = styled.ul`
  display: flex;
  list-style: none;
  min-height: var(--base-height);
`;

const Option = styled.li`
  border-radius: var(--radius-m);
  border: 1px solid ${({ $isSelected }) => ($isSelected ? 'var(--blue50)' : 'var(--grey300)')};
  color: ${({ $isSelected }) => ($isSelected ? 'var(--blue500)' : 'var(--grey900)')};
  background-color: ${({ $isSelected }) => $isSelected && 'var(--blue50)'};
  padding: var(--space-m);
  cursor: pointer;
  display: grid;
  place-items: center;

  :not(:last-child) {
    margin-right: var(--space-s);
  }
`;

export { TitleWrapper, Title, OptionWrapper, Option };
