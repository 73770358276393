import { returnNullIfEmptyString } from 'commons/helper';
import instance from 'service/interceptor';

async function getProducts({
  pageNumber = 0,
  pageSize = 100,

  productStatus = null,
  productType = null,

  searchCondition = 'PRODUCT_NAME',
  searchContent = '',
} = {}) {
  const { data } = await instance.post('/api/v2/product/supply/vendor/standard/list', {
    productStatus: returnNullIfEmptyString(productStatus),
    productType: returnNullIfEmptyString(productType),
    searchCondition,
    searchContent,
    pageNumber,
    pageSize,
  });

  return data;
}

export default { getProducts };
