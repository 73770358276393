import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import amendmentRequestApi from 'service/hq/amendmentRequest';
import useFetch from 'hooks/useFetch';

import Card from 'components/Card';

import { INITIAL_SEARCH_OPTIONS } from './commons/constant';
import { getAmendmentRequestListParams } from './commons/helper';

import SearchContainer from './containers/SearchContainer';
import ResultContainer from './containers/ResultContainer';
import ProductMasterModal from './components/ProductMasterModal';
import VendorModal from './components/VendorModal';
import ProductPriceModal from './components/ProductPriceModal';

const AdminAmendmentRequestListPage = () => {
  const [controlledPageIndex, setControlledPage] = useState(0);
  const searchOptionsSnapshot = useRef(INITIAL_SEARCH_OPTIONS);
  const {
    data: { content = [], totalElements = 0, totalPages = 0 } = {},
    execute: getAmendmentRequestList,
  } = useFetch(amendmentRequestApi.getAmendmentRequestList);
  const { selectedAmendmentRequest } = useSelector((state) => state.amendmentRequest);

  const setSearchOptionsSnapshot = (value) => {
    searchOptionsSnapshot.current = value;
  };

  useEffect(() => {
    if (selectedAmendmentRequest.code === undefined && selectedAmendmentRequest.id === undefined) {
      getAmendmentRequestList(
        getAmendmentRequestListParams(searchOptionsSnapshot.current, controlledPageIndex)
      );
    }
  }, [selectedAmendmentRequest]);

  return (
    <Card title='수정요청 내역'>
      <SearchContainer
        setControlledPage={setControlledPage}
        getAmendmentRequestList={getAmendmentRequestList}
        setSearchOptionsSnapshot={setSearchOptionsSnapshot}
      />
      <ResultContainer
        totalElements={totalElements}
        totalPages={totalPages}
        content={content}
        getAmendmentRequestList={getAmendmentRequestList}
        controlledPageIndex={controlledPageIndex}
        setControlledPage={setControlledPage}
        searchOptionsSnapshot={searchOptionsSnapshot}
      />
      <ProductMasterModal />
      <ProductPriceModal />
      <VendorModal />
    </Card>
  );
};

export default AdminAmendmentRequestListPage;
