import PropTypes from 'prop-types';

import useSelectOptions from 'hooks/useSelectOptions';

import * as Styled from './styled';

const SupplyRequestStatus = ({ currentStatusCode, hideDraft }) => {
  const { productSupplyRequestStatusCode } = useSelectOptions(['productSupplyRequestStatusCode']);

  return (
    <div>
      <Styled.LabelWrapper>
        <Styled.Label>발주 상태</Styled.Label>
      </Styled.LabelWrapper>
      <Styled.StatusWrapper>
        {productSupplyRequestStatusCode.map(({ key, value }, index) => {
          if (index === 0 && hideDraft) {
            return null;
          }

          return (
            <Styled.Status $isSelected={currentStatusCode === key} key={key}>
              {value}
            </Styled.Status>
          );
        })}
      </Styled.StatusWrapper>
    </div>
  );
};

SupplyRequestStatus.propTypes = {
  currentStatusCode: PropTypes.oneOf([
    'PETFRIENDS_DRAFT',
    'PETFRIENDS_REGISTER',
    'MODIFY',
    'VENDOR_REQUEST',
    'PETFRIENDS_CONFIRM',
    'CONFIRM_CANCEL',
    'CANCEL',
    '',
  ]),
  hideDraft: PropTypes.bool,
};

export default SupplyRequestStatus;
