import PropTypes from 'prop-types';

import ResultHeader from 'components/ResultHeader';
import PaginationTable from 'components/Table/PaginationTable';

import { AMENDMENT_REQUEST_COLUMNS } from '../../commons/constant';
import { getAmendmentRequestListParams } from '../../commons/helper';
import RequestIdCell from '../../components/RequestIdCell';

/**
 * @param {*} props
 */
const ResultContainer = ({
  totalElements,
  totalPages,
  content,
  getAmendmentRequestList,
  controlledPageIndex,
  setControlledPage,
  searchOptionsSnapshot,
}) => {
  const tableOptions = {
    initialState: { pageSize: 100 },
    manualPagination: true,
    pageCount: totalPages,
    useControlledState: (state) => ({
      ...state,
      pageIndex: controlledPageIndex,
    }),
  };

  const handlePageChange = ({ state }) => {
    getAmendmentRequestList(
      getAmendmentRequestListParams(searchOptionsSnapshot.current, state.pageIndex)
    );
  };

  const renderCell = (cell) => {
    if (cell.column.id === 'requestId') {
      return <RequestIdCell {...cell} />;
    }

    return cell.value;
  };

  return (
    <>
      <ResultHeader totalCount={totalElements} />
      <PaginationTable
        columns={AMENDMENT_REQUEST_COLUMNS}
        data={content}
        onPageChange={handlePageChange}
        tableOptions={tableOptions}
        setControlledPage={setControlledPage}
        renderCell={renderCell}
      />
    </>
  );
};

ResultContainer.propTypes = {
  totalElements: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  content: PropTypes.arrayOf(PropTypes.object),
  getAmendmentRequestList: PropTypes.func.isRequired,
  controlledPageIndex: PropTypes.number.isRequired,
  setControlledPage: PropTypes.func.isRequired,
  searchOptionsSnapshot: PropTypes.shape({
    current: PropTypes.shape({
      requestCode: PropTypes.oneOf(['VENDOR', 'PRODUCT_PRICE', 'PRODUCT_MASTER', '']),
      vendorRequestStatusType: PropTypes.oneOf(['CANCEL', 'REQUEST', 'APPROVE', 'REJECT', '']),
      requestDay: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
      approveDay: PropTypes.arrayOf(PropTypes.instanceOf(Date)),

      requestSearchCondition: PropTypes.oneOf(['VENDOR_ID', 'VENDOR_NAME']),
      query: PropTypes.string,
    }),
  }).isRequired,
};

export default ResultContainer;
