/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedAmendmentRequest: {
    code: null,
    id: null,
  },
};

const slice = createSlice({
  name: 'amendmentRequest',
  initialState,
  reducers: {
    setSelectedAmendmentRequest: (state, action) => {
      state.selectedAmendmentRequest = action.payload;
    },
  },
});

export const { setSelectedAmendmentRequest } = slice.actions;

export default slice.reducer;
