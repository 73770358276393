import { useState, useEffect } from 'react';
import useFetch from 'hooks/useFetch';
import { useGlobalModals } from 'hooks/pages';

/**
 *
 * @param {yearMonth, centerId?, onClick, isVendor} yearMonth : 날짜, onClick: 이벤트 클릭 시 액션, api: apis, centerId: 센타ID
 * @returns { data, execute } data: 캘린더 이벤트 리스트, execute: api 호출 메소드
 */
export function useCalendarList({ yearMonth, centerId, api, onClick }) {
  const { execute } = useFetch(api, { initialExecute: false });
  const [, setModalOpen] = useGlobalModals();
  const [calendarEvents, setCalendarEvents] = useState([]);

  const executeHandler = async () => {
    setModalOpen('activityIndicator', true);

    const result = await execute({ yearMonth, centerId }).finally(() => {
      setModalOpen('activityIndicator', false);
    });

    if ('data' in result) {
      const mappingDate = result?.data.map(
        ({ amount, pcsCount, receiveAvailableDay, skuCount, unreceivedSkuCount, overflowed }) => ({
          title: `${receiveAvailableDay}-task`,
          start: receiveAvailableDay,
          extendedProps: {
            receiveAvailableDay,
            amount,
            pcsCount,
            skuCount,
            unreceivedSkuCount: unreceivedSkuCount ?? 0,
            onClick,
            centerId,
            overflowed,
          },
        })
      );

      setCalendarEvents(mappingDate);
    }
  };

  useEffect(() => {
    executeHandler();
  }, [yearMonth, centerId]);

  return { calendarEvents, executeHandler };
}
