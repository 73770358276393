import styled from 'styled-components';

export const InputLabelWrapper = styled.div`
  display: flex;
  gap: var(--space-m);

  > div {
    flex: 1;
  }

  textarea {
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export default { InputLabelWrapper };
