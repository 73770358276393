import styled from 'styled-components';
import PaginationTableComponent from 'components/Table/PaginationTable';

export const TableWrapper = styled.div`
  overflow-x: auto;
`;

export const PaginationTable = styled(PaginationTableComponent)`
  thead {
    tr {
      th {
        &:first-child {
          width: 80px;
        }
        &:nth-child(2) {
          width: 50px;
        }
        &:nth-child(3) {
          width: 250px;
        }
        &:nth-child(4) {
          width: 250px;
        }
        &:nth-child(5) {
          width: 320px;
        }
      }
    }
  }
`;

export default { TableWrapper, PaginationTable };
