import PropTypes from 'prop-types';

import { thousandSeparator } from 'commons/helper';
import { TAX_INVOICE_STATUS_CODE_HASH } from 'commons/hash';

import Table from 'components/Table';

import Styled from './styled';

/**
 * @param {*} props
 */
const TaxInvoiceDetailContainer = ({ columns, data, isTitle = true }) => {
  const renderCell = ({ column, value = [], render, row }) => {
    const lastValue =
      thousandSeparator(parseInt(value, 10)) === '' ? '0' : thousandSeparator(parseInt(value, 10));
    switch (column?.id) {
      case 'index':
        return row.index + 1;
      case 'settlementPrice':
      case 'supplyCostTotal':
      case 'taxTotal':
        return lastValue;
      case 'taxInvoiceStatusCode':
        return TAX_INVOICE_STATUS_CODE_HASH[value];
      default:
        return render('Cell');
    }
  };

  return (
    <Styled.Div>
      {isTitle && (
        <h5 style={{ padding: '1rem 0' }}>
          세금계산서 금액 계산 <b>{data?.taxInvoiceInfoList?.length || 0}</b>건
        </h5>
      )}
      <Table
        columns={columns}
        data={data ?? []}
        $height='unset'
        $width='unset'
        renderCell={renderCell}
      />
    </Styled.Div>
  );
};

TaxInvoiceDetailContainer.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isTitle: PropTypes.bool,
};

export default TaxInvoiceDetailContainer;
