/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleError } from 'commons/helper';
import {
  VENDOR_PRODUCT_LIST,
  INITIAL_SEARCH_OPTIONS,
} from 'pages/admin/AdminProductManagePage/commons/constant';

import vendorProductApi from 'service/hq/vendorProduct';

const initialState = {
  [VENDOR_PRODUCT_LIST]: {
    searchFilter: INITIAL_SEARCH_OPTIONS,
    tableData: [],
    totalPages: 0,
    totalElements: 0,
    pageIndex: 0,

    selectedVendor: null,
  },
};

export const getVendorList = createAsyncThunk(
  'vendor/product/getVendorList',
  async (params, { rejectWithValue }) => {
    try {
      const { response } = await vendorProductApi.getVendorList({
        searchCondition: params.condition,
        searchContent: params.query,
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
      });
      return {
        searchFilter: params,
        content: response?.data?.content,
        totalElements: response?.data?.totalElements,
        totalPages: response?.data?.totalPages,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const slice = createSlice({
  name: 'vendorProduct',
  initialState,
  reducers: {
    setSearchFilter: (state, { payload }) => {
      state[payload.path].searchFilter = payload.searchFilter;
    },
    setPageIndex: (state, { payload }) => {
      state[payload.path].pageIndex = payload.pageIndex;
    },

    setSelectedVendor: (state, { payload }) => {
      state[payload.path].selectedVendor = payload.selectedVendor;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVendorList.fulfilled, (state, { payload }) => {
      state[VENDOR_PRODUCT_LIST].searchFilter = payload.searchFilter;
      state[VENDOR_PRODUCT_LIST].tableData = payload.content;
      state[VENDOR_PRODUCT_LIST].totalElements = payload.totalElements;
      state[VENDOR_PRODUCT_LIST].totalPages = payload.totalPages;
    });
    builder.addCase(getVendorList.rejected, (state, { payload }) => {
      handleError(payload);
    });
  },
});

export const { setSearchFilter, setPageIndex, setSelectedVendor } = slice.actions;

export default slice.reducer;
