export const SETTLEMENT_LIST = '/admin/settlement/list';
export const SETTLEMENT_COMPLETE = '/admin/settlement/complete/list';
export const SETTLEMENT_PREPAID = '/admin/settlement/prepaid';

export const SUPPLY_INFO_COLUMNS = [
  { Header: '발주번호', accessor: 'productSupplyRequestId' },
  { Header: '발주유형 ', accessor: 'productSupplyTypeCode' },
  { Header: '발주상태', accessor: 'productSupplyRequestStatusCode' },
  { Header: '선지급금 정산 여부', accessor: 'prepaidSettlement' },
  { Header: '입고 품목 수', accessor: 'totalItemKindCount', isSum: true },
  { Header: '총 입고 수량', accessor: 'totalReceivedQuantity', isSum: true },
  { Header: '입고예정일', accessor: 'receiveAvailableDay' },
  { Header: '입고완료일', accessor: 'receiveCompleteDay' },
  { Header: '발주금액(₩)', accessor: 'productSupplyPrice', isSum: true },
  { Header: '입고결과 금액(₩)', accessor: 'receivedQuantityPrice', isSum: true },
  { Header: '할인적용(%)', accessor: 'discountRate', isEditable: true },
  { Header: '할인적용 금액(₩)', accessor: 'discountApplyPrice', isSum: true },
  { Header: '펫프담당자 메모', accessor: 'petfriendsComment' },
];

export const REVERSE_INFO_COLUMNS = [
  { Header: '반품번호', accessor: 'reverseNo' },
  { Header: '반품사유', accessor: 'reverseReason' },
  { Header: '반품상태', accessor: 'reverseStatus' },
  { Header: '출고 품목 수', accessor: 'totalReversedItemCount', isSum: true },
  { Header: '총 출고 수량', accessor: 'totalReversedQuantity', isSum: true },
  { Header: '출고예정일', accessor: 'reverseAvailableDay' },
  { Header: '출고완료일', accessor: 'reverseCompleteDay' },
  { Header: '반품금액(₩)', accessor: 'reverseAvailablePrice', isSum: true },
  { Header: '출고결과 금액(₩)', accessor: 'reversedPrice', isSum: true },
];

export const TAX_INVOICE_COLUMNS_V2 = [
  { Header: '#', accessor: 'index' },
  { Header: '과세형태', accessor: 'settlementTaxationTypeCode' },
  { Header: '적요', accessor: 'taxInvoiceItemName', isEditable: true },
  { Header: '작성일자 ', accessor: 'taxInvoiceTransformDay', isEditable: true },
  { Header: '공급가액', accessor: 'supplyCostTotal' },
  { Header: '세액', accessor: 'taxTotal' },
  { Header: '정산금액', accessor: 'settlementPrice', isEditable: true },
  { Header: '', accessor: 'delete', isEditable: true },
];

export const TAX_INVOICE_COLUMNS = [
  { Header: '#', accessor: 'index' },
  { Header: '과세형태', accessor: 'settlementTaxationTypeCode' },
  { Header: '작성일자 ', accessor: 'taxInvoiceTransformDay', isEditable: true },
  { Header: '최종 정산금액(₩)', accessor: 'settlementPrice' },
  { Header: '공금가액', accessor: 'supplyCostTotal' },
  { Header: '세액(₩)', accessor: 'taxTotal' },
];
export const TAX_INVOICE_CHECK_BILL_COLUMNS = [
  { Header: '#', accessor: 'index' },
  { Header: '과세형태', accessor: 'settlementTaxationTypeCode' },
  { Header: '영수/청구', accessor: 'settlementPurposeTypeCode', isEditable: true },
  { Header: '작성일자 ', accessor: 'taxInvoiceTransformDay', isEditable: true },
  { Header: '최종 정산금액(₩)', accessor: 'settlementPrice' },
  { Header: '공금가액', accessor: 'supplyCostTotal', isEditable: true },
  { Header: '세액(₩)', accessor: 'taxTotal', isEditable: true },
];
export const TAX_INVOICE_CARD_COLUMNS = [
  { Header: '#', accessor: 'index' },
  { Header: '과세형태', accessor: 'settlementTaxationTypeCode' },
  { Header: '승인번호', accessor: 'approvalNumber', isEditable: true },
  { Header: '결제일자 ', accessor: 'taxInvoiceTransformDay', isEditable: true },
  { Header: '최종 정산금액(₩)', accessor: 'settlementPrice' },
  { Header: '공금가액', accessor: 'supplyCostTotal', isEditable: true },
  { Header: '세액(₩)', accessor: 'taxTotal', isEditable: true },
];
export const TAX_INVOICE_CASH_COLUMNS = [
  { Header: '#', accessor: 'index' },
  { Header: '과세형태', accessor: 'settlementTaxationTypeCode' },
  { Header: '승인번호', accessor: 'approvalNumber', isEditable: true },
  { Header: '거래일자 ', accessor: 'taxInvoiceTransformDay', isEditable: true },
  { Header: '최종 정산금액(₩)', accessor: 'settlementPrice' },
  { Header: '공금가액', accessor: 'supplyCostTotal', isEditable: true },
  { Header: '세액(₩)', accessor: 'taxTotal', isEditable: true },
];

export const TAX_INVOICE_DETAIL_COLUMNS = [
  { Header: 'No.', accessor: 'index' },
  { Header: '적요', accessor: 'taxInvoiceItemName' },
  { Header: '공급가액', accessor: 'supplyCostTotal' },
  { Header: '세액', accessor: 'taxTotal' },
  { Header: '정산금액', accessor: 'settlementPrice' },
];

export const SETTLEMENT_PREPAID_COLUMNS = [
  { Header: '거래처번호', accessor: 'vendorId' },
  { Header: '거래처명', accessor: 'vendorDisplayName' },
  { Header: '정산명', accessor: 'settlementName' },
  { Header: '정산번호', accessor: 'settlementId' },
  { Header: '정산일', accessor: 'settlementDay' },
  { Header: '정산 상태', accessor: 'settlementStatusType' },
  { Header: '최종 정산금액 합계 (₩)', accessor: 'totalSettlementPrice' },
  { Header: '선지급금 잔액 (₩)', accessor: 'balance' },
  { Header: '기존 선지급금 사용액 (₩)', accessor: 'totalPrepaidPrice' },
  { Header: '선지급금 사용액 (₩)', accessor: 'usePrepaidPrice' },
  { Header: '사용 구분', accessor: 'actionCode' },
  { Header: '사유', accessor: 'memo' },
];

export default {
  SETTLEMENT_LIST,
  SETTLEMENT_COMPLETE,
  SETTLEMENT_PREPAID,
  SUPPLY_INFO_COLUMNS,
  TAX_INVOICE_COLUMNS,
  TAX_INVOICE_DETAIL_COLUMNS,
  SETTLEMENT_PREPAID_COLUMNS,
  TAX_INVOICE_CHECK_BILL_COLUMNS,
  TAX_INVOICE_CARD_COLUMNS,
  TAX_INVOICE_CASH_COLUMNS,
};
