import styled from 'styled-components';

export const Div = styled.div``;

export const Header = styled.header`
  display: flex;
  align-items: center;

  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -15px;
    right: -15px;
    border-bottom: 1px solid #b1b1b1;
  }

  .currunt-index {
    margin-left: 7px;
    margin-right: 7px;
  }
  .pagenation {
    display: flex;
    align-self: flex-start;
  }
`;

export const Box = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  background: #ffffff;
  border: 1px solid #626262;
  :active {
    background: rgb(0, 0, 0, 0.2);
  }
  > span {
    width: 7px;
    height: 14px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const Infos = styled.span`
  display: flex;
  > span {
    padding: 0 0.9rem 1rem;
  }
`;

export default { Div, Header, Box, Infos };
