import styled from 'styled-components';
import PropTypes from 'prop-types';

const Checkbox = ({ id, label, value, checked, onChange }) => {
  return (
    <>
      <StyledInput type='checkbox' id={id} value={value} checked={checked} onChange={onChange} />
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
    </>
  );
};

const StyledInput = styled.input`
  display: none;

  &:checked + label {
    background-color: var(--blue50);
    border-color: var(--blue500);
    color: var(--blue500);
  }
`;

const StyledLabel = styled.label`
  width: 100%;
  cursor: pointer;
  border-radius: var(--radius-m);
  border: 1px solid var(--border-color);
  padding: var(--space-m);
  font-weight: var(--font-weight-regular) !important;
`;

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
