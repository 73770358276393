import PropTypes from 'prop-types';
import Input from 'components/Input';
import * as Styled from './styled';

const Radio = ({ id, name, label, ...props }) => {
  return (
    <Styled.Wrapper>
      <Input type='radio' id={id} name={name} {...props} />
      <Styled.Label htmlFor={id}>{label}</Styled.Label>
    </Styled.Wrapper>
  );
};

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Radio;
