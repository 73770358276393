import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getHash, thousandSeparator } from 'commons/helper';

import TotalTable from '../../components/TotalTable';

import Styled from './styled';

/**
 * @param {*} props
 */
const SupplyInfoDetailContainer = ({ columns, data, index = 0 }) => {
  const { requestInfoList = [] } = data || {};
  const [state, setState] = useState([]);

  useEffect(() => {
    setState(requestInfoList);
  }, [index]);

  const renderCell = useCallback((cell) => {
    const { column, value } = cell;
    const { id, Header } = column;
    return {
      // Price thousand separate
      [Header.match(/₩/) && id]: thousandSeparator(value) || 0,
      [getHash(id, value) && id]: getHash(id, value),
      totalReceivedQuantity: thousandSeparator(value) || 0,
      productSupplyRequestId: (
        <a className='text-primary' href={`/admin/supply/registration/${value}`}>
          {value}
        </a>
      ),
    }[id];
  }, []);

  return (
    <Styled.Div>
      <h5 style={{ padding: '1rem 0' }}>
        대상 발주 정보 <b>{requestInfoList.length}</b>건
      </h5>
      <TotalTable
        columns={columns}
        data={state}
        setData={setState}
        renderCell={renderCell}
        $height='unset'
      />
    </Styled.Div>
  );
};
SupplyInfoDetailContainer.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  index: PropTypes.number,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
export default SupplyInfoDetailContainer;
