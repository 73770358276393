import { useState } from 'react';
import PropTypes from 'prop-types';

import { Flex } from 'styles/components';

import Card from 'components/Card';
import InputLabel from 'components/InputLabel';

import VendorRegisterModal from './components/VendorRegisterModal';

const VendorInfo = ({ vendorInfo, statusCode, setSupplyRequestDetail }) => {
  const [isVendorRegisterModalOpen, setIsVendorRegisterModalOpen] = useState(false);

  const handleVendorRegisterClick = () => setIsVendorRegisterModalOpen(true);

  const inputLabels = [
    { id: 'vendorId', label: '거래처 번호', value: vendorInfo.vendorId },
    { id: 'displayName', label: '거래처명', value: vendorInfo.displayName },
    { id: 'businessNumber', label: '사업자등록번호', value: vendorInfo.businessNumber },
  ];

  return (
    <>
      <Card
        title='거래처 정보'
        label='거래처 선택'
        onClick={handleVendorRegisterClick}
        buttonDisabled={statusCode !== ''}
      >
        <Flex $flexDirection='column' $gap='var(--space-m)'>
          <Flex $gap='var(--space-m)' $flexWrap='wrap'>
            {inputLabels.map(({ id, label, value }) => (
              <InputLabel key={id} id={id} label={label} value={value ?? ''} disabled />
            ))}
          </Flex>
        </Flex>
      </Card>
      <VendorRegisterModal
        isOpen={isVendorRegisterModalOpen}
        closeCallback={() => setIsVendorRegisterModalOpen(false)}
        setSupplyRequestDetail={setSupplyRequestDetail}
      />
    </>
  );
};

VendorInfo.propTypes = {
  vendorInfo: PropTypes.shape({
    vendorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    displayName: PropTypes.string.isRequired,
    businessNumber: PropTypes.string.isRequired,
  }),
  statusCode: PropTypes.oneOf([
    'PETFRIENDS_DRAFT',
    'PETFRIENDS_REGISTER',
    'MODIFY',
    'VENDOR_REQUEST',
    'PETFRIENDS_CONFIRM',
    'CANCEL',
    'CONFIRM_CANCEL',
    '',
  ]),
  setSupplyRequestDetail: PropTypes.func.isRequired,
};

export default VendorInfo;
