import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function convertBinaryIntoOctet(string) {
  const buffer = new ArrayBuffer(string.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < string.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    view[i] = string.charCodeAt(i) & 0xff;
  }
  return buffer;
}

function createWorkBook(workSheet, sheetName) {
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);

  return workBook;
}

const createExcel = (workBook) =>
  XLSX.write(workBook, {
    bookType: 'xlsx',
    type: 'binary',
  });

const saveExcel = (wbout, fileName) =>
  saveAs(
    new Blob([convertBinaryIntoOctet(wbout)], { type: 'application/octet-stream' }),
    `${fileName}.xlsx`
  );

const exportExcel = (workSheet, sheetName, fileName) => {
  saveExcel(createExcel(createWorkBook(workSheet, sheetName)), fileName);
};

export const downloadJsonAsExcel = ({ header = [], data, options, sheetName, fileName }) => {
  const workSheet = XLSX.utils.aoa_to_sheet(header);

  exportExcel(XLSX.utils.sheet_add_json(workSheet, data, options), sheetName, fileName);
};

export default { downloadJsonAsExcel };
