import styled from 'styled-components';

const LabelWrapper = styled.div`
  height: 30px;
`;

const Label = styled.span`
  color: var(--grey700);
  font-weight: var(--font-weight-medium);
`;

const StatusWrapper = styled.ul`
  display: flex;
  list-style: none;
  height: var(--base-height);
`;

const Status = styled.li`
  border-radius: var(--radius-m);
  border: 1px solid ${({ $isSelected }) => ($isSelected ? 'var(--blue50)' : 'var(--grey300)')};
  color: ${({ $isSelected }) => ($isSelected ? 'var(--blue500)' : 'var(--grey900)')};
  background-color: ${({ $isSelected }) => $isSelected && 'var(--blue50)'};
  padding: var(--space-m);
  display: grid;
  place-items: center;
  position: relative;

  :not(:last-child) {
    margin-right: calc(var(--space-m) * 2);
  }

  :nth-last-child(n + 3)::after {
    content: '>';
    position: absolute;
    right: -16px;
    color: black;
    width: 10px;
  }
`;

export { LabelWrapper, Label, StatusWrapper, Status };
