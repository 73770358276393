import { useHistory, useLocation, useParams } from 'react-router-dom';

const useRouter = () => {
  const location = useLocation();
  const { push, replace } = useHistory();
  const query = new URLSearchParams(location.search);
  const params = useParams();

  const pushWithSearchParams = (pathname) => {
    push({
      pathname,
      search: location.search,
    });
  };

  return {
    push,
    pushWithSearchParams,
    replace,
    location,
    query,
    params,
  };
};

export default useRouter;
