import { SUPPLY_STATUS } from 'commons/constant';

export const isSendButtonDisabled = (status) => {
  return (
    status === '' ||
    status === 'PETFRIENDS_REGISTER' ||
    status === 'MODIFY' ||
    status === 'VENDOR_REQUEST' ||
    status === 'PETFRIENDS_CONFIRM' ||
    status === 'CANCEL'
  );
};
export const isApproveButtonDisabled = (status) => {
  return (
    status === '' ||
    status === 'PETFRIENDS_DRAFT' ||
    status === 'PETFRIENDS_REGISTER' ||
    status === 'MODIFY' ||
    status === 'PETFRIENDS_CONFIRM' ||
    status === 'CANCEL'
  );
};
export const isRejectButtonDisabled = (status) => {
  return (
    status === '' ||
    status === 'PETFRIENDS_DRAFT' ||
    status === 'PETFRIENDS_REGISTER' ||
    status === 'MODIFY' ||
    status === 'PETFRIENDS_CONFIRM' ||
    status === 'CANCEL'
  );
};
export const isCancelButtonDisabled = (status) => {
  return status === 'CANCEL';
};

export const getSuccessMessage = (idList, status) => {
  if (status === 'PETFRIENDS_REGISTER') {
    return `거래처 전송되었습니다.
    ${idList.length}건의 발주가 상태가 <${SUPPLY_STATUS[status]}>으로 변경되었습니다.
    `;
  }

  return `${idList.length}건의 발주가 ${SUPPLY_STATUS[status]} 처리 되었습니다.`;
};

export const getExcelSupplyRequestList = (list) =>
  list.map((item) => ({
    productSupplyRequestId: item.productSupplyRequestId,
    productSupplyTypeCodeName: item.productSupplyTypeCodeName,
    statusCodeName: item.statusCodeName,
    prepaidSettlement: item.prepaidSettlement,
    inboundWikey: item.inboundWikey,
    vendorId: item.vendorId,
    displayName: item.displayName,
    purchaseManager: item.purchaseManager,
    totalItemKindCount: item.totalItemKindCount,
    totalItemQuantity: item.totalItemQuantity,
    totalPrice: item.totalPrice,
    totalReceivedPrice: item.totalReceivedPrice,
    centerName: item.centerName,
    receiveRequestDay: item.receiveRequestDay,
    receiveAvailableDay: item.receiveAvailableDay,
    requestWorkerName: item.requestWorkerName,
    createDate: item.createDate,
    updateDate: item.updateDate,
  }));
