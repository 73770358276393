import { useEffect } from 'react';

import { useHistory, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getWmsCenterList } from 'commons/store/modules/admin/supply';
import { getCheckCapacityValidationThunk } from 'commons/store/modules/user';
import { getTokenData } from 'commons/token';
import AdminPrepaidPage from './AdminPrepaidPage';
import AdminProductManagePage from './AdminProductManagePage';
import AdminProductMasterPage from './AdminProductMasterPage';
import AdminProductMasterUploadPage from './AdminProductMasterUploadPage';
import AdminReceivingManagePage from './AdminReceivingManagePage';
import AdminRequestListPage from './AdminAmendmentRequestListPage';

import SettlementRoute from './settlement/SettlementRoute';
import NoticeRoute from './notice/NoticeRoute';
import AdminPrepaidDetailPage from './AdminPrepaidDetailPage';
import SupplyRoute from './supply/SupplyRoute';
import AdminDashboard from './AdminDashboard';
import AdminReverse from './AdminReverse';

const AdminRoute = (props) => {
  const dispatch = useDispatch();
  const { wmsCenterList } = useSelector((state) => state.supply);

  useEffect(() => {
    if (wmsCenterList.length) return;
    dispatch(getWmsCenterList());
    dispatch(getCheckCapacityValidationThunk());
  }, []);

  return (
    <>
      <Route path='/admin/dashboard' component={AdminDashboard} />
      <Route path='/admin/notice' component={NoticeRoute} />
      <Route path='/admin/settlement' component={SettlementRoute} {...props} />
      <Route path='/admin/prepaid/list' component={AdminPrepaidPage} />
      <Route path='/admin/prepaid/detail/:vendorId' component={AdminPrepaidDetailPage} />
      <Route path='/admin/vendor/product/list' component={AdminProductManagePage} />
      <Route path='/admin/product/list' component={AdminProductMasterPage} />
      <Route path='/admin/product/upload' component={AdminProductMasterUploadPage} />
      <Route path='/admin/supply' component={SupplyRoute} />
      <Route path='/admin/receiving/list' component={AdminReceivingManagePage} />
      <Route path='/admin/request/list' component={AdminRequestListPage} />
      <Route path='/admin/reverse' component={AdminReverse} />
    </>
  );
};

export default AdminRoute;
