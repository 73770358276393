import PropTypes from 'prop-types';

import { REASON_FOR_CHANGE_OPTIONS, REQUEST_STATUS_CODE } from 'commons/constant';
import { Flex } from 'styles/components';

import Button from 'components/Button';

import Select from 'components/Select';
import { useState } from 'react';
import {
  isMainButtonsEnabled,
  isRejected,
  isRequestCancelButtonEnabled,
  isSaveButtonsEnabled,
} from './commons/helper';
import * as Styled from './styled';

const Header = ({
  targetProducts,
  setTargetProducts,
  statusCode,
  onProductRemoveClick,
  setTargetButton,
  onRequestCancelClick,
}) => {
  const [reasonForChange, setReasonForChange] = useState('');

  const handleReasonForChangeChange = (e) => {
    setTargetProducts(
      targetProducts.map((product) => ({ ...product, reasonForChange: e.target.value }))
    );

    setReasonForChange('');
  };

  const buttons = [
    {
      onClick: onProductRemoveClick,
      label: '품목 삭제',
      condition: isMainButtonsEnabled(statusCode),
    },
    {
      type: 'submit',
      onClick: () => setTargetButton('임시 저장'),
      label: '임시 저장',
      condition: isSaveButtonsEnabled(statusCode),
    },
    {
      type: 'submit',
      $variant: 'secondary',
      onClick: () => setTargetButton('요청'),
      label: isRejected(statusCode) ? '재요청' : '수정 요청',
      condition: isMainButtonsEnabled(statusCode),
    },
  ];

  function renderMainButtons() {
    return buttons.map(
      ({ onClick, label, condition, type = 'button', $variant = 'light' }) =>
        condition && (
          <Button key={label} type={type} $variant={$variant} onClick={onClick}>
            {label}
          </Button>
        )
    );
  }

  return (
    <Styled.Wrapper>
      <span>
        대상 품목 내역 {targetProducts.length}건이 검색되었습니다.{' '}
        <small>
          한 번의 수정 요청으로 <strong>최대 100개</strong>의 품목까지 변경할 수 있습니다.
        </small>
      </span>
      <Flex $gap='var(--space-m)'>
        {isMainButtonsEnabled(statusCode) && (
          <Select
            id='reaon-for-change'
            placeholder='변경사유 일괄 변경'
            value={reasonForChange}
            onChange={handleReasonForChangeChange}
            options={REASON_FOR_CHANGE_OPTIONS}
            disabled={!targetProducts.length}
          />
        )}
        {renderMainButtons()}
        {isRequestCancelButtonEnabled(statusCode) && (
          <Button $size='small' $variant='light' onClick={onRequestCancelClick}>
            요청 취소
          </Button>
        )}
      </Flex>
    </Styled.Wrapper>
  );
};

Header.propTypes = {
  targetProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  setTargetProducts: PropTypes.func.isRequired,
  statusCode: PropTypes.oneOf([
    REQUEST_STATUS_CODE.SAVE,
    REQUEST_STATUS_CODE.REQUEST,
    REQUEST_STATUS_CODE.REJECT,
    REQUEST_STATUS_CODE.APPROVE,
    REQUEST_STATUS_CODE.CANCEL,
    null,
  ]),
  onProductRemoveClick: PropTypes.func.isRequired,
  setTargetButton: PropTypes.func.isRequired,
  onRequestCancelClick: PropTypes.func.isRequired,
};

export default Header;
