import PropTypes from 'prop-types';

import Styled from './styled';

const SettlementDetailHeader = ({ settlementInfo }) => {
  return (
    <Styled.Header>
      <b>{settlementInfo?.settlementName}</b>
    </Styled.Header>
  );
};

SettlementDetailHeader.propTypes = {
  settlementInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default SettlementDetailHeader;
