import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { addAllOption } from 'commons/helper';
import useSelectOptions from 'hooks/useSelectOptions';
import { Flex } from 'styles/components';

import Select from 'components/Select';
import Choice from 'components/Choice';
import DatePicker from 'components/DatePicker';

import RadioGroup from 'components/RadioGroup';
import { PREPAID_SETTLEMENT_OPTIONS } from 'commons/constant';
import { DATE_PICKERS } from './commons/constant';

const Options = ({ searchOptions, setState }) => {
  const { statusCodes } = searchOptions;
  const { wmsCenterList } = useSelector((state) => state.supply);

  const selectOptions = useSelectOptions(['purchaseOrderStatus', 'purchaseOrderType']);

  const handleSelectChange = (e) => setState(e.target.name)(e.target.value);
  const setStatusCodes = setState('statusCodes');

  const handleChoice = (e) => {
    const value = e.target.dataset.key;
    if (value === '') {
      setStatusCodes(['']);
      return;
    }

    if (statusCodes.includes(value) && statusCodes.length > 1) {
      setStatusCodes((prevStatusCodes) =>
        prevStatusCodes.filter((statusCode) => statusCode !== value)
      );
    } else {
      setStatusCodes((prevStatusCodes) =>
        [...prevStatusCodes, value].filter((statusCode) => statusCode !== '')
      );
    }
  };

  return (
    <>
      <Flex $gap='var(--space-m)'>
        <Select
          id='centerId'
          name='centerId'
          label='입고센터'
          value={searchOptions.centerId}
          onChange={handleSelectChange}
          options={addAllOption(wmsCenterList)}
        />
        <Select
          id='productSupplyTypeCode'
          name='productSupplyTypeCode'
          label='발주 유형'
          value={searchOptions.productSupplyTypeCode}
          onChange={handleSelectChange}
          options={addAllOption(selectOptions.purchaseOrderType)}
        />
        <RadioGroup
          name='prepaidSettlement'
          title='선지급금 정산 여부'
          radios={[
            { id: 'PREPAID_SETTLEMENT_OPTIONS_null', label: '전체', value: '' },
            ...PREPAID_SETTLEMENT_OPTIONS,
          ]}
          checkedValue={searchOptions.prepaidSettlement ?? ''}
          onCheckedValueChange={handleSelectChange}
          width='200px'
        />
      </Flex>
      <Choice
        title='발주 상태'
        options={addAllOption(selectOptions.purchaseOrderStatus)}
        value={searchOptions.statusCodes}
        onClick={handleChoice}
      />
      <Flex $gap='var(--space-m)'>
        {DATE_PICKERS.map(({ label, name }) => (
          <DatePicker
            key={name}
            id={name}
            label={label}
            onChange={setState(name)}
            startDate={searchOptions[name][0]}
            endDate={searchOptions[name][1]}
            selectsRange
          />
        ))}
      </Flex>
    </>
  );
};

Options.propTypes = {
  searchOptions: PropTypes.shape({
    centerId: PropTypes.string,
    productSupplyTypeCode: PropTypes.string,
    statusCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
    productSupplyRequestDay: PropTypes.arrayOf(PropTypes.instanceOf(new Date())),
    totalReceiveConfirmDay: PropTypes.arrayOf(PropTypes.instanceOf(new Date())),
    receiveAvailableDay: PropTypes.arrayOf(PropTypes.instanceOf(new Date())),
    prepaidSettlement: PropTypes.string,

    searchOption: PropTypes.string,
    query: PropTypes.string,
  }),
  setState: PropTypes.func.isRequired,
};

export default Options;
