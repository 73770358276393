import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
`;

const Spinner = styled.div.attrs(({ $type, $variant, $size }) => ({
  className: `spinner-${$type} text-${$variant} ${$size === 'small' && `spinner-${$type}-sm`}`,
  role: 'status',
}))``;

export default { Wrapper, Spinner };
