import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f3f4f6;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 20px;
`;

const Description = styled.small`
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  margin-bottom: 28px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 26px 40px 40px 40px;
  width: 460px;
  height: 560px;
  border-radius: 10px;
  margin-bottom: 30px;
`;

const Input = styled.input`
  width: 380px;
  height: 60px;
  border-radius: 6px;
  margin-bottom: 14px;
  box-sizing: border-box;
  padding: 20px;
`;

const Button = styled.button`
  width: 380px;
  height: 60px;
  margin-top: 26px;
  margin-bottom: 14px;
  border-radius: 6px;
  background-color: #ff4081;
  color: #fff;
`;

const HelperText = styled.span`
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  margin-bottom: 20px;
`;

const BottomWrapper = styled.div`
  width: 380px;
  height: 84px;
  background: rgba(242, 244, 246, 0.51);
  padding: 20px 25px;

  span {
    font-size: 13px;
    color: #222222;
  }
  button {
    width: 59px;
    height: 22px;
    font-size: 12px;
    border: 1px solid #2f7df6;
    border-radius: 4px;
    margin-left: 5px;
    color: #2f7df6;
  }
`;

const Highlight = styled.strong`
  display: block;
  font-weight: 700;
  font-size: 14px;
`;

const ImageWrapper = styled.div`
  width: 460px;
  height: 90px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const ErrorMessage = styled.strong`
  color: var(--red400);
  height: 14px;
`;

const Link = styled.a`
  color: #2f7df6;
  font-size: 14px;
  margin-top: 38px;
  text-decoration-line: underline;
`;

const AdminLoginButton = styled.button.attrs({ type: 'button' })`
  position: absolute;
  right: 16px;
  top: 16px;
  padding: var(--space-s);

  img {
    width: 18px;
    height: 20px;
    margin-right: 5px;
  }
`;

export {
  Wrapper,
  Form,
  Title,
  Description,
  Input,
  Button,
  HelperText,
  BottomWrapper,
  Highlight,
  ImageWrapper,
  Link,
  ErrorMessage,
  AdminLoginButton,
};
