import { useState } from 'react';

import { Flex } from 'styles/components';
import Card from 'components/Card';

import RadioGroup from 'components/RadioGroup';
import Searchbar from 'components/Searchbar';
import Table from 'components/Table';
import toastify from 'commons/toast';
import { TransitionContainer, Text } from './styled';
import { STEP1_COLUMNS } from '../../constant';
import { usePurchaseOrderReverses } from '../../apis';

const ids = ['PRODUCT_ITEM_ID', 'VENDOR_ID', 'PRODUCT_ITEM'];

function Step1({ purchaseOrderReverseSearchCondition }) {
  const [params, setParams] = useState({
    condition: 'VENDOR_ID',
    searchWord: '',
  });

  const { purchaseOrderList, executeHandler } = usePurchaseOrderReverses(params);

  const onChangeHandler = (key) => (e) => {
    const { value } = e.target;
    setParams((prev) => ({ ...prev, [key]: value }));
  };

  const validation = () => {
    if (!params.searchWord.trim()) {
      toastify('검색어를 입력해주세요.', { variant: 'error' });
      return false;
    }

    if (ids.includes(params.condition) && /[^\d, \n]/g.test(params.searchWord)) {
      toastify('숫자만 입력할 수 있습니다.', { variant: 'error' });
      return false;
    }

    if (ids.includes(params.condition) && params.searchWord.length > 9) {
      toastify('입력 할 수 있는 자릿수를 초과하였습니다.', { variant: 'error' });
      return false;
    }

    return true;
  };

  const search = () => {
    if (!validation()) return false;
    executeHandler();
  };

  return (
    <TransitionContainer $flexDirection='column'>
      <Card title='STEP 1. 발주 검색'>
        <Flex $rowGap={30} $flexDirection='column'>
          <Flex $gap='var(--space-m)' $flexDirection='column'>
            <RadioGroup
              title='검색조건'
              name='condition'
              radios={purchaseOrderReverseSearchCondition}
              checkedValue={`${params.condition}`}
              onCheckedValueChange={onChangeHandler('condition')}
            />
            <Searchbar
              name='searchWord'
              onClick={search}
              value={params.searchWord}
              onChange={onChangeHandler('searchWord')}
            />
          </Flex>
          <Flex $gap='var(--space-m)' $flexDirection='column'>
            <Text>검색결과</Text>
            <Table columns={STEP1_COLUMNS} data={purchaseOrderList} />
          </Flex>
        </Flex>
      </Card>
    </TransitionContainer>
  );
}

export default Step1;
