import PropTypes from 'prop-types';

import toastify from 'commons/toast';
import { REQUEST_STATUS_CODE } from 'commons/constant';
import vendorInfo from 'service/vendor/info';
import { Flex } from 'styles';

import Button from 'components/Button';
import HeaderModal from 'components/modals/HeaderModal';
import { handleError } from 'commons/helper';

import { MODAL_LEFT_BUTTON_LABELS, MODAL_RIGHT_BUTTON_LABELS } from './commons/constant';
import { isInputDisabledStatus } from './commons/helper';
import useForm from './hooks/useForm';

import FormFooter from './components/FormFooter';
import SupplyInfoForm from './components/SupplyInfoForm';
import PaymentInfoForm from './components/PaymentInfoForm';
import BusinessmanInfoForm from './components/BusinessmanInfoForm';

const RequestModifyModal = ({ isOpen, setModalOpen, request }) => {
  const { statusCode, statusCodeValue, id } = request;
  const hasProcessingRequest = id !== null;
  const [form, setForm] = useForm(request);

  const openRequestsModal = () => setModalOpen({ requestsModal: true });
  const handleRequestCancelClick = async () => {
    try {
      await vendorInfo.cancelModifyRequest(id);
      toastify('취소되었습니다.', { variant: 'success' });
      openRequestsModal();
    } catch (error) {
      handleError(error);
    }
  };

  const modalButtons = {
    leftButton: <Button type='submit'>{MODAL_LEFT_BUTTON_LABELS[statusCode]}</Button>,
    rightButton: (
      <Button $variant='light' onClick={handleRequestCancelClick}>
        {MODAL_RIGHT_BUTTON_LABELS[statusCode]}
      </Button>
    ),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!hasProcessingRequest || statusCode === REQUEST_STATUS_CODE.REJECT) {
        await vendorInfo.modifyRequest(form);

        toastify('수정요청 되었습니다.', { variant: 'success' });
      } else if (statusCode === REQUEST_STATUS_CODE.REQUEST) {
        await vendorInfo.cancelModifyRequest(id);
        toastify('취소되었습니다.', { variant: 'success' });
      }
    } catch (error) {
      handleError(error);
    } finally {
      openRequestsModal();
    }
  };

  return (
    <HeaderModal
      onSubmit={handleSubmit}
      as='form'
      $size='lg'
      isOpen={isOpen}
      closeCallback={setModalOpen}
      title='거래처 정보 수정 요청'
      leftButton={modalButtons.leftButton}
      rightButton={modalButtons.rightButton}
    >
      <Flex $flexDirection='column' $gap='var(--space-m)'>
        <BusinessmanInfoForm
          form={form}
          setForm={setForm}
          inputDisabled={isInputDisabledStatus(statusCode)}
        />
        <PaymentInfoForm form={form} setForm={setForm} statusCode={statusCode} />
        <SupplyInfoForm
          form={form}
          setForm={setForm}
          inputDisabled={isInputDisabledStatus(statusCode)}
        />
        <FormFooter request={request} statusCode={statusCode} statusCodeValue={statusCodeValue} />
      </Flex>
    </HeaderModal>
  );
};

RequestModifyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  request: PropTypes.shape({
    statusCode: PropTypes.oneOf([
      REQUEST_STATUS_CODE.REQUEST,
      REQUEST_STATUS_CODE.REJECT,
      REQUEST_STATUS_CODE.APPROVE,
      REQUEST_STATUS_CODE.CANCEL,
      null,
    ]),
    statusCodeValue: PropTypes.oneOf([
      '임시 저장',
      '요청 취소',
      '요청 중',
      '승인 완료',
      '요청 반려',
    ]),
    id: PropTypes.number,
  }),
};

export default RequestModifyModal;
