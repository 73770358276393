import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addAllOption, getType } from 'commons/helper';

import {
  setCompletePageFilter,
  fetchSettlementCompleteList,
} from 'commons/store/modules/admin/settlement';
import { formatDateAsYYYYMMDD } from 'commons/date';
import useSelectOptions from 'hooks/useSelectOptions';

import { Flex } from 'styles';

import { SETTLEMENT_COMPLETE } from 'pages/admin/settlement/constant';

import Card from 'components/Card';
import Select from 'components/Select';
import DatePicker from 'components/DatePicker';
import RadioGroup from 'components/RadioGroup';
import TextareaOrInputSearchbar from 'components/TextareaOrInputSearchbar';

import { PREPAID_SETTLEMENT_OPTIONS } from 'commons/constant';
import {
  TAX_INVOICE_TYPE_FILTER_OPTIONS,
  SETTLEMENT_STATUS_TYPE_FILTER_OPTIONS,
  TAX_INVOICE_STATUS_CODE_FILTER_OPTIONS,
  SETTLEMENT_SEARCH_CONTITION_FILTER_OPTIONS,
  COMPLETE_PAGE_SEARCH_FILTER_INIT_VALUES,
} from '../../commons/constant';

const SearchFilterContainer = () => {
  const dispatch = useDispatch();
  const [searchFilterState, setSearchFilterState] = useState(
    COMPLETE_PAGE_SEARCH_FILTER_INIT_VALUES
  );
  const selectOptions = useSelectOptions(['paymentDate']);

  useEffect(() => {
    dispatch(fetchSettlementCompleteList(searchFilterState));
  }, []);

  const onTextareaFormat = (value = '', searchOption) => {
    let newValue = value;
    if (searchOption !== 'VENDOR_NAME')
      newValue = newValue.replace(/\s\s/g, ' ').replace(/\s/g, ', ').replace(/,,/g, ',');
    return newValue;
  };

  const handleFormSelectChange = (targetName) => (e) => {
    /** params의 searchWord 제외 빈 값은 null로 전송해야 합니다. */
    if (targetName === 'searchOption') {
      setSearchFilterState((prev) => ({ ...prev, searchWord: '' }));
    }
    const value = getType(e) === 'object' ? e?.target?.value : e;
    setSearchFilterState((prev) => ({ ...prev, [targetName]: value || null }));
  };

  const handleFormDatePickerChange = (beforeKey, afterKey) => (dates) => {
    const [startDate, endDate] = dates;
    setSearchFilterState((prev) => ({
      ...prev,
      [beforeKey]: formatDateAsYYYYMMDD(startDate),
      [afterKey]: formatDateAsYYYYMMDD(endDate),
    }));
  };

  const handleSearchClick = () => {
    dispatch(
      setCompletePageFilter({
        path: SETTLEMENT_COMPLETE,
        data: searchFilterState,
      })
    );
    dispatch(fetchSettlementCompleteList(searchFilterState));
  };

  const getDateFormat = (date) => {
    if (date !== null) {
      return new Date(date);
    }
    return date;
  };

  const renderPlaceholder = (name) => {
    if (name === 'VENDOR_NAME') {
      return '검색어를 입력해 주세요.';
    }
    return '검색어를 입력해 주세요. 구분 문자 (쉼표, 엔터)로 복수 품목을 검색할 수 있습니다.';
  };
  return (
    <Card title='정산 내역'>
      <Flex $flexDirection='column' $gap='var(--space-m)'>
        <Flex>
          <Select
            id='settlement-date-filter'
            label='결제일'
            value={searchFilterState.paymentDate || ''}
            onChange={handleFormSelectChange('paymentDate')}
            options={addAllOption(selectOptions.paymentDate)}
          />
        </Flex>
        <Flex $gap='var(--space-m)'>
          <DatePicker
            id='register-date'
            label='등록일시'
            selected={getDateFormat(searchFilterState.beforeCreatedAt)}
            onChange={handleFormDatePickerChange('beforeCreatedAt', 'afterCreatedAt')}
            startDate={getDateFormat(searchFilterState.beforeCreatedAt)}
            endDate={getDateFormat(searchFilterState.afterCreatedAt)}
            dataFormat='yyyy-mm-dd'
            selectsRange
          />
          <DatePicker
            id='complete-date'
            label='정산완료일'
            selected={getDateFormat(searchFilterState.beforeProductSupplySettlementCompletionDay)}
            onChange={handleFormDatePickerChange(
              'beforeProductSupplySettlementCompletionDay',
              'afterProductSupplySettlementCompletionDay'
            )}
            startDate={getDateFormat(searchFilterState.beforeProductSupplySettlementCompletionDay)}
            endDate={getDateFormat(searchFilterState.afterProductSupplySettlementCompletionDay)}
            dataFormat='yyyy-mm-dd'
            selectsRange
          />
          <Select
            id='settlement-date-filter'
            label='전표 유형'
            value={searchFilterState.taxInvoiceType || ''}
            onChange={handleFormSelectChange('taxInvoiceType')}
            options={TAX_INVOICE_TYPE_FILTER_OPTIONS}
          />
        </Flex>

        <Flex $flex-direction='column' $gap='var(--space-l)'>
          <Flex $flex-direction='row' $gap='var(--space-l)'>
            <RadioGroup
              title='정산 상태'
              name='productSupplySettlementStatusType'
              radios={SETTLEMENT_STATUS_TYPE_FILTER_OPTIONS}
              checkedValue={searchFilterState.productSupplySettlementStatusType || ''}
              onCheckedValueChange={handleFormSelectChange('productSupplySettlementStatusType')}
            />
            <RadioGroup
              title='선지급금 정산 여부'
              name='prepaidSettlement'
              radios={[
                { id: 'PREPAID_SETTLEMENT_OPTIONS_null', label: '전체', value: '' },
                ...PREPAID_SETTLEMENT_OPTIONS,
              ]}
              checkedValue={searchFilterState.prepaidSettlement || ''}
              onCheckedValueChange={handleFormSelectChange('prepaidSettlement')}
            />
          </Flex>

          <RadioGroup
            title='세금계산서 상태'
            name='productSupplyTaxInvoiceStatusCode'
            radios={TAX_INVOICE_STATUS_CODE_FILTER_OPTIONS}
            checkedValue={searchFilterState.productSupplyTaxInvoiceStatusCode || ''}
            onCheckedValueChange={handleFormSelectChange('productSupplyTaxInvoiceStatusCode')}
          />
          <TextareaOrInputSearchbar
            title='검색조건'
            name='searchArea'
            type={searchFilterState.searchOption === 'VENDOR_NAME' ? 'input' : 'textarea'}
            radios={SETTLEMENT_SEARCH_CONTITION_FILTER_OPTIONS}
            checkedValue={searchFilterState.searchOption || ''}
            onCheckedValueChange={handleFormSelectChange('searchOption')}
            textareaValue={searchFilterState.searchWord || ''}
            onTextareaChange={handleFormSelectChange('searchWord')}
            onSearchClick={handleSearchClick}
            placeholder={renderPlaceholder(searchFilterState.searchOption)}
            onTextareaFormat={onTextareaFormat}
          />
        </Flex>
      </Flex>
    </Card>
  );
};

export default SearchFilterContainer;

SearchFilterContainer.propTypes = {};
