import PropTypes from 'prop-types';

import { postSupplySettlementByVendorList, postSupplyTaxInvoiceIssue } from 'service/hq/settlement';
import { Divider } from 'styles';

import { SUPPLY_INFO_COLUMNS, TAX_INVOICE_CASH_COLUMNS } from 'pages/admin/settlement/constant';

import SupplyInfoContainer from 'pages/admin/settlement/containers/SupplyInfoContainer';
import TaxInvoiceContainer from 'pages/admin/settlement/containers/TaxInvoiceContainer';
import FinalSettlementInfoContainer from 'pages/admin/settlement/containers/FinalSettlementInfoContainer';

import SettlementDetailHeader from 'pages/admin/settlement/components/SettlementDetailHeader';
import SettlementDetailFooter from 'pages/admin/settlement/components/SettlementDetailFooter';

import { useSettlementDetail } from '../../commons/hooks';

import Styled from './styled';

const type = 'cash';

/**
 * 로직이 달라질 경우를 고려해 일부러 분리된 상세 컨테이너입니다.
 * @param {*} props - { isOpen = false, onClose = () => {} }
 */
const SettlementCashDetail = ({ isOpen = false, onClose = () => {} }) => {
  const {
    /// list state
    data,
    execute,
    isLoading,
    /// index state
    index,
    setIndex,
    /// event handler
    handleStateChange,
    handleFinalExecute,
    handleClose,
  } = useSettlementDetail({
    isOpen,
    onClose,
    listApiFunc: postSupplySettlementByVendorList(type),
    submitApiFunc: postSupplyTaxInvoiceIssue(type),
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    handleFinalExecute();
  };

  return (
    <Styled.Div>
      <SettlementDetailHeader
        columns={SUPPLY_INFO_COLUMNS}
        datas={data}
        index={index}
        setIndex={setIndex}
      />
      {isOpen && !isLoading && (
        <>
          <SupplyInfoContainer
            columns={SUPPLY_INFO_COLUMNS}
            datas={data}
            index={index}
            handleStateChange={handleStateChange('requestInfoList')}
            execute={execute}
          />
          <Divider $backgroundColor='#B1B1B1' />
          <form onSubmit={handleSubmit}>
            <TaxInvoiceContainer
              labelText='현금영수증 입력'
              columns={TAX_INVOICE_CASH_COLUMNS}
              datas={data}
              index={index}
              handleStateChange={handleStateChange('taxInvoiceInfoList')}
            />
            <Divider $backgroundColor='#B1B1B1' />
            <FinalSettlementInfoContainer
              datas={data}
              index={index}
              finalWarningText='위 내역을 저장합니다. 현금영수증 정산은 세금계산서 발행 처리를 하지 않습니다.'
            />
          </form>
        </>
      )}

      <Divider $backgroundColor='#b1b1b1' />
      <SettlementDetailFooter
        noticeList={[
          '카드 전표를 등록할 때는 반드시 전표에 표시된 결제금액과 발주금액이 일치하는 지 확인 바랍니다.',
        ]}
      />
      <Styled.Button onClick={handleClose}>닫기</Styled.Button>
    </Styled.Div>
  );
};

SettlementCashDetail.propTypes = { isOpen: PropTypes.bool, onClose: PropTypes.func };

export default SettlementCashDetail;
