import instance from 'service/interceptor';
import { returnNullIfEmptyString } from 'commons/helper';

async function fetchProductMasterList({
  productStatus = null,
  productType = null,
  searchCondition = 'PRODUCT_ITEM_ID',
  searchContent = '',
  pageIndex = 0,
  pageSize = 50,
} = {}) {
  const { data } = await instance.post('/api/v2/hq/product/supply/item/master/list', {
    productStatus: returnNullIfEmptyString(productStatus),
    productType: returnNullIfEmptyString(productType),
    searchCondition,
    searchContent,
    pageNumber: pageIndex,
    pageSize,
  });

  return data;
}

async function saveProductMasterList(updateItemList) {
  const data = await instance.post('/api/v2/hq/product/supply/item/master/update', {
    updateItemList,
  });

  return data;
}

async function readUploadedExcel(file) {
  const formData = new FormData();
  formData.append('file1', file);
  const { data } = await instance.post(
    '/api/v2/hq/product/supply/item/master/excel-upload',
    formData
  );

  return data;
}

async function saveUploadedExcel(upsertItemList) {
  const { data } = await instance.post('/api/v2/hq/product/supply/item/master/excel-upsert', {
    upsertItemList,
  });

  return data;
}

export default {
  fetchProductMasterList,
  saveProductMasterList,
  readUploadedExcel,
  saveUploadedExcel,
};
