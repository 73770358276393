import styled from 'styled-components';

export const Text = styled.p`
  font-size: 15px;
  color: #4e5968;
  margin: 0;
  font-weight: 500;
`;

export const InfoWrapper = styled.div`
  margin-bottom: 25px;
  border-bottom: 1px solid #e9ecef;

  & > h4,
  & > h5 {
    margin-bottom: 20px;
  }
`;
