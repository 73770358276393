import PropTypes from 'prop-types';

import { updateNestedObject } from 'commons/helper';
import { Flex } from 'styles/components';

import Card from 'components/Card';
import Select from 'components/Select';
import InputLabel from 'components/InputLabel';

import { CARGO_TYPE_OPTIONS, TRANSPORT_TYPE_OPTIONS } from './commons/constant';

const TransportInfo = ({ transportInfo, setSupplyRequestDetail, statusCode }) => {
  const handleTransportInfoChange = (e) =>
    setSupplyRequestDetail((prev) =>
      updateNestedObject(prev, ['transportInfo', e.target.name], () => e.target.value)
    );

  const isEditDisabled = statusCode !== 'PETFRIENDS_REGISTER' && statusCode !== 'MODIFY';

  return (
    <Card title='화물 정보' description='입고 준비가 완료된 후 입력해 주세요.'>
      <Flex $gap='var(--space-m)' $flexWrap='wrap'>
        <Select
          id='transportTypeCode'
          name='transportTypeCode'
          label='운송 유형'
          value={transportInfo.transportTypeCode ?? ''}
          onChange={handleTransportInfoChange}
          options={TRANSPORT_TYPE_OPTIONS}
          disabled={isEditDisabled}
          required
        />
        <InputLabel
          id='transportQuantity'
          name='transportQuantity'
          type='number'
          label='화물 수량'
          min={0}
          step={1}
          value={transportInfo.transportQuantity ?? ''}
          onChange={handleTransportInfoChange}
          disabled={isEditDisabled}
          required
        />
        <Select
          id='transportCargoTypeCode'
          name='transportCargoTypeCode'
          label='화물 유형'
          value={transportInfo.transportCargoTypeCode ?? ''}
          onChange={handleTransportInfoChange}
          options={CARGO_TYPE_OPTIONS}
          disabled={isEditDisabled}
          required
        />
        <InputLabel
          id='transportInfoComment'
          name='transportInfoComment'
          label='비고'
          value={transportInfo.transportInfoComment ?? ''}
          onChange={handleTransportInfoChange}
          disabled={isEditDisabled}
        />
      </Flex>
    </Card>
  );
};

TransportInfo.propTypes = {
  transportInfo: PropTypes.shape({
    transportCargoTypeCode: PropTypes.string,
    transportCargoTypeCodeName: PropTypes.string,
    transportInfoComment: PropTypes.string,
    transportQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    transportTypeCode: PropTypes.string,
    transportTypeCodeName: PropTypes.string,
  }),
  setSupplyRequestDetail: PropTypes.func.isRequired,
  statusCode: PropTypes.oneOf([
    'PETFRIENDS_DRAFT',
    'PETFRIENDS_REGISTER',
    'MODIFY',
    'VENDOR_REQUEST',
    'PETFRIENDS_CONFIRM',
    'CANCEL',
    'CONFIRM_CANCEL',
    '',
  ]),
};

export default TransportInfo;
