import { Redirect, Route } from 'react-router-dom';

import { SETTLEMENT_COMPLETE, SETTLEMENT_LIST, SETTLEMENT_PREPAID } from './constant';

import SettlementListPage from './SettlementListPage';
import SettlementCompletePage from './SettlementCompletePage';
import SettlementPrepaidPage from './SettlementPrepaidPage';

const SettlementRoute = (props) => {
  return (
    <>
      <Route path={SETTLEMENT_LIST} component={SettlementListPage} {...props} />
      <Route path={SETTLEMENT_COMPLETE} component={SettlementCompletePage} {...props} />
      <Route path={SETTLEMENT_PREPAID} component={SettlementPrepaidPage} {...props} />
      <Route path='/admin/settlement/complete'>
        <Redirect to={SETTLEMENT_COMPLETE} />
      </Route>
      <Route exact path='/admin/settlement'>
        <Redirect to={SETTLEMENT_LIST} />
      </Route>
    </>
  );
};

export default SettlementRoute;
