import PropTypes from 'prop-types';

import { addPlaceholderOption, setObjectState } from 'commons/helper';
import useSelectOptions from 'hooks/useSelectOptions';

import { Flex } from 'styles/components';
import Card from 'components/Card';
import InputLabel from 'components/InputLabel';
import Select from 'components/Select';
import { ACTIVE_OPTIONS } from 'pages/admin/notice/AdminNoticeListPage/commons/constant';

/**
 * @param {*} props
 */
const NoticeInfoContainer = ({ noticeInfo, setNoticeInfo, isLoading }) => {
  const { noticeCategoryCode } = useSelectOptions(['noticeCategoryCode']);

  const handleSelectChange = (e) => setObjectState(setNoticeInfo, e.target.name, e.target.value);

  return (
    <Card title='게시물 정보' isLoading={isLoading}>
      <Flex $gap='var(--space-m)' $flexWrap='wrap'>
        <Select
          id='noticeCategoryCode'
          name='noticeCategoryCode'
          label='카테고리'
          value={noticeInfo.noticeCategoryCode}
          onChange={handleSelectChange}
          options={addPlaceholderOption(noticeCategoryCode, '카테고리 선택')}
          required
        />
        <Select
          id='active'
          name='active'
          label='활성 여부'
          value={noticeInfo.active}
          onChange={handleSelectChange}
          options={addPlaceholderOption(ACTIVE_OPTIONS, '활성 여부 선택')}
          required
        />
        <InputLabel id='createdAt' label='등록일시' value={noticeInfo.createdAt || ''} disabled />
        <InputLabel id='updatedAt' label='수정일시' value={noticeInfo.updatedAt || ''} disabled />
      </Flex>
    </Card>
  );
};

NoticeInfoContainer.propTypes = {
  noticeInfo: PropTypes.shape({
    title: PropTypes.string,
    contents: PropTypes.string,
    noticeCategoryCode: PropTypes.string,
    active: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }).isRequired,
  setNoticeInfo: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default NoticeInfoContainer;
