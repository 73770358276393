import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getNoticeList, setPageIndex } from 'commons/store/modules/admin/notice';

import Button from 'components/Button';
import ResultHeader from 'components/ResultHeader';
import PaginationTable from 'components/Table/PaginationTable';

import Styled from './styled';

import { ACTIVE_VALUES, NOTICE_LIST_COLUMNS } from '../../commons/constant';

import TitleCell from '../../components/TitleCell';

const ResultContainer = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { tableData, totalElements, totalPages, pageIndex, searchFilter } = useSelector(
    (state) => state.notice[pathname]
  );
  const dispatch = useDispatch();

  const tableOptions = {
    initialState: { pageSize: 20 },
    manualPagination: true,
    pageCount: totalPages,
    useControlledState: (state) => ({
      ...state,
      pageIndex,
    }),
  };

  const handlePageChange = ({ state }) =>
    dispatch(getNoticeList({ ...searchFilter, pageNumber: state.pageIndex }));

  const setControlledPage = (newPageIndex) =>
    dispatch(setPageIndex({ path: pathname, pageIndex: newPageIndex }));

  const handleRegisterClick = () => history.push('/admin/notice/register');

  const headerButton = [
    <Button $variant='link' onClick={handleRegisterClick}>
      등록
    </Button>,
  ];

  const renderCell = (cell) => {
    switch (cell.column.id) {
      case 'title':
        return <TitleCell {...cell} />;
      case 'active':
        return ACTIVE_VALUES[cell.value];
      default:
        return cell.value;
    }
  };

  return (
    <Styled.Div>
      <ResultHeader totalCount={totalElements} buttons={headerButton} />
      <PaginationTable
        columns={NOTICE_LIST_COLUMNS}
        data={tableData}
        tableOptions={tableOptions}
        setControlledPage={setControlledPage}
        onPageChange={handlePageChange}
        renderCell={renderCell}
      />
    </Styled.Div>
  );
};

export default ResultContainer;
