import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { formatDate } from 'commons/date';
import { setSelectedNotice } from 'commons/store/modules/vendor/dashboard';
import { usePageModals } from 'hooks/pages';

import { CategoryBadge, NoticeItem } from './styled';

const Notice = ({ date, category, title, paragraph }) => {
  const dispatch = useDispatch();
  const { 1: setModalOpen } = usePageModals();

  const handleDetailModalOpen = () => {
    dispatch(setSelectedNotice({ date, category, title, paragraph }));
    setModalOpen('detail');
  };

  const formattedTime = formatDate(date, 'YYYY-MM-DD (dd)');

  return (
    <NoticeItem>
      <td>
        <CategoryBadge>{category}</CategoryBadge>
      </td>
      <td>
        <button type='button' onClick={handleDetailModalOpen}>
          {title}
        </button>
      </td>
      <td>{formattedTime}</td>
    </NoticeItem>
  );
};

Notice.propTypes = {
  date: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
};

export default Notice;
