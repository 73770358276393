import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Flex } from 'styles/components';
import PageItem from 'components/Table/components/Pagination/components/PageItem';

import Styled from './styled';

const Pagination = ({ pageCount: initialPageCount, pageIndex, onPageIndexChange }) => {
  const [pageCount, setPageCount] = useState(initialPageCount);

  useEffect(() => {
    if (initialPageCount !== 0) {
      setPageCount(initialPageCount);
    }
  }, [initialPageCount]);

  const isFirstPage = pageIndex === 0;
  const isLastPage = pageIndex === pageCount - 1;

  const handlePageClick = (page) => () => onPageIndexChange(page - 1);
  const handleFirstPageClick = () => onPageIndexChange(0);
  const handlePreviousPageClick = () => onPageIndexChange(pageIndex - 1);
  const handleNextPageClick = () => onPageIndexChange(pageIndex + 1);
  const handleLastPageClick = () => onPageIndexChange(pageCount - 1);

  function renderPages() {
    let pages;

    if (pageIndex < 2 || pageCount < 5) {
      pages = [1, 2, 3, 4, 5].slice(0, pageCount);
    } else if (pageIndex + 3 > pageCount) {
      pages = [pageCount - 4, pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
    } else {
      pages = [pageIndex - 1, pageIndex, pageIndex + 1, pageIndex + 2, pageIndex + 3];
    }

    return pages.map((page) => (
      <PageItem
        key={page}
        item={page}
        onClick={handlePageClick(page)}
        $isCurrentPage={pageIndex + 1 === page}
      />
    ));
  }

  const pageItems = [
    {
      arrow: '<<',
      onClick: handleFirstPageClick,
      disabled: isFirstPage,
    },
    {
      arrow: '<',
      onClick: handlePreviousPageClick,
      disabled: isFirstPage,
    },
    { renderFunction: renderPages },
    {
      arrow: '>',
      onClick: handleNextPageClick,
      disabled: isLastPage,
    },
    {
      arrow: '>>',
      onClick: handleLastPageClick,
      disabled: isLastPage,
    },
  ];

  return (
    <Flex $justifyContent='center'>
      <Styled.Pagination>
        {pageItems.map(({ arrow, onClick, disabled, renderFunction }) => {
          if (renderFunction) {
            return renderFunction();
          }
          return <PageItem key={arrow} item={arrow} onClick={onClick} disabled={disabled} />;
        })}
      </Styled.Pagination>
    </Flex>
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  onPageIndexChange: PropTypes.func.isRequired,
};

export default Pagination;
