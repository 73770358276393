import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const ResultHeader = ({ totalCount, buttons = [], ...props }) => {
  return (
    <Styled.Wrapper {...props}>
      <span>
        <strong>{totalCount}</strong>
        건이 검색되었습니다.
      </span>
      <Styled.Buttons>
        {buttons.map((button, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>{button}</React.Fragment>
        ))}
      </Styled.Buttons>
    </Styled.Wrapper>
  );
};

ResultHeader.propTypes = {
  totalCount: PropTypes.number.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.element),
};

export default ResultHeader;
