import PropTypes from 'prop-types';
import Styled from './styled';

const Spinner = ({ $size, $type = 'border', $variant = 'primary' }) => {
  return (
    <Styled.Wrapper>
      <Styled.Spinner $type={$type} $variant={$variant} $size={$size} />
    </Styled.Wrapper>
  );
};

Spinner.propTypes = {
  $size: PropTypes.oneOf(['small']),
  $type: PropTypes.oneOf(['border', 'grow']),
  $variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
};

export default Spinner;
