import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useFetch from 'hooks/useFetch';

import { postPurchaseOrderCalendarDaily, getOverviewData, getCenterDefaultSku } from '../apis';

export const useDaily = () => {
  const [dailyData, setDailyData] = useState({});
  const { execute, isLoading } = useFetch(postPurchaseOrderCalendarDaily, {
    initialExecute: false,
  });

  const executeHandler = async ({ searchDate, centerId }, cb) => {
    const result = await execute({ searchDate, centerId });
    setDailyData(result?.data ?? {});
    if (cb) cb();
  };

  return { dailyData, executeHandler, isLoading };
};

export const useOverview = ({ yearMonth, centerId }) => {
  const [overviewData, setOverviewData] = useState({});
  const { execute } = useFetch(getOverviewData, { initialExecute: false });

  const reloadingOverview = async (params) => {
    const { data } = await execute(params);
    setOverviewData(data);
  };

  useEffect(() => {
    reloadingOverview({ yearMonth, centerId });
  }, [yearMonth, centerId]);

  return { overviewData };
};

export const useCenterDefaultData = () => {
  const { wmsCenterList } = useSelector((state) => state.supply);
  const { execute } = useFetch(getCenterDefaultSku, { initialExecute: false });
  const [defaultSkuList, setDefaultSkuList] = useState([]);

  const reloadSku = async () => {
    const { data } = await execute();
    setDefaultSkuList(data);
  };

  return { wmsCenterList, defaultSkuList, reloadSku };
};
