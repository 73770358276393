import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { logout } from 'commons/firebase/helper';
import { handleError } from 'commons/helper';

import { setUser } from 'commons/store/modules/user';

import Button from 'components/Button';
import { getTokenData } from 'commons/token';
import { useAuthInfo } from 'hooks/useSyncAuth';
import { jwtDecode } from '@teampetfriends/utils';
import * as Styled from './styled';

const Header = () => {
  const dispatch = useDispatch();
  const userData = getTokenData();

  const { storage, remove } = useAuthInfo(['accessToken']);

  const userName = useMemo(() => {
    if (storage) {
      const userData = jwtDecode(storage);
      return userData?.user_name ?? '';
    }

    const user = getTokenData();

    return user?.user_name;
  }, [storage]);

  const handleLogout = async () => {
    try {
      if (!userData?.is_vendor) {
        await logout();
      }
      remove();
      dispatch(setUser({ accessToken: null, refreshToken: null }));
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Styled.Wrapper>
      <Styled.LeftSide>
        <Styled.NavItem>
          <Styled.NavLink data-widget='pushmenu'>
            <Styled.MenuIcon />
          </Styled.NavLink>
        </Styled.NavItem>
      </Styled.LeftSide>

      <Styled.RightSide>
        <Styled.DisplayName>{userName}</Styled.DisplayName>
        <Button $variant='light' onClick={handleLogout}>
          로그아웃
        </Button>
      </Styled.RightSide>
    </Styled.Wrapper>
  );
};

export default Header;
