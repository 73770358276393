import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { addPlaceholderOption, updateNestedObject } from 'commons/helper';
import { formatStringAsDate, formatDateAsYYYYMMDD } from 'commons/date';
import useSelectOptions from 'hooks/useSelectOptions';
import { Flex } from 'styles/components';

import Card from 'components/Card';
import InputLabel from 'components/InputLabel';
import Select from 'components/Select';
import SupplyRequestStatus from 'components/SupplyRequestStatus';
import DatePicker from 'components/DatePicker';
import RadioGroup from 'components/RadioGroup';
import { PREPAID_SETTLEMENT_OPTIONS } from 'commons/constant';

const now = new Date();

const RequestInfoForm = ({ supplyInfo, setSupplyRequestDetail }) => {
  const { productSupplyRequestId } = useParams();
  const { purchaseOrderType } = useSelectOptions(['purchaseOrderType']);

  const updateRequestInfo = (obj, key, func) => updateNestedObject(obj, ['requestInfo', key], func);

  const handleSelectChange = (e) =>
    setSupplyRequestDetail((prev) =>
      updateRequestInfo(prev, 'productSupplyTypeCode', () => e.target.value)
    );

  const handleProductSupplyRequestDayChange = (date) => {
    setSupplyRequestDetail((prev) =>
      updateRequestInfo(prev, 'productSupplyRequestDay', () => formatDateAsYYYYMMDD(date))
    );
  };

  const handlePrepaidSettlementChange = (e) =>
    setSupplyRequestDetail((prev) =>
      updateRequestInfo(prev, 'prepaidSettlement', () => e.target.value)
    );

  return (
    <Card title='발주 정보'>
      <Flex $flexDirection='column' $gap='var(--space-m)'>
        <Flex $gap='var(--space-m)' $flexWrap='wrap'>
          <InputLabel
            id='productSupplyRequestId'
            label='발주번호'
            value={productSupplyRequestId ?? ''}
            disabled
          />
          <DatePicker
            id='productSupplyRequestDay'
            label='발주 요청일'
            onChange={handleProductSupplyRequestDayChange}
            selected={formatStringAsDate(supplyInfo.productSupplyRequestDay)}
            disabled={!!productSupplyRequestId && supplyInfo.statusCode !== 'PETFRIENDS_DRAFT'}
            maxDate={now}
          />
          <Select
            id='productSupplyTypeCode'
            name='productSupplyTypeCode'
            label='발주유형'
            value={supplyInfo.productSupplyTypeCode ?? ''}
            onChange={handleSelectChange}
            options={addPlaceholderOption(purchaseOrderType, '발주유형 선택')}
            disabled={supplyInfo.statusCode !== '' && supplyInfo.statusCode !== 'PETFRIENDS_DRAFT'}
            required
          />
          <RadioGroup
            name='prepaidSettlement'
            title='선지급금 정산 여부'
            radios={PREPAID_SETTLEMENT_OPTIONS}
            checkedValue={supplyInfo.prepaidSettlement ?? 'N'}
            onCheckedValueChange={handlePrepaidSettlementChange}
            width='200px'
            disabled={!!productSupplyRequestId}
          />
        </Flex>
        <SupplyRequestStatus currentStatusCode={supplyInfo.statusCode} />
      </Flex>
    </Card>
  );
};

RequestInfoForm.propTypes = {
  supplyInfo: PropTypes.shape({
    productSupplyRequestDay: PropTypes.string,
    productSupplyTypeCode: PropTypes.oneOf([
      'NEW_ORDER',
      'RE_ORDER',
      'PROMOTION',
      'PREPARE_EVENT',
      'PREPARE_SOLD_OUT',
      'PREPARE_INCREASE',
      'REPLACE_ORDER',
      'RETAIL_SALE',
      '',
    ]),
    productSupplyTypeCodeName: PropTypes.string,
    requestWorkerId: PropTypes.string,
    requestWorkerName: PropTypes.string,
    statusCode: PropTypes.oneOf([
      'PETFRIENDS_DRAFT',
      'PETFRIENDS_REGISTER',
      'MODIFY',
      'VENDOR_REQUEST',
      'PETFRIENDS_CONFIRM',
      'CANCEL',
      'CONFIRM_CANCEL',
      '',
    ]),
  }),
  setSupplyRequestDetail: PropTypes.func.isRequired,
};

export default RequestInfoForm;
