import PropTypes from 'prop-types';

import { thousandSeparator } from 'commons/helper';

import Button from 'components/Button';

import Styled from './styled';

/**
 * @param {*} props
 */
const FinalSettlementCompleteContainer = ({
  data = [],
  finalWarningText,
  onCompleteButtonClick,
  onCancelButtonClick,
}) => {
  const { settlementInfo = {}, vendorInfo = {} } = data || {};

  const renderLabalPrice = (label, price, color) => {
    if (!price && typeof price !== 'number') return null;

    return (
      <Styled.LabalPrice $color={color}>
        <label>{label}</label>
        <div>
          <b>₩{thousandSeparator(price) || 0}</b>
        </div>
      </Styled.LabalPrice>
    );
  };

  return (
    <Styled.Div>
      <h5 style={{ padding: '1rem 0' }}>최종 정산내역</h5>
      <div>
        <Styled.Flex>
          {renderLabalPrice('입고결과 금액 합계', settlementInfo.receivedQuantityPrice, 'red')}
          {renderLabalPrice('기타비용 합계', settlementInfo.otherCosts, 'red')}
          {renderLabalPrice('할인 적용금액 합계', settlementInfo.discountApplyPrice, 'blue')}
          {renderLabalPrice('반품금액 합계', settlementInfo.reversedPrice, 'blue')}
          {renderLabalPrice('선지급금 사용 합계', settlementInfo.usedPrepaidPrice || 0, 'blue')}
          {renderLabalPrice('최종 정산금액 합계', settlementInfo.settlementPrice, 'red')}
          {renderLabalPrice('공급가액 합계', settlementInfo.supplyCostTotal)}
          {renderLabalPrice('세액 합계', settlementInfo.taxTotal)}
        </Styled.Flex>
        <br />
        {settlementInfo.productSupplySettlementStatusType === 'REGISTER' && (
          <>
            <p>
              {finalWarningText || (
                <span>
                  위 내역에 대해 <b>{vendorInfo.displayName}</b>&nbsp;정산 담당자에게 세금계산서
                  발행(역발행)을 요청합니다.
                </span>
              )}
            </p>
            <Styled.Buttons>
              <Button $variant='success' onClick={onCompleteButtonClick}>
                정산완료
              </Button>
              <Button $variant='danger' onClick={onCancelButtonClick}>
                정산취소
              </Button>
            </Styled.Buttons>
          </>
        )}
        &nbsp;&nbsp;&nbsp;
      </div>
    </Styled.Div>
  );
};

FinalSettlementCompleteContainer.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  finalWarningText: PropTypes.string,
  onCompleteButtonClick: PropTypes.func,
  onCancelButtonClick: PropTypes.func,
};

export default FinalSettlementCompleteContainer;
