import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getType } from 'commons/helper';
import { formatDateAsYYYYMMDD, formatStringAsDate } from 'commons/date';
import { fetchSettlementList } from 'commons/store/modules/vendor/settlement';

import { Flex } from 'styles';

import Card from 'components/Card';
import Select from 'components/Select';
import DatePicker from 'components/DatePicker';
import RadioGroup from 'components/RadioGroup';
import TextareaOrInputSearchbar from 'components/TextareaOrInputSearchbar';

import {
  TAX_INVOICE_TYPE_FILTER_OPTIONS,
  SETTLEMENT_STATUS_TYPE_FILTER_OPTIONS,
  TAX_INVOICE_STATUS_CODE_FILTER_OPTIONS,
  SETTLEMENT_SEARCH_CONTITION_FILTER_OPTIONS,
  SEARCH_FILTER_INIT_VALUES,
} from '../../commons/constant';

const SearchFilterContainer = () => {
  const dispatch = useDispatch();
  const [searchFilterState, setSearchFilterState] = useState(SEARCH_FILTER_INIT_VALUES);

  useEffect(() => {
    dispatch(fetchSettlementList(SEARCH_FILTER_INIT_VALUES));
  }, []);

  const getPlaceholder = (name) => {
    if (name === 'VENDOR_NAME') {
      return '검색어를 입력해 주세요.';
    }
    return '검색어를 입력해 주세요. 구분 문자 (쉼표, 엔터)로 복수 품목을 검색할 수 있습니다.';
  };

  const handleFormSelectChange = (targetName) => (e) => {
    if (targetName === 'searchOption') {
      setSearchFilterState((prev) => ({ ...prev, searchWord: '' }));
    }
    const value = getType(e) === 'object' ? e?.target?.value : e;
    setSearchFilterState((prev) => ({ ...prev, [targetName]: value || null }));
  };
  const handleFormDatePickerChange = (beforeKey, afterKey) => (dates) => {
    const [startDate, endDate] = dates;
    setSearchFilterState((prev) => ({
      ...prev,
      [beforeKey]: formatDateAsYYYYMMDD(startDate),
      [afterKey]: formatDateAsYYYYMMDD(endDate),
    }));
  };
  const handleSearchClick = () => {
    dispatch(fetchSettlementList(searchFilterState));
  };
  const handleResetClick = () => {
    dispatch(fetchSettlementList(SEARCH_FILTER_INIT_VALUES));
    setSearchFilterState(SEARCH_FILTER_INIT_VALUES);
  };

  return (
    <Card title='정산 내역'>
      <Flex $flexDirection='column' $gap='var(--space-m)'>
        <Flex $gap='var(--space-m)'>
          <DatePicker
            id='register-date'
            label='등록일시'
            selected={formatStringAsDate(searchFilterState.beforeCreatedAt)}
            onChange={handleFormDatePickerChange('beforeCreatedAt', 'afterCreatedAt')}
            startDate={formatStringAsDate(searchFilterState.beforeCreatedAt)}
            endDate={formatStringAsDate(searchFilterState.afterCreatedAt)}
            dataFormat='yyyy-mm-dd'
            selectsRange
          />
          <DatePicker
            id='complete-date'
            label='정산완료일'
            selected={formatStringAsDate(
              searchFilterState.beforeProductSupplySettlementCompletionDay,
              searchFilterState.afterProductSupplySettlementCompletionDay
            )}
            onChange={handleFormDatePickerChange(
              'beforeProductSupplySettlementCompletionDay',
              'afterProductSupplySettlementCompletionDay'
            )}
            startDate={formatStringAsDate(
              searchFilterState.beforeProductSupplySettlementCompletionDay
            )}
            endDate={formatStringAsDate(
              searchFilterState.afterProductSupplySettlementCompletionDay
            )}
            dataFormat='yyyy-mm-dd'
            selectsRange
          />
          <Select
            id='settlement-date-filter'
            label='전표 유형'
            value={searchFilterState.taxInvoiceType || ''}
            onChange={handleFormSelectChange('taxInvoiceType')}
            options={TAX_INVOICE_TYPE_FILTER_OPTIONS}
          />
        </Flex>

        <RadioGroup
          title='정산 상태'
          name='productSupplySettlementStatusType'
          radios={SETTLEMENT_STATUS_TYPE_FILTER_OPTIONS}
          checkedValue={searchFilterState.productSupplySettlementStatusType || ''}
          onCheckedValueChange={handleFormSelectChange('productSupplySettlementStatusType')}
        />
        <RadioGroup
          title='세금계산서 상태'
          name='productSupplyTaxInvoiceStatusCode'
          radios={TAX_INVOICE_STATUS_CODE_FILTER_OPTIONS}
          checkedValue={searchFilterState.productSupplyTaxInvoiceStatusCode || ''}
          onCheckedValueChange={handleFormSelectChange('productSupplyTaxInvoiceStatusCode')}
        />

        <TextareaOrInputSearchbar
          title='검색조건'
          name='searchArea'
          type={searchFilterState.searchOption === 'VENDOR_NAME' ? 'input' : 'textarea'}
          radios={SETTLEMENT_SEARCH_CONTITION_FILTER_OPTIONS}
          checkedValue={searchFilterState.searchOption || ''}
          onCheckedValueChange={handleFormSelectChange('searchOption')}
          textareaValue={searchFilterState.searchWord || ''}
          onTextareaChange={handleFormSelectChange('searchWord')}
          onSearchClick={handleSearchClick}
          onResetClick={handleResetClick}
          placeholder={getPlaceholder(searchFilterState.searchOption)}
        />
      </Flex>
    </Card>
  );
};

SearchFilterContainer.propTypes = {};

export default SearchFilterContainer;
