import { useState, useEffect } from 'react';

import productPrice from 'service/product/price';
import productItem from 'service/product/item';
import useFetch from 'hooks/useFetch';

import HeaderModal from 'components/modals/HeaderModal';
import Button from 'components/Button';

import { REQUEST_INITIAL_VALUES, PRODUCT_SERVICES, COLUMNS } from './commons/constant';

import * as Styled from './styled';
import ProductManageModal from './components/ProductManageModal';
import ProductManage from './components/ProductManage';

function ProductManagePage() {
  const [request, setRequest] = useState(REQUEST_INITIAL_VALUES);
  const [isOpen, setIsOpen] = useState({ requestsModal: false, productManageModal: false });
  const [controlledPage, setControlledPage] = useState(0);

  const {
    data: { content: priceRequestsContent = [], totalPages: priceRequestsTotalPages = 0 } = {},
    execute: fetchPriceRequests,
  } = useFetch(productPrice.getRequests, { initialExecute: false });
  const {
    data: { content: itemRequestsContent = [], totalPages: itemRequestsTotalPages = 0 } = {},
    execute: fetchItemRequests,
  } = useFetch(productItem.getRequests, { initialExecute: false });

  const FETCH_REQUESTS = {
    '가격 수정 요청': fetchPriceRequests,
    '품목 정보 수정 요청': fetchItemRequests,
  };

  const PAGE_COUNT = {
    '가격 수정 요청': priceRequestsTotalPages,
    '품목 정보 수정 요청': itemRequestsTotalPages,
  };

  const CONTENT = {
    '가격 수정 요청': priceRequestsContent,
    '품목 정보 수정 요청': itemRequestsContent,
  };

  const tableOptions = {
    initialState: { pageIndex: 0, pageSize: 10 },
    manualPagination: true,
    pageCount: PAGE_COUNT[request.target],
    useControlledState: (state) => ({
      ...state,
      pageIndex: controlledPage,
    }),
  };

  const setModalOpen = ({ requestsModal = false, productManageModal = false } = {}) =>
    setIsOpen({ requestsModal, productManageModal });

  const changePage = ({ state }) => FETCH_REQUESTS[request.target]({ pageNumber: state.pageIndex });

  useEffect(() => {
    if (!isOpen.requestsModal && !isOpen.productManageModal) {
      setRequest(REQUEST_INITIAL_VALUES);
      setControlledPage(0);
    }

    if (isOpen.requestsModal) {
      FETCH_REQUESTS[request.target]();
    }
  }, [isOpen]);

  const handleRowClick = async ({ values }) => {
    const { response } = await PRODUCT_SERVICES[request.target].getRequestDetail(values.id);
    setRequest((prev) => ({ ...prev, ...response.data }));
    setModalOpen({ productManageModal: true });
  };

  const openProductManageModal = () => setModalOpen({ productManageModal: true });

  const renderHeaderButton = <Button onClick={openProductManageModal}>등록</Button>;

  return (
    <div>
      <ProductManage setModalOpen={setModalOpen} setRequest={setRequest} />

      <HeaderModal
        isOpen={isOpen.requestsModal}
        closeCallback={setModalOpen}
        title={request.target}
        leftButton={renderHeaderButton}
        $size='lg'
      >
        <Styled.PaginationTable
          columns={COLUMNS}
          data={CONTENT[request.target]}
          onRowClick={handleRowClick}
          tableOptions={tableOptions}
          onPageChange={changePage}
          setControlledPage={setControlledPage}
        />
      </HeaderModal>
      <ProductManageModal
        isOpen={isOpen.productManageModal}
        setModalOpen={setModalOpen}
        request={request}
        setRequest={setRequest}
      />
    </div>
  );
}

export default ProductManagePage;
