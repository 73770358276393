import { useEffect, useRef, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useReactToPrint } from 'react-to-print';

import { SUPPLY_REQUEST_STATEMENTS_INITIAL_VALUES } from 'commons/constant';
import supplyApi from 'service/supply/supply';
import useFetch from 'hooks/useFetch';
import { Flex } from 'styles/components';

import Button from 'components/Button';
import Card from 'components/Card';
import QuestionMark from 'components/QuestionMark';
import RequestStatements from 'components/RequestStatements';
import { COLUMNS } from 'components/RequestStatements/commons/constant';

import {
  isSaveButtonEnabled,
  isConfirmButtonDisabled,
  isListLinkButtonDisabled,
} from './commons/helper';

const StatusChangeButtons = ({ statusCode, setTargetButtonRef, onExcelDownloadClick }) => {
  const { productSupplyRequestId = '' } = useParams();
  const componentRef = useRef(null);
  const history = useHistory();
  const columns = useMemo(
    () =>
      COLUMNS.filter(
        ({ accessor }) =>
          accessor !== 'productSupplyUnitPrice' && accessor !== 'receivedExpectPrice'
      ),
    []
  );

  const printPDF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: '납품명세서',
    onBeforeGetContent: async () => {},
  });

  const {
    data: supplyRequestStatements = SUPPLY_REQUEST_STATEMENTS_INITIAL_VALUES,
    execute: getSupplyRequestStatements,
  } = useFetch(supplyApi.getSupplyRequestStatements, {
    initialExecute: false,
  });

  const onReceiveListClick = () => history.push(`/receiving/list?q=${productSupplyRequestId}`);

  useEffect(() => {
    if (productSupplyRequestId && statusCode === 'PETFRIENDS_CONFIRM') {
      getSupplyRequestStatements(productSupplyRequestId);
    }
  }, [productSupplyRequestId, statusCode]);

  const handleSaveClick = () => {
    setTargetButtonRef('전체 저장');
  };

  const handleConfirmClick = () => {
    setTargetButtonRef('발주 확정요청');
  };

  const handleRequestStatementsDownloadClick = () => {
    printPDF();
  };

  return (
    <Card title='발주 업무 처리'>
      <Flex $gap='var(--space-s)' $alignItems='center'>
        {isSaveButtonEnabled(statusCode) && (
          <Button type='submit' onClick={handleSaveClick}>
            전체 저장
          </Button>
        )}
        <Button
          $variant='secondary'
          type='submit'
          onClick={handleConfirmClick}
          disabled={isConfirmButtonDisabled(statusCode)}
        >
          발주 확정요청
        </Button>
        <Button
          $variant='link'
          onClick={onReceiveListClick}
          disabled={isListLinkButtonDisabled(statusCode)}
        >
          입고 내역으로 이동
        </Button>
        <Button
          $variant='success'
          onClick={handleRequestStatementsDownloadClick}
          disabled={statusCode !== 'PETFRIENDS_CONFIRM'}
        >
          납품명세서 다운로드
        </Button>

        <QuestionMark helperText='입고센터 납품 시, 납품명세서를 반드시 출력해 지참하셔야 정상 입고가 가능합니다.' />

        <Button $variant='success' onClick={onExcelDownloadClick}>
          엑셀 다운로드
        </Button>
      </Flex>
      <RequestStatements
        columns={columns}
        ref={componentRef}
        supplyRequestStatements={supplyRequestStatements}
      />
    </Card>
  );
};

StatusChangeButtons.propTypes = {
  statusCode: PropTypes.oneOf([
    'PETFRIENDS_DRAFT',
    'PETFRIENDS_REGISTER',
    'MODIFY',
    'VENDOR_REQUEST',
    'PETFRIENDS_CONFIRM',
    'CANCEL',
    'CONFIRM_CANCEL',
    '',
  ]),
  setTargetButtonRef: PropTypes.func.isRequired,
  onExcelDownloadClick: PropTypes.func.isRequired,
};

export default StatusChangeButtons;
