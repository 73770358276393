import styled from 'styled-components';

const Div = styled.div`
  margin-top: var(--space-l);
`;

const HelperMessage = styled.div`
  background-color: var(--grey100);
  padding: var(--space-m);

  p {
    margin: 0;
  }
`;

export default { Div, HelperMessage };
