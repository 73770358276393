import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { addAllOption, getRadioOption, setObjectState } from 'commons/helper';
import { getNoticeList, setPageIndex, setSearchFilter } from 'commons/store/modules/admin/notice';
import { formatDateAsYYYYMMDD, formatStringAsDate } from 'commons/date';
import useSelectOptions from 'hooks/useSelectOptions';

import { Flex } from 'styles';
import DatePicker from 'components/DatePicker';
import RadioGroup from 'components/RadioGroup';
import Searchbar from 'components/Searchbar';
import Select from 'components/Select';

import { ACTIVE_OPTIONS, INITIAL_SEARCH_OPTIONS } from '../../commons/constant';

const SearchContainer = () => {
  const [searchOptions, setSearchOptions] = useState(INITIAL_SEARCH_OPTIONS);
  const { noticeCategoryCode, noticeSearchCondition } = useSelectOptions([
    'noticeCategoryCode',
    'noticeSearchCondition',
  ]);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNoticeList(searchOptions));
  }, []);

  const handleChange = (e) => setObjectState(setSearchOptions, e.target.name, e.target.value);

  const handleDateChange = (field) => (dates) =>
    setObjectState(setSearchOptions, field, dates.map(formatDateAsYYYYMMDD));
  const handleQueryChange = (e) => setObjectState(setSearchOptions, 'query', e.target.value);

  const handleSearchClick = () => {
    dispatch(setSearchFilter({ path: pathname, searchFilter: searchOptions }));
    dispatch(getNoticeList(searchOptions));
    dispatch(setPageIndex({ path: pathname, pageIndex: 0 }));
  };

  const handleResetClick = () => {
    dispatch(setSearchFilter({ path: pathname, searchFilter: INITIAL_SEARCH_OPTIONS }));
    dispatch(getNoticeList(INITIAL_SEARCH_OPTIONS));
    dispatch(setPageIndex({ path: pathname, pageIndex: 0 }));
    setSearchOptions(INITIAL_SEARCH_OPTIONS);
  };

  return (
    <Flex $flexDirection='column' $gap='var(--space-m)'>
      <Flex $gap='var(--space-m)' $flexWrap='wrap'>
        <Select
          id='active'
          name='active'
          label='활성 여부'
          value={searchOptions.active}
          onChange={handleChange}
          options={addAllOption(ACTIVE_OPTIONS)}
        />
        <Select
          id='noticeCategoryCode'
          name='noticeCategoryCode'
          label='카테고리'
          value={searchOptions.noticeCategoryCode}
          onChange={handleChange}
          options={addAllOption(noticeCategoryCode)}
        />
        <DatePicker
          id='createdDay'
          label='등록일'
          onChange={handleDateChange('createdDay')}
          selected={formatStringAsDate(searchOptions.createdDay[0])}
          startDate={formatStringAsDate(searchOptions.createdDay[0])}
          endDate={formatStringAsDate(searchOptions.createdDay[1])}
          selectsRange
        />
      </Flex>
      <RadioGroup
        title='검색 조건'
        name='noticeSearchCondition'
        radios={getRadioOption(noticeSearchCondition)}
        checkedValue={searchOptions.noticeSearchCondition}
        onCheckedValueChange={handleChange}
      />
      <Searchbar
        name='searchbar'
        value={searchOptions.query}
        onChange={handleQueryChange}
        onClick={handleSearchClick}
        onReset={handleResetClick}
      />
    </Flex>
  );
};

export default SearchContainer;
