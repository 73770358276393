import { useState, forwardRef } from 'react';

import useSelectOptions from 'hooks/useSelectOptions';

import { addAllOption } from 'commons/helper';

import Select from 'components/Select';
import DatePicker from 'components/DatePicker';
import TextareaSearchbar from 'components/TextareaSearchbar';

import { Flex } from 'styles/components';
import { SEARCH_CONDITIONS, SEARCH_OPTIONS_INITIAL_VALUE } from '../../commons/constant';

const AdminReceivingFilter = forwardRef(({ search }, ref) => {
  const [searchOptions, setSearchOptions] = useState(SEARCH_OPTIONS_INITIAL_VALUE);
  const { productSupplyReceivingStatusCode } = useSelectOptions([
    'productSupplyReceivingStatusCode',
  ]);

  const handleSelectChange = (e) => {
    setSearchOptions((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleReceiveAvailableDayChange = (dates) => {
    setSearchOptions((prev) => ({
      ...prev,
      receiveAvailableDay: dates,
    }));
  };

  const handleInboundCompletionDayChange = (dates) => {
    setSearchOptions((prev) => ({
      ...prev,
      inboundCompletionDay: dates,
    }));
  };

  const handleSearchQueryChange = (e) => {
    setSearchOptions((prev) => ({ ...prev, query: e.target.value }));
  };

  const handleCheckedValueChange = (e) => {
    setSearchOptions((prev) => ({ ...prev, searchCondition: e.target.value }));
  };

  const handleSearchClick = () => {
    ref.current = searchOptions;
    search();
  };

  return (
    <>
      <Flex $gap='var(--space-m)'>
        <Select
          id='productSupplyReceivingStatusCode'
          name='productSupplyReceivingStatusCode'
          label='입고 상태'
          value={searchOptions.productSupplyReceivingStatusCode}
          onChange={handleSelectChange}
          options={addAllOption(productSupplyReceivingStatusCode)}
        />
        <DatePicker
          id='receiveAvailableDay'
          label='입고 예정일'
          onChange={handleReceiveAvailableDayChange}
          startDate={searchOptions.receiveAvailableDay[0]}
          endDate={searchOptions.receiveAvailableDay[1]}
          selectsRange
        />
        <DatePicker
          id='inboundCompletionDay'
          label='입고 완료일'
          onChange={handleInboundCompletionDayChange}
          startDate={searchOptions.inboundCompletionDay[0]}
          endDate={searchOptions.inboundCompletionDay[1]}
          selectsRange
        />
      </Flex>
      <TextareaSearchbar
        name='vendorSearchCondition'
        textareaValue={searchOptions.query}
        onTextareaChange={handleSearchQueryChange}
        radios={SEARCH_CONDITIONS}
        onSearchClick={handleSearchClick}
        checkedValue={searchOptions.searchCondition}
        onCheckedValueChange={handleCheckedValueChange}
        placeholder='검색어를 입력해 주세요. 구분 문자 (쉼표, 엔터)로 복수 품목을 검색할 수 있습니다.'
      />
    </>
  );
});

export default AdminReceivingFilter;
