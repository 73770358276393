import { useEffect, useRef, useState } from 'react';

import { formatDateAsYYYYMMDD } from 'commons/date';
import { setQueries } from 'commons/helper';
import useFetch from 'hooks/useFetch';
import instance from 'service';

/**
 * @param {object} params
 * @param {'REGISTERED' | 'CONFIRMED' | 'CANCELED'} params.status
 * @param {'ERROR' | 'CS' | 'BREAK' | 'MISSING' | 'POOR_SALES'} params.reason
 * @param {string} params.centerId
 * @param {Date[]} params.createdDay
 * @param {Date[]} params.completeDay
 * @param {'PURCHASE_ORDER' | 'INBOUND' | 'PRODUCT_ITEM_ID' | 'PRODUCT_ITEM_NAME' | 'REVERSE' | 'OUTBOUND'} params.searchCondition
 * @param {string} searchWord
 */
export const getSCReverseList = async (params) => {
  const { createdDay, completeDay, ...rest } = params;
  const [beforeCreatedDay, afterCreatedDay] = createdDay;
  const [beforeCompleteDay, afterCompleteDay] = completeDay;

  const body = {
    beforeCreatedDay: formatDateAsYYYYMMDD(beforeCreatedDay),
    afterCreatedDay: formatDateAsYYYYMMDD(afterCreatedDay),
    beforeCompleteDay: formatDateAsYYYYMMDD(beforeCompleteDay),
    afterCompleteDay: formatDateAsYYYYMMDD(afterCompleteDay),
    ...rest,
  };

  const queyParams = setQueries(body);
  const { data } = await instance.get(`/api/v2/product/supply/reverses?${queyParams}`);

  return data;
};

export const useSCReverseList = (params) => {
  const { execute } = useFetch(getSCReverseList, { initialExecute: false });
  const [scReverseList, setSCReverseList] = useState([]);
  const total = useRef(0);

  const executeHandler = async () => {
    const { data } = await execute(params);
    total.current = data?.length || 0;
    setSCReverseList(data || []);
  };

  useEffect(() => {
    executeHandler();
  }, []);

  return { scReverseList, total: total.current, executeHandler };
};

/**
 *
 * @param {number} reverseId
 */
export const getSCReverseDetail = async (reverseId) => {
  const { data } = await instance.get(`/api/v2/product/supply/reverses/${reverseId}`);
  return data;
};

export const useSCReverseDetail = (reverseId) => {
  const { execute } = useFetch(getSCReverseDetail, { initialExecute: false });
  const [detail, setDetail] = useState({
    reverse: {},
    purchaseOrder: {},
    outbound: {},
    reverseItems: [],
  });

  const executeHandler = async () => {
    const { data } = await execute(reverseId);
    setDetail(data);
  };

  return { info: detail, executeHandler };
};
