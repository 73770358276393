import { formatDateAsYYYYMMDD } from 'commons/date';
import { returnNullIfEmptyString } from 'commons/helper';
import instance from 'service/interceptor';

const fetchReceivingResultList = async ({
  productSupplyReceivingStatusCode = 'COMPLETION',
  searchCondition = 'PRODUCT_SUPPLY_REQUEST',
  searchWord = '',
  beforeReceiveAvailableDay = null,
  afterReceiveAvailableDay = null,
  beforeInboundCompletionDay = null,
  afterInboundCompletionDay = null,
  pageIndex = 0,
  pageSize = 500,
} = {}) => {
  const { data } = await instance.post('/api/v2/product/supply/inbound/list', {
    productSupplyReceivingStatusCode: returnNullIfEmptyString(productSupplyReceivingStatusCode),
    searchCondition,
    searchWord,
    beforeReceiveAvailableDay: formatDateAsYYYYMMDD(beforeReceiveAvailableDay),
    afterReceiveAvailableDay: formatDateAsYYYYMMDD(afterReceiveAvailableDay),
    beforeInboundCompletionDay: formatDateAsYYYYMMDD(beforeInboundCompletionDay),
    afterInboundCompletionDay: formatDateAsYYYYMMDD(afterInboundCompletionDay),
    pageNumber: pageIndex,
    pageSize,
  });

  return data;
};

const confirmReceivingResultList = async (inboundConfirmRequestItemIdList) => {
  const { data } = await instance.post('/api/v2/product/supply/inbound/result/confirm', {
    inboundConfirmRequestItemIdList,
  });

  return data;
};

export default { fetchReceivingResultList, confirmReceivingResultList };
