import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { addAllOption, getRadioOption, setObjectState } from 'commons/helper';
import useSelectOptions from 'hooks/useSelectOptions';
import { Flex } from 'styles/components';

import DatePicker from 'components/DatePicker';
import Select from 'components/Select';
import TextareaSearchbar from 'components/TextareaSearchbar';

import { getFetchReceivingResultListParams } from '../../commons/helper';
import { SEARCH_OPTIONS_INITIAL_VALUES } from '../../commons/constant';

/**
 * @param {*} props
 */
function SearchContainer({
  setControlledPage,
  setSearchOptionsSnapshot,
  fetchReceivingResultList,
}) {
  const { search } = useLocation();
  const query = new URLSearchParams(search).get('q');
  const [searchOptions, setSearchOptions] = useState({
    ...SEARCH_OPTIONS_INITIAL_VALUES,
    query: query || '',
  });
  const { productSupplyReceivingStatusCode, inboundResultSearchCondition } = useSelectOptions([
    'productSupplyReceivingStatusCode',
    'inboundResultSearchCondition',
  ]);
  const history = useHistory();

  useEffect(() => {
    history.push(`/receiving/list?q=${searchOptions.query}`);
  }, [searchOptions.query]);

  const handleSearchClick = () => {
    setSearchOptionsSnapshot(searchOptions);
    fetchReceivingResultList(getFetchReceivingResultListParams(searchOptions));
    setControlledPage(0);
  };

  const handleChange = (e) => setObjectState(setSearchOptions, e.target.name, e.target.value);

  const handleDateChange = (field) => (dates) => setObjectState(setSearchOptions, field, dates);

  const handleTextareaChange = (e) => setObjectState(setSearchOptions, 'query', e.target.value);

  return (
    <Flex $flexDirection='column' $gap='var(--space-m)'>
      <Flex $gap='var(--space-m)'>
        <Select
          id='productSupplyReceivingStatusCode'
          name='productSupplyReceivingStatusCode'
          label='입고 상태'
          value={searchOptions.productSupplyReceivingStatusCode}
          onChange={handleChange}
          options={addAllOption(productSupplyReceivingStatusCode)}
        />
        <DatePicker
          id='receiveAvailableDay'
          label='입고 예정일'
          onChange={handleDateChange('receiveAvailableDay')}
          startDate={searchOptions.receiveAvailableDay[0]}
          endDate={searchOptions.receiveAvailableDay[1]}
          selectsRange
        />
        <DatePicker
          id='inboundCompletionDay'
          label='입고 완료일'
          onChange={handleDateChange('inboundCompletionDay')}
          startDate={searchOptions.inboundCompletionDay[0]}
          endDate={searchOptions.inboundCompletionDay[1]}
          selectsRange
        />
      </Flex>
      <TextareaSearchbar
        name='inboundResultSearchCondition'
        textareaValue={searchOptions.query}
        onTextareaChange={handleTextareaChange}
        radios={getRadioOption(inboundResultSearchCondition)}
        onSearchClick={handleSearchClick}
        checkedValue={searchOptions.inboundResultSearchCondition}
        onCheckedValueChange={handleChange}
        placeholder='검색어를 입력해 주세요. 구분 문자 (쉼표, 엔터)로 복수 품목을 검색할 수 있습니다.'
      />
    </Flex>
  );
}

SearchContainer.propTypes = {
  setControlledPage: PropTypes.func.isRequired,
  setSearchOptionsSnapshot: PropTypes.func.isRequired,
  fetchReceivingResultList: PropTypes.func.isRequired,
};

export default SearchContainer;
