import styled from 'styled-components';
import { flexRowCenter } from 'styles/commons';

const ModalHeader = styled.div`
  ${flexRowCenter};
  gap: var(--space-m);
`;

const ModalTitle = styled.h3`
  font-size: var(--font-size-xl);
  margin-bottom: 0;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  resize: none;
`;

export default { ModalHeader, ModalTitle, Textarea };
