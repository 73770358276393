export const NOTICE_LIST = '/admin/notice/list';

export const INITIAL_SEARCH_OPTIONS = {
  active: '',
  noticeCategoryCode: '',
  createdDay: [null, null],
  noticeSearchCondition: 'TITLE',
  query: '',
};

export const ACTIVE_OPTIONS = [
  { key: 'Y', value: '활성' },
  { key: 'N', value: '비활성' },
];

export const NOTICE_LIST_COLUMNS = [
  { Header: '공지사항 ID', accessor: 'id' },
  { Header: '카테고리', accessor: 'noticeCategoryCodeValue' },
  { Header: '제목', accessor: 'title' },
  { Header: '활성 여부', accessor: 'active' },
  { Header: '등록일시', accessor: 'createdAt' },
  { Header: '수정일시', accessor: 'updatedAt' },
  { Header: '작성자', accessor: 'workerName' },
];

export const ACTIVE_VALUES = {
  Y: '활성',
  N: '비활성',
};
