/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleError } from 'commons/helper';
import {
  PREPAID_LIST,
  INITIAL_SEARCH_OPTIONS,
} from 'pages/admin/AdminPrepaidPage/commons/constant';

import prepaidApi from 'service/hq/prepaid';

const initialState = {
  [PREPAID_LIST]: {
    searchFilter: INITIAL_SEARCH_OPTIONS,
    tableData: [],
    totalPages: 0,
    totalElements: 0,
    pageIndex: 0,
  },
};

export const getPrepaidList = createAsyncThunk(
  'prepaid/getPrepaidList',
  async (params, { rejectWithValue }) => {
    try {
      const { response } = await prepaidApi.getPrepaidList({
        searchCondition: params.condition,
        searchContent: params.query,
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
      });
      return {
        searchFilter: params,
        content: response?.data?.content,
        totalElements: response?.data?.totalElements,
        totalPages: response?.data?.totalPages,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const slice = createSlice({
  name: 'prepaid',
  initialState,
  reducers: {
    setSearchFilter: (state, { payload }) => {
      state[payload.path].searchFilter = payload.searchFilter;
    },
    setPageIndex: (state, { payload }) => {
      state[payload.path].pageIndex = payload.pageIndex;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPrepaidList.fulfilled, (state, { payload }) => {
      state[PREPAID_LIST].searchFilter = payload.searchFilter;
      state[PREPAID_LIST].tableData = payload.content;
      state[PREPAID_LIST].totalElements = payload.totalElements;
      state[PREPAID_LIST].totalPages = payload.totalPages;
    });
    builder.addCase(getPrepaidList.rejected, (state, { payload }) => {
      handleError(payload);
    });
  },
});

export const { setSearchFilter, setPageIndex } = slice.actions;

export default slice.reducer;
