import { Link as LinkComponent } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Wrapper = styled.aside.attrs({
  className: 'main-sidebar bg-white sidebar-primary border-right',
})`
  border-right: 1px solid #24292f !important;
  background-color: #24292f !important;

  p {
    color: var(--grey900);
  }
`;

const Header = styled(LinkComponent).attrs({ className: 'brand-link' })`
  display: flex;
  justify-content: center;
`;

const Body = styled.div.attrs({ className: 'sidebar' })`
  padding: 0;
`;

const Menu = styled.nav``;

const MenuList = styled.ul.attrs({
  className: 'nav nav-pills nav-sidebar flex-column',
})``;

const MenuItem = styled.li.attrs({ className: 'nav-item has-treeview treeview' })``;

const Link = styled.span.attrs({ className: 'nav-link' })`
  color: var(--grey100) !important;
  font-size: var(--font-size-s);
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  cursor: pointer;

  &:hover {
    background-color: var(--grey800);
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: var(--grey800) !important;
      color: var(--grey50) !important;
    `}
`;

const Group1List = styled.div.attrs({ className: 'nav-item' })``;

const Group1ListLink = styled.p.attrs()`
  padding: 0.75rem 1rem;
  cursor: auto;
  margin: 0;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  display: none;

  span {
    font-weight: bold;
    font-size: var(--font-size-xl);
  }
`;

const Group2List = styled.div.attrs({ className: 'nav-item treeview-menu' })`
  p {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-m);
  }
`;

const Group2ListLink = styled.p.attrs({ className: 'nav-link' })`
  color: rgb(255, 255, 255) !important;
  margin-bottom: 0px;
  border-radius: 0px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 0 !important;
  display: none;

  &:hover {
    background: rgba(255, 255, 255, 0.3) !important;
  }

  span {
    color: var(--grey700);
  }
`;

const Group2Icon = styled.i.attrs((props) => ({ className: props.icon }))`
  width: 1.09375rem !important;
  font-size: 14px !important;
  margin: 0px 0.75rem 0px 0px !important;
  text-align: center !important;
`;

const RightArrow = styled.i.attrs({ className: 'right fa fa-angle-left' })`
  top: 50% !important;
  margin-top: -5px;
  color: #828282;
  font-size: 11px;
  right: 1.2rem !important;
`;

const Group2TreeView = styled.ul.attrs({ className: 'nav nav-treeview' })`
  display: block !important;
`;

export {
  Wrapper,
  Header,
  Body,
  Menu,
  MenuList,
  MenuItem,
  Link,
  Group1List,
  Group1ListLink,
  Group2List,
  Group2ListLink,
  Group2Icon,
  RightArrow,
  Group2TreeView,
};
