import styled from 'styled-components';

export const Form = styled.form`
  .card-body {
    height: 35vh;
  }

  textarea {
    resize: none;
  }
`;

export default { Form };
