import PropTypes from 'prop-types';

import toastify from 'commons/toast';

import Button from 'components/Button';
import RadioGroup from 'components/RadioGroup';

import { Flex } from 'styles/components';

import * as Styled from './styled';

const TextareaSearchbar = ({
  textareaValue,
  onTextareaChange,
  onSearchClick,
  onResetClick,
  checkedValue,
  title = '검색 조건',
  placeholder = '검색어를 입력해 주세요. 거래처 번호인 경우, 복수 품목을 검색 할 수 있습니다. (Enter 또는 ","로 구분, 최대 50개)',
  ...props
}) => {
  const ids = ['PRODUCT_ITEM_ID', 'VENDOR_ID', 'PRODUCT_ITEM'];

  const handleSearchClick = () => {
    if (ids.includes(checkedValue) && /[^\d, \n]/g.test(textareaValue)) {
      toastify('숫자만 입력할 수 있습니다.', { variant: 'error' });
      return;
    }

    onSearchClick();
  };

  return (
    <Styled.Wrapper>
      <Flex $gap='var(--space-m)' $flexDirection='column'>
        <Flex $justifyContent='space-between' $alignItems='flex-end'>
          <RadioGroup title={title} checkedValue={checkedValue} {...props} />
          <Flex $gap='var(--space-s)'>
            <Button onClick={handleSearchClick}>검색</Button>
            {onResetClick && (
              <Button onClick={onResetClick} $variant='light'>
                초기화
              </Button>
            )}
          </Flex>
        </Flex>
        <Styled.TextareaWrapper>
          <Styled.Textarea
            placeholder={placeholder}
            value={textareaValue}
            onChange={onTextareaChange}
          />
        </Styled.TextareaWrapper>
      </Flex>
    </Styled.Wrapper>
  );
};

TextareaSearchbar.propTypes = {
  textareaValue: PropTypes.string.isRequired,
  onTextareaChange: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  onResetClick: PropTypes.func,
  checkedValue: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
};

export default TextareaSearchbar;
