import dayjs from 'dayjs';
import styled from 'styled-components';
import Caution from 'icons/png/caution_yellow.png';

const DayCellContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CautionWrapper = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    margin-top: -4px;
    height: 100%;
  }
`;

const formatting = (date) => dayjs(date).format('YYYY-MM-DD');

function DayCellContent({ dayNumberText, events, date }) {
  const targetDate = events.find(({ start }) => formatting(start) === formatting(date));
  const isCautionIconShow = targetDate ? targetDate.extendedProps.overflowed : false;

  return (
    <DayCellContentWrapper>
      <CautionWrapper>
        {isCautionIconShow && <img src={Caution} alt='일자별 sku를 넘었습니다.' />}
      </CautionWrapper>
      <span>{dayNumberText}</span>
    </DayCellContentWrapper>
  );
}

export default DayCellContent;
