import { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { handleUserLogin } from 'service/auth';
import toastify from 'commons/toast';
import { handleError } from 'commons/helper';
import { signInWithGooglePopup } from 'commons/firebase/helper';
import { setUser } from 'commons/store/modules/user';

import Spinner from 'components/Spinner';

import adminImage from 'icons/png/Vector.png';
import { useHistory } from 'react-router-dom';

import { useAuthInfo } from 'hooks/useSyncAuth';
import * as Styled from './styled';

function LoginPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { storage, cookie, update } = useAuthInfo(['accessToken', 'refreshToken']);

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const emailRef = useRef(null);

  const handleSetUser = (user) => dispatch(setUser(user));
  const handleIdChange = (e) => setId(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const replace = () => history.replace('/');

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await handleUserLogin({
        account: id,
        password,
        originDomain: 'SUPPLY',
      });

      setLoading(false);
      const { accessToken, refreshToken } = response;
      handleSetUser({
        accessToken,
        refreshToken,
      });
      update({ accessToken });
      replace();
    } catch (error) {
      let message = '로그인에 실패하였습니다, 네트워크를 확인해주세요.';

      if (error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }

      setErrorMessage(message);
      setLoading(false);
    }
  };

  const handleEmailCopyButtonClick = () => {
    const email = emailRef.current.innerText;
    navigator.clipboard.writeText(email);
    toastify('납품문의 담당 메일주소가 복사되었습니다.', { variant: 'success' });
  };

  const handleAdminLoginClick = async () => {
    try {
      const result = await signInWithGooglePopup();
      const { accessToken, refreshToken } = result;
      handleSetUser({
        accessToken,
        refreshToken,
      });
      update({ accessToken });
      replace();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    const [accessToken, refreshToken] = cookie;

    if (accessToken && refreshToken) {
      handleSetUser({
        accessToken,
        refreshToken,
      });
      if (!storage) update({ accessToken });
      replace();
    }
  }, [storage]);

  return (
    <Styled.Wrapper>
      <Styled.AdminLoginButton onClick={handleAdminLoginClick}>
        <img src={adminImage} alt='admin' />
        관리자 로그인
      </Styled.AdminLoginButton>
      <Styled.Form>
        <Styled.Title>발주 관리자 로그인</Styled.Title>
        <Styled.Description>
          반려동물 1등 쇼핑몰에서
          <br />
          50만명의 펫프렌즈 고객을 만날 수 있어요!
        </Styled.Description>
        <Styled.Input
          type='text'
          placeholder='아이디를 입력해주세요.'
          value={id}
          onChange={handleIdChange}
        />
        <Styled.Input
          type='password'
          placeholder='비밀번호를 입력해주세요.'
          value={password}
          onChange={handlePasswordChange}
          autoComplete='on'
        />
        <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>
        <Styled.Button $size='large' onClick={handleLogin} disabled={!id || !password || loading}>
          {loading ? <Spinner $variant='light' $size='small' /> : '로그인'}
        </Styled.Button>
        <Styled.HelperText>
          아이디/비밀번호를 잊으셨다면 담당 MD분께 연락 바랍니다.
        </Styled.HelperText>
        <Styled.BottomWrapper>
          <Styled.Highlight>납품문의를 도와드려요!</Styled.Highlight>
          <span>
            펫프렌즈 납품문의 신청:
            <span ref={emailRef}>md@pet-friends.co.kr</span>
          </span>
          <button type='button' onClick={handleEmailCopyButtonClick}>
            메일복사
          </button>
        </Styled.BottomWrapper>
      </Styled.Form>

      <Styled.ImageWrapper>
        <img
          src='https://test-image.pet-friends.co.kr/seller/info.png'
          alt='문제가 생기셨나요? 담당 MD가 있는 판매자께서는 담당MD에게 연락 바랍니다.'
        />
      </Styled.ImageWrapper>

      <Styled.Link
        href='https://seller.pet-friends.co.kr/'
        target='_blank'
        rel='noopener noreferrer'
      >
        입점거래를 하는 파트너신가요?
      </Styled.Link>
    </Styled.Wrapper>
  );
}

export default LoginPage;
