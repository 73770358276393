import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;

  > div {
    &:first-child {
      max-width: 640px;
    }

    flex: 1;
    height: calc(100vh - 83.5px);
    margin-bottom: 0;

    &:nth-child(1) {
      margin-left: 0;
    }
    &:nth-child(2) {
      margin-right: 0;
    }
  }
`;

export default { Wrapper };
