import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;

  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -15px;
    right: -15px;
    border-bottom: 1px solid #b1b1b1;
  }
`;

export default { Header };
