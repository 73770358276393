import PriceEditableCell from '../components/PriceEditableCell';
import ItemEditableCell from '../components/ItemEditableCell';

export const PRICE_COLUMNS = [
  { Header: '품목ID', accessor: 'productItemId' },
  { Header: '품목명', accessor: 'productName' },
  { Header: '상태', accessor: 'productStatus' },

  { Header: '과세 구분', accessor: 'beforeTax' },
  { Header: '기준 공급가(₩)', accessor: 'beforeProductStandardUnitPrice' },
  { Header: '기준 공급가 수정(₩)', accessor: 'afterProductStandardUnitPrice' },
  { Header: '최소 주문 수량(EA)', accessor: 'beforeMinimumOrderQuantity' },
  { Header: '최소 주문 수량 수정(EA)', accessor: 'afterMinimumOrderQuantity' },

  { Header: '변경 사유', accessor: 'reasonForChange' },
  { Header: '반영일자', accessor: 'effectiveDate' },
  { Header: '펫프 담당자 코멘트', accessor: 'petfriendsComment' },
];

export const ITEM_COLUMNS = [
  { Header: '품목ID', accessor: 'productItemId' },
  { Header: '품목명', accessor: 'productName' },
  { Header: '상태', accessor: 'productStatus' },

  { Header: '품목 바코드', accessor: 'itemBarcode' },
  { Header: '박스 바코드', accessor: 'boxBarcode' },
  { Header: '박스 내입수', accessor: 'boxUnit' },
  { Header: '팔레트 내입수', accessor: 'pltUnit' },
  { Header: '가로(cm)', accessor: 'width' },
  { Header: '세로(cm)', accessor: 'length' },
  { Header: '높이(cm)', accessor: 'height' },
  { Header: '중량(kg)', accessor: 'weight' },
  { Header: '용량(ml)', accessor: 'volume' },
  { Header: '보관온도', accessor: 'storageTemperature' },

  { Header: '변경 사유', accessor: 'reasonForChange' },
  { Header: '반영일자', accessor: 'effectiveDate' },
  { Header: '펫프 담당자 코멘트', accessor: 'petfriendsComment' },
];

export const COLUMNS = {
  '가격 수정 요청': PRICE_COLUMNS,
  '품목 정보 수정 요청': ITEM_COLUMNS,
};

export const EDITABLE_CELLS = {
  '가격 수정 요청': PriceEditableCell,
  '품목 정보 수정 요청': ItemEditableCell,
};

export default { PRICE_COLUMNS, ITEM_COLUMNS, COLUMNS, EDITABLE_CELLS };
