export const COLUMNS = [
  { Header: '품목ID', accessor: 'productItemId' },
  { Header: '품목명', accessor: 'productName' },
  { Header: '상태', accessor: 'productStatusName' },
  { Header: '분류', accessor: 'productGroupName' },
  { Header: '기준 공급가(₩)', accessor: 'productStandardUnitPrice' },
  { Header: '최소주문수량(EA)', accessor: 'minimumOrderQuantity' },
];

export default { COLUMNS };
