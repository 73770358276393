import Underline from 'components/Underline';

export const COLUMNS = [
  { Header: '거래처 번호', accessor: 'vendorId' },
  { Header: '거래처 명', accessor: 'displayName' },
  { Header: '사업자등록번호', accessor: 'businessNumber' },
  { Header: '계좌번호', accessor: 'accountNumber' },
  { Header: '은행', accessor: 'bankValue' },
  { Header: '예금주', accessor: 'depositor' },
  { Header: '선지급금 잔고(₩)', accessor: 'totalPrice', Cell: Underline },
  { Header: '정산담당자', accessor: 'moneyManagerName' },
  { Header: '발주담당자', accessor: 'supplyManagerName' },
];

export const PREPAID_LIST = '/admin/prepaid/list';

export const INITIAL_SEARCH_OPTIONS = { query: '', condition: 'VENDOR_ID' };

export const SELECT_OPTIONS = ['productSupplyPrepaidSearchCondition'];
