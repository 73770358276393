import PropTypes from 'prop-types';

import { addAllOption, setObjectState } from 'commons/helper';
import useSelectOptions from 'hooks/useSelectOptions';

import Choice from 'components/Choice';
import DatePicker from 'components/DatePicker';
import RadioGroup from 'components/RadioGroup';
import Select from 'components/Select';

import { Flex } from 'styles/components';

import { PRODUCT_SUPPLY_REQUEST_STATUS_CODE, SEARCH_CONDITION } from './commons/constant';

const SearchOptions = ({ searchOptions, setSearchOptions }) => {
  const { statusCodes } = searchOptions;
  const selectOptions = useSelectOptions(['purchaseOrderType']);

  const handleSelectChange = (e) => setObjectState(setSearchOptions, e.target.name, e.target.value);

  const handleProductSupplyRequestDayChange = (dates) =>
    setObjectState(setSearchOptions, 'productSupplyRequestDay', dates);

  const handleReceiveAvailableDayChange = (dates) =>
    setObjectState(setSearchOptions, 'receiveAvailableDay', dates);

  const setStatusCodes = (value) => {
    setObjectState(
      setSearchOptions,
      'statusCodes',
      typeof value === 'function' ? value(searchOptions.statusCodes) : value
    );
  };

  const handleChoice = (e) => {
    const value = e.target.dataset.key;
    if (value === '') {
      setStatusCodes(['']);
      return;
    }

    if (statusCodes.includes(value) && statusCodes.length > 1) {
      setStatusCodes((prevStatusCodes) =>
        prevStatusCodes.filter((statusCode) => statusCode !== value)
      );
    } else {
      setStatusCodes((prevStatusCodes) =>
        [...prevStatusCodes, value].filter((statusCode) => statusCode !== '')
      );
    }
  };

  const handleCheckedValueChange = (e) =>
    setObjectState(setSearchOptions, 'condition', e.target.value);

  return (
    <>
      <Flex $gap='var(--space-m)'>
        <Select
          id='productSupplyTypeCode'
          name='productSupplyTypeCode'
          label='발주 유형'
          value={searchOptions.productSupplyTypeCode}
          onChange={handleSelectChange}
          options={addAllOption(selectOptions.purchaseOrderType)}
        />
        <DatePicker
          id='productSupplyRequestDay'
          label='발주 등록일'
          onChange={handleProductSupplyRequestDayChange}
          startDate={searchOptions.productSupplyRequestDay[0]}
          endDate={searchOptions.productSupplyRequestDay[1]}
          selectsRange
        />
        <DatePicker
          id='receiveAvailableDay'
          label='입고 예정일'
          onChange={handleReceiveAvailableDayChange}
          startDate={searchOptions.receiveAvailableDay[0]}
          endDate={searchOptions.receiveAvailableDay[1]}
          selectsRange
        />
      </Flex>

      <Choice
        title='발주 상태'
        options={addAllOption(PRODUCT_SUPPLY_REQUEST_STATUS_CODE)}
        value={searchOptions.statusCodes}
        onClick={handleChoice}
      />
      <RadioGroup
        title='검색 조건'
        radios={SEARCH_CONDITION}
        name='searchCondition'
        checkedValue={searchOptions.condition}
        onCheckedValueChange={handleCheckedValueChange}
      />
    </>
  );
};

SearchOptions.propTypes = {
  searchOptions: PropTypes.shape({
    productSupplyTypeCode: PropTypes.string,

    productSupplyRequestDay: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    receiveAvailableDay: PropTypes.arrayOf(PropTypes.instanceOf(Date)),

    statusCodes: PropTypes.arrayOf(PropTypes.string).isRequired,

    condition: PropTypes.oneOf(['PRODUCT_SUPPLY_REQUEST', 'PRODUCT_ITEM']),
    query: PropTypes.string,
  }).isRequired,
  setSearchOptions: PropTypes.func.isRequired,
};

export default SearchOptions;
