import styled from 'styled-components';

import _Button from 'components/Button';

export const Div = styled.div``;

export const Button = styled(_Button)`
  position: fixed;
  bottom: 6%;
  right: 6%;
`;

export default { Div, Button };
