import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.span`
  font-size: var(--font-size-s);
`;

const Count = styled.span`
  color: var(--blue500);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-medium);
  line-height: 1.2;
`;

const Unit = styled.span`
  font-size: var(--font-size-s);
  line-height: 1.2;
`;

export { Wrapper, Label, Count, Unit };
