import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--base-height);
  margin-bottom: var(--space-l);
`;

export default { Wrapper };
