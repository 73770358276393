import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { numberExtractor, thousandSeparator } from 'commons/helper';
import Select from 'components/Select';
import Input from 'components/Input';
import DatePicker from 'components/DatePicker';
import { formatDateAsYYYYMMDD, formatStringAsDate } from 'commons/date';

const IS_FREE_GIFT_OPTIONS = [
  {
    key: 'N',
    value: 'N',
  },
  {
    key: 'Y',
    value: 'Y',
  },
];

const EditableCell = ({
  value: initialValue,
  row,
  column: { id: columnName },
  updateMyData,
  statusCode,
  taxSettlement,
}) => {
  const { index: rowIndex } = row;
  const { productSupplyUnitPrice, requestQuantity, isFreeGift } = row.original;
  const price = productSupplyUnitPrice * requestQuantity;

  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    updateMyData(rowIndex, 'productSupplyPrice', price);
  }, [productSupplyUnitPrice, requestQuantity]);

  useEffect(() => {
    if (isFreeGift === 'Y') {
      updateMyData(rowIndex, 'productSupplyUnitPrice', 0);
    }
  }, [isFreeGift]);

  const handleChange = (e) => setValue(e.target.value);
  const handleBlur = () => updateMyData(rowIndex, columnName, value);
  const handleNumberInputChange = (e) => {
    setValue(numberExtractor(e.target.value));
  };

  const handleDateChange = (date) => {
    setValue(formatDateAsYYYYMMDD(date));
    updateMyData(rowIndex, columnName, formatDateAsYYYYMMDD(date));
  };

  const handleDateChangeRaw = (e) => {
    if (!e.target.value) return false;

    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    const inputValue = e.target.value;

    const match = inputValue.match(/^(\d{4})(\d{2})(\d{2})$/);

    if (match) {
      // eslint-disable-next-line no-unused-vars
      const [_, year, month, day] = match;
      handleDateChange(`${year}-${month}-${day}`);
    }
  };

  const handleSelectChange = (e) => {
    setValue(e.target.value);
    updateMyData(rowIndex, columnName, e.target.value);
  };

  const isDisabledStatus = (currentStatusCode) =>
    currentStatusCode === 'VENDOR_REQUEST' ||
    currentStatusCode === 'PETFRIENDS_CONFIRM' ||
    currentStatusCode === 'CANCEL' ||
    currentStatusCode === 'CONFIRM_CANCEL';

  if (columnName === 'isFreeGift') {
    return (
      <Select
        id='isFreeGift'
        name='isFreeGift'
        value={value}
        onChange={handleSelectChange}
        onBlur={handleBlur}
        options={IS_FREE_GIFT_OPTIONS}
        required
        disabled={isDisabledStatus(statusCode)}
      />
    );
  }

  if (columnName === 'productSupplyUnitPrice') {
    const productSupplyUnitPriceValue = isFreeGift === 'Y' ? 0 : thousandSeparator(value);

    return (
      <Input
        type='text'
        value={productSupplyUnitPriceValue}
        onChange={handleNumberInputChange}
        onBlur={handleBlur}
        required
        maxLength={8}
        disabled={taxSettlement === 'Y'}
      />
    );
  }

  if (columnName === 'requestQuantity') {
    return (
      <Input
        type='text'
        value={thousandSeparator(value)}
        onChange={handleNumberInputChange}
        onBlur={handleBlur}
        required
        maxLength={8}
        disabled={isDisabledStatus(statusCode)}
      />
    );
  }

  if (columnName === 'shelfLifeDay') {
    return (
      <DatePicker
        id={columnName}
        onChangeRaw={handleDateChangeRaw}
        onChange={handleDateChange}
        selected={formatStringAsDate(value)}
        disabled={isDisabledStatus(statusCode)}
      />
    );
  }

  if (columnName === 'petfriendsComment') {
    return (
      <Input
        type='text'
        value={value ?? ''}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={200}
        placeholder='발주 품목을 확인하고 입력해 주세요'
        disabled={isDisabledStatus(statusCode)}
      />
    );
  }

  if (columnName === 'productSupplyPrice') {
    return thousandSeparator(price);
  }

  return value;
};

EditableCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  row: PropTypes.shape({
    index: PropTypes.number,
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  updateMyData: PropTypes.func.isRequired,
  statusCode: PropTypes.oneOf([
    'PETFRIENDS_DRAFT',
    'PETFRIENDS_REGISTER',
    'MODIFY',
    'VENDOR_REQUEST',
    'PETFRIENDS_CONFIRM',
    'CONFIRM_CANCEL',
    'CANCEL',
    '',
  ]),
  taxSettlement: PropTypes.oneOf(['Y', 'N']),
};

export default EditableCell;
