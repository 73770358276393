export const PAYMENT_INFO_SELECTS = [
  { name: 'paymentMethod', label: '결제 방법' },
  { name: 'paymentDate', label: '결제 일' },
  { name: 'bank', label: '은행' },
];

export const PAYMENT_INFO_INPUT_LABELS = [
  {
    name: 'accountNumber',
    label: '계좌번호',
    placeholder: "'-' 포함",
    type: 'tel',
    pattern: '[0-9-]+',
    helperText: "숫자 또는 '-'를 입력해 주세요.",
  },
  { name: 'depositor', label: '예금주' },
];

export default { PAYMENT_INFO_SELECTS, PAYMENT_INFO_INPUT_LABELS };
