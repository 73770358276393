import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'commons/helper';

import { Flex } from 'styles';
import Input from 'components/Input';

import Styled from './styled';

const FileUpload = ({ onChange, multiple, buttonName = 'browse', accept = '.xlsx', $width }) => {
  const [fileName, setFileName] = useState([]);
  const formRef = useRef();

  const handleInputChange = (e) => {
    const [...fileList] = e.target.files;
    const nameList = fileList.map((file) => file.name).join(',');
    setFileName(nameList);

    onChange(e);
  };

  const handleFileResetClick = () => {
    formRef.current.reset();
    setFileName([]);
  };

  return (
    <Flex $alignItems='center' $gap='var(--space-s)'>
      <form ref={formRef}>
        <Input
          onChange={handleInputChange}
          $display='none'
          $width={$width}
          id='input-file'
          type='file'
          multiple={multiple}
          accept={accept}
        />
      </form>
      <Styled.InputWrapper>
        <Input readOnly placeholder='파일을 선택해주세요.' value={fileName} $width={$width} />
        {!isEmpty(fileName) && (
          <button type='button' onClick={handleFileResetClick}>
            ×
          </button>
        )}
      </Styled.InputWrapper>
      <Styled.Label htmlFor='input-file' $variant='light'>
        {buttonName}
      </Styled.Label>
    </Flex>
  );
};

export default FileUpload;

FileUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  buttonName: PropTypes.string,
  accept: PropTypes.string,
  $width: PropTypes.string,
};
