/* eslint-disable react/no-array-index-key */
import { memo } from 'react';
import PropTypes from 'prop-types';

const SettlementDetailFooter = memo(({ noticeList = [] }) => {
  return (
    <div>
      <p>
        <b>유의사항</b>
      </p>
      <dl>
        {noticeList.map((notice, index) => {
          return <li key={`notice-${index}`}>{notice}</li>;
        })}
      </dl>
    </div>
  );
});

SettlementDetailFooter.propTypes = {
  noticeList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default SettlementDetailFooter;
