import styled from 'styled-components';
import ResultHeadeComponent from 'components/ResultHeader';

export const ResultHeader = styled(ResultHeadeComponent)`
  margin-top: 0;
`;

export const TableWrapper = styled.div``;

export default { ResultHeader, TableWrapper };
