import styled, { css } from 'styled-components';

import { REQUEST_STATUS_CODE } from 'commons/constant';
import { styleSelector } from 'commons/helper';

import CardComponent from 'components/Card';

const Flex = styled.div`
  display: flex;
  ${styleSelector}
  ${({ $spacer }) =>
    $spacer &&
    css`
      gap: var(--space-l);
      margin-bottom: var(--space-l);
    `};
`;

const Row = styled.div.attrs({
  className: 'row',
})`
  margin: 0;
`;

const Card = styled(CardComponent).attrs({
  className: 'col-md',
})``;

const ResultHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-l);
  justify-content: space-between;
  align-items: center;
  margin-top: var(--space-l);

  p {
    margin: 0;
  }

  div:last-child {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-l);
  }
`;

const ResultBody = styled.div`
  margin-top: var(--space-l);
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const SectionTitle = styled.h3`
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-semibold);
  margin-bottom: var(--space-m);
`;

const Status = styled.mark`
  font-weight: var(--font-weight-semibold);
  color: ${({ $status }) => {
    const colorTable = {
      [REQUEST_STATUS_CODE.REQUEST]: 'var(--blue500)',
      [REQUEST_STATUS_CODE.REJECT]: 'var(--red500)',
      [REQUEST_STATUS_CODE.CANCEL]: 'var(--red500)',
      [REQUEST_STATUS_CODE.APPROVE]: 'var(--green500)',
      [REQUEST_STATUS_CODE.SAVE]: 'var(--teal500)',
    };

    return colorTable[$status] || 'var(--grey900)';
  }};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: black;
  ${styleSelector}
`;

export { Flex, Row, Card, ResultHeader, ResultBody, TableWrapper, SectionTitle, Status, Divider };
