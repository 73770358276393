import { useHistory } from 'react-router-dom';

import Button from 'components/Button';

import Styled from './styled';

const ButtonContainer = () => {
  const history = useHistory();

  const handleListClick = () => history.push('/admin/notice/list');

  return (
    <Styled.Div>
      <Button $variant='link' onClick={handleListClick}>
        목록
      </Button>
      <Button type='submit'>저장</Button>
    </Styled.Div>
  );
};

export default ButtonContainer;
