import styled from 'styled-components';

export const Div = styled.div`
  margin-top: var(--space-l);
`;

const Category = styled.span`
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-semibold);
`;

export default { Div, Category };
