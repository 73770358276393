import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Input from 'components/Input';
import NumericCell from 'components/NumericCell/NumericCell';
import { NUMERIC_COLUMN_NAMES } from '../../commons/constant';

const EditableCell = ({
  value: initialValue,
  row,
  column: { id: columnName },
  updateMyData,
  statusValue,
}) => {
  const { index: rowIndex } = row;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e) => setValue(e.target.value);
  const handleBlur = () => updateMyData(rowIndex, columnName, value);

  if (columnName === 'petfriendsComment' && statusValue === '요청 중') {
    return (
      <Input
        type='text'
        value={value ?? ''}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={200}
      />
    );
  }

  if (NUMERIC_COLUMN_NAMES.includes(columnName)) {
    return <NumericCell value={value} />;
  }

  return value ?? '';
};

EditableCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  row: PropTypes.shape({
    index: PropTypes.number,
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  updateMyData: PropTypes.func.isRequired,
  statusValue: PropTypes.string,
};

export default EditableCell;
