import CompleteTableContainer from './containers/CompleteTableContainer';
import SearchFilterContainer from './containers/SearchFilterContainer';
import ModalContainer from './containers/ModalContainer';

/**
 * @param {*} props
 */
const SettlementCompletePage = (props = {}) => {
  return (
    <>
      <SearchFilterContainer {...props} />
      <CompleteTableContainer {...props} />
      <ModalContainer {...props} />
    </>
  );
};

export default SettlementCompletePage;
