import PropTypes from 'prop-types';

import Radio from 'components/Radio';

import * as Styled from './styled';

const RadioGroup = ({ title, radios, name, checkedValue, onCheckedValueChange, ...props }) => {
  return (
    <Styled.Wrapper>
      <legend>{title}</legend>
      <div>
        {radios.map(({ id, label, value }) => (
          <Radio
            key={id}
            name={name}
            id={id}
            label={label}
            value={value}
            onChange={onCheckedValueChange}
            checked={value === checkedValue}
            {...props}
          />
        ))}
      </div>
    </Styled.Wrapper>
  );
};

RadioGroup.propTypes = {
  title: PropTypes.string.isRequired,
  radios: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  checkedValue: PropTypes.string.isRequired,
  onCheckedValueChange: PropTypes.func,
};

export default RadioGroup;
