import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getRadioOption, setObjectState } from 'commons/helper';
import {
  getVendorList,
  setPageIndex,
  setSearchFilter,
} from 'commons/store/modules/admin/vendorProduct';
import useSelectOptions from 'hooks/useSelectOptions';
import toastify from 'commons/toast';

import RadioGroup from 'components/RadioGroup';
import Searchbar from 'components/Searchbar';

import Styled from './styled';
import {
  INITIAL_SEARCH_OPTIONS,
  VENDOR_PRODUCT_LIST,
  SELECT_OPTIONS,
} from '../../commons/constant';

const VendorSearchContainer = () => {
  const [searchOptions, setSearchOptions] = useState(INITIAL_SEARCH_OPTIONS);
  const { vendorSearchCondition } = useSelectOptions(SELECT_OPTIONS);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVendorList(searchOptions));
  }, []);

  const handleCheckedValueChange = (e) =>
    setObjectState(setSearchOptions, e.target.name, e.target.value);
  const handleSearchQueryChange = (e) => setObjectState(setSearchOptions, 'query', e.target.value);

  const handleSearchClick = () => {
    if (searchOptions.condition === 'VENDOR_ID' && /[^\d]/g.test(searchOptions.query)) {
      toastify('숫자만 입력할 수 있습니다.', { variant: 'error' });
      return;
    }

    dispatch(setSearchFilter({ path: VENDOR_PRODUCT_LIST, searchFilter: searchOptions }));
    dispatch(getVendorList(searchOptions));
    dispatch(setPageIndex({ path: VENDOR_PRODUCT_LIST, pageIndex: 0 }));
  };

  const handleResetClick = () => {
    dispatch(setSearchFilter({ path: VENDOR_PRODUCT_LIST, searchFilter: INITIAL_SEARCH_OPTIONS }));
    dispatch(getVendorList(INITIAL_SEARCH_OPTIONS));
    dispatch(setPageIndex({ path: VENDOR_PRODUCT_LIST, pageIndex: 0 }));
    setSearchOptions(INITIAL_SEARCH_OPTIONS);
  };

  return (
    <Styled.Div>
      <RadioGroup
        title='검색 조건'
        radios={getRadioOption(vendorSearchCondition)}
        name='condition'
        checkedValue={searchOptions.condition}
        onCheckedValueChange={handleCheckedValueChange}
      />
      <Searchbar
        name='vendorSearch'
        value={searchOptions.query}
        onChange={handleSearchQueryChange}
        onClick={handleSearchClick}
        onReset={handleResetClick}
      />
    </Styled.Div>
  );
};

export default VendorSearchContainer;
