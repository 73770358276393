import { useEffect, useRef, useState } from 'react';

import supplyApi from 'service/hq/supply';
import {
  downloadSupplyRequestList,
  handleError,
  isEmpty,
  setDuplicatePreventClick,
} from 'commons/helper';
import toastify from 'commons/toast';

import useRouter from 'hooks/useRouter';
import { useGlobalModals } from 'hooks/pages';
import VendorInfoForm from './components/VendorInfoForm';
import RequestInfoForm from './components/RequestInfoForm';
import StatusChangeButtons from './components/StatusChangeButtons';
import ReceiveInfoForm from './components/ReceiveInfoForm';
import CommentInfoForm from './components/CommentInfoForm';
import ItemAndPriceInfoForm from './components/ItemAndPriceInfoForm';
import TransportInfoForm from './components/TransportInfoForm';
import { MAPPING_STATUS_CODE, SUPPLY_REQUEST_DETAIL_INITIAL_VALUES } from './commons/constant';
import HistoryListForm from './components/HistoryListForm';
import HelperButtons from './components/HelperButtons';

const AdminSupplyRegisterPage = () => {
  const clickDisabled = useRef(false);
  const [supplyRequestDetail, setSupplyRequestDetail] = useState(
    SUPPLY_REQUEST_DETAIL_INITIAL_VALUES
  );
  const [hasError, setHasError] = useState(false);
  const {
    pushWithSearchParams,
    params: { productSupplyRequestId = '' },
  } = useRouter();

  const targetButtonRef = useRef();

  const { 1: setGlobalModalOpen } = useGlobalModals();

  const getSupplyRequestDetail = async (id) => {
    try {
      const { response } = await supplyApi.getSupplyRequestDetail(id);
      setSupplyRequestDetail(response.data);
    } catch (error) {
      setHasError(true);
    }
  };

  useEffect(() => {
    if (productSupplyRequestId) {
      getSupplyRequestDetail(productSupplyRequestId);
    }
  }, [productSupplyRequestId]);

  const pushToAdminSupplyManagePage = () => pushWithSearchParams('/admin/supply/list');

  if (hasError) {
    return null;
  }

  const updateStatus = async (statusCode, successMessage, rejectYn) => {
    try {
      const { response } = await supplyApi.saveSupplyRequestDetail({
        productSupplyRequestId,
        supplyRequestDetail,
        statusCode,
        rejectYn,
      });
      toastify(successMessage, { variant: 'success' });
      setSupplyRequestDetail(response.data);
    } catch (error) {
      handleError(error);
    }
  };

  const getValidItemList = (prevItemList) =>
    prevItemList.filter(({ active }) => active === 'Y' || active === null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!supplyRequestDetail.requestInfo.productSupplyRequestDay) {
      toastify('발주 요청일을 등록해 주세요.', { variant: 'warn' });
      return;
    }
    if (!supplyRequestDetail.vendorInfo.vendorId) {
      toastify('거래처 등록을 진행해 주세요.', { variant: 'warn' });
      return;
    }
    if (isEmpty(getValidItemList(supplyRequestDetail.itemList))) {
      toastify('품목 등록을 진행해 주세요.', { variant: 'warn' });
      return;
    }

    setDuplicatePreventClick(
      clickDisabled,
      async () => {
        if (targetButtonRef.current === '저장') {
          try {
            const data = await supplyApi.saveSupplyRequestDetail({
              productSupplyRequestId,
              supplyRequestDetail,
              statusCode:
                MAPPING_STATUS_CODE[supplyRequestDetail.requestInfo.statusCode] ||
                supplyRequestDetail.requestInfo.statusCode,
              confirmYn: 'N',
            });
            if (!data) {
              return;
            }

            toastify('저장되었습니다.', { variant: 'success' });
            pushToAdminSupplyManagePage();
          } catch (error) {
            handleError(error);
          }
        } else if (targetButtonRef.current === '거래처 전송') {
          updateStatus('PETFRIENDS_REGISTER', '거래처 전송 되었습니다.');
        }
      },
      500
    );
  };

  const handleApproveClick = () => updateStatus('PETFRIENDS_CONFIRM', '확정 승인 되었습니다.');
  const handleRejectClick = () => updateStatus('MODIFY', '확정 반려 되었습니다.', 'Y');
  const handleCancelClick = () => {
    if (window.confirm('취소된 발주는 되돌릴 수 없습니다. 취소하시겠습니까?')) {
      updateStatus('CANCEL', '발주 취소 되었습니다.');
    }
  };
  const handleCompletionCancelClick = async () => {
    const params = { requestId: productSupplyRequestId };
    try {
      const { response } = await supplyApi.confirmCancel(params);
      toastify('확정 취소되었습니다.', { variant: 'success' });
      setSupplyRequestDetail(response.data);
    } catch (err) {
      handleError(err);
    }
  };

  const handleExcelDownloadClick = () => {
    try {
      setGlobalModalOpen('activityIndicator', true);
      downloadSupplyRequestList(productSupplyRequestId, supplyRequestDetail.itemList);
    } finally {
      setGlobalModalOpen('activityIndicator', false);
    }
  };

  const setTargetButtonRef = (value) => {
    targetButtonRef.current = value;
  };

  const handleSaveClick = () => {
    setTargetButtonRef('저장');
  };

  return (
    <form onSubmit={handleSubmit}>
      <HelperButtons
        handleListClick={pushToAdminSupplyManagePage}
        handleSaveClick={handleSaveClick}
        statusCode={supplyRequestDetail.requestInfo.statusCode}
      />
      {productSupplyRequestId && (
        <>
          <HistoryListForm historyList={supplyRequestDetail.historyList} />
          <StatusChangeButtons
            statusCode={supplyRequestDetail.requestInfo.statusCode}
            setTargetButtonRef={setTargetButtonRef}
            onApproveClick={handleApproveClick}
            onRejectClick={handleRejectClick}
            onCompletionCancelClick={handleCompletionCancelClick}
            onCancelClick={handleCancelClick}
            onExcelDownloadClick={handleExcelDownloadClick}
          />
        </>
      )}

      <VendorInfoForm
        vendorInfo={supplyRequestDetail.vendorInfo}
        statusCode={supplyRequestDetail.requestInfo.statusCode}
        setSupplyRequestDetail={setSupplyRequestDetail}
      />
      <RequestInfoForm
        supplyInfo={supplyRequestDetail.requestInfo}
        setSupplyRequestDetail={setSupplyRequestDetail}
        statusCode={supplyRequestDetail.requestInfo.statusCode}
      />
      <ReceiveInfoForm
        receiveInfo={supplyRequestDetail.receiveInfo}
        setSupplyRequestDetail={setSupplyRequestDetail}
        statusCode={supplyRequestDetail.requestInfo.statusCode}
      />
      <CommentInfoForm
        commentInfo={supplyRequestDetail.commentInfo}
        setSupplyRequestDetail={setSupplyRequestDetail}
        statusCode={supplyRequestDetail.requestInfo.statusCode}
      />
      <ItemAndPriceInfoForm
        totalInfo={supplyRequestDetail.totalInfo}
        itemList={supplyRequestDetail.itemList}
        vendorId={supplyRequestDetail.vendorInfo.vendorId}
        setSupplyRequestDetail={setSupplyRequestDetail}
        statusCode={supplyRequestDetail.requestInfo.statusCode}
        taxSettlement={supplyRequestDetail.requestInfo.taxSettlement}
      />
      <TransportInfoForm transportInfo={supplyRequestDetail.transportInfo} />
      <HelperButtons
        handleListClick={pushToAdminSupplyManagePage}
        handleSaveClick={handleSaveClick}
        statusCode={supplyRequestDetail.requestInfo.statusCode}
      />
    </form>
  );
};

export default AdminSupplyRegisterPage;
