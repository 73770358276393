import SearchFilterContainer from './containers/SearchFilterContainer';
import TableContainer from './containers/TableContainer';
import ModalContainer from './containers/ModalContainer';

/**
 * @param {*} props
 */
const SettlementListPage = (props = {}) => {
  return (
    <>
      <SearchFilterContainer {...props} />
      <TableContainer {...props} />
      <ModalContainer {...props} />
    </>
  );
};

SettlementListPage.propTypes = {};

export default SettlementListPage;
