import PropTypes from 'prop-types';

import Card from 'components/Card';
import Table from 'components/Table';

import { COLUMNS } from './commons/constant';

const TransportInfoForm = ({ transportInfo }) => {
  return (
    <Card title='화물 정보'>
      <Table columns={COLUMNS} data={[transportInfo]} $height='150px' />
    </Card>
  );
};

TransportInfoForm.propTypes = {
  transportInfo: PropTypes.shape({
    transportCargoTypeCode: PropTypes.string,
    transportCargoTypeCodeName: PropTypes.string,
    transportInfoComment: PropTypes.string,
    transportQuantity: PropTypes.number,
    transportTypeCode: PropTypes.string,
    transportTypeCodeName: PropTypes.string,
  }),
};

export default TransportInfoForm;
