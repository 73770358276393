import PropTypes from 'prop-types';

import { formatDateAsYYYYMMDD, formatStringAsDate } from 'commons/date';
import { updateNestedObject } from 'commons/helper';
import { Flex } from 'styles/components';

import Card from 'components/Card';
import LabelAndText from 'components/LabelAndText';
import DatePicker from 'components/DatePicker';

const ReceiveInfo = ({ receiveInfo, setSupplyRequestDetail, statusCode }) => {
  const handleReceiveAvailableDayChange = (date) =>
    setSupplyRequestDetail((prev) =>
      updateNestedObject(prev, ['receiveInfo', 'receiveAvailableDay'], () =>
        formatDateAsYYYYMMDD(date)
      )
    );

  const isDatePickerDisabled = (currentStatusCode) =>
    currentStatusCode === 'VENDOR_REQUEST' ||
    currentStatusCode === 'PETFRIENDS_CONFIRM' ||
    currentStatusCode === 'CANCEL' ||
    currentStatusCode === 'CONFIRM_CANCEL';

  return (
    <Card title='입고 정보' style={{ flex: '1' }} description={receiveInfo.centerAddress}>
      <Flex $flexWrap='wrap'>
        <LabelAndText label='입고센터' text={receiveInfo.centerName} />
        <LabelAndText label='입고번호' text={receiveInfo.inboundWikey} />
        <LabelAndText label='입고요청일' text={receiveInfo.receiveRequestDay} />
        <DatePicker
          id='receiveAvailableDay'
          label='입고예정일'
          onChange={handleReceiveAvailableDayChange}
          selected={formatStringAsDate(receiveInfo.receiveAvailableDay)}
          disabled={isDatePickerDisabled(statusCode)}
          required
        />
      </Flex>
    </Card>
  );
};

ReceiveInfo.propTypes = {
  receiveInfo: PropTypes.shape({
    centerName: PropTypes.string,
    centerAddress: PropTypes.string,
    inboundWikey: PropTypes.string,
    receiveAvailableDay: PropTypes.string,
    receiveRequestDay: PropTypes.string,
  }).isRequired,
  setSupplyRequestDetail: PropTypes.func.isRequired,
  statusCode: PropTypes.oneOf([
    'PETFRIENDS_DRAFT',
    'PETFRIENDS_REGISTER',
    'MODIFY',
    'VENDOR_REQUEST',
    'PETFRIENDS_CONFIRM',
    'CANCEL',
    '',
  ]),
};

export default ReceiveInfo;
