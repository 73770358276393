import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reverseId: null,
  purchaseOrderId: null,
};

const slice = createSlice({
  name: 'reverse',
  initialState,
  reducers: {
    setReverseId: (state, action) => {
      state.reverseId = action.payload;
    },
    setPurchaseOrderId: (state, action) => {
      state.purchaseOrderId = action.payload;
    },
  },
});

export const { setReverseId, setPurchaseOrderId } = slice.actions;

export default slice.reducer;
