import { Route } from 'react-router-dom';

import AdminSupplyManagePage from '../AdminSupplyManagePage';
import AdminSupplyRegisterPage from '../AdminSupplyRegisterPage';
import AdminSupplyUploadPage from '../AdminSupplyUploadPage';
import AdminSupplyForecastPage from '../AdminSupplyForecastPage';

const SupplyRoute = () => {
  return (
    <>
      <Route path='/admin/supply/list' component={AdminSupplyManagePage} />
      <Route
        path={['/admin/supply/registration/:productSupplyRequestId', '/admin/supply/registration']}
        component={AdminSupplyRegisterPage}
      />
      <Route path='/admin/supply/upload/supply' component={AdminSupplyUploadPage} />
      <Route path='/admin/supply/forecast' component={AdminSupplyForecastPage} />
    </>
  );
};

export default SupplyRoute;
