import styled from 'styled-components';

const Wrapper = styled.li.attrs({ className: 'page-item' })``;

const PageLink = styled.button.attrs({ className: 'page-link', type: 'button' })`
  background-color: ${({ $isCurrentPage }) => $isCurrentPage && 'var(--blue50)'};
  border: 1px solid var(--grey300);

  &:hover {
    background-color: var(--grey100);
  }
  &:focus {
    box-shadow: none;
  }

  &:disabled {
    cursor: default;
    background-color: var(--grey200);
    color: var(--grey500);
  }
`;

export { Wrapper, PageLink };
