import PropTypes from 'prop-types';

import { Flex } from 'styles/components';

import Card from 'components/Card';
import LabelAndText from 'components/LabelAndText';

const RequestInfo = ({ requestInfo, productSupplyRequestId }) => {
  return (
    <Card title='발주 정보' style={{ flex: '1' }}>
      <Flex $flexWrap='wrap'>
        <LabelAndText label='발주번호' text={productSupplyRequestId} />
        <LabelAndText label='발주요청일' text={requestInfo.productSupplyRequestDay} />
        <LabelAndText label='발주유형' text={requestInfo.productSupplyTypeCodeName} />
        <LabelAndText label='발주담당자' text={requestInfo.requestWorkerName} />
      </Flex>
    </Card>
  );
};

RequestInfo.propTypes = {
  requestInfo: PropTypes.shape({
    productSupplyRequestDay: PropTypes.string,
    productSupplyTypeCode: PropTypes.string,
    productSupplyTypeCodeName: PropTypes.string,
    requestWorkerId: PropTypes.string,
    requestWorkerName: PropTypes.string,
  }).isRequired,
  productSupplyRequestId: PropTypes.string.isRequired,
};

export default RequestInfo;
