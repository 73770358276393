import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { handleError, isEmpty, translateObjectKeys } from 'commons/helper';
import { downloadJsonAsExcel } from 'commons/excel';
import toastify from 'commons/toast';
import receivingApi from 'service/receiving';
import { useGlobalModals } from 'hooks/pages';

import { Flex } from 'styles/components';
import Button from 'components/Button';
import QuestionMark from 'components/QuestionMark';
import ResultHeader from 'components/ResultHeader';
import { ROW_SELECT_PLUGIN_HOOKS } from 'components/Table/commons/constant';
import PaginationTable from 'components/Table/PaginationTable';
import NumericCell from 'components/NumericCell/NumericCell';
import Cell from 'components/Table/components/Cell';

import Styled from './styled';

import {
  RECEIVING_RESULT_TABLE_COLUMNS,
  RECEIVING_EXCEL_MAPPING_KEYS,
} from '../../commons/constant';
import {
  getFetchReceivingResultListParams,
  getProductSupplyRequestItemIdList,
  isNumericColumn,
  isValidList,
} from '../../commons/helper';

/**
 * @param {*} props
 */
function ResultContainer({
  controlledPageIndex,
  setControlledPage,

  content,
  totalElements,
  totalPages,
  fetchReceivingResultList,
  searchOptionsSnapshot,
}) {
  const [selectedReceivingResultList, setSelectedReceivingResultList] = useState([]);
  const [, setModalOpen] = useGlobalModals();

  const tableOptions = {
    initialState: { pageSize: 500 },
    manualPagination: true,
    pageCount: totalPages,
    useControlledState: (state) => ({
      ...state,
      pageIndex: controlledPageIndex,
    }),
  };

  const handlePageChange = ({ state }) => {
    fetchReceivingResultList(
      getFetchReceivingResultListParams(searchOptionsSnapshot.current, state.pageIndex)
    );
  };

  const handleExcelDownloadClick = async () => {
    if (isEmpty(content)) {
      toastify('입고 결과가 존재하지 않습니다.', { variant: 'warn' });
      return;
    }

    try {
      const { response } = await receivingApi.fetchReceivingResultList(
        getFetchReceivingResultListParams(searchOptionsSnapshot.current, 0, 10000)
      );

      const transList = response?.data?.content?.map((item) =>
        translateObjectKeys(item, RECEIVING_EXCEL_MAPPING_KEYS)
      );
      downloadJsonAsExcel({
        data: transList,
        sheetName: '입고 결과',
        fileName: '입고 결과',
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleTableSelectChange = ({ selectedFlatRows }) => {
    setSelectedReceivingResultList(selectedFlatRows.map((item) => item.original));
  };

  const handleYesClick = async () => {
    try {
      await receivingApi.confirmReceivingResultList(
        getProductSupplyRequestItemIdList(selectedReceivingResultList)
      );
      toastify('선택한 품목의 입고결과가 확정되었습니다.', { variant: 'success' });
      fetchReceivingResultList(
        getFetchReceivingResultListParams(searchOptionsSnapshot.current, controlledPageIndex)
      );
      setModalOpen('alert', false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleReceivingConfirmClick = async () => {
    if (isEmpty(selectedReceivingResultList)) {
      toastify('선택된 항목이 존재하지 않습니다.', { variant: 'warn' });
      return;
    }
    if (!isValidList(selectedReceivingResultList)) {
      toastify('입고완료, 미입고 항목만 입고 확정 처리가 가능합니다.', { variant: 'warn' });
      return;
    }

    setModalOpen('alert', true, {
      title: '입고 확정 클릭',
      content: (
        <div>
          <p>입고 확정된 수량으로 정산대상이 됩니다.</p>
          <p>선택한 품목을 입고 확정하시겠습니까?</p>
        </div>
      ),
      buttons: [
        { text: '예', onClick: handleYesClick },
        { text: '아니오', onClick: setModalOpen('alert', false), variant: 'secondary' },
      ],
    });
  };

  // eslint-disable-next-line no-unused-vars
  const renderCell = (cell) => {
    const columnId = cell.column.id;
    if (columnId === 'selection') {
      return <Cell {...cell} />;
    }
    if (isNumericColumn(columnId)) {
      return <NumericCell {...cell} />;
    }

    return cell.value;
  };

  const resultHeaderButtons = [
    <Button $variant='light' onClick={handleExcelDownloadClick}>
      엑셀 다운로드
    </Button>,
    <Flex $alignItems='center'>
      <Button $variant='secondary' onClick={handleReceivingConfirmClick}>
        입고 확정
      </Button>
      <QuestionMark
        helperText='입고 결과가 모두 확인완료된 발주는 정산대상으로 등록됩니다.'
        $position='left'
      />
    </Flex>,
  ];

  const FooterElement =
    (valueKey) =>
    ({ rows }) => {
      const total = useMemo(() => {
        return rows.reduce((acc, cur) => cur.values[valueKey] + acc, 0);
      }, [rows]);

      return <NumericCell value={total} />;
    };

  const [customColumn] = useState(() => {
    const nextFooterColumn = RECEIVING_RESULT_TABLE_COLUMNS.find(
      (it) => it.Header === '입고 결과 개요'
    ).columns.map((it, idx) => (idx <= 3 ? it : { ...it, Footer: FooterElement(it.accessor) }));

    return RECEIVING_RESULT_TABLE_COLUMNS.map((item) => {
      if (item.Header === '입고 결과 개요') {
        return { ...item, columns: nextFooterColumn };
      }
      return item;
    });
  });

  const renderFooterTotalSum = (footerGroups) => {
    const [footerArgs] = footerGroups;

    return (
      <tfoot>
        <tr {...footerArgs.getFooterGroupProps()}>
          {footerArgs.headers.map((column, index) => (
            <td {...column.getFooterProps()} className={index > 1 ? 'right' : ''}>
              {column.render('Footer')}
            </td>
          ))}
        </tr>
      </tfoot>
    );
  };

  return (
    <Styled.Div>
      <Styled.HelperMessage>
        <strong>입고 확정 안내</strong>
        <p>
          정산을 위해서 입고 결과를 확인 후 입고 확정 처리를 진행해 주십시오. 입고 확정된 품목 -
          수량으로 정산됩니다.
        </p>
      </Styled.HelperMessage>
      <ResultHeader totalCount={totalElements} buttons={resultHeaderButtons} />
      <PaginationTable
        columns={customColumn}
        data={content}
        onPageChange={handlePageChange}
        tableOptions={tableOptions}
        pluginHooks={ROW_SELECT_PLUGIN_HOOKS}
        onSelectChange={handleTableSelectChange}
        setControlledPage={setControlledPage}
        renderCell={renderCell}
        renderTableFoot={renderFooterTotalSum}
      />
    </Styled.Div>
  );
}

ResultContainer.propTypes = {
  controlledPageIndex: PropTypes.number.isRequired,
  setControlledPage: PropTypes.func.isRequired,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalElements: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  fetchReceivingResultList: PropTypes.func.isRequired,
  searchOptionsSnapshot: PropTypes.shape({
    current: PropTypes.shape({
      productSupplyReceivingStatusCode: PropTypes.string,
      receiveAvailableDay: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
      inboundCompletionDay: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
      query: PropTypes.string,
      inboundResultSearchCondition: PropTypes.string,
    }),
  }).isRequired,
};

export default ResultContainer;
