import styled from 'styled-components';

export const Wrapper = styled.fieldset`
  height: 45px;
  min-width: 200px;
  legend {
    color: var(--grey700);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-m);
  }

  > div {
    display: flex;
    gap: 16px;
  }
`;

export default { Wrapper };
