import PropTypes from 'prop-types';

import { formatDateAsYYYYMMDD, formatStringAsDate } from 'commons/date';
import { updateNestedObject } from 'commons/helper';
import { Flex } from 'styles/components';

import Card from 'components/Card';
import RadioGroup from 'components/RadioGroup';
import DatePicker from 'components/DatePicker';
import InputLabel from 'components/InputLabel';

import { RECEIVE_DELAY_OPTIONS } from './commons/constant';
import * as Styled from './styled';

const DelayInfo = ({ inboundWikey, delayInfo, setSupplyRequestDetail }) => {
  const handleReceiveDelayAvailableDayChange = (date) =>
    setSupplyRequestDetail((prev) =>
      updateNestedObject(prev, ['delayInfo', 'receiveDelayAvailableDay'], () =>
        formatDateAsYYYYMMDD(date)
      )
    );

  const handleReceiveDelayReasonChange = (e) =>
    setSupplyRequestDetail((prev) =>
      updateNestedObject(prev, ['delayInfo', e.target.name], () => e.target.value)
    );

  const handleCheckedValueChange = (e) =>
    setSupplyRequestDetail((prev) =>
      updateNestedObject(prev, ['delayInfo', 'receiveDelay'], () => e.target.value)
    );

  return (
    <Styled.Wrapper>
      <Card title='입고 지연' description='입고 지연 발생 시 입력해 주세요.'>
        <Flex $gap='var(--space-m)'>
          <RadioGroup
            title='입고지연 여부'
            radios={RECEIVE_DELAY_OPTIONS}
            name='receiveDelay'
            checkedValue={delayInfo.receiveDelay}
            onCheckedValueChange={handleCheckedValueChange}
            disabled={!inboundWikey}
          />
          {delayInfo.receiveDelay === 'Y' && (
            <DatePicker
              id='receiveDelayAvailableDay'
              label='지연 후 입고가능일'
              selected={formatStringAsDate(delayInfo.receiveDelayAvailableDay)}
              onChange={handleReceiveDelayAvailableDayChange}
              required={delayInfo.receiveDelay === 'Y'}
            />
          )}
        </Flex>
        {delayInfo.receiveDelay === 'Y' && (
          <InputLabel
            id='receiveDelayReason'
            name='receiveDelayReason'
            label='입고 지연 사유'
            value={delayInfo.receiveDelayReason ?? ''}
            onChange={handleReceiveDelayReasonChange}
            as='textarea'
            required={delayInfo.receiveDelay === 'Y'}
          />
        )}
      </Card>
    </Styled.Wrapper>
  );
};

DelayInfo.propTypes = {
  delayInfo: PropTypes.shape({
    receiveDelay: PropTypes.oneOf(['Y', 'N', '']),
    receiveDelayAvailableDay: PropTypes.string,
    receiveDelayReason: PropTypes.string,
  }),
  setSupplyRequestDetail: PropTypes.func.isRequired,
  inboundWikey: PropTypes.string,
};

export default DelayInfo;
