/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { postRequestSettlementList, getSettlementList } from 'service/hq/settlement';

import { SETTLEMENT_LIST, SETTLEMENT_COMPLETE } from 'pages/admin/settlement/constant';

import { SEARCH_FILTER_INIT_VALUES } from 'pages/admin/settlement/SettlementListPage/commons/constant';
import { COMPLETE_PAGE_SEARCH_FILTER_INIT_VALUES } from 'pages/admin/settlement/SettlementCompletePage/commons/constant';

const initialState = {
  [SETTLEMENT_LIST]: {
    searchFilter: SEARCH_FILTER_INIT_VALUES,
    tableData: [],
    totalElements: 0,
    totalPages: 0,
    selectedDatas: [],
  },
  [SETTLEMENT_COMPLETE]: {
    searchFilter: COMPLETE_PAGE_SEARCH_FILTER_INIT_VALUES,
    tableData: {
      content: [],
      totalElements: 0,
      totalPages: 0,
    },
    selectedDatas: [],
    selectedSettlementId: '',
    error: null,
  },
};

export const fetchSettlementList = createAsyncThunk(
  'settlement/fetchSettlementList',
  async (params, { getState }) => {
    const newParams = params || getState().settlement[SETTLEMENT_LIST].searchFilter;
    const response = await postRequestSettlementList(newParams);

    return {
      searchFilter: newParams,
      content: response?.data?.content,
      totalElements: response?.data?.totalElements,
      totalPages: response?.data?.totalPages,
    };
  }
);

export const fetchSettlementCompleteList = createAsyncThunk(
  'settlement/fetchSettlementCompleteList',
  async (params, { rejectWithValue, getState }) => {
    try {
      const newParams = params || getState().settlement[SETTLEMENT_COMPLETE].searchFilter;
      const response = await getSettlementList(newParams);
      return {
        path: SETTLEMENT_COMPLETE,
        data: response?.data?.data || response?.data,
        content: response?.data?.content,
        totalElements: response?.data?.totalElements,
        totalPages: response?.data?.totalPages,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const slice = createSlice({
  name: 'settlement',
  initialState,
  reducers: {
    setPageFilter: (state, action) => {
      state[action.payload.path].searchFilter = action.payload.searchFilter;
    },
    setSelectedDatas: (state, action) => {
      state[action.payload.path].selectedDatas = action.payload.selectedDatas;
    },
    setCompletePageFilter: (state, { payload }) => {
      state[payload.path].searchFilter = {
        ...state[payload.path].searchFilter,
        ...payload.data,
      };
    },
    setSelectedSettlementId: (state, action) => {
      state[action.payload.path].selectedSettlementId = action.payload.selectedSettlementId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSettlementList.fulfilled, (state, action) => {
      state[SETTLEMENT_LIST].tableData = action.payload.content;
      state[SETTLEMENT_LIST].searchFilter = action.payload.searchFilter;
      state[SETTLEMENT_LIST].totalElements = action.payload.totalElements;
      state[SETTLEMENT_LIST].totalPages = action.payload.totalPages;
    });
    builder.addCase(fetchSettlementList.rejected, (state) => {
      state[SETTLEMENT_LIST].tableData = [];
      state[SETTLEMENT_LIST].totalElements = 0;
      state[SETTLEMENT_LIST].totalPages = 0;
    });
    builder.addCase(fetchSettlementCompleteList.fulfilled, (state, action) => {
      state[SETTLEMENT_COMPLETE].error = null;
      state[SETTLEMENT_COMPLETE].tableData = {
        content: action.payload?.content,
        totalElements: action.payload?.totalElements,
        totalPages: action.payload?.totalPages,
      };
    });
    builder.addCase(fetchSettlementCompleteList.rejected, (state, action) => {
      if (action.error) {
        state[SETTLEMENT_COMPLETE].tableData = {
          content: [],
          totalElements: 0,
          totalPages: 0,
        };
        state[SETTLEMENT_COMPLETE].error = action.error;
      }
    });
  },
});

export const { setPageFilter, setSelectedDatas, setCompletePageFilter, setSelectedSettlementId } =
  slice.actions;

export default slice.reducer;
