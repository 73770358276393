import PropTypes from 'prop-types';

import Card from 'components/Card';
import LabelAndText from 'components/LabelAndText';
import * as Styled from './styled';

const VendorInfo = ({ vendor }) => {
  return (
    <Card title='거래처 정보'>
      <Styled.InnerWrapper>
        <div>
          <LabelAndText label='거래처 번호' text={vendor.vendorId} />
          <LabelAndText label='계좌번호' text={vendor.accountNumber} />
        </div>
        <div>
          <LabelAndText label='거래처명' text={vendor.displayName} />
          <LabelAndText label='은행' text={vendor.bankValue} />
        </div>
        <div>
          <LabelAndText label='사업자등록번호' text={vendor.businessNumber} />
          <LabelAndText label='예금주' text={vendor.depositor} />
        </div>
      </Styled.InnerWrapper>
    </Card>
  );
};

VendorInfo.propTypes = {
  vendor: PropTypes.shape({
    accountNumber: PropTypes.string,
    bankValue: PropTypes.string,
    businessNumber: PropTypes.string,
    depositor: PropTypes.string,
    displayName: PropTypes.string,
    vendorId: PropTypes.number,
  }).isRequired,
};

export default VendorInfo;
