export const getFetchReceivingResultListParams = (searchOptions, pageIndex, pageSize) => ({
  productSupplyReceivingStatusCode: searchOptions.productSupplyReceivingStatusCode,
  searchCondition: searchOptions.inboundResultSearchCondition,
  searchWord: searchOptions.query,
  beforeReceiveAvailableDay: searchOptions.receiveAvailableDay[0],
  afterReceiveAvailableDay: searchOptions.receiveAvailableDay[1],
  beforeInboundCompletionDay: searchOptions.inboundCompletionDay[0],
  afterInboundCompletionDay: searchOptions.inboundCompletionDay[1],
  pageIndex,
  pageSize,
});

export const isValidList = (selectedReceivingResultList) =>
  selectedReceivingResultList.every(
    ({ productSupplyReceivingStatusValue }) =>
      productSupplyReceivingStatusValue === '입고완료' ||
      productSupplyReceivingStatusValue === '미입고'
  );

export const getProductSupplyRequestItemIdList = (list) =>
  list.map((item) => item.productSupplyRequestItemId);

export const isNumericColumn = (columnId) =>
  columnId === 'productSupplyUnitPrice' ||
  columnId === 'availableReceiveQuantity' ||
  columnId === 'receivedQuantity' ||
  columnId === 'unreceivedQuantity' ||
  columnId === 'receivedQuantityPrice' ||
  columnId === 'unreceivedQuantityPrice';
