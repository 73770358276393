import styled from 'styled-components';

const Wrapper = styled.div.attrs({
  className: `card`,
})`
  margin: 0px calc(var(--space-l) / 2) var(--space-l) calc(var(--space-l) / 2);
  padding: 0;
  border: 1px solid var(--border-color);
  box-shadow: none;

  @media (max-width: 766px) {
    margin: 0 0 var(--space-l) 0;
    border-radius: 0;
  }
`;

const Header = styled.div.attrs({
  className: 'card-header',
})`
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  height: 60px;

  &::after {
    display: none;
  }
`;

const Title = styled.h2.attrs({
  className: 'card-title',
})`
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-l);
  color: var(--grey900);
`;

const Description = styled.span`
  margin-left: var(--space-m);
`;

const Body = styled.div.attrs({
  className: 'card-body',
})``;

export { Wrapper, Header, Title, Description, Body };
