export const SEARCH_CONDITION = [
  {
    id: 'PRODUCT_SUPPLY_REQUEST',
    label: '발주번호',
    value: 'PRODUCT_SUPPLY_REQUEST',
  },
  {
    id: 'PRODUCT_ITEM',
    label: '품목 ID',
    value: 'PRODUCT_ITEM',
  },
];

export const PRODUCT_SUPPLY_REQUEST_STATUS_CODE = [
  { key: 'PETFRIENDS_REGISTER', value: '발주등록' },
  { key: 'MODIFY', value: '발주수정중' },
  { key: 'CONFIRM_CANCEL', value: '확정취소' },
  { key: 'VENDOR_REQUEST', value: '확정요청' },
  { key: 'PETFRIENDS_CONFIRM', value: '발주확정' },
  { key: 'CANCEL', value: '발주취소' },
];
