import { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import { TAX_OPTIONS } from 'commons/constant';
import { numberExtractor, thousandSeparator } from 'commons/helper';

import Select from 'components/Select';
import Input from 'components/Input';

const EditableCell = ({ value: initialValue, row, column: { id: columnName }, updateMyData }) => {
  const { index: rowIndex, isSelected } = row;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e) => setValue(e.target.value);
  const handleBlur = () => updateMyData(rowIndex, columnName, value);
  const handleNumberInputChange = (e) => setValue(numberExtractor(e.target.value));

  if (columnName === 'tax') {
    return (
      <Select
        id='tax'
        name='tax'
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        options={TAX_OPTIONS}
        required={isSelected}
      />
    );
  }

  if (columnName === 'standardUnitPrice' || columnName === 'minimumOrderQuantity') {
    const manipulatedValue =
      columnName === 'standardUnitPrice' ? thousandSeparator(value) || 0 : thousandSeparator(value);

    return (
      <Input
        type='text'
        value={manipulatedValue}
        onChange={handleNumberInputChange}
        onBlur={handleBlur}
        maxLength={12}
        required={isSelected}
      />
    );
  }

  return value;
};

EditableCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  row: PropTypes.shape({
    index: PropTypes.number,
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  updateMyData: PropTypes.func.isRequired,
};

export default memo(EditableCell);
