import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';

import amendmentRequestApi from 'service/hq/amendmentRequest';
import { handleError } from 'commons/helper';
import toastify from 'commons/toast';
import { setSelectedAmendmentRequest } from 'commons/store/modules/admin/amendmentRequest';
import { Flex } from 'styles';

import Button from 'components/Button';
import ImageUploader from 'components/ImageUploader';
import InputLabel from 'components/InputLabel';

import Styled from './styled';

/**
 * @param {*} props
 */
const VendorInfoContainer = ({ vendorInfo, requestId, reason, statusValue }) => {
  const dispatch = useDispatch();
  const updateVendorAmendmentRequestStatus = (status) =>
    amendmentRequestApi.updateVendorAmendmentRequestStatus({
      id: requestId,
      reason,
      status,
    });

  const handleModalClose = () =>
    dispatch(
      setSelectedAmendmentRequest({
        code: undefined,
        id: undefined,
      })
    );

  const handleApproveClick = async () => {
    try {
      const response = await updateVendorAmendmentRequestStatus('approval');
      if (response) {
        toastify('승인되었습니다.', { variant: 'success' });
        handleModalClose();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleRejectClick = async () => {
    try {
      const response = await updateVendorAmendmentRequestStatus('reject');
      if (response) {
        toastify('반려되었습니다.', { variant: 'success' });
        handleModalClose();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleImageDownloadClick = async () => {
    try {
      const response = await amendmentRequestApi.getImageById(vendorInfo.bankBookVendorImageId);
      saveAs(response, vendorInfo.bankBookVendorImageName);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Styled.Div>
      {statusValue === '요청 중' && (
        <Flex $justifyContent='flex-end'>
          <Flex $gap='var(--space-m)'>
            <Button onClick={handleApproveClick}>승인</Button>
            <Button $variant='light' onClick={handleRejectClick}>
              반려
            </Button>
          </Flex>
        </Flex>
      )}
      <Flex $flexDirection='column' $gap='var(--space-l)'>
        <Styled.Category>사업자 정보</Styled.Category>
        <Flex $alignItems='center' $gap='var(--space-m)' $flexWrap='wrap'>
          <InputLabel
            id='companyName'
            label='법인명'
            value={vendorInfo?.companyName || ''}
            disabled
          />
          <InputLabel id='ceoName' label='대표자 성명' value={vendorInfo?.ceoName || ''} disabled />
          <InputLabel
            id='businessNumber'
            label='사업자등록번호 (‘-’ 포함)'
            value={vendorInfo?.businessNumber || ''}
            disabled
          />
        </Flex>
        <Styled.Category>결제 정보</Styled.Category>
        <Flex $alignItems='flex-start' $gap='var(--space-m)' $flexWrap='wrap'>
          <InputLabel
            id='paymentMethodValue'
            label='결제 방법'
            value={vendorInfo?.paymentMethodValue || ''}
            disabled
          />
          <InputLabel
            id='paymentDateValue'
            label='결제 일'
            value={vendorInfo?.paymentDateValue || ''}
            disabled
          />
          <InputLabel id='bankValue' label='은행' value={vendorInfo?.bankValue || ''} disabled />
          <InputLabel
            id='accountNumber'
            label='계좌번호 (‘-’ 포함)'
            value={vendorInfo?.accountNumber || ''}
            disabled
          />
          <InputLabel id='depositor' label='예금주' value={vendorInfo?.depositor || ''} disabled />
          <ImageUploader
            id='bankBookVendorImage'
            title='통장 사본'
            imageName={vendorInfo?.bankBookVendorImageName}
            imageId={vendorInfo?.bankBookVendorImageId}
            onImageDownloadClick={handleImageDownloadClick}
            disabled
          />
        </Flex>
        <Styled.Category>발주 정보</Styled.Category>
        <InputLabel
          id='receiveMethodValue'
          label='기본 입고 방법'
          value={vendorInfo?.receiveMethodValue || ''}
          disabled
        />
      </Flex>
    </Styled.Div>
  );
};

VendorInfoContainer.propTypes = {
  vendorInfo: PropTypes.shape({
    accountNumber: PropTypes.string,
    bankValue: PropTypes.string,
    bankBookVendorImageId: PropTypes.number,
    bankBookVendorImageName: PropTypes.string,
    bankBookVendorImageUrl: PropTypes.string,
    businessNumber: PropTypes.string,
    ceoName: PropTypes.string,
    companyName: PropTypes.string,
    depositor: PropTypes.string,
    id: PropTypes.number,
    paymentDateValue: PropTypes.string,
    paymentMethodValue: PropTypes.string,
    reason: PropTypes.string,
    receiveMethod: PropTypes.string,
    statusCode: PropTypes.string,
    statusCodeValue: PropTypes.string,
    vendorId: PropTypes.number,
    receiveMethodValue: PropTypes.string,
  }),
  requestId: PropTypes.number,
  reason: PropTypes.string,
  statusValue: PropTypes.string,
};

export default VendorInfoContainer;
