import { styleSelector } from 'commons/helper';
import styled from 'styled-components';

export const Div = styled.div``;

export const LabalPrice = styled.div`
  ${styleSelector}
  > label {
    text-align: center;
    width: 100%;
    color: black;
  }
  > div {
    align-self: center;
    border: 0.5px solid #626262;
    border-radius: 7px;
    text-align: center;
    font-size: 12px;
    padding: 0.5rem 1rem;
  }
`;

export const Flex = styled.div`
  display: flex;
  > div {
    padding-right: 2rem;
  }
`;

export default { Div, LabalPrice, Flex };
