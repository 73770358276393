import { useEffect, useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import { Flex } from 'styles/components';
import * as Styled from './styled';
import PageItem from './components/PageItem';

const Pagination = ({ tableInstance, onPageChange, setControlledPage }) => {
  const {
    pageCount: initialPageCount,
    canPreviousPage,
    canNextPage,
    state: { pageIndex },
  } = tableInstance;
  const [pageCount, setPageCount] = useState(initialPageCount);
  useEffect(() => {
    if (initialPageCount !== 0) {
      setPageCount(initialPageCount);
    }
  }, [initialPageCount]);

  const handlePageChange = (index) => {
    setControlledPage(index);
    onPageChange({ ...tableInstance, state: { ...tableInstance.state, pageIndex: index } });
  };

  const handlePageClick = useAsyncDebounce((page) => handlePageChange(page - 1), 100);
  const handleFirstPageClick = useAsyncDebounce(() => handlePageChange(0), 100);
  const handlePreviousPageClick = useAsyncDebounce(() => handlePageChange(pageIndex - 1), 100);
  const handleNextPageClick = useAsyncDebounce(() => handlePageChange(pageIndex + 1), 100);
  const handleLastPageClick = useAsyncDebounce(() => handlePageChange(pageCount - 1), 100);

  function renderPages() {
    let pages;
    if (pageIndex < 2 || pageCount < 5) {
      pages = [1, 2, 3, 4, 5].slice(0, pageCount);
    } else if (pageIndex + 3 > pageCount) {
      pages = [pageCount - 4, pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
    } else {
      pages = [pageIndex - 1, pageIndex, pageIndex + 1, pageIndex + 2, pageIndex + 3];
    }

    return pages.map((page) => (
      <PageItem
        key={page}
        item={page}
        onClick={() => handlePageClick(page)}
        $isCurrentPage={pageIndex + 1 === page}
      />
    ));
  }

  const pageItems = [
    {
      arrow: '<<',
      onClick: handleFirstPageClick,
      disabled: !canPreviousPage,
    },
    {
      arrow: '<',
      onClick: handlePreviousPageClick,
      disabled: !canPreviousPage,
    },
    { renderFunction: renderPages },
    {
      arrow: '>',
      onClick: handleNextPageClick,
      disabled: !canNextPage,
    },
    {
      arrow: '>>',
      onClick: handleLastPageClick,
      disabled: !canNextPage,
    },
  ];

  return (
    <Flex $justifyContent='center'>
      <Styled.Pagination>
        {pageItems.map(({ arrow, onClick, disabled, renderFunction }) => {
          if (renderFunction) {
            return renderFunction();
          }
          return <PageItem key={arrow} item={arrow} onClick={onClick} disabled={disabled} />;
        })}
      </Styled.Pagination>
    </Flex>
  );
};

Pagination.propTypes = {
  tableInstance: PropTypes.shape({
    gotoPage: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    pageCount: PropTypes.number.isRequired,
    canPreviousPage: PropTypes.bool.isRequired,
    canNextPage: PropTypes.bool.isRequired,
    pageIndex: PropTypes.number.isRequired,
    state: PropTypes.shape({ pageSize: PropTypes.number, pageIndex: PropTypes.number }),
  }).isRequired,
  onPageChange: PropTypes.func.isRequired,
  setControlledPage: PropTypes.func,
};

export default Pagination;
