import { useState, useRef, useMemo, useEffect } from 'react';
import useFetch from 'hooks/useFetch';
import instance from 'service';
import { formatDateAsYYYYMMDD } from 'commons/date';

import { thousandSeparator, setQueries } from 'commons/helper';

/**
 * @param {object} params
 * @param {'REGISTERED' | 'CONFIRMED' | 'CANCELED'} params.status
 * @param {'ERROR' | 'CS' | 'BREAK' | 'MISSING' | 'POOR_SALES'} params.reason
 * @param {string} params.centerId
 * @param {Date[]} params.createdDay
 * @param {Date[]} params.completeDay
 * @param {'VENDOR_ID' | 'VENDOR_NAME' | 'PURCHASE_ORDER' | 'INBOUND' | 'REVERSE' | 'OUTBOUND'} params.searchCondition
 * @param {string} searchWord
 */
export const getReverseList = async (params) => {
  const { createdDay, completeDay, ...rest } = params;
  const [beforeCreatedDay, afterCreatedDay] = createdDay;
  const [beforeCompleteDay, afterCompleteDay] = completeDay;

  const body = {
    beforeCreatedDay: formatDateAsYYYYMMDD(beforeCreatedDay),
    afterCreatedDay: formatDateAsYYYYMMDD(afterCreatedDay),
    beforeCompleteDay: formatDateAsYYYYMMDD(beforeCompleteDay),
    afterCompleteDay: formatDateAsYYYYMMDD(afterCompleteDay),
    ...rest,
  };

  const queryParams = setQueries(body);

  const { data } = await instance.get(`/api/v2/hq/reverses?${queryParams}`);

  return data;
};

export const useReverseList = (params) => {
  const { execute } = useFetch(getReverseList, { initialExecute: false });
  const [reverseList, setReverseList] = useState([]);
  const total = useRef(0);

  const executeHandler = async () => {
    const { data } = await execute(params);
    total.current = data?.length || 0;
    setReverseList(data || []);
  };

  return { reverseList, setReverseList, total: total.current, executeHandler };
};

/**
 *
 * @param {number} reverseId
 */
export const getReverseDetail = async (reverseId) => {
  const { data } = await instance.get(`/api/v2/hq/reverses/${reverseId}`);
  return data;
};

export const useReverseDetail = (reverseId) => {
  const { execute } = useFetch(getReverseDetail, { initialExecute: false });
  const [detail, setDetail] = useState({
    reverse: {},
    purchaseOrder: {},
    outbound: {},
    reverseItems: [],
  });
  const totalSum = useMemo(() => {
    return detail?.reverseItems.reduce((acc, cur) => {
      acc += cur.reverseAvailablePrice;
      return acc;
    }, 0);
  }, [JSON.stringify(detail.reverseItems)]);

  const executeHandler = async () => {
    const { data } = await execute(reverseId);
    setDetail(data);
  };

  return { info: detail, executeHandler, totalSum: thousandSeparator(totalSum) || 0 };
};

/**
 *
 * @param {object} params
 * @param {'PRODUCT_ID' | 'PRODUCT_SUPPLY_REQUEST' | 'INBOUND_ID' | 'VENDOR_ID' | 'VENDOR_NAME'} params.condition
 * @param {string} searchWord
 */
export const getPurchaseOrderReverses = async (params) => {
  const { data } = await instance.get(
    `/api/v2/hq/purchase-order-reverses?searchWord=${params.searchWord}&searchCondition=${params.condition}`
  );

  return data;
};

export const usePurchaseOrderReverses = (params) => {
  const { execute } = useFetch(getPurchaseOrderReverses, { initialExecute: false });
  const [purchaseOrderList, setPurchaseOrderList] = useState([]);

  const executeHandler = async () => {
    const { data } = await execute(params);
    setPurchaseOrderList(data);
  };

  return { purchaseOrderList, executeHandler };
};

/**
 *
 * @param {string} purchaseOrderId
 */
export const getPurchaseOrders = async (purchaseOrderId) => {
  const { data } = await instance.get(`/api/v2/hq/reverses/purchase-orders/${purchaseOrderId}`);
  return data;
};

export const usePurchaseOrdersStep2Info = (purchaseOrderId) => {
  const { execute } = useFetch(getPurchaseOrders, { initialExecute: false });
  const [info, setInfo] = useState({
    centerName: '',
    inboundWikey: '',
    purchaseOrderId: '',
    purchaseOrderPrice: '',
    purchaseOrderRequestDay: '',
    vendorId: '',
    vendorName: '',
  });
  const [step2List, setStep2List] = useState([]);

  const executeHandler = async () => {
    const { data } = await execute(purchaseOrderId);
    setInfo({ ...data.purchaseOrder });
    setStep2List([...data.purchaseOrderItems]);
  };

  useEffect(() => {
    if (!purchaseOrderId) return;
    executeHandler();
  }, [purchaseOrderId]);

  return { info, list: step2List, setStep2List };
};

/**
 * @typedef items
 * @property {number} purchaseOrderItemId
 * @property {string} productItemName
 * @property {number} reverseAvailableQuantity
 */

/**
 *
 * @param {object} params
 * @param {string} params.purchaseOrderId
 * @param {number} params.vendorId
 * @param {string} params.reason
 * @param {number} params.centerId
 * @param {string} params.deliveryType
 * @param {string} params.availableDay
 * @param {string} params.recipient
 * @param {string} params.zipcode
 * @param {string} params.address
 * @param {string} params.contact
 * @param {items[]} params.items
 */
export const postReverses = async (params) => {
  const body = {
    ...params,
    availableDay: formatDateAsYYYYMMDD(params.availableDay),
  };
  const { data } = await instance.post('/api/v2/hq/reverses', body);
  return data;
};

/**
 *
 * @param {number} reverseId
 */
export const putReverseCancel = async (reverseId) => {
  const { data } = await instance.put(`/api/v2/hq/reverses/${reverseId}/cancellation`);

  return data;
};
