import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;

  input {
    width: 200px;
    height: var(--base-height);
    padding-left: var(--space-m);
    color: var(--grey900);
    cursor: pointer;
    border: 1px solid var(--grey300);
    border-radius: var(--radius-m);
    font-size: ${({ selectsRange }) => (selectsRange ? '13px' : '15px')};

    &:disabled {
      background-color: #e9ecef;
      cursor: default;
    }

    &:focus {
      border-color: #80bdff;
      outline: none;
      box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition-property: border-color, box-shadow;
      transition-duration: 0.15s, 0.15s;
      transition-timing-function: ease-in-out, ease-in-out;
      transition-delay: 0s, 0s;
    }
  }

  .react-datepicker__navigation {
    top: 7px;
  }
`;

const Label = styled.label`
  color: var(--grey700);
  font-weight: var(--font-weight-medium) !important;
  cursor: pointer;
`;

export { Wrapper, Label };
