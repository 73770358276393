const FIRST_TEXT = '먼저, 변경할 품목을 검색해 대상으로 등록합니다.';
const FOURTH_TEXT =
  '요청 후에는 수정할 수 없고 <요청 취소>를 클릭해 요청을 취소한 뒤 새로 등록하셔야 합니다. <수정 요청> 전에 저장이 필요한 경우 <임시 저장>을 이용해 주세요.';

export const TITLES = {
  '가격 수정 요청': '가격 정보 수정 안내',
  '품목 정보 수정 요청': '품목 정보 수정 안내',
};

export const SUB_TITLES = {
  '가격 수정 요청': '품목의 기준 정보가 / 최소 주문 수량 변경을 요청할 수 있습니다.',
  '품목 정보 수정 요청': '품목의 제원 정보 변경을 요청할 수 있습니다.',
};

export const TEXTS = {
  '가격 수정 요청': [
    FIRST_TEXT,
    '대상 품목마다 기준 공급가와 최소주문수량 변경 사유를 입력한 뒤 <수정 요청> 버튼을 클릭해 펫프렌즈 담당자에게 수정을 요청합니다.',
    '펫프렌즈 담당자가 승인을 하면, 품목의 기준 공급가와 최소주문수량이 변경됩니다.',
    FOURTH_TEXT,
  ],
  '품목 정보 수정 요청': [
    FIRST_TEXT,
    '대상 품목마다 변경된 정보와 변경 사유를입력한 뒤 <수정 요청> 버튼을 클릭해 펫프렌즈 담당자에게 승인을 요청합니다.',
    '펫프렌즈 담당자가 승인하면, 품목 정보가 변경됩니다.',
    FOURTH_TEXT,
  ],
};

export default {
  TITLES,
  SUB_TITLES,
  TEXTS,
};
