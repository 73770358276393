import styled from 'styled-components';
import { blueUnderline, ellipsis } from 'styles/commons';

const Title = styled.h3`
  margin: 0;
  font-size: var(--font-size-l);

  ${ellipsis}
  ${blueUnderline}
`;

const Paragraph = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const CategoryBadge = styled.span`
  border: 1px solid var(--grey900);
  padding: calc(var(--space-s) / 2) var(--space-s);
  border-radius: var(--radius-s);
  white-space: nowrap;

  border: 1px solid var(--red50);
  background-color: var(--red50);
  color: var(--red500);
  font-size: 12px;
`;

export const NoticeItem = styled.tr`
  & td {
    padding-bottom: 12px;
  }

  & td:nth-of-type(2) > button {
    font-size: 12px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    color: #3182f6;
    cursor: pointer;
  }

  & td:nth-of-type(3) {
    color: #8b95a1;
    font-size: 12px;
  }
`;

export { Title, Paragraph };
