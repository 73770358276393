export const MAPPING_KEYS = {
  managerName: '담당자',
  receiveAvailableDay: '입고예정일',
  purchaseOrderId: '발주번호',
  inboundWikey: '입고번호',
  vendorName: '거래처명',
  productItemId: '품목번호',
  productItemName: '품목명',
  unitPrice: '적용공급가(₩)',
  availableReceiveQuantity: '입고예정수량',
  availableReceivePrice: '입고예정금액',
  transportType: '운송유형',
  shelfLifeDay: '유통기한',
};
