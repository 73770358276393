import { useState } from 'react';
import dayjs from 'dayjs';
import Card from 'components/Card';
import DashboardCalendar, { useCalendarList } from 'components/DashboardCalendar';
import { postVendorPurchaseOrderCalendar } from './apis';
import { useOverview } from './hooks';
import Overview from './components/Overview';

function CalendarContainer() {
  const [currentDate, setCurrentDate] = useState(dayjs(new Date()).format('YYYY-MM'));
  const { overviewData } = useOverview({ yearMonth: currentDate });
  const { calendarEvents } = useCalendarList({
    yearMonth: currentDate,
    api: postVendorPurchaseOrderCalendar,
  });

  return (
    <>
      <Overview currentDate={currentDate} overviewData={overviewData} />
      <Card title=''>
        <DashboardCalendar
          className='vendor'
          width='100%'
          calendarEvents={calendarEvents}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          dayCellProps={{ events: calendarEvents }}
        />
      </Card>
    </>
  );
}

export default CalendarContainer;
