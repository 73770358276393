import styled from 'styled-components';
import ResultHeadeComponent from 'components/ResultHeader';

export const ResultHeader = styled(ResultHeadeComponent)`
  margin-top: 0;
`;

export const TableWrapper = styled.div`
  thead {
    th:nth-child(n + 4):nth-child(-n + 5) {
      text-align: right;
    }
  }
  tbody {
    td:nth-child(n + 4):nth-child(-n + 5) {
      text-align: right;
    }
  }
`;

export default { ResultHeader, TableWrapper };
