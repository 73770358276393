import styled from 'styled-components';

const Wrapper = styled.div`
  width: 200px;
  min-height: 63px;
  margin-bottom: var(--space-l);
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    flex: 1;
    color: var(--grey900);
  }
`;

const Label = styled.div`
  color: var(--grey700);
  font-weight: var(--font-weight-medium);
  margin-bottom: 7.5px;
`;

export { Wrapper, Label };
