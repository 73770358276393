import { authStore } from 'hooks/useSyncAuth';
import { incorrectPermissionInterceptor, tokenRefreshInterceptor } from './interceptorUtil';
import instance from './index';
import { STATUS_CODE } from './commons/constant';

instance.interceptors.request.use(
  (config) => {
    const newConfig = config;
    const {
      cookie: [accessToken],
    } = authStore.get(['accessToken']);

    newConfig.headers.Authorization = accessToken ? `Bearer ${accessToken}` : null;
    return newConfig;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    const {
      data: { code, message },
      headers,
    } = response;

    if (
      headers?.['content-type'] === 'ms-vnd/excel' ||
      headers?.['content-type'].startsWith('image/')
    ) {
      return response;
    }

    if (code !== 0) {
      response.status = code;
      const error = new Error(message);
      error.response = response;
      throw error;
    }

    return response;
  },
  async (error) => {
    const { response } = error;

    if (response.status === STATUS_CODE.UNAUTHORIZED) {
      tokenRefreshInterceptor(error);
      return;
    }

    if (response.status === STATUS_CODE.FORBIDDEN) {
      incorrectPermissionInterceptor();
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default instance;
