import Card from 'components/Card';

import * as Styled from './styled';

import ProductConnectionModal from './components/ProductConnectionModal';

import VendorSearchContainer from './containers/VendorSearchContainer';
import VendorResultContainer from './containers/VendorResultContainer';
import VendorProductListContainer from './containers/VendorProductListContainer';

const AdminProductManagePage = () => {
  return (
    <Styled.Wrapper>
      <Card title='거래처 조회'>
        <VendorSearchContainer />
        <VendorResultContainer />
      </Card>
      <VendorProductListContainer />
      <ProductConnectionModal />
    </Styled.Wrapper>
  );
};

export default AdminProductManagePage;
