import PropTypes from 'prop-types';

import { thousandSeparator } from 'commons/helper';

import Button from 'components/Button';

import Styled from './styled';
import { useSum } from './useSum';

/**
 * @param {*} props
 */
const FinalSettlementInfoContainer = ({
  datas = [],
  index = 0,
  finalText = '저장',
  finalWarningText,
  prevTaxInvoiceListData,
  disabled,
}) => {
  const { vendorInfo = {}, productSupplySettlementId } = datas[index] || {};
  const {
    입고결과금액합계,
    기타비용합계,
    할인적용금액합계,
    최종정산금액합계,
    공급가액합계,
    세액합계,
    반품금액합계,
  } = useSum(datas[index], prevTaxInvoiceListData[index]);

  const renderLabalPrice = (label, price, color) => {
    if (!price && typeof price !== 'number') return null;

    return (
      <Styled.LabalPrice $color={color}>
        <label>{label}</label>
        <div>
          <b>₩{thousandSeparator(price) || 0}</b>
        </div>
      </Styled.LabalPrice>
    );
  };

  return (
    <Styled.Div>
      <h5 style={{ padding: '1rem 0' }}>최종 정산내역</h5>
      <div>
        <Styled.Flex>
          {renderLabalPrice('입고결과 금액 합계', 입고결과금액합계, 'red')}
          {finalText === '세금계산서 발행 요청' &&
            renderLabalPrice('기타비용 합계', 기타비용합계, 'red')}
          {renderLabalPrice('할인 적용금액 합계', 할인적용금액합계, 'blue')}
          {renderLabalPrice('반품금액 합계', 반품금액합계, 'blue')}
          {renderLabalPrice('최종 정산금액 합계', 최종정산금액합계, 'red')}
          {renderLabalPrice('공급가액 합계', 공급가액합계)}
          {renderLabalPrice('세액 합계', 세액합계)}
        </Styled.Flex>
        <br />
        {!productSupplySettlementId && (
          <>
            <p>
              {finalWarningText || (
                <span>
                  위 내역에 대해 <b>{vendorInfo.displayName}</b>&nbsp;정산 담당자에게 세금계산서
                  발행(역발행)을 요청합니다.
                </span>
              )}
            </p>
            <Button $variant='success' type='submit' disabled={disabled}>
              {finalText}
            </Button>
          </>
        )}
        &nbsp;&nbsp;&nbsp;
      </div>
    </Styled.Div>
  );
};

FinalSettlementInfoContainer.propTypes = {
  index: PropTypes.number,
  finalText: PropTypes.string,
  finalWarningText: PropTypes.string,
  datas: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  prevTaxInvoiceListData: PropTypes.object,
};

export default FinalSettlementInfoContainer;
