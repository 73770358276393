import { forwardRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useGlobalModals } from 'hooks/pages';

import Button from 'components/Button';

import Styled from './styled';
import Modal from '../Modal';

const Content = forwardRef((props, ref) => (
  <div {...props} ref={ref}>
    {props.children}
  </div>
));

Content.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

/**
 * @param {boolean} isOpen
 * @param info: { title, content, component, buttons }
 * @example
 *    {isOpen: false, { title: null, content: '', buttons: [{ text: '확인' }] }}
 * @param {string} info.title
 * @param {string} info.content
 * @param {string} info.component
 * @param {array} info.buttons - @example [{ text: '확인' }]
 */
const AlertModal = () => {
  const [{ alert }, setModalOpen] = useGlobalModals(false);
  const {
    isOpen: isAlertOpen,
    info: { title, content, component, buttons, modalProps = {} } = {},
  } = alert;
  const innerRef = useRef();
  const Component = <Content ref={innerRef}>{content}</Content>;

  const handleAlertModalOpen = () => setModalOpen('alert', false);

  useEffect(() => {
    if (component) {
      innerRef.current.innerHTML = component;
    }
  }, [component]);

  const renderButtons = () => {
    if (!buttons) return null;
    return buttons.map((info) => (
      <Button
        key={info.text}
        $variant={info.variant || 'primary'}
        onClick={info.onClick || handleAlertModalOpen}
      >
        {info.text}
      </Button>
    ));
  };

  return (
    <Modal
      className='alert-modal'
      name='alert'
      isOpen={isAlertOpen}
      closeCallback={handleAlertModalOpen}
      header={title}
      {...modalProps}
    >
      <>
        {Component}
        <Styled.Buttons>
          {renderButtons() || (
            <Button $variant='primary' onClick={handleAlertModalOpen}>
              확인
            </Button>
          )}
        </Styled.Buttons>
      </>
    </Modal>
  );
};

export default AlertModal;
