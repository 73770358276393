import styled from 'styled-components';

export const TableWrapper = styled.div`
  thead {
    th:nth-child(8) {
      text-align: right;
    }
    th:nth-child(9) {
      text-align: right;
    }
    th:nth-child(10) {
      text-align: right;
    }
  }
  tbody {
    td:nth-child(8) {
      text-align: right;
    }
    td:nth-child(9) {
      input {
        text-align: right;
      }
    }
    td:nth-child(10) {
      input {
        text-align: right;
      }
    }
  }
`;

export default { TableWrapper };
