import PropTypes from 'prop-types';

import { Flex } from 'styles/components';
import { thousandSeparator } from 'commons/helper';

import * as Styled from './styled';

const LabelAndCount = ({ label, count, ...props }) => {
  return (
    <Styled.Wrapper {...props}>
      <Styled.Label>{label}</Styled.Label>
      <Flex $alignItems='center' $gap='var(--space-s)'>
        <Styled.Count>{thousandSeparator(count) || 0}</Styled.Count>
        <Styled.Unit>건</Styled.Unit>
      </Flex>
    </Styled.Wrapper>
  );
};

LabelAndCount.propTypes = {
  label: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

export default LabelAndCount;
