import { useMemo } from 'react';
import useSelectOptions from 'hooks/useSelectOptions';

const setRadioOption = (arr) =>
  arr.map(({ key, value }) => ({ id: key, label: value, value: key }));

export const useReverseOptions = () => {
  const { reverseScSearchCondition, ...options } = useSelectOptions([
    'reverseStatus',
    'reverseReason',
    'reverseScSearchCondition',
  ]);

  const mappingReverseScSearchCondition = useMemo(() => {
    return setRadioOption(reverseScSearchCondition);
  }, [reverseScSearchCondition]);

  return {
    ...options,
    reverseScSearchCondition: mappingReverseScSearchCondition,
  };
};
