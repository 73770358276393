import Styled from './styled';

const Input = (props) => {
  const handleKeydown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return <Styled.Wrapper onKeyDown={handleKeydown} {...props} />;
};

export default Input;
