import { getType } from 'commons/helper';
import { useState } from 'react';

/**
 * @param {*} initialState
 * @returns [state, setState, onChangeKey]
 */
const useStateKey = (initialState) => {
  const [state, setState] = useState(initialState);

  const onChangeKey = (key) => (e) => {
    const isValue = typeof e === 'object' && getType(e) !== 'date' && getType(e) !== 'array';
    const v = isValue && e !== null ? e.target.value : e;
    const newState = { ...state, [key]: v };
    if (key === 'searchOption') newState.searchWord = '';
    setState(newState);
  };

  return [state, setState, onChangeKey];
};

export default useStateKey;
