import { formatDateAsYYYYMMDD, getThisMonthStartEndDate } from 'commons/date';

export const SETTLEMENT_LIST = '/settlement/list';

export const TAX_INVOICE_TYPE_FILTER_OPTIONS = [
  { key: '', value: '전체' },
  { key: 'TAX_INVOICE', value: '세금계산서' },
  { key: 'CARD', value: '카드' },
  { key: 'CASH', value: '현금영수증' },
  { key: 'CHECK_BILL', value: '지류 세금계산서' },
];

export const SETTLEMENT_STATUS_TYPE_FILTER_OPTIONS = [
  { id: 'settlementStatusType-전체', label: '전체', value: '' },
  { id: 'settlementStatusType-정산등록', label: '정산등록', value: 'REGISTER' },
  { id: 'settlementStatusType-정산완료', label: '정산완료', value: 'COMPLETE' },
  { id: 'settlementStatusType-정산취소', label: '정산취소', value: 'NORMAL_CANCEL' },
  { id: 'settlementStatusType-비정상취소', label: '비정상취소', value: 'ABNORMAL_CANCEL' },
];

export const TAX_INVOICE_STATUS_CODE_FILTER_OPTIONS = [
  { id: 'taxInvoiceStatusCode-전체', label: '전체', value: '' },
  { id: 'taxInvoiceStatusCode-발행요청', label: '발행요청', value: 'REQUEST' },
  { id: 'taxInvoiceStatusCode-발행완료', label: '발행완료', value: 'COMPLETE' },
  { id: 'taxInvoiceStatusCode-발행거절', label: '발행거절', value: 'REFUSE' },
  { id: 'taxInvoiceStatusCode-발행취소', label: '발행취소', value: 'CANCEL' },
  { id: 'taxInvoiceStatusCode-국세청 전송완료', label: '국세청 전송완료', value: 'NTS_COMPLETE' },
  { id: 'taxInvoiceStatusCode-국세청 전송중', label: '국세청 전송중', value: 'NTS_REQUEST' },
  { id: 'taxInvoiceStatusCode-국세청 전송실패', label: '국세청 전송실패', value: 'NTS_FAILURE' },
];

export const SETTLEMENT_SEARCH_CONTITION_FILTER_OPTIONS = [
  { id: 'searchCondition-정산번호', label: '정산번호', value: 'PRODUCT_SUPPLY_SETTLEMENT' },
  { id: 'searchCondition-발주번호', label: '발주번호', value: 'PRODUCT_SUPPLY_REQUEST' },
];

export const SETTLEMENT_LIST_TABLE_COLUMNS = [
  {
    Header: '정산 개요',
    columns: [
      { Header: '정산명', accessor: 'settlementName' },
      { Header: '정산번호', accessor: 'settlementId' },
      { Header: '발주번호', accessor: 'requestIds' },
      { Header: '반품번호', accessor: 'reverseNumbers' },
      { Header: '전표 유형', accessor: 'taxInvoiceType' },
      { Header: '전표 수량', accessor: 'taxInvoiceCount' },
      { Header: '최종 정산금액 합계 (₩)', accessor: 'totalSettlementPrice' },
      { Header: '선지급금 사용액 (₩)', accessor: 'totalPrepaidPrice' },
      { Header: '정산 상태', accessor: 'settlementStatusType' },
      { Header: '정산일', accessor: 'settlementDay' },
      { Header: '정산완료일', accessor: 'settlementCompletionDay' },
    ],
  },
  {
    Header: '등록정보',
    columns: [
      { Header: '등록자', accessor: 'workerName' },
      { Header: '등록일시', accessor: 'createdAt' },
      { Header: '수정일시', accessor: 'updatedAt' },
    ],
  },
];

export const MAPPING_KEYS = {
  settlementName: '정산명',
  settlementId: '정산번호',
  requestIds: '발주번호',
  reverseNumbers: '반품번호',
  taxInvoiceType: '전표 유형',
  taxInvoiceCount: '전표 수량',
  totalSettlementPrice: '최종 정산금액 합계',
  totalPrepaidPrice: '선지급금 사용액',
  settlementStatusType: '정산 상태',
  settlementDay: '정산일',
  settlementCompletionDay: '정산완료일',
  workerName: '등록자',
  createdAt: '등록일시',
  updatedAt: '수정일시',
};

export const SEARCH_FILTER_INIT_VALUES = {
  searchOption: 'PRODUCT_SUPPLY_SETTLEMENT',
  searchWord: '',
  beforeCreatedAt: formatDateAsYYYYMMDD(getThisMonthStartEndDate().firstDay),
  afterCreatedAt: formatDateAsYYYYMMDD(getThisMonthStartEndDate().today),
  beforeProductSupplySettlementCompletionDay: null,
  afterProductSupplySettlementCompletionDay: null,
  taxInvoiceType: null,
  productSupplySettlementStatusType: null,
  productSupplyTaxInvoiceStatusCode: null,
  pageNumber: 0,
  pageSize: 50,
};

export const TABLE_OPTIONS = {
  initialState: { pageIndex: 0, pageSize: 2 },
  manualPagination: true,
};

export const SETTLEMENT_COMPLETE_LIST_DETAIL_INIT_VALUES = {
  requestTime: '',
  requestInfoList: [],
  settlementInfo: {
    discountApplyPrice: 0,
    receivedQuantityPrice: 5770000,
    settlementId: 'ST21122100572002',
    settlementName: '2021년 11월 입고분 정산',
    settlementPrice: 15140000,
    supplyCostTotal: 13763636,
    taxTotal: 1376364,
  },
  taxInvoiceInfoList: [],
  vendorInfo: {
    displayName: '지앤원',
    moneyManagerFormat: null,
    paymentDate: 'NEXT_5',
    paymentMethod: 'PREPAID',
    vendorId: 572,
  },
};

export const SUPPLY_INFO_COLUMNS = [
  { Header: '발주번호', accessor: 'productSupplyRequestId' },
  { Header: '발주유형 ', accessor: 'productSupplyTypeCode' },
  { Header: '발주상태', accessor: 'productSupplyRequestStatusCode' },
  { Header: '선지급금 정산 여부', accessor: 'prepaidSettlement' },
  { Header: '입고 품목 수', accessor: 'totalItemKindCount', isSum: true },
  { Header: '총 입고 수량', accessor: 'totalReceivedQuantity', isSum: true },
  { Header: '입고예정일', accessor: 'receiveAvailableDay' },
  { Header: '입고완료일', accessor: 'receiveCompleteDay' },
  { Header: '발주금액(₩)', accessor: 'productSupplyPrice', isSum: true },
  { Header: '입고결과 금액(₩)', accessor: 'receivedQuantityPrice', isSum: true },
  { Header: '할인적용(%)', accessor: 'discountRate' },
  { Header: '할인적용 금액(₩)', accessor: 'discountApplyPrice', isSum: true },
  { Header: '펫프담당자 메모', accessor: 'petfriendsComment' },
];

export const TAX_INVOICE_COLUMNS = [
  { Header: 'No.', accessor: 'index' },
  { Header: '적요', accessor: 'taxInvoiceItemName' },
  { Header: '공급가액', accessor: 'supplyCostTotal' },
  { Header: '세액', accessor: 'taxTotal' },
  { Header: '정산금액', accessor: 'settlementPrice' },
];

export const REVERSE_INFO_COLUMNS = [
  { Header: '반품번호', accessor: 'reverseNo' },
  { Header: '반품사유', accessor: 'reverseReason' },
  { Header: '반품상태', accessor: 'reverseStatus' },
  { Header: '출고 품목 수', accessor: 'totalReversedItemCount', isSum: true },
  { Header: '총 출고 수량', accessor: 'totalReversedQuantity', isSum: true },
  { Header: '출고예정일', accessor: 'reverseAvailableDay' },
  { Header: '출고완료일', accessor: 'reverseCompleteDay' },
  { Header: '반품금액(₩)', accessor: 'reverseAvailablePrice', isSum: true },
  { Header: '출고결과 금액(₩)', accessor: 'reversedPrice', isSum: true },
];

export default {
  SETTLEMENT_LIST,
  TAX_INVOICE_TYPE_FILTER_OPTIONS,
  SETTLEMENT_STATUS_TYPE_FILTER_OPTIONS,
  TAX_INVOICE_STATUS_CODE_FILTER_OPTIONS,
  SETTLEMENT_SEARCH_CONTITION_FILTER_OPTIONS,
  SETTLEMENT_LIST_TABLE_COLUMNS,
  SEARCH_FILTER_INIT_VALUES,
  TABLE_OPTIONS,
  SETTLEMENT_COMPLETE_LIST_DETAIL_INIT_VALUES,
  SUPPLY_INFO_COLUMNS,
  TAX_INVOICE_COLUMNS,
  REVERSE_INFO_COLUMNS,
};
