export const getConnectItemList = (selectedProductList) =>
  selectedProductList.map((item) => ({
    productItemId: item.productItemId,
    degree: item.degree,
    productStandardUnitPrice: item.standardUnitPrice,
    minimumOrderQuantity: item.minimumOrderQuantity,
    tax: item.tax,
  }));

export function addProperties(content) {
  return content.map((item) => {
    const newItem = { ...item };
    newItem.tax = newItem.tax ?? 'TAX';
    newItem.standardUnitPrice = 0;
    newItem.minimumOrderQuantity = 0;
    return newItem;
  });
}

export default { getConnectItemList, addProperties };
