export const COLUMNS = [
  { Header: '거래처 번호', accessor: 'vendorId' },
  { Header: '거래처 명', accessor: 'vendorDisplayName' },
  { Header: '사업자등록번호', accessor: 'businessNumber' },
  { Header: '계좌번호', accessor: 'accountNumber' },
  { Header: '은행', accessor: 'bankValue' },
  { Header: '예금주', accessor: 'depositor' },
  { Header: '선지급금 잔고', accessor: 'totalPrice' },

  { Header: '구분', accessor: 'actionCode' },
  { Header: '금액 입력(₩)', accessor: 'price' },
  { Header: '사유', accessor: 'reason' },
];

export default { COLUMNS };
