import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { REQUEST_STATUS_CODE } from 'commons/constant';
import { handleError, isEmpty } from 'commons/helper';
import toastify from 'commons/toast';
import { STORAGE_TEMPERATURE_TYPE_HASH, TAX_TYPE_HASH } from 'commons/hash';

import { ROW_SELECT_PLUGIN_HOOKS } from 'components/Table/commons/constant';
import { PRODUCT_SERVICES } from 'pages/vendor/ProductManagePage/commons/constant';

import * as StyledPage from 'styles';
import Table from 'components/Table';

import { COLUMNS, EDITABLE_CELLS } from './commons/constant';
import * as Styled from './styled';
import Header from './components/Header';
import {
  isOnlyTargetProductsEnabled,
  getProductSupplyRequestItem,
  getProductSupplyItemMasterRequestItem,
} from './commons/helper';

const TargetProducts = ({
  targetProducts,
  setTargetProducts,
  request,
  setRequest,
  setModalOpen,
}) => {
  const autoResetSelectedRows = useRef(true);
  const targetButton = useRef();
  const {
    productSupplyPriceRequestInfo: {
      productSupplyPriceRequestId,
      productSupplyItemMasterRequestId,
      status: statusCode,
    },
    productSupplyPriceTargetItemList,
  } = request;
  const REQUEST_IDS = {
    '가격 수정 요청': productSupplyPriceRequestId,
    '품목 정보 수정 요청': productSupplyItemMasterRequestId,
  };
  const requestId = REQUEST_IDS[request.target];
  const [selectedProductItemIds, setSelectedProductItemIds] = useState([]);

  const activeTargetProducts = targetProducts.filter(({ active }) => active !== 'N');

  const tableOptions = {
    autoResetSelectedRows: autoResetSelectedRows.current,
    defaultColumn: {
      Cell: EDITABLE_CELLS[request.target],
    },
    updateMyData: (row, columnId, value) => {
      autoResetSelectedRows.current = false;
      const editedTargetProducts = targetProducts.map((item) => {
        if (row.original.productItemId === item.productItemId) {
          return { ...item, [columnId]: value };
        }
        return item;
      });
      setTargetProducts(editedTargetProducts);
    },
  };

  const redirectToRequestsModal = () => {
    setModalOpen({ requestsModal: true });
    setRequest({
      id: null,
      productSupplyPriceRequestInfo: { status: null },
      target: request.target,
    });
  };

  const removeProducts = () => {
    if (isEmpty(selectedProductItemIds)) {
      toastify('삭제할 품목을 먼저 선택해 주십시오.', { variant: 'warning' });
      return;
    }

    const updatedTargetProducts = targetProducts.filter(
      ({ productItemId }) => !selectedProductItemIds.includes(productItemId)
    );

    setTargetProducts(updatedTargetProducts);
  };

  const setTargetButton = (value) => {
    targetButton.current = value;
  };

  useEffect(() => {
    if (!productSupplyPriceTargetItemList) {
      return;
    }
    setTargetProducts(productSupplyPriceTargetItemList);
  }, [productSupplyPriceTargetItemList]);

  useEffect(() => {
    autoResetSelectedRows.current = true;
  }, [targetProducts, selectedProductItemIds]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmpty(activeTargetProducts)) {
      toastify('대상 품목이 존재하지 않습니다.', { variant: 'warning' });
      return;
    }

    const effectiveDatesValidationList = targetProducts.map((it) =>
      Math.floor(dayjs(it.effectiveDate).diff(dayjs(), 'day', true))
    );

    if (targetButton.current === '요청' && effectiveDatesValidationList.some((it) => it < -1)) {
      toastify('과거 날짜를 선택할 수 없습니다.', { variant: 'warning' });
      return;
    }

    let requestItem;
    if (request.target === '가격 수정 요청') {
      requestItem = getProductSupplyRequestItem(targetProducts);
    } else if (request.target === '품목 정보 수정 요청') {
      requestItem = getProductSupplyItemMasterRequestItem(targetProducts);
    }

    try {
      if (targetButton.current === '임시 저장') {
        await PRODUCT_SERVICES[request.target].saveProducts(requestItem, requestId);
        toastify('임시 저장되었습니다.', { variant: 'success' });
      } else if (targetButton.current === '요청') {
        await PRODUCT_SERVICES[request.target].modifyProducts(requestItem, requestId);
        toastify('수정 요청되었습니다.', { variant: 'success' });
      }
      redirectToRequestsModal();
    } catch (error) {
      handleError(error);
    }
  };

  const handleRequestCancelClick = async () => {
    try {
      await PRODUCT_SERVICES[request.target].cancelModifyProducts(requestId);
      toastify('취소되었습니다.', { variant: 'success' });
    } catch (error) {
      handleError(error);
    } finally {
      redirectToRequestsModal();
    }
  };

  const handleSelectChange = ({ flatRows }) => {
    autoResetSelectedRows.current = false;
    const productItemIds = flatRows
      .filter(({ isSelected }) => isSelected)
      .map(({ original }) => original.productItemId);
    setSelectedProductItemIds(productItemIds);
  };

  const translate = (products) =>
    products.map((item) => ({
      ...item,
      beforeTax: TAX_TYPE_HASH[item.beforeTax],
      afterTax: TAX_TYPE_HASH[item.afterTax],
      storageTemperature: STORAGE_TEMPERATURE_TYPE_HASH[item.storageTemperature],
    }));

  return (
    <Styled.Wrapper
      as='form'
      $isStandalone={isOnlyTargetProductsEnabled(statusCode)}
      onSubmit={handleSubmit}
    >
      <StyledPage.SectionTitle>대상 품목</StyledPage.SectionTitle>
      <Header
        targetProducts={activeTargetProducts}
        setTargetProducts={setTargetProducts}
        statusCode={statusCode}
        onProductRemoveClick={removeProducts}
        setTargetButton={setTargetButton}
        onRequestCancelClick={handleRequestCancelClick}
      />
      {isOnlyTargetProductsEnabled(statusCode) ? (
        <Table columns={COLUMNS[request.target]} data={translate(activeTargetProducts)} />
      ) : (
        <Table
          columns={COLUMNS[request.target]}
          data={activeTargetProducts}
          onSelectChange={handleSelectChange}
          tableOptions={tableOptions}
          pluginHooks={ROW_SELECT_PLUGIN_HOOKS}
        />
      )}
    </Styled.Wrapper>
  );
};

TargetProducts.propTypes = {
  targetProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
  setTargetProducts: PropTypes.func.isRequired,
  request: PropTypes.shape({
    target: PropTypes.string.isRequired,
    productSupplyPriceRequestInfo: PropTypes.shape({
      status: PropTypes.oneOf([
        REQUEST_STATUS_CODE.SAVE,
        REQUEST_STATUS_CODE.REQUEST,
        REQUEST_STATUS_CODE.REJECT,
        REQUEST_STATUS_CODE.APPROVE,
        REQUEST_STATUS_CODE.CANCEL,
        null,
      ]),
      productSupplyPriceRequestId: PropTypes.number,
      productSupplyItemMasterRequestId: PropTypes.number,
    }),
    productSupplyPriceTargetItemList: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  setRequest: PropTypes.func.isRequired,
  setModalOpen: PropTypes.func.isRequired,
};

export default TargetProducts;
