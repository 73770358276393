import productPrice from 'service/product/price';
import productItem from 'service/product/item';
import { REQUEST_STATUS } from 'commons/constant';
import Underline from 'components/Underline';

export const REQUEST_INITIAL_VALUES = {
  id: null,
  target: '',
  productSupplyPriceRequestInfo: {
    status: null,
  },
};

export const PRODUCT_SERVICES = {
  '가격 수정 요청': productPrice,
  '품목 정보 수정 요청': productItem,
};

export const COLUMNS = [
  {
    Header: '상태',
    accessor: 'status',
    Cell: ({ value }) => REQUEST_STATUS[value],
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: Underline,
  },
  {
    Header: '등록 일시',
    accessor: 'createdAt',
  },
  {
    Header: '요청 일시',
    accessor: 'requestAt',
  },
  {
    Header: '승인 일시',
    accessor: 'approveAt',
  },
  {
    Header: '처리자 (펫프 담당자)',
    accessor: 'petfriendsWorker',
  },
];

export default {
  REQUEST_INITIAL_VALUES,
  PRODUCT_SERVICES,
  COLUMNS,
};
