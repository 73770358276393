export const COLUMNS = [
  {
    Header: '거래처 정보',
    columns: [
      { Header: '거래처번호', accessor: 'vendorId' },
      { Header: '거래처명', accessor: 'vendorName' },
      { Header: '정산담당자', accessor: 'moneyManager' },
      { Header: '결재방법', accessor: 'paymentMethod' },
      { Header: '결제일', accessor: 'paymentDate' },
    ],
  },
  {
    Header: '발주 정보',
    columns: [
      { Header: '발주번호', accessor: 'productSupplyRequestId' },
      { Header: '입고번호', accessor: 'inboundWikey' },
      { Header: '반품번호', accessor: 'reverseNo' },
      { Header: '출고번호', accessor: 'wokey' },
      { Header: '발주유형', accessor: 'productSupplyTypeCode' },
      { Header: '발주금액(₩)', accessor: 'productSupplyPrice' },
      { Header: '입고결과 금액(₩)', accessor: 'receivedQuantityPrice' },
      { Header: '출고결과 금액(₩)', accessor: 'reversedPrice' },
      { Header: '입고예정일', accessor: 'receiveAvailableDay' },
      { Header: '입고완료일', accessor: 'receiveCompleteDay' },
      { Header: '출고예정일', accessor: 'reverseAvailableDay' },
      { Header: '출고완료일', accessor: 'reverseCompleteDay' },
    ],
  },
];

export const MAPPING_KEYS = {
  vendorId: '거래처번호',
  vendorName: '거래처명',
  moneyManager: '정산 담당자',
  paymentMethod: '결재방법',
  paymentDate: '결제일',
  productSupplyRequestId: '발주번호',
  inboundWikey: '입고번호',
  reverseNo: '반품번호',
  wokey: '출고번호',
  productSupplyTypeCode: '발주유형',
  productSupplyPrice: '발주금액',
  receivedQuantityPrice: '입고결과 금액',
  reversedPrice: '출고결과 금액',
  receiveAvailableDay: '입고예정일',
  receiveCompleteDay: '입고완료일',
  reverseAvailableDay: '출고예정일',
  reverseCompleteDay: '출고완료일',
};

export const SETTLEMENT_EXECUTION_ALERT = {
  title: '정산 실행 클릭 - 알림 Message',
  // content: '선택한 발주가 없습니다. 먼저 목록에서 정산할 발주를 선택해 주세요.',
  component: `
      <div>선택한 발주가 없습니다.</div>
      <div>먼저 목록에서 정산할 발주를 선택해 주세요.</div>
    `,
};

const initPageSize = 1000;
export const SEARCH_FILTER_INIT_VALUES = {
  searchOption: 'PRODUCT_SUPPLY_REQUEST',
  prepaidSettlement: '',
  searchWord: '',
  paymentDate: null,
  receiveCompleteDay: [null, null],
  pageNumber: 0,
  pageSize: initPageSize,
};
export const TABLE_OPTIONS = {
  initialState: { pageIndex: 0, pageSize: initPageSize },
  manualPagination: true,
};

export default {
  COLUMNS,
  MAPPING_KEYS,
  SEARCH_FILTER_INIT_VALUES,
  SETTLEMENT_EXECUTION_ALERT,
  TABLE_OPTIONS,
};
