import PropTypes from 'prop-types';

import { Flex } from 'styles/components';
import { thousandSeparator, updateMyDataMaker } from 'commons/helper';

import Card from 'components/Card';
import Table from 'components/Table';

import EditableCell from './components/EditableCell';

import { HELPER_TEXTS, ITEM_COLUMNS, TOTAL_COLUMNS } from './commons/constant';

const SupplyProduct = ({ totalInfo, itemList, setSupplyRequestDetail, statusCode }) => {
  const tableOptions = {
    defaultColumn: {
      Cell: EditableCell,
    },
    updateMyData: updateMyDataMaker(setSupplyRequestDetail, { properties: ['itemList'] }),
    statusCode,
  };

  const renderCell = (cell) => thousandSeparator(cell.value) || 0;

  return (
    <Card title='발주 품목'>
      <Flex $flexDirection='column' $gap='var(--space-m)'>
        <div>
          <b>발주 검토하기</b>
          <ol>
            {HELPER_TEXTS.map((text) => (
              <li key={text}>{text}</li>
            ))}
          </ol>
          <small>
            ※ 여러 유통기한이 섞인 경우, 가장 빨리 도래하는 유통기한 날짜를 입력해 주세요.
          </small>
        </div>
        <Table columns={TOTAL_COLUMNS} data={[totalInfo]} renderCell={renderCell} $height='unset' />
        <Table columns={ITEM_COLUMNS} data={itemList} tableOptions={tableOptions} $height='unset' />
      </Flex>
    </Card>
  );
};

SupplyProduct.propTypes = {
  totalInfo: PropTypes.shape({
    totalAvailableReceiveQuantity: PropTypes.number,
    totalItemKindCount: PropTypes.number,
    totalItemQuantity: PropTypes.number,
    totalPrice: PropTypes.number,
    totalReceivedPrice: PropTypes.number,
  }).isRequired,
  itemList: PropTypes.arrayOf(PropTypes.object),
  setSupplyRequestDetail: PropTypes.func.isRequired,
  statusCode: PropTypes.oneOf([
    'PETFRIENDS_DRAFT',
    'PETFRIENDS_REGISTER',
    'MODIFY',
    'VENDOR_REQUEST',
    'PETFRIENDS_CONFIRM',
    'CANCEL',
    '',
  ]),
};

export default SupplyProduct;
