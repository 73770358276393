import styled from 'styled-components';

export const Div = styled.div`
  width: fit-content;
  padding-right: 5px;
  tfoot {
    background-color: var(--grey100);
    font-weight: bold;
  }
`;

export default { Div };
