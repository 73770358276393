import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const QuestionMark = ({ helperText, $position = 'center' }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [height, setHeight] = useState(0);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  return (
    <Styled.Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <span>?</span>
      {isHovered && (
        <Styled.Tooltip ref={measuredRef} $position={$position} $height={height}>
          <span>{helperText}</span>
        </Styled.Tooltip>
      )}
    </Styled.Wrapper>
  );
};

QuestionMark.propTypes = {
  helperText: PropTypes.string.isRequired,
  $position: PropTypes.oneOf(['left', 'center', 'right']),
};

export default QuestionMark;
