import instance from 'service/interceptor';

const getPrepaidInfo = async ({ pageNumber = 0, pageSize = 50 } = {}) => {
  const { data } = await instance.post('/api/v2/product/supply/prepaid/history', {
    pageNumber,
    pageSize,
  });

  return data;
};

export default { getPrepaidInfo };
