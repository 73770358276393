export const SUPPLY_REQUEST_DETAIL_INITIAL_VALUES = {
  historyList: [],
  requestInfo: {},
  receiveInfo: {
    receiveAvailableDay: null,
  },
  commentInfo: {
    petfriendsComment: '',
    vendorComment: '',
  },
  totalInfo: {},
  itemList: [],
  transportInfo: {
    transportTypeCode: '',
    transportQuantity: '',
    transportCargoTypeCode: '',
    transportInfoComment: '',
  },
  delayInfo: {
    receiveDelay: '',
    receiveDelayAvailableDay: null,
    receiveDelayReason: '',
  },
};

export const NEXT_STATUS_CODE = {
  PETFRIENDS_REGISTER: 'MODIFY',
};

export default { SUPPLY_REQUEST_DETAIL_INITIAL_VALUES };
