import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import productApi from 'service/hq/product';
import toastify from 'commons/toast';
import { usePageModals } from 'hooks/pages';

import { ROW_SELECT_PLUGIN_HOOKS } from 'components/Table/commons/constant';
import HeaderModal from 'components/modals/HeaderModal';
import TextareaSearchbar from 'components/TextareaSearchbar';
import { handleError, isEmpty, updateMyDataMaker } from 'commons/helper';
import PaginationTable from 'components/Table/PaginationTable';
import ResultHeader from 'components/ResultHeader';
import Button from 'components/Button';

import { PRODUCT_COLUMNS, VENDOR_PRODUCT_LIST } from '../../commons/constant';
import { productSearchCondition } from './commons/constant';
import EditableCell from './components/EditableCell';
import { getConnectItemList, addProperties } from './commons/helper';

const ProductConnectionModal = () => {
  const [searchOptions, setSearchOptions] = useState({ query: '', condition: 'PRODUCT_ITEM_ID' });
  const [productList, setProductList] = useState({ content: [], totalElements: 0, totalPages: 0 });
  const [selectedProductIndexes, setSelectedProductIndexes] = useState([]);
  const [controlledPage, setControlledPage] = useState(0);
  const searchQuery = useRef('');
  const autoResetSelectedRows = useRef();
  const { selectedVendor } = useSelector(({ vendorProduct }) => vendorProduct[VENDOR_PRODUCT_LIST]);
  const [{ detail }, setModalOpen] = usePageModals();

  const toggleModal = () => setModalOpen('detail');

  const tableOptions = useMemo(
    () => ({
      initialState: { pageIndex: 0, pageSize: 50 },
      manualPagination: true,
      pageCount: productList.totalPages,
      useControlledState: (state) => ({
        ...state,
        pageIndex: controlledPage,
      }),
      autoResetSelectedRows: autoResetSelectedRows.current,
      defaultColumn: {
        Cell: EditableCell,
      },
      updateMyData: updateMyDataMaker(setProductList, {
        properties: ['content'],
        autoResetSelectedRows,
      }),
    }),
    [productList.totalPages, controlledPage, autoResetSelectedRows.current]
  );

  const fetchProductList = async ({
    vendorId,
    searchCondition = 'PRODUCT_NAME',
    searchContent = '',
    pageIndex = 0,
    pageSize = 50,
  } = {}) => {
    try {
      const { response } = await productApi.getProductConnectionList({
        vendorId,
        searchCondition,
        searchContent,
        pageNumber: pageIndex,
        pageSize,
      });
      const { content, totalElements, totalPages } = response.data;
      setProductList({ content: addProperties(content), totalElements, totalPages });
    } catch (error) {
      handleError(error);
    }
  };

  const handleSearchClick = () => {
    searchQuery.current = searchOptions.query;
    setControlledPage(0);
    fetchProductList({
      searchCondition: searchOptions.condition,
      searchContent: searchQuery.current,
      vendorId: selectedVendor.id,
    });
  };

  useEffect(() => {
    autoResetSelectedRows.current = true;
  }, [productList.content]);

  useEffect(() => {
    if (!detail) {
      setSearchOptions({ query: '', condition: 'PRODUCT_ITEM_ID' });
      setProductList({ content: [], totalElements: 0, totalPages: 0 });
      setControlledPage(0);
      searchQuery.current = '';
      return;
    }
    handleSearchClick();
    // fetchProductList();
  }, [detail]);

  const handleSearchConditionChange = (e) =>
    setSearchOptions((prev) => ({ ...prev, condition: e.target.value }));
  const handleSearchQueryChange = (e) =>
    setSearchOptions((prev) => ({ ...prev, query: e.target.value }));

  const handlePageChange = useCallback(
    (tableInstance) => {
      fetchProductList({
        ...tableInstance.state,
        searchCondition: searchOptions.condition,
        searchContent: searchQuery.current,
        vendorId: selectedVendor.id,
      });
    },
    [searchOptions.condition, selectedVendor]
  );

  const handleProductConnection = async (e) => {
    e.preventDefault();
    if (isEmpty(selectedProductIndexes)) {
      toastify('선택된 품목이 존재하지 않습니다.', { variant: 'warn' });
      return;
    }

    const selectedProductList = productList.content.filter((_, index) =>
      selectedProductIndexes.includes(index)
    );

    const taxList = selectedProductList.map(({ tax }) => tax);
    const is과세구분통일이니 = !(
      taxList.length > 1 && taxList.filter((it, index) => taxList.indexOf(it) === index).length > 1
    );

    if (!is과세구분통일이니) {
      toastify('동일한 과세구분만 저장할 수 있습니다.', { variant: 'warn' });
      return;
    }

    try {
      await productApi.connectProductListWithVendor(
        selectedVendor.id,
        getConnectItemList(selectedProductList)
      );
      toastify('선택한 품목이 연결되었습니다.', { variant: 'success' });
      toggleModal();
    } catch (error) {
      handleError(error);
    }
  };

  const handleProductSelect = useCallback(({ selectedFlatRows }) => {
    const productIndexes = selectedFlatRows.map(({ index }) => index);
    setSelectedProductIndexes(productIndexes);
  }, []);

  const headerButtons = [
    <Button $variant='secondary' type='submit'>
      선택한 품목 연결
    </Button>,
  ];

  return (
    <HeaderModal
      isOpen={detail}
      closeCallback={toggleModal}
      title='품목 연결'
      $size='xl'
      as='form'
      onSubmit={handleProductConnection}
    >
      <TextareaSearchbar
        name='vendorSearchCondition'
        textareaValue={searchOptions.query}
        onTextareaChange={handleSearchQueryChange}
        radios={productSearchCondition}
        onSearchClick={handleSearchClick}
        checkedValue={searchOptions.condition}
        onCheckedValueChange={handleSearchConditionChange}
        placeholder='검색어를 입력해 주세요. 품목 ID인 경우, 복수 품목을 검색 할 수 있습니다. (Enter 또는 ","로 구분, 최대 50개)'
      />

      <ResultHeader totalCount={productList.totalElements} buttons={headerButtons} />
      <PaginationTable
        columns={PRODUCT_COLUMNS}
        data={productList.content}
        onPageChange={handlePageChange}
        tableOptions={tableOptions}
        pluginHooks={ROW_SELECT_PLUGIN_HOOKS}
        setControlledPage={setControlledPage}
        onSelectChange={handleProductSelect}
        $height='calc(95vh - 380px)'
      />
    </HeaderModal>
  );
};

export default ProductConnectionModal;
