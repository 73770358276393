export function addAdditionalColumns(content) {
  return content.map((item) => {
    const newItem = { ...item };
    newItem.actionCode = 'SAVE';
    newItem.price = 0;
    newItem.reason = '';
    return newItem;
  });
}

export function filterParameters(content) {
  return content.map(({ vendorId, actionCode, price, reason }) => ({
    vendorId,
    actionCode,
    price: actionCode === 'SAVE' ? price : -price,
    reason,
  }));
}
