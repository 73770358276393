import { useState, useCallback, useEffect } from 'react';

import { thousandSeparator } from 'commons/helper';

import TotalTable from '../../components/TotalTable';

function ReverseContainer({ columns, datas = {}, index = 0, reverseStatus, reverseReason }) {
  const [reverseList, setReverseList] = useState([]);

  const getValue = (options, targetKey) =>
    options.find(({ key }) => key === targetKey)?.value ?? '';

  const renderCell = useCallback(({ column, value }) => {
    const { id } = column;
    return {
      reverseReason: getValue(reverseReason, value),
      reverseStatus: getValue(reverseStatus, value),
      totalReversedItemCount: thousandSeparator(value) || 0,
      totalReversedQuantity: thousandSeparator(value) || 0,
      reversedPrice: thousandSeparator(value) || 0,
      reverseAvailablePrice: thousandSeparator(value) || 0,
      ...column,
    }[id];
  }, []);

  useEffect(() => {
    setReverseList(datas[index]?.reverses);
  }, [index]);

  return (
    <div>
      <br />
      <h5 style={{ padding: '1rem 0' }}>
        대상 반품 정보 <b>{reverseList?.length ?? 0}</b>건
      </h5>
      <TotalTable
        columns={columns}
        data={reverseList}
        renderCell={renderCell}
        setData={setReverseList}
        $height='unset'
      />
    </div>
  );
}

export default ReverseContainer;
