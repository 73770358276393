import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { STORAGE_TEMPERATURE_TYPE_HASH, TAX_TYPE_HASH } from 'commons/hash';
import {
  handleError,
  addAllOption,
  getRadioOption,
  setObjectState,
  translateList,
  thousandSeparator,
  isEmpty,
} from 'commons/helper';
import { downloadJsonAsExcel } from 'commons/excel';
import useSelectOptions from 'hooks/useSelectOptions';
import productCommon from 'service/product/common';
import useFetch from 'hooks/useFetch';
import toastify from 'commons/toast';

import Card from 'components/Card';
import Select from 'components/Select';
import Searchbar from 'components/Searchbar';
import Button from 'components/Button';
import PaginationTable from 'components/Table/PaginationTable';
import RadioGroup from 'components/RadioGroup';
import ResultHeader from 'components/ResultHeader';

import { useGlobalModals } from 'hooks/pages';

import { Flex } from 'styles/components';

import {
  SELECT_OPTIONS,
  SEARCH_OPTIONS_INITIAL_VALUES,
  COLUMNS,
  MAPPING_KEYS,
} from './commons/constant';

function ProductManage({ setModalOpen, setRequest }) {
  const [searchOptions, setSearchOptions] = useState(SEARCH_OPTIONS_INITIAL_VALUES);
  const [controlledPageIndex, setControlledPage] = useState(0);
  const searchOptionsSnapshot = useRef(SEARCH_OPTIONS_INITIAL_VALUES);
  const { itemTypeCode, stockProductStatus, productSearchCondition } =
    useSelectOptions(SELECT_OPTIONS);

  const { data: { content = [], totalPages = 0, totalElements = 0 } = {}, execute: fetchProducts } =
    useFetch(productCommon.getProducts);

  const { 1: setGlobalModalOpen } = useGlobalModals();

  const tableOptions = useMemo(
    () => ({
      initialState: { pageIndex: 0, pageSize: 100 },
      manualPagination: true,
      pageCount: totalPages,
      useControlledState: (state) => ({
        ...state,
        pageIndex: controlledPageIndex,
      }),
    }),
    [totalPages, controlledPageIndex]
  );

  const handleSearchClick = () => {
    searchOptionsSnapshot.current = searchOptions;
    fetchProducts({
      productStatus: searchOptions.stockProductStatus,
      productType: searchOptions.itemTypeCode,
      searchCondition: searchOptions.productSearchCondition,
      searchContent: searchOptions.query,
    });
    setControlledPage(0);
  };

  const handleResetButtonClick = () => {
    setSearchOptions(SEARCH_OPTIONS_INITIAL_VALUES);
    searchOptionsSnapshot.current = SEARCH_OPTIONS_INITIAL_VALUES;
    setControlledPage(0);
    fetchProducts();
  };

  const handleRequestsModalOpen = (target) => {
    setModalOpen({ requestsModal: true });
    setRequest((state) => ({ ...state, target }));
  };

  const translate = (productList) =>
    productList.map((item) => ({
      ...item,
      tax: TAX_TYPE_HASH[item.tax],
      storageTemperature: STORAGE_TEMPERATURE_TYPE_HASH[item.storageTemperature],
    }));

  const handleExcelDownload = async () => {
    if (isEmpty(content)) {
      toastify('품목 결과가 존재하지 않습니다.', { variant: 'warn' });
      return;
    }

    setGlobalModalOpen('activityIndicator', true);

    try {
      const { response } = await productCommon.getProducts({
        pageNumber: 0,
        pageSize: 10000,
        searchContent: searchOptionsSnapshot.current.query,
        productType: searchOptionsSnapshot.current.itemTypeCode,
        productStatus: searchOptionsSnapshot.current.stockProductStatus,
        searchCondition: searchOptionsSnapshot.current.productSearchCondition,
      });
      downloadJsonAsExcel({
        data: translateList(translate(response.data.content), MAPPING_KEYS),
        sheetName: '품목 리스트',
        fileName: '품목 리스트',
      });
    } catch (error) {
      handleError(error);
    } finally {
      setGlobalModalOpen('activityIndicator', false);
    }
  };

  const handleChange = (e) => setObjectState(setSearchOptions, e.target.name, e.target.value);
  const handleQueryChange = (e) => setObjectState(setSearchOptions, 'query', e.target.value);

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleTablePageChange = useCallback(
    ({ state }) => {
      fetchProducts({
        pageNumber: state.pageIndex,

        productStatus: searchOptionsSnapshot.current.stockProductStatus,
        productType: searchOptionsSnapshot.current.itemTypeCode,
        searchCondition: searchOptionsSnapshot.current.productSearchCondition,
        searchContent: searchOptionsSnapshot.current.query,
      });
    },
    [fetchProducts, searchOptionsSnapshot.current]
  );

  const renderCell = (cell) => {
    switch (cell.column.id) {
      case 'tax':
        return TAX_TYPE_HASH[cell.value];
      case 'storageTemperature':
        return STORAGE_TEMPERATURE_TYPE_HASH[cell.value];
      case 'standardUnitPrice':
      case 'minimumOrderQuantity':
        return thousandSeparator(cell.value) || 0;
      default:
        return cell.value;
    }
  };

  const resultHeaderButtons = [
    <Button $variant='secondary' onClick={() => handleRequestsModalOpen('가격 수정 요청')}>
      가격 수정
    </Button>,
    <Button $variant='secondary' onClick={() => handleRequestsModalOpen('품목 정보 수정 요청')}>
      품목 정보 수정
    </Button>,
    <Button $variant='light' onClick={handleExcelDownload}>
      엑셀 다운로드
    </Button>,
  ];

  return (
    <Card title='품목 관리'>
      <Flex $flexDirection='column' $gap='var(--space-m)'>
        <Flex $gap='var(--space-m)'>
          <Select
            id='itemTypeCode'
            name='itemTypeCode'
            label='품목 유형'
            options={addAllOption(itemTypeCode)}
            value={searchOptions.itemTypeCode}
            onChange={handleChange}
          />
          <Select
            id='stockProductStatus'
            name='stockProductStatus'
            label='상태'
            options={addAllOption(stockProductStatus)}
            value={searchOptions.stockProductStatus}
            onChange={handleChange}
          />
        </Flex>

        <RadioGroup
          title='검색 조건'
          radios={getRadioOption(productSearchCondition)}
          name='productSearchCondition'
          checkedValue={searchOptions.productSearchCondition}
          onCheckedValueChange={handleChange}
        />
        <Searchbar
          name='query'
          value={searchOptions.query}
          onChange={handleQueryChange}
          onClick={handleSearchClick}
          onReset={handleResetButtonClick}
        />
      </Flex>

      <ResultHeader totalCount={totalElements} buttons={resultHeaderButtons} />
      <PaginationTable
        columns={COLUMNS}
        data={content}
        onPageChange={handleTablePageChange}
        tableOptions={tableOptions}
        setControlledPage={setControlledPage}
        renderCell={renderCell}
      />
    </Card>
  );
}

ProductManage.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  setRequest: PropTypes.func.isRequired,
};

export default ProductManage;
