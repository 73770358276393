import styled from 'styled-components';

export const Div = styled.div`
  margin-top: var(--space-l);

  tbody {
    td:last-child {
      min-width: 250px;
    }
  }
`;

export default { Div };
