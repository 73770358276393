import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { Divider } from 'styles';

import { SUPPLY_INFO_COLUMNS, TAX_INVOICE_COLUMNS } from '../../commons/constant';
import Styled from './styled';

import SupplyInfoContainer from '../SupplyInfoContainer';
import VendorSettlementCompleteTaxArea from '../TaxInvoiceContainer/components/VendorSettlementCompleteTaxArea';
import FinalSettlementInfoContainer from '../FinalSettlementInfoContainer';

import SettlementDetailHeader from '../../components/SettlementDetailHeader';
import SettlementDetailFooter from '../../components/SettlementDetailFooter';
import ReverseContainer from '../../components/ReverseContainer';

/**
 * @param {*} props - { onClose = () => {} }
 */
const SettlementListDetail = ({ onClose = () => {} }) => {
  const { path } = useRouteMatch();
  const {
    detailData: {
      requestInfoList,
      taxInvoiceItemInfos,
      settlementInfo,
      taxInvoiceInfoList,
      reverses,
    },
    selectedSettlementId,
  } = useSelector((state) => state.vendorSettlement[path]);

  return (
    <div>
      <SettlementDetailHeader columns={SUPPLY_INFO_COLUMNS} settlementInfo={settlementInfo} />
      <SupplyInfoContainer
        columns={SUPPLY_INFO_COLUMNS}
        requestInfoList={requestInfoList}
        selectedSettlementId={selectedSettlementId}
      />
      <ReverseContainer data={reverses} />
      <br />
      <VendorSettlementCompleteTaxArea
        columns={TAX_INVOICE_COLUMNS}
        taxInvoiceInfoList={taxInvoiceInfoList}
        taxInvoiceItemInfos={taxInvoiceItemInfos}
      />
      <Divider $backgroundColor='#b1b1b1' />
      <br />

      <FinalSettlementInfoContainer settlementInfo={settlementInfo} />
      <br />
      <Divider $backgroundColor='#b1b1b1' />
      <SettlementDetailFooter
        noticeList={[
          '위 정산 내역은 입고 실적에 따라 계산된 금액이며 위 내용대로 정산이 진행됩니다.',
          '정산에 대해 이의가 있는 경우, 담당 발주 담당자에게 연락 바랍니다.',
        ]}
      />
      <Styled.Button onClick={onClose}>닫기</Styled.Button>
    </div>
  );
};

SettlementListDetail.propTypes = { onClose: PropTypes.func };

export default SettlementListDetail;
