import Button from 'components/Button';
import Card from 'components/Card';
import Close from 'icons/png/close.png';
import useFetch from 'hooks/useFetch';
import { useGlobalModals } from 'hooks/pages';
import { translateObjectKeys, handleError } from 'commons/helper';
import { downloadJsonAsExcel } from 'commons/excel';
import { SideBarWrapper, CloseButton, SideBarText, ButtonWrapper } from './SideBar.style';
import SideBarTable from './SideBarTable';
import { postReceiveScheduled } from '../../apis';
import { MAPPING_KEYS } from './constant';

const INBOUND_DETAIL_THEAD = [
  { id: '1', label: '운송유형' },
  { id: '2', label: '품목(sku)' },
  { id: '3', label: '수량(pcs)' },
  { id: '4', label: '금액(원)' },
];

const MANAGERS_DETAIL_THEAD = [
  { id: '11', label: '발주담당자' },
  { id: '12', label: '품목(sku)' },
  { id: '13', label: '수량(pcs)' },
  { id: '14', label: '금액(원)' },
];

function SideBar({ onClose, visible, dailyData, title, centerId }) {
  const { transportTypes, total, unreceived, managers } = dailyData;
  const [, setModalOpen] = useGlobalModals();
  const { execute } = useFetch(postReceiveScheduled, {
    initialExecute: false,
  });

  const translateList = (list) => {
    return list.map((item) => translateObjectKeys(item, MAPPING_KEYS));
  };

  const downloadExcel = async () => {
    setModalOpen('activityIndicator', true);

    try {
      const result = await execute({ searchDate: title, centerId });

      downloadJsonAsExcel({
        data: translateList(result?.data),
        sheetName: `${title}_입고예정리스트`,
        fileName: `${title}_입고예정리스트`,
      });
    } catch (err) {
      handleError(err);
    } finally {
      setModalOpen('activityIndicator', false);
    }
  };

  return (
    <SideBarWrapper visible={visible}>
      <Card title={title}>
        <CloseButton type='button' onClick={onClose}>
          <img src={Close} alt='사이드바 닫기' />
        </CloseButton>
        <div>
          <SideBarText>입고상세</SideBarText>
          <SideBarTable
            width='25%'
            thead={INBOUND_DETAIL_THEAD}
            tbody={transportTypes ?? []}
            tfoot={[
              { ...total, label: 'TOTAL' },
              { ...unreceived, label: '미입고' },
            ]}
          />
          <ButtonWrapper>
            <Button $variant='success' $size='small' onClick={downloadExcel}>
              입고예정서 다운로드
            </Button>
          </ButtonWrapper>
          <SideBarText>담당자 상세</SideBarText>
          <SideBarTable height='20px' thead={MANAGERS_DETAIL_THEAD} tbody={managers ?? []} />
        </div>
      </Card>
    </SideBarWrapper>
  );
}

export default SideBar;
