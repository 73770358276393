import {
  ReverseIdCell,
  NumberCell,
  DisabledInputCell,
  VendorIdChoiceButton,
  ReverseCountCell,
} from './components/Cells';

export const INITIAL_STATE = {
  status: '',
  reason: '',
  centerId: '',
  createdDay: [null, null],
  completeDay: [null, null],
  searchWord: '',
  searchCondition: 'VENDOR_ID',
};

export const REVERSE_REASON_OPTIONS = [
  { key: '제품불량', value: '제품불량' },
  { key: 'CS', value: 'CS' },
  { key: '제품변질', value: '제품변질' },
  { key: '파손', value: '파손' },
  { key: '구성품 누락', value: '구성품 누락' },
  { key: '판매부진', value: '판매부진' },
];

export const SEARCH_RADIOS_OPTIONS = [
  {
    id: 'PRODUCT_ID',
    label: '품목코드',
    value: 'PRODUCT_ID',
  },
  {
    id: 'PRODUCT_SUPPLY_REQUEST',
    label: '발주번호',
    value: 'PRODUCT_SUPPLY_REQUEST',
  },
  {
    id: 'INBOUND_ID',
    label: '입고번호',
    value: 'INBOUND_ID',
  },
  {
    id: 'VENDOR_ID',
    label: '거래처번호',
    value: 'VENDOR_ID',
  },
  {
    id: 'VENDOR_NAME',
    label: '거래처명',
    value: 'VENDOR_NAME',
  },
];

export const EXCEL_MAPPING_KEYS = {
  vendorId: '거래처번호',
  vendorName: '거래처명',
  reverseNo: '반품번호',
  reverseStatus: '반품상태',
  reverseReason: '반품사유',
  purchaseOrderId: '발주번호',
  inboundWikey: '입고번호',
  centerName: '출고센터',
  wokey: '출고번호',
  availableDay: '출고예정일',
  completeDay: '출고완료일',
  productItemId: '품목코드',
  productName: '품목명',
  reverseAvailableQuantity: '출고예정수량',
  reversedQuantity: '출고결과수량',
  unreversedQuantity: '미출고수량',
  reversedPrice: '출고결과금액(₩)',
  unreversedPrice: '미출고금액(₩)',
  createdUserName: '등록자',
  createdAt: '등록일시',
};

export const MAIN_COLUMNS = [
  {
    Header: '거래처 정보',
    columns: [
      {
        Header: '거래처번호',
        accessor: 'vendorId',
      },
      {
        Header: '거래처명',
        accessor: 'vendorName',
      },
    ],
  },
  {
    Header: '반품 정보',
    columns: [
      {
        Header: '반품번호',
        accessor: 'reverseNo',
        Cell: ReverseIdCell,
      },
      {
        Header: '반품상태',
        accessor: 'reverseStatus',
      },
      {
        Header: '반품사유',
        accessor: 'reverseReason',
      },
      {
        Header: '발주번호',
        accessor: 'purchaseOrderId',
      },
      {
        Header: '입고번호',
        accessor: 'inboundWikey',
      },
    ],
  },
  {
    Header: '출고 정보',
    columns: [
      {
        Header: '출고센터',
        accessor: 'centerName',
      },
      {
        Header: '출고번호',
        accessor: 'wokey',
      },
      {
        Header: '출고예정일',
        accessor: 'availableDay',
      },
      {
        Header: '출고완료일',
        accessor: 'completeDay',
      },
      {
        Header: '품목코드',
        accessor: 'productItemId',
      },
      {
        Header: '품목명',
        accessor: 'productName',
      },
      {
        Header: '출고예정수량',
        accessor: 'reverseAvailableQuantity',
        Cell: NumberCell,
      },
      {
        Header: '출고결과수량',
        accessor: 'reversedQuantity',
        Cell: NumberCell,
      },
      {
        Header: '미출고수량',
        accessor: 'unreversedQuantity',
        Cell: NumberCell,
      },
      {
        Header: '출고결과금액(₩)',
        accessor: 'reversedPrice',
        Cell: NumberCell,
      },
      {
        Header: '미출고금액(₩)',
        accessor: 'unreversedPrice',
        Cell: NumberCell,
      },
    ],
  },
  {
    Header: '등록 정보',
    columns: [
      {
        Header: '등록자',
        accessor: 'createdUserName',
      },
      {
        Header: '등록일시',
        accessor: 'createdAt',
      },
    ],
  },
];

export const STEP1_COLUMNS = [
  {
    Header: '발주번호',
    accessor: 'purchaseOrderId',
  },
  {
    Header: '입고번호',
    accessor: 'inboundWikey',
  },
  {
    Header: '거래처 번호',
    accessor: 'vendorId',
  },
  {
    Header: '거래처명',
    accessor: 'vendorName',
  },
  {
    Header: '입고센터',
    accessor: 'centerName',
  },
  {
    Header: '발주금액',
    accessor: 'purchaseOrderPrice',
    Cell: NumberCell,
  },
  {
    Header: '발주요청일',
    accessor: 'purchaseOrderRequestDay',
  },
  {
    Header: ' ',
    accessor: '8',
    Cell: VendorIdChoiceButton,
  },
];

export const STEP2_COLUMNS = [
  {
    Header: '품목코드',
    accessor: 'productItemId',
  },
  {
    Header: '품목유형',
    accessor: 'itemType',
  },
  {
    Header: '품목명',
    accessor: 'productName',
  },
  {
    Header: '과세구분',
    accessor: 'taxationType',
  },
  {
    Header: '적용공급가',
    accessor: 'purchaseOrderUnitPrice',
    Cell: NumberCell,
  },
  {
    Header: '발주수량',
    accessor: 'requestQuantity',
    Cell: NumberCell,
  },
  {
    Header: '발주금액',
    accessor: 'requestPrice',
    Cell: NumberCell,
  },
  {
    Header: '반품수량',
    accessor: 'reverseAvailableQuantity',
    Cell: ReverseCountCell,
  },
  {
    Header: '반품금액(₩)',
    accessor: 'reverseAvailablePrice',
    Cell: NumberCell,
  },
];

export const DETAIL_COLUMNS = [
  {
    Header: '품목코드',
    accessor: 'productItemId',
  },
  {
    Header: '품목유형',
    accessor: 'itemType',
  },
  {
    Header: '품목명',
    accessor: 'productName',
  },
  {
    Header: '과세구분',
    accessor: 'taxationType',
  },
  {
    Header: '적용공급가',
    accessor: 'purchaseOrderUnitPrice',
    Cell: NumberCell,
  },
  {
    Header: '발주수량',
    accessor: 'requestQuantity',
    Cell: NumberCell,
  },
  {
    Header: '발주금액',
    accessor: 'requestPrice',
    Cell: NumberCell,
  },
  {
    Header: '입고수량',
    accessor: 'receivedQuantity',
    Cell: NumberCell,
  },
  {
    Header: '입고금액',
    accessor: 'receivedPrice',
    Cell: NumberCell,
  },
  {
    Header: '반품수량',
    accessor: 'reverseAvailableQuantity',
    Cell: DisabledInputCell,
  },
  {
    Header: '반품금액(₩)',
    accessor: 'reverseAvailablePrice',
    Cell: NumberCell,
  },
];

export const TRANSFER_TYPE = [
  {
    key: 'TRUCK',
    value: '차량',
  },
  {
    key: 'PARCEL',
    value: '택배',
  },
];

export const ETC_OPTIONS_VALUE_TABLE = {
  collect: {
    reverseRecipient: '거래처 직접 회수',
    reverseZipcode: '06038',
    reverseAddress: '서울시 강남구 도산대로 4길 15',
    reverseContact: '02-511-6381',
  },
  dispose: {
    reverseRecipient: '자체 폐기',
    reverseZipcode: '06038',
    reverseAddress: '서울시 강남구 도산대로 4길 15',
    reverseContact: '02-511-6381',
  },
  default: {
    reverseRecipient: '',
    reverseZipcode: '',
    reverseAddress: '',
    reverseContact: '',
  },
};
