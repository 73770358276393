import PropTypes from 'prop-types';
import * as Styled from './styled';

const LabelAndText = ({ label, text, children, ...props }) => {
  return (
    <Styled.Wrapper {...props}>
      <Styled.Label>{label}</Styled.Label>
      <p>{text}</p>
      {children}
    </Styled.Wrapper>
  );
};

LabelAndText.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default LabelAndText;
