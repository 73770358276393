export const MANAGER_INFO_COLUMNS = [
  { Header: '구분', accessor: 'position' },
  { Header: '성명 ', accessor: 'manager' },
  { Header: '연락처', accessor: 'telNo' },
  { Header: '이메일', accessor: 'email' },
];

export const TARGET_INDEX = 1;

export const VIEW_MANAGE_TABLE_INFO = [
  {
    key: 'vendor',
    label: '거래처 담당자',
    isVendorTable: true,
  },
  {
    key: 'petfriends',
    label: '펫프렌즈 담당자',
    isVendorTable: false,
  },
];

export default { MANAGER_INFO_COLUMNS, VIEW_MANAGE_TABLE_INFO, TARGET_INDEX };
