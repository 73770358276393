import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import useFetch from 'hooks/useFetch';
import { useGlobalModals } from 'hooks/pages';
import {
  getSettlementDetail,
  postSettlementComplete,
  postSettlementCancel,
} from 'service/hq/settlement';
import { fetchSettlementCompleteList } from 'commons/store/modules/admin/settlement';
import { handleError } from 'commons/helper';
import toastify from 'commons/toast';
import { Divider } from 'styles';

import ReverseContainer from 'pages/admin/settlement/containers/ReverseContainer';
import useSelectOptions from 'hooks/useSelectOptions';
import {
  SUPPLY_INFO_COLUMNS,
  TAX_INVOICE_DETAIL_COLUMNS,
  REVERSE_INFO_COLUMNS,
} from '../../constant';
import Styled from './styled';
import SettlementCompleteTaxArea from './components/SettlementCompleteTaxArea';

import SupplyInfoDetailContainer from '../SupplyInfoDetailContainer';
import FinalSettlementCompleteContainer from '../FinalSettlementCompleteContainer';

import SettlementCompleteDetailHeader from './SettlementCompleteDetailHeader';
import SettlementDetailFooter from '../../components/SettlementDetailFooter';

const SettlementCompleteDetail = ({ isOpen = false, onClose, match: { path } }) => {
  const dispatch = useDispatch();
  const [, setModalOpen] = useGlobalModals();
  const handleClose = () => {
    dispatch(fetchSettlementCompleteList());
    onClose();
  };
  const { selectedSettlementId } = useSelector((state) => state.settlement[path]);
  const options = useSelectOptions(['reverseStatus', 'reverseReason']);

  const {
    data,
    execute: fetchGetSettlementDetail,
    isLoading,
  } = useFetch(getSettlementDetail, {
    initialExecute: false,
  });

  const { execute: fetchPostSettlementComplete } = useFetch(postSettlementComplete, {
    initialExecute: false,
  });

  const { execute: fetchPostSettlementCancel } = useFetch(postSettlementCancel, {
    initialExecute: false,
  });

  useEffect(() => {
    if (isOpen) {
      fetchGetSettlementDetail(selectedSettlementId);
    }
  }, [isOpen]);

  const handleSettlementCompleteClick = async () => {
    const { error, data: completeData } = await fetchPostSettlementComplete(selectedSettlementId);

    if (completeData) {
      toastify('정산 완료 처리 되었습니다');
      handleClose();
    } else if (error) {
      handleError(error);
    }
  };
  const handleSettlementCancelClick = () => {
    const onConfirmClick = async () => {
      const { error, data: completeData } = await fetchPostSettlementCancel(selectedSettlementId);
      if (completeData) {
        toastify('정산 취소 처리 되었습니다');
        handleClose();
      } else if (error) {
        handleError(error);
      }

      setModalOpen('alert', false);
    };
    const onCancelClick = () => {
      setModalOpen('alert', false);
    };

    setModalOpen('alert', true, {
      title: '정산 취소 클릭',
      content: '정산을 취소하고, 모든 세금계산서를 삭제 처리합니다. 실행하시겠습니까?',
      buttons: [
        { text: '예', onClick: onConfirmClick },
        { text: '아니오', onClick: onCancelClick },
      ],
    });
  };

  return (
    <div>
      {isOpen && !isLoading && (
        <Styled.Div>
          <SettlementCompleteDetailHeader data={data} />
          <SupplyInfoDetailContainer
            columns={SUPPLY_INFO_COLUMNS}
            data={data}
            execute={fetchGetSettlementDetail}
          />
          <ReverseContainer
            columns={REVERSE_INFO_COLUMNS}
            datas={{ 0: data }}
            index={0}
            reverseStatus={options.reverseStatus}
            reverseReason={options.reverseReason}
          />
          <br />
          <SettlementCompleteTaxArea columns={TAX_INVOICE_DETAIL_COLUMNS} data={data ?? {}} />
          <Divider $backgroundColor='#b1b1b1' />
          <FinalSettlementCompleteContainer
            data={data}
            finalWarningText='위 세금계산서에 대해서 입금을 완료한 경우, 아래 버튼을 클릭해 정산완료 처리를 해주세요.'
            onCompleteButtonClick={handleSettlementCompleteClick}
            onCancelButtonClick={handleSettlementCancelClick}
          />
          <Divider $backgroundColor='#b1b1b1' />
          <SettlementDetailFooter
            noticeList={[
              '카드 전표를 등록할 때는 반드시 전표에 표시된 결제금액과 발주금액이 일치하는 지 확인 바랍니다.',
              '거래처가 세금계산서 발행을 1건이라도 거절하는 경우에는, 모든 세금계산서 발행요청이 취소되며, 다시 처음부터 정산을 진행해야 합니다',
              ' 선지급금은 세금계산서 실제 발행 및 국세청 전송과 무관하게 선 차감되며, 세금계산서 발행 거절 시 복원됩니다.',
              '정산을 실행하면, 정산 대상 목록에서 사라집니다.',
            ]}
          />
        </Styled.Div>
      )}
    </div>
  );
};

SettlementCompleteDetail.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default SettlementCompleteDetail;
