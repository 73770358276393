import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import toastify from 'commons/toast';
import { downloadJsonAsExcel } from 'commons/excel';
import {
  getHash,
  handleError,
  isEmpty,
  thousandSeparator,
  translateFieldsByHash,
  translateList,
} from 'commons/helper';
import { setModalIsOpen } from 'commons/store/modules/modals';
import { fetchSettlementList, setSelectedDatas } from 'commons/store/modules/admin/settlement';
import { useDispatchModals } from 'hooks/pages';
import { postRequestSettlementList } from 'service/hq/settlement';

import Card from 'components/Card';
import Button from 'components/Button';
import { ROW_SELECT_PLUGIN_HOOKS } from 'components/Table/commons/constant';
import PaginationTable from 'components/Table/PaginationTable';

import {
  COLUMNS,
  MAPPING_KEYS,
  SETTLEMENT_EXECUTION_ALERT,
  TABLE_OPTIONS,
} from '../../commons/constant';

import Styled from './styled';

const TableContainer = ({ match: { path } }) => {
  const dispatch = useDispatch();
  const {
    searchFilter,
    tableData = [],
    selectedDatas,
    totalElements,
    totalPages,
  } = useSelector((state) => state.settlement[path]);
  const [controlledPage, setControlledPage] = useState(0);
  const setModalOpen = useDispatchModals(path);

  const handlePageChange = ({ state: { pageIndex } }) => {
    dispatch(
      fetchSettlementList({
        ...searchFilter,
        pageNumber: pageIndex,
      })
    );
  };
  const handleRowSelectionChange = ({ selectedFlatRows }) => {
    dispatch(
      setSelectedDatas({
        path,
        selectedDatas: selectedFlatRows.map((x) => x.original),
      })
    );
  };
  const handleDetailModalOpen =
    (modalName = 'detail') =>
    () => {
      if (selectedDatas.length > 0) {
        setModalOpen(modalName, true);
      } else {
        dispatch(
          setModalIsOpen({
            modalName: 'alert',
            isOpen: true,
            info: SETTLEMENT_EXECUTION_ALERT,
          })
        );
      }
    };

  const handleExcelDownloadClick = async () => {
    if (isEmpty(tableData)) {
      toastify('검색결과가 존재하지 않습니다.', { variant: 'warn' });
      return;
    }

    try {
      const params = {
        ...searchFilter,
        pageNumber: 0,
        pageSize: 10000,
      };

      const response = await postRequestSettlementList(params);

      downloadJsonAsExcel({
        data: translateList(translateFieldsByHash(response.data.content), MAPPING_KEYS),
        sheetName: '정산 실행 검색 결과',
        fileName: '정산 실행 검색 결과',
      });
    } catch (error) {
      handleError(error);
    }
  };

  const renderCell = useCallback(({ value, column: { id, Header = '' } }) => {
    if (typeof Header === 'string')
      return {
        [Header.match(/₩/) && id]: thousandSeparator(value) || 0,
        [getHash(id, value) && id]: getHash(id, value),
        totalItemKindCount: thousandSeparator(value) || 0,
        totalReceivedQuantity: thousandSeparator(value) || 0,
      }[id];
    return null;
  }, []);

  const resultHeaderButtons = useMemo(
    () => [
      <Button $variant='success' onClick={handleExcelDownloadClick}>
        엑셀 다운로드
      </Button>,
      <Button $variant='primary' onClick={handleDetailModalOpen('detail')}>
        정산 실행
      </Button>,
    ],
    [tableData, selectedDatas, searchFilter]
  );

  return (
    <Card title='정산 대상 내역'>
      <Styled.ResultHeader totalCount={totalElements} buttons={resultHeaderButtons} />
      <Styled.TableWrapper>
        <PaginationTable
          columns={COLUMNS}
          data={tableData}
          onSelectChange={handleRowSelectionChange}
          onPageChange={handlePageChange}
          tableOptions={{
            ...TABLE_OPTIONS,
            pageCount: totalPages,
            useControlledState: (state) => ({
              ...state,
              pageIndex: controlledPage,
            }),
          }}
          pluginHooks={ROW_SELECT_PLUGIN_HOOKS}
          renderCell={renderCell}
          setControlledPage={setControlledPage}
        />
      </Styled.TableWrapper>
    </Card>
  );
};

TableContainer.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default TableContainer;
