const BUSINESS_NUMBER_PATTERN = '\\d{3}-\\d{2}-\\d{5}';

export const BUSINESSMAN_INFO_INPUT_LABELS = [
  {
    name: 'companyName',
    label: '법인명',
  },
  {
    name: 'ceoName',
    label: '대표자 성명',
  },
  {
    name: 'businessNumber',
    label: '사업자 등록번호',
    placeholder: "'-' 포함",
    maxLength: 12,
    pattern: BUSINESS_NUMBER_PATTERN,
    helperText: '예) 123-12-12345',
  },
];

export default { BUSINESSMAN_INFO_INPUT_LABELS };
