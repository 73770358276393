import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';

const EditableCell = ({
  value: initialValue,
  row: { index: rowIndex },
  column: { id: columnName },
  updateMyData,
  isVendorTable,
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!isVendorTable) {
    return <>{value}</>;
  }

  if (columnName === 'position') {
    return value;
  }

  return (
    <Input
      value={value || ''}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => updateMyData(rowIndex, columnName, value)}
      required={columnName !== 'email'}
      maxLength={columnName === 'telNo' ? 20 : 100}
    />
  );
};

EditableCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    index: PropTypes.number,
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  updateMyData: PropTypes.func.isRequired,
};

export default EditableCell;
