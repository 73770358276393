import instance from 'service/interceptor';

async function getRequests({ pageNumber = 0, pageSize = 10 } = {}) {
  const { data } = await instance.post(`/api/v2/product/supply/price/request/list`, {
    pageNumber,
    pageSize,
  });

  return data;
}

async function getRequestDetail(requestId) {
  const { data } = await instance.get(
    `/api/v2/product/supply/price/request/info?productSupplyPriceRequestId=${requestId}`
  );

  return data;
}

async function getProducts({ pageNumber = 0, pageSize = 100, query = '', requestId } = {}) {
  const { data } = await instance.post(`/api/v2/product/supply/price/vendor/product/item/list`, {
    pageNumber,
    pageSize,
    productName: query,
    requestId,
  });

  return data;
}

async function isIncludedInTargetProducts(productItemIdList) {
  const { data } = await instance.post('/api/v2/product/supply/price/target/item/check', {
    productItemIdList,
  });

  return data;
}

async function saveProducts(productSupplyRequestItem, productSupplyPriceRequestId = null) {
  const { data } = await instance.post('/api/v2/product/supply/price/save/request', {
    productSupplyPriceRequestId,
    productSupplyRequestItem,
  });

  return data;
}

async function modifyProducts(productSupplyRequestItem, productSupplyPriceRequestId = null) {
  const { data } = await instance.post('/api/v2/product/supply/price/change/request', {
    productSupplyPriceRequestId,
    productSupplyRequestItem,
  });

  return data;
}

async function cancelModifyProducts(productSupplyPriceRequestId) {
  const { data } = await instance.get(
    `/api/v2/product/supply/price/cancel/request?productSupplyPriceRequestId=${productSupplyPriceRequestId}`
  );

  return data;
}

export default {
  getRequests,
  getRequestDetail,
  getProducts,
  isIncludedInTargetProducts,
  saveProducts,
  modifyProducts,
  cancelModifyProducts,
};
