import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import receivingApi from 'service/receiving';
import useFetch from 'hooks/useFetch';

import Card from 'components/Card';

import { SEARCH_OPTIONS_INITIAL_VALUES } from './commons/constant';

import SearchContainer from './containers/SearchContainer';
import ResultContainer from './containers/ResultContainer';

const ReceivingManagePage = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search).get('q');
  const [controlledPageIndex, setControlledPage] = useState(0);
  const searchOptionsSnapshot = useRef(SEARCH_OPTIONS_INITIAL_VALUES);
  const {
    data: { content = [], totalElements = 0, totalPages = 0 } = {},
    execute: fetchReceivingResultList,
  } = useFetch(receivingApi.fetchReceivingResultList, { initialExecute: false });

  useEffect(() => {
    fetchReceivingResultList({
      searchWord: query || '',
    });
  }, []);

  const setSearchOptionsSnapshot = (value) => {
    searchOptionsSnapshot.current = value;
  };

  return (
    <Card title='입고 결과'>
      <SearchContainer
        setSearchOptionsSnapshot={setSearchOptionsSnapshot}
        fetchReceivingResultList={fetchReceivingResultList}
        setControlledPage={setControlledPage}
      />
      <ResultContainer
        controlledPageIndex={controlledPageIndex}
        setControlledPage={setControlledPage}
        content={content}
        totalElements={totalElements}
        totalPages={totalPages}
        fetchReceivingResultList={fetchReceivingResultList}
        searchOptionsSnapshot={searchOptionsSnapshot}
      />
    </Card>
  );
};

export default ReceivingManagePage;
