import { thousandSeparator } from 'commons/helper';

import { SideTableWrapper } from './SideBar.style';

function SideBarTable({ width, height, thead, tbody, tfoot }) {
  return (
    <SideTableWrapper width={width} height={height}>
      <thead>
        <tr>
          {thead.map((head) => (
            <td key={`${head.label}=${head.id}`}>{head.label}</td>
          ))}
        </tr>
      </thead>
      <tbody>
        {tbody.map(({ managerName, transportType, skuCount, pcsCount, amount }) => (
          <tr key={`${amount}-${managerName ?? transportType}`}>
            <td>{managerName ?? thousandSeparator(transportType)}</td>
            <td>{thousandSeparator(skuCount)}</td>
            <td>{thousandSeparator(pcsCount)}</td>
            <td>{thousandSeparator(amount)}</td>
          </tr>
        ))}
      </tbody>
      {tfoot && (
        <tfoot>
          {tfoot.map(({ id, label, skuCount, pcsCount, amount }) => (
            <tr key={`${id}-${label}`}>
              <td>{thousandSeparator(label)}</td>
              <td>{thousandSeparator(skuCount)}</td>
              <td>{thousandSeparator(pcsCount)}</td>
              <td>{thousandSeparator(amount)}</td>
            </tr>
          ))}
        </tfoot>
      )}
    </SideTableWrapper>
  );
}

export default SideBarTable;
