import Styled from './styled';

import CalendarContainer from './containers/CalendarContainer';
import NoticeContainer from './containers/NoticeContainer';
import TotalStatusContainer from './containers/TotalStatusContainer';
import NoticeModalContainer from './containers/NoticeModalContainer';

const DashboardPage = () => {
  return (
    <Styled.Div>
      <article>
        <NoticeContainer />
        <TotalStatusContainer />
        <NoticeModalContainer />
      </article>
      <article>
        <CalendarContainer />
      </article>
    </Styled.Div>
  );
};

export default DashboardPage;
