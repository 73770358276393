import { useReactToPrint } from 'react-to-print';

import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { SUPPLY_REQUEST_STATEMENTS_INITIAL_VALUES } from 'commons/constant';
import supplyApi from 'service/hq/supply';
import useFetch from 'hooks/useFetch';

import { Flex } from 'styles/components';
import Card from 'components/Card';
import Button from 'components/Button';
import RequestStatements from 'components/RequestStatements';
import { COLUMNS } from 'components/RequestStatements/commons/constant';

const StatusChangeButtons = ({
  statusCode,
  onApproveClick,
  onRejectClick,
  onCompletionCancelClick,
  onCancelClick,
  setTargetButtonRef,
  onExcelDownloadClick,
}) => {
  const { productSupplyRequestId = '' } = useParams();
  const requestStatementsRef = useRef(null);
  const [columns, setColumns] = useState([]);

  const printPDF = useReactToPrint({
    content: () => requestStatementsRef.current,
    documentTitle: '납품명세서',
  });

  const {
    data: supplyRequestStatements = SUPPLY_REQUEST_STATEMENTS_INITIAL_VALUES,
    execute: getSupplyRequestStatements,
  } = useFetch(supplyApi.getSupplyRequestStatements, {
    initialExecute: false,
  });

  useEffect(() => {
    if (productSupplyRequestId && statusCode === 'PETFRIENDS_CONFIRM') {
      getSupplyRequestStatements(productSupplyRequestId);
    }
  }, [productSupplyRequestId, statusCode]);

  const handleSendClick = () => {
    setTargetButtonRef('거래처 전송');
  };

  const handleRequestStatementsDownloadClick = (isCustomColumns) => {
    setColumns(
      isCustomColumns
        ? COLUMNS.filter(
            ({ accessor }) =>
              accessor !== 'productSupplyUnitPrice' && accessor !== 'receivedExpectPrice'
          )
        : COLUMNS
    );
  };

  useEffect(() => {
    if (columns.length) printPDF();
  }, [columns]);

  return (
    <Card title='발주 상태 변경' description='버튼 클릭하면, 변경된 데이터 저장 후에 처리됩니다.'>
      <Flex $gap='var(--space-s)'>
        <Button
          $variant='secondary'
          type='submit'
          onClick={handleSendClick}
          disabled={statusCode !== 'PETFRIENDS_DRAFT'}
        >
          거래처 전송
        </Button>
        <Button
          $variant='secondary'
          onClick={onApproveClick}
          disabled={statusCode !== 'VENDOR_REQUEST' && statusCode !== 'CONFIRM_CANCEL'}
        >
          확정 승인
        </Button>
        <Button
          $variant='secondary'
          onClick={onCompletionCancelClick}
          disabled={statusCode !== 'PETFRIENDS_CONFIRM'}
        >
          확정 취소
        </Button>
        <Button
          $variant='secondary'
          onClick={onRejectClick}
          disabled={statusCode !== 'VENDOR_REQUEST' && statusCode !== 'CONFIRM_CANCEL'}
        >
          확정 반려
        </Button>
        <Button
          $variant='secondary'
          onClick={onCancelClick}
          disabled={statusCode === 'CANCEL' || statusCode === ''}
        >
          발주 취소
        </Button>
        <Button
          $variant='success'
          disabled={statusCode !== 'PETFRIENDS_CONFIRM'}
          onClick={() => handleRequestStatementsDownloadClick(false)}
        >
          납품명세서 다운로드
        </Button>
        <Button
          $variant='success'
          disabled={statusCode !== 'PETFRIENDS_CONFIRM'}
          onClick={() => handleRequestStatementsDownloadClick(true)}
        >
          납품명세서 다운로드 (금액 미포함)
        </Button>
        <Button $variant='success' onClick={onExcelDownloadClick}>
          엑셀 다운로드
        </Button>
      </Flex>
      <RequestStatements
        columns={columns}
        ref={requestStatementsRef}
        supplyRequestStatements={supplyRequestStatements}
      />
    </Card>
  );
};

StatusChangeButtons.propTypes = {
  statusCode: PropTypes.oneOf([
    'PETFRIENDS_DRAFT',
    'PETFRIENDS_REGISTER',
    'MODIFY',
    'VENDOR_REQUEST',
    'PETFRIENDS_CONFIRM',
    'CANCEL',
    'CONFIRM_CANCEL',
    '',
  ]),
  onApproveClick: PropTypes.func.isRequired,
  onRejectClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onCompletionCancelClick: PropTypes.func.isRequired,
  setTargetButtonRef: PropTypes.func.isRequired,
  onExcelDownloadClick: PropTypes.func.isRequired,
};

export default StatusChangeButtons;
