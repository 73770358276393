export const COLUMNS = [
  {
    Header: '품목코드',
    accessor: 'productItemId',
  },
  { Header: '품목바코드', accessor: 'itemBarcode' },
  { Header: '품목명', accessor: 'productName' },
  { Header: '예정수량 (EA)', accessor: 'availableReceiveQuantity' },
  { Header: '예정수량 (박스)', accessor: 'availableReceiveBox' },
  { Header: '공급가(VAT포함)', accessor: 'productSupplyUnitPrice' },
  { Header: '입고예정금액(VAT포함)', accessor: 'receivedExpectPrice' },
  { Header: '유통기한', accessor: 'shelfLifeDay' },
  { Header: '비고', accessor: 'comment' },
];

export const RECEIVE_INFO_TEXTS = [
  { label: '입고예정일자', key: 'receiveAvailableDay' },
  { label: '입고번호', key: 'inboundWikey' },
  { label: '납품센터', key: 'centerName' },
  { label: '운송유형', key: 'transportTypeCodeName' },
];

export const REQUEST_INFO_TEXTS = [
  { label: '발주일자', key: 'productSupplyRequestDay' },
  { label: '발주번호', key: 'productSupplyRequestId' },
  { label: '발주유형', key: 'productSupplyTypeCodeName' },
  { label: '발주수량', key: 'totalItemQuantity', unit: 'ea' },
  { label: '발주품목수', key: 'totalItemKindCount', unit: 'SKU' },
];

export const PARTNER_INFO_TEXTS = [
  { label: '업체명', key: 'displayName' },
  { label: '업체코드', key: 'vendorId' },
  { label: '담당자', key: 'purchaseManager' },
  { label: '연락처', key: 'purchaseManagerTelNo' },
];
