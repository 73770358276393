import dayjs from 'dayjs';

export const getYearMonthList = (currentMonth, period) => {
  let target = currentMonth;

  if (target instanceof Date) target = dayjs(currentMonth).format('YYYY-MM');

  const result = Array.from({ length: period }, (_, i) => i + 1).reduce(
    (acc, cur) => {
      const prev = dayjs(new Date(target)).subtract(cur, 'month').format('YYYY-MM');
      const next = dayjs(new Date(target)).add(cur, 'month').format('YYYY-MM');

      acc = [prev, ...acc, next];
      return acc;
    },
    [target]
  );

  return result;
};
