import { useEffect, useRef, useState } from 'react';
import search from 'service/search/condition';

const useSelectOptions = (options, { allSelectableOptions, condition = true } = {}) => {
  const [state, setState] = useState(() =>
    options.reduce((object, option) => {
      const clonedObject = { ...object };
      clonedObject[option] = [];
      return clonedObject;
    }, {})
  );
  const isFetched = useRef(false);

  useEffect(() => {
    if (!condition || isFetched.current) {
      return;
    }

    async function fetchOptions() {
      try {
        const {
          response: { data },
        } = await search.getOptions(options.toString());

        if (allSelectableOptions) {
          allSelectableOptions.forEach((key) => data[key].unshift({ key: '', value: '전체' }));
        }

        setState(data);
        isFetched.current = true;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error, error.response);
      }
    }
    fetchOptions();
  }, [condition]);

  return state;
};

export default useSelectOptions;
