import PropTypes from 'prop-types';
import Button from 'components/Button';
import * as Styled from './styled';

const Header = ({ setModalOpen }) => {
  const handleInfoModifyRequestClick = () => setModalOpen({ requestsModal: true });

  return (
    <Styled.Header>
      <span>
        <strong>중요</strong> &lt;결제 정보&gt;, &lt;사업자 정보&gt; 변경 시, 반드시{' '}
        <Styled.Highlight>정보 수정 요청</Styled.Highlight>을 진행하시기 바랍니다.
      </span>
      <Button onClick={handleInfoModifyRequestClick}>정보 수정 요청</Button>
    </Styled.Header>
  );
};

Header.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
};

export default Header;
