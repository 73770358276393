import styled from 'styled-components';

export const Wrapper = styled.div`
  @media screen {
    display: none;
  }

  padding: calc(var(--space-l) * 3);

  h1 {
    margin-bottom: calc(var(--space-l) * 3);
  }

  h2 {
    font-size: var(--font-size-xs);
    margin-top: var(--space-l);
    margin-bottom: 0;
    text-align: center;
  }

  hr {
    margin-top: 3px;
  }

  svg {
    width: auto;
    height: 72px;
  }

  ul {
    list-style: none;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableWrapper = styled.div`
  min-height: 800px;

  table {
    width: 1000px;
    table-layout: fixed;
    border-left: 1px solid var(--grey300);

    th,
    td {
      word-wrap: break-word;
      white-space: normal;
      font-size: 12px;
    }

    th {
      &:first-child,
      &:nth-child(4),
      :nth-child(5) {
        width: 70px;
      }
      &:nth-child(2) {
        width: 130px;
      }
      &:nth-child(6) {
        width: 80px;
      }
      &:nth-child(8) {
        width: 100px;
      }
      &:nth-child(7),
      :nth-child(9) {
        width: 90px;
      }
    }
  }

  > div {
    overflow: hidden;
  }
`;

export const CommentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  border: 1px solid var(--grey300);

  > div {
    &:first-child {
      height: 20px;
      border-bottom: 1px solid var(--grey300);
      background-color: var(--grey200);
      text-align: center;
    }
    &:nth-child(2) {
      height: 80px;
    }
  }
`;

export const ConfirmBox = styled.div`
  display: flex;
  > div {
    border: 1px solid var(--grey300);

    display: flex;
    flex-direction: column;

    > div {
      &:first-child {
        width: 120px;
        height: 20px;
        border-bottom: 1px solid var(--grey300);
        text-align: center;
        background-color: var(--grey200);
      }
      &:nth-child(2) {
        height: 50px;
      }
    }
  }
`;

export const ConfirmHelperMessage = styled.small`
  width: 240px;
`;

export const Logo = styled.img`
  width: 100px;
  height: 25px;
  margin-bottom: 15px;
`;

export const Red = styled.span`
  color: var(--red500);
`;

export default { Wrapper, Header, TableWrapper, ConfirmBox, Logo };
