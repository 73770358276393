import { getTokens, redirectToLoginPage } from 'commons/token';
import { handleError } from 'commons/helper';
import { authStore } from 'hooks/useSyncAuth';
import axios from 'axios';
import { UPDATE_ACCESS_TOKEN_ENDPOINT } from './commons/constant';
import { updateAccessToken } from './auth';
import instance from './index';

const originalRequests = [];
let refreshInterceptorDebounce = null;
let incorrectPermissionDebounce = null;

export const tokenRefreshInterceptor = (error) => {
  const { config } = error;
  const originalRequest = config;

  originalRequests.push(originalRequest);

  if (refreshInterceptorDebounce) clearTimeout(refreshInterceptorDebounce);

  refreshInterceptorDebounce = setTimeout(async () => {
    try {
      const [refreshToken] = getTokens(['refreshToken']);

      if (!refreshToken || config.url === UPDATE_ACCESS_TOKEN_ENDPOINT) {
        redirectToLoginPage();
        return Promise.reject(error);
      }

      const { data } = await updateAccessToken({ refreshToken, originDomain: 'SUPPLY' });

      authStore.update({ ...data });

      originalRequests.map(async (originalRequest) => {
        const [accessToken] = getTokens(['accessToken']);
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return axios(originalRequest);
      });

      originalRequests.splice(0);
    } catch (err) {
      authStore.remove();
      handleError();
      redirectToLoginPage();
    }
  }, 500);
};

export const incorrectPermissionInterceptor = () => {
  if (incorrectPermissionDebounce) clearTimeout(incorrectPermissionDebounce);

  incorrectPermissionDebounce = setTimeout(() => {
    alert(`권한이 변경되었습니다.\n재로그인 후 확인 부탁드립니다.`);
    authStore.remove();
    redirectToLoginPage();
  }, 500);
};
