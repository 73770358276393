import { memo } from 'react';
import PropTypes from 'prop-types';

import * as StyledPage from 'styles';

import LabelAndText from 'components/LabelAndText';

const SupplyInfo = ({ transport }) => {
  return (
    <StyledPage.Card title='발주 정보'>
      <LabelAndText label='기본 입고 방법' text={transport?.receiveMethodValue} />
    </StyledPage.Card>
  );
};

SupplyInfo.propTypes = {
  transport: PropTypes.shape({
    receiveMethodValue: PropTypes.string,
  }),
};

export default memo(SupplyInfo);
