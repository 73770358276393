import styled from 'styled-components';
import { REQUEST_STATUS_CODE } from 'commons/constant';

const Category = styled.span`
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-semibold);
  margin-bottom: var(--space-m);
`;

const Status = styled.mark`
  font-weight: var(--font-weight-semibold);
  color: ${({ $statusCode }) => {
    const colorTable = {
      [REQUEST_STATUS_CODE.REQUEST]: 'var(--blue500)',
      [REQUEST_STATUS_CODE.REJECT]: 'var(--red500)',
      [REQUEST_STATUS_CODE.APPROVE]: 'var(--green500)',
    };

    return colorTable[$statusCode] || 'var(--grey900)';
  }};
`;

export { Category, Status };
