/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { setModalIsOpen } from 'commons/store/modules/modals';

/**
 * useGlobalModals
 * @returns [modals, setModalOpen]
 */
export const useGlobalModals = () => {
  const dispatch = useDispatch();

  const modals = useSelector((state) => state.modals) || {};

  /**
   * setModalOpen
   */
  const setModalOpen = (
    modalName,
    isOpen,
    info = { title: null, content: '', buttons: [{ text: '확인' }] }
  ) => {
    if (!modalName || modalName === '') return;

    dispatch(
      setModalIsOpen({
        modalName,
        isOpen: typeof isOpen === 'boolean' ? isOpen : !modals[modalName],
        info,
      })
    );
  };

  return [modals, setModalOpen];
};
/**
 * usePageModals
 * @param {string} path - route match path
 * @returns [modals, setModalOpen]
 */
export const usePageModals = (path) => {
  const p = path || useRouteMatch().path;
  const dispatch = useDispatch();

  const modals = useSelector((state) => state.modals[p]) || {};

  /**
   * setModalOpen
   */
  const setModalOpen = (modalName, isOpen, info) => {
    if (!modalName || modalName === '') return;
    dispatch(
      setModalIsOpen({
        path: p,
        modalName,
        isOpen: typeof isOpen === 'boolean' ? isOpen : !modals[modalName],
        info,
      })
    );
  };

  return [modals, setModalOpen];
};

/**
 * usePageModals
 * @param {string} path - route match path
 * @returns setModalOpen
 */
export const useDispatchModals = (path) => {
  const p = path || useRouteMatch().path;
  const dispatch = useDispatch();

  /**
   * @param { string } modalName
   * @param { boolean } isOpen
   * @param { object } info
   */
  const setModalOpen = (modalName, isOpen = false, info) => {
    if (!modalName || modalName === '') return;
    dispatch(
      setModalIsOpen({
        path: p,
        modalName,
        isOpen,
        info,
      })
    );
  };

  return setModalOpen;
};

export default { useGlobalModals, usePageModals, useDispatchModals };
