import { useBarcode } from 'react-barcodes';

import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { formatDate } from 'commons/date';

import logo from 'icons/png/logo.png';
import { thousandSeparator } from 'commons/helper';

import { Flex } from 'styles/components';
import Table from 'components/Table';
import NumericCell from 'components/NumericCell/NumericCell';

import { RECEIVE_INFO_TEXTS, REQUEST_INFO_TEXTS, PARTNER_INFO_TEXTS } from './commons/constant';

import * as Styled from './styled';
import Field from './components/Field';

const RequestStatements = forwardRef(({ columns, supplyRequestStatements }, ref) => {
  const { itemList, centerContact, partnerInfo, receiveInfo, requestInfo } =
    supplyRequestStatements;

  const { inputRef } = useBarcode({
    value: receiveInfo.inboundWikey,
  });

  const renderCell = (cell) => {
    switch (cell.column.id) {
      case 'availableReceiveQuantity':
      case 'productSupplyUnitPrice':
      case 'receivedExpectPrice':
        return <NumericCell value={cell.value} />;
      case 'availableReceiveBox':
        return cell.row.original.isAvailableReceiveBox ? cell.value : '###';
      default:
        return cell.value;
    }
  };

  const formattedReceiveAvailableDay = formatDate(
    receiveInfo.receiveAvailableDay,
    'YYYY년 MM월 DD일'
  );

  return (
    <Styled.Wrapper ref={ref}>
      <Flex $justifyContent='space-between'>
        <h1>납품 내역서</h1>
        <svg ref={inputRef} />
      </Flex>
      <Flex $justifyContent='flex-end'>
        <h2>입고 담당자 : {centerContact}</h2>
      </Flex>
      <hr />
      <Flex $justifyContent='space-between'>
        <Flex $flexDirection='column'>
          <h3>입고 정보</h3>
          {RECEIVE_INFO_TEXTS.map(({ label, key }) => (
            <Field key={key} label={label} value={receiveInfo[key]} />
          ))}
          <span>
            화물수량: {thousandSeparator(receiveInfo.transportQuantity) || 0}{' '}
            {receiveInfo.transportCargoTypeCodeName}
          </span>
        </Flex>

        <Flex $flexDirection='column'>
          <h3>발주 정보</h3>
          {REQUEST_INFO_TEXTS.map(({ label, key, unit }) => (
            <Field key={key} label={label} value={requestInfo[key]} unit={unit} />
          ))}
        </Flex>
        <Flex $flexDirection='column'>
          <h3>파트너사 정보</h3>
          {PARTNER_INFO_TEXTS.map(({ label, key }) => (
            <Field key={key} label={label} value={partnerInfo[key]} />
          ))}
        </Flex>
      </Flex>
      <span>납품상세내역</span>
      <hr />
      <Styled.TableWrapper>
        <Table columns={columns} data={itemList} $height='none' renderCell={renderCell} />
      </Styled.TableWrapper>
      <Flex $justifyContent='space-between'>
        <Styled.CommentBox>
          <div>비고</div>
          <div />
        </Styled.CommentBox>

        <Flex $flexDirection='column'>
          <Styled.ConfirmBox>
            <div>
              <div>인계자</div>
              <div />
            </div>
            <div>
              <div>인수자</div>
              <div />
            </div>
          </Styled.ConfirmBox>
          <Styled.ConfirmHelperMessage>
            {formattedReceiveAvailableDay} 납품된 위 납품내역에 대하여 수량,유통기한을 모두 확인
            하였으며, 이상이 없음을 확인하였습니다.
          </Styled.ConfirmHelperMessage>
        </Flex>
      </Flex>
      <strong>
        <Styled.Red>※ 입고 전 유의사항</Styled.Red>
      </strong>
      <ul>
        <li>
          입고시간 : 09:00 ~ 15:00 <Styled.Red>(점심시간 : 11:50 ~ 12:50)</Styled.Red>
        </li>
        <li>본 거래명세서 미지참시 입고불가</li>
        <li>그 외 메뉴얼에 기재된 입고규정 미준수시 입고불가</li>
      </ul>
      <Flex $flexDirection='column' $alignItems='center'>
        <Styled.Logo src={logo} alt='펫프렌즈' />
        <small>
          본 문서는 기밀정보, 영업비밀을 포함하고 있습니다. 수신인 외에는 관련 정보의 전부 또는
          일부의 보관, 공개, 복사, 배포, 전달 등의 비인가 사용이 금지되기에 어떠한 무단 사용도
          불법이 될 수 있으며 본 문서를 실수로 수신하는 경우 발신인에게 즉시 알려주시고 메일 포함
          모든 관련 문서와 정보를 영구히 삭제해주시기 바랍니다.
        </small>
      </Flex>
    </Styled.Wrapper>
  );
});

RequestStatements.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.string,
    })
  ),
  supplyRequestStatements: PropTypes.shape({
    itemList: PropTypes.arrayOf(
      PropTypes.shape({
        boxBarcode: PropTypes.string,
        comment: PropTypes.string,
        itemBarcode: PropTypes.string,
        productItemId: PropTypes.number,
        productName: PropTypes.string,
        productStandardUnitPrice: PropTypes.number,
        productSupplyPrice: PropTypes.number,
        productSupplyUnitPrice: PropTypes.number,
        requestQuantity: PropTypes.number,
        shelfLifeDay: PropTypes.string,
      })
    ),
    partnerInfo: PropTypes.shape({
      displayName: PropTypes.string,
      vendorId: PropTypes.number,
      purchaseManager: PropTypes.string,
      purchaseManagerTelNo: PropTypes.string,
    }),
    receiveInfo: PropTypes.shape({
      receiveDelay: PropTypes.oneOf(['Y', 'N']),
      receiveDelayReason: PropTypes.string,
      centerName: PropTypes.string,
      transportTypeCodeName: PropTypes.string,
      transportQuantity: PropTypes.number,
      transportCargoTypeCodeName: PropTypes.string,
      receiveAvailableDay: PropTypes.string,
      inboundWikey: PropTypes.string,
    }),
    requestInfo: PropTypes.shape({
      productSupplyRequestDay: PropTypes.string,
      productSupplyRequestId: PropTypes.string,
      productSupplyTypeCode: PropTypes.oneOf([
        'NEW_ORDER',
        'RE_ORDER',
        'PROMOTION',
        'PREPARE_EVENT',
        '',
      ]),
      totalItemKindCount: PropTypes.number,
      totalItemQuantity: PropTypes.number,
      totalPrice: PropTypes.number,
      productSupplyTypeCodeName: PropTypes.string,
    }),
  }),
};

export default RequestStatements;
