import PropTypes from 'prop-types';
import { useState } from 'react';
import ProductListModal from './components/ProductListModal';

import * as Styled from './styled';

const ProductCell = ({ value, row }) => {
  const [isProductListModalOpen, setIsProductListModalOpen] = useState(false);

  const openProductListModal = () => setIsProductListModalOpen(true);
  const closeProductListModal = () => setIsProductListModalOpen(false);

  return (
    <>
      <Styled.Wrapper onClick={openProductListModal}>{value}</Styled.Wrapper>
      <ProductListModal
        isOpen={isProductListModalOpen}
        closeCallback={closeProductListModal}
        productSupplyRequestId={row.original.productSupplyRequestId}
      />
    </>
  );
};

ProductCell.propTypes = {
  value: PropTypes.number,
  row: PropTypes.shape({
    original: PropTypes.shape({
      productSupplyRequestId: PropTypes.string.isRequired,
    }),
  }),
};

export default ProductCell;
