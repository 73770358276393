import { useState } from 'react';
import prepaidApi from 'service/prepaid';
import { thousandSeparator } from 'commons/helper';
import useFetch from 'hooks/useFetch';

import Card from 'components/Card';
import ResultHeader from 'components/ResultHeader';
import PaginationTable from 'components/Table/PaginationTable';

import { PREPAID_INFO_COLUMNS } from './commons/constant';

import Styled from './styled';

const PrepaidInfoPage = () => {
  const {
    data: { content = [], totalElements = 0, totalPrice = 0 } = {},
    execute: getPrepaidInfo,
  } = useFetch(prepaidApi.getPrepaidInfo);
  const [controlledPage, setControlledPage] = useState(0);

  const tableOptions = {
    initialState: { pageIndex: 0, pageSize: 50 },
    manualPagination: true,
    pageCount: Math.ceil(totalElements / 50),
    useControlledState: (state) => ({
      ...state,
      pageIndex: controlledPage,
    }),
  };

  const handlePageChange = ({ state }) => {
    getPrepaidInfo({ pageNumber: state.pageIndex });
  };

  const renderCell = (cell) => {
    const columnId = cell.column.id;
    if (columnId === 'price') {
      return (
        <Styled.PriceCell $value={cell.value}>
          {thousandSeparator(cell.value) || 0}
        </Styled.PriceCell>
      );
    }
    if (columnId === 'totalPrice') {
      return thousandSeparator(cell.value);
    }

    return cell.value;
  };

  return (
    <Card title='선지급금 정보'>
      <strong>선지금금 잔고</strong> ₩{thousandSeparator(totalPrice)}
      <ResultHeader totalCount={totalElements} />
      <Styled.TableWrapper>
        <PaginationTable
          columns={PREPAID_INFO_COLUMNS}
          data={content}
          onPageChange={handlePageChange}
          tableOptions={tableOptions}
          renderCell={renderCell}
          setControlledPage={setControlledPage}
        />
      </Styled.TableWrapper>
    </Card>
  );
};

export default PrepaidInfoPage;
