import { styleSelector } from 'commons/helper';
import styled from 'styled-components';

export const TableWrapper = styled.div`
  height: ${({ $height }) => $height || '500px'};
  overflow: auto;

  &::-webkit-scrollbar {
    background-color: #f9f9f9;
    border: 1px solid #eee;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
  }
`;

export const Table = styled.table`
  width: 100%;
  table-layout: auto;
  font-size: var(--font-size-s);
  border-collapse: collapse;

  ${styleSelector}

  th,
  td {
    padding: var(--space-m);
    word-break: break-word;
    border: 1px solid #d3d8e1;
    box-sizing: border-box;
    white-space: nowrap;

    input {
      border: 1px solid #d3d8e1;

      :focus {
        box-shadow: none !important;
      }
    }
    select {
      border: 1px solid #d3d8e1;
      height: 43px;

      :focus {
        box-shadow: none !important;
      }
    }
  }

  thead {
    position: sticky;
    top: 0;
    background-color: #f5f8fa;
    z-index: 1;

    th {
      font-weight: var(--font-weight-semibold);
      position: relative;
      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
      }

      &::before {
        top: -1px;
        border-top: 1px solid #d3d8e1;
      }
      &::after {
        bottom: -1px;
        border-bottom: 1px solid #d3d8e1;
      }
    }
  }

  tbody {
    tr {
      cursor: ${({ $useHover }) => $useHover && 'pointer'};
      &:hover {
        background-color: ${({ $useHover }) => $useHover && 'var(--grey50)'};
      }
    }
    td:not(:first-child) {
      input {
        width: 100%;
        min-width: 100px;
      }
    }
  }

  tfoot {
    background-color: #f5f8fa;
    font-weight: bold;

    td.right {
      text-align: right;
    }
  }
`;

export const NoResultMessageWrapper = styled.div`
  margin-top: calc(var(--space-l) * 5);
  display: grid;
  place-items: center;
`;

export const Tr = styled.tr`
  background-color: ${({ $isSelected }) => $isSelected && 'var(--blue50)'};

  &:hover {
    background-color: ${({ $isSelected }) => $isSelected && 'var(--blue50) !important'};
  }
`;
