import PropTypes from 'prop-types';

import { thousandSeparator } from 'commons/helper';

const Field = ({ label, value, unit }) => {
  return (
    <span>
      {label}: {unit ? thousandSeparator(value) || 0 : value} {unit}
    </span>
  );
};

Field.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string,
};

export default Field;
