import { useCallback, useEffect, useState } from 'react';

import { handleError } from 'commons/helper';
import toastify from 'commons/toast';
import vendorInfo from 'service/vendor/info';
import useFetch from 'hooks/useFetch';

import { Flex } from 'styles/components';
import HeaderModal from 'components/modals/HeaderModal';
import Button from 'components/Button';

import { COLUMNS, IS_OPEN_INITIAL_VALUES, REQUEST_INITIAL_VALUES } from './commons/constant';
import useRequest from './hooks/useRequest';

import * as Styled from './styled';
import RequestModifyModal from './components/RequestModifyModal';
import VendorInfo from './components/VendorInfo';
import BusinessmanInfo from './components/BusinessmanInfo';
import PaymentInfo from './components/PaymentInfo';
import ManagerInfo from './components/ManagerInfo';
import SupplyInfo from './components/SupplyInfo/SupplyInfo';
import Header from './components/Header';

function VendorInfoPage() {
  const [isOpen, setIsOpen] = useState(IS_OPEN_INITIAL_VALUES);
  const [controlledPage, setControlledPage] = useState(0);

  const [request, setRequest] = useRequest(REQUEST_INITIAL_VALUES);
  const { data: vendor = {} } = useFetch(vendorInfo.getAllVendorInfo);
  const { data: { content = [], totalPages = 0 } = {}, execute: fetchRequests } = useFetch(
    vendorInfo.getModifyRequests,
    { initialExecute: false }
  );

  const tableOptions = {
    initialState: { pageIndex: 0, pageSize: 10 },
    manualPagination: true,
    pageCount: totalPages,
    useControlledState: (state) => ({
      ...state,
      pageIndex: controlledPage,
    }),
  };

  const setModalOpen = ({ requestsModal = false, requestModifyModal = false } = {}) =>
    setIsOpen({ requestsModal, requestModifyModal });

  function handleRequestModifyModalOpen(updatedRequest) {
    setModalOpen({ requestModifyModal: true });
    setRequest(updatedRequest);
  }

  const handlePageChange = ({ state }) => fetchRequests({ pageNumber: state.pageIndex });

  useEffect(() => {
    if (!isOpen.requestsModal) {
      setControlledPage(0);
      return;
    }

    fetchRequests();
  }, [isOpen.requestsModal]);

  useEffect(() => {
    if (!isOpen.requestsModal && !isOpen.requestModifyModal) {
      setRequest(REQUEST_INITIAL_VALUES);
    }
  }, [isOpen]);

  const handleRegisterClick = async () => {
    const { response } = await vendorInfo.getProcessingRequest().catch(handleError);
    const { id } = response.data;
    handleRequestModifyModalOpen(response.data);

    const hasProcessingRequest = id !== null;
    if (hasProcessingRequest) {
      toastify('기존에 수정 요청한 내역이 존재합니다. 기존 수정 요청 내역으로 이동합니다.');
    }
  };

  const handleRequestClick = useCallback(async ({ values }) => {
    const requestId = values.id;
    const { response } = await vendorInfo.getRequestForm(requestId).catch(handleError);
    handleRequestModifyModalOpen(response.data);
  }, []);

  const RequestsModalButton = <Button onClick={handleRegisterClick}>등록</Button>;

  return (
    <div>
      <Header setModalOpen={setModalOpen} />
      <Flex>
        <VendorInfo vendor={vendor.vendor} />
        <BusinessmanInfo business={vendor.business} />
      </Flex>
      <Flex>
        <PaymentInfo payment={vendor.payment} />
        <ManagerInfo manager={vendor.manager} />
      </Flex>

      <SupplyInfo transport={vendor.transport} />

      <HeaderModal
        isOpen={isOpen.requestsModal}
        closeCallback={setModalOpen}
        leftButton={RequestsModalButton}
        title='거래처 정보 수정 요청'
      >
        <Styled.PaginationTable
          columns={COLUMNS}
          data={content}
          onRowClick={handleRequestClick}
          tableOptions={tableOptions}
          onPageChange={handlePageChange}
          setControlledPage={setControlledPage}
        />
      </HeaderModal>
      <RequestModifyModal
        isOpen={isOpen.requestModifyModal}
        setModalOpen={setModalOpen}
        request={request}
      />
    </div>
  );
}

export default VendorInfoPage;
