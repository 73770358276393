import { useEffect, useState } from 'react';

const useForm = (request) => {
  const [form, setForm] = useState({});

  useEffect(() => {
    setForm({
      companyName: request.companyName,
      ceoName: request.ceoName,
      businessNumber: request.businessNumber,

      paymentMethod: request.paymentMethod ?? 'PREPAID',
      paymentDate: request.paymentDate ?? 'NEXT_5',
      bank: request.bank ?? 'KB',
      accountNumber: request.accountNumber,
      depositor: request.depositor,

      bankBookVendorImageId: request.bankBookVendorImageId,
      bankBookVendorImageName: request.bankBookVendorImageName,
      bankBookVendorImageUrl: request.bankBookVendorImageUrl,

      receiveMethod: request.receiveMethod ?? 'TRUCK',
      reason: request.reason,

      id: request.id,
    });
  }, [request]);

  return [form, setForm];
};

export default useForm;
