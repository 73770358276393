import { useMemo, useSyncExternalStore } from 'react';
import { getTokens, removeTokens, setTokens } from 'commons/token';
import { TARGET_LOCAL_STORAGE_TOKEN } from 'service/commons/constant';

const authStoreInstance = () => {
  const subscribe = (listener) => {
    window.addEventListener('storage', listener);
    return () => window.removeEventListener('storage', listener);
  };

  const get = (tokens) => {
    return {
      storage: localStorage.getItem(TARGET_LOCAL_STORAGE_TOKEN),
      cookie: getTokens(tokens),
    };
  };

  const update = (tokens) => {
    setTokens(tokens);
    localStorage.setItem(TARGET_LOCAL_STORAGE_TOKEN, tokens?.accessToken ?? '');

    window.dispatchEvent(
      new StorageEvent('storage', {
        key: TARGET_LOCAL_STORAGE_TOKEN,
        newValue: tokens?.accessToken ?? '',
      })
    );
  };

  const remove = () => {
    removeTokens();
    localStorage.removeItem(TARGET_LOCAL_STORAGE_TOKEN);

    window.dispatchEvent(
      new StorageEvent('storage', { key: TARGET_LOCAL_STORAGE_TOKEN, newValue: null })
    );
  };

  return {
    subscribe,
    get,
    update,
    remove,
  };
};

export const authStore = authStoreInstance();

export const useAuthInfo = (tokenName) => {
  const [selector, update, remove] = useMemo(
    () => [
      () => authStore.get(tokenName)?.storage,
      (value) => authStore.update(value),
      () => authStore.remove(),
    ],
    [JSON.stringify(tokenName)]
  );

  const value = useSyncExternalStore(authStore.subscribe, selector);

  return {
    storage: value,
    cookie: authStore.get(tokenName)?.cookie,
    update,
    remove,
  };
};

export const useAuthInfoAction = () => {
  const [update, remove] = useMemo(
    () => [(value) => authStore.update(value), () => authStore.remove()],
    []
  );

  return { update, remove };
};
