import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import prepaidApi from 'service/hq/prepaid';
import { handleError } from 'commons/helper';

import VendorInfo from './components/VendorInfo';
import PrepaidDetail from './components/PrepaidDetail';

const PrepaidDetailPage = () => {
  const { vendorId } = useParams();
  const [vendor, setVendor] = useState({});
  const [prepaidHistoryList, setPrepaidHistoryList] = useState({
    pageCount: 0,
    totalElements: 0,
    content: [],
  });

  const fetchPrepaidDetail = async ({ pageIndex = 0, pageSize = 50 } = {}) => {
    try {
      const { response } = await prepaidApi.getPrepaidDetail(vendorId, {
        pageNumber: pageIndex,
        pageSize,
      });
      const { totalElements, content, vendorInfo } = response.data;
      setVendor(vendorInfo);
      setPrepaidHistoryList({
        pageCount: Math.ceil(totalElements / pageSize),
        totalElements,
        content,
      });
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchPrepaidDetail();
  }, []);

  return (
    <div>
      <VendorInfo vendor={vendor} />
      <PrepaidDetail
        prepaidHistoryList={prepaidHistoryList}
        fetchPrepaidDetail={fetchPrepaidDetail}
      />
    </div>
  );
};

export default PrepaidDetailPage;
