import { css } from 'styled-components';

export const blueUnderline = css`
  font-weight: bold;
  color: var(--blue500);
  text-decoration: underline;
  cursor: pointer;
`;

export const priceFluctuationColor = css`
  color: ${({ $value }) => {
    if ($value > 0) {
      return 'var(--red500)';
    }

    if ($value < 0) {
      return 'var(--blue500)';
    }

    return 'black';
  }};
`;

export const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const flexRowCenter = css`
  display: flex;
  align-items: center;
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;
