import instance from 'service/interceptor';

async function getProductList({ vendorId, pageNumber = 0, pageSize = 50 } = {}) {
  const { data } = await instance.post('/api/v2/hq/product/supply/vendor/standard/list', {
    vendorId,
    pageNumber,
    pageSize,
  });

  return data;
}

async function getProductConnectionList({
  vendorId,
  searchCondition,
  searchContent,
  pageNumber,
  pageSize,
}) {
  const { data } = await instance.post('/api/v2/hq/product/supply/connect/list', {
    vendorId,
    searchCondition,
    searchContent,
    pageNumber,
    pageSize,
  });

  return data;
}

async function removeProductListFromVendor(productSupplyVendorStandardIdList) {
  const data = instance.post('/api/v2/hq/product/supply/connect/delete', {
    productSupplyVendorStandardIdList,
  });

  return data;
}

async function updateProductList(vendorId, updateProductSupplyVendorStandardList) {
  const { data } = await instance.post('/api/v2/hq/product/supply/vendor/standard/update', {
    vendorId,
    updateProductSupplyVendorStandardList,
  });

  return data;
}

async function connectProductListWithVendor(vendorId, connectItemList) {
  const { data } = await instance.post('/api/v2/hq/product/supply/connect', {
    vendorId,
    connectItemList,
  });

  return data;
}

export default {
  getProductList,
  getProductConnectionList,
  removeProductListFromVendor,
  updateProductList,
  connectProductListWithVendor,
};
