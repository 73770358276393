import PropTypes from 'prop-types';
import * as Styled from './styled';

const Select = ({ id, options, label, placeholder, ...props }) => {
  const renderOptions = () => {
    if (options.length === 0) {
      return null;
    }

    return options.map(({ key, value }) => (
      <option key={`${key}_${value}`} value={key}>
        {value}
      </option>
    ));
  };

  return (
    <Styled.Wrapper>
      {label && <Styled.Label htmlFor={id}>{label}</Styled.Label>}

      <Styled.FormSelect id={id} {...props}>
        {placeholder && (
          <option value='' disabled hidden>
            {placeholder}
          </option>
        )}
        {renderOptions()}
      </Styled.FormSelect>
    </Styled.Wrapper>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.string,
      id: PropTypes.string,
    })
  ).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default Select;
