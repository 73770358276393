import { memo } from 'react';
import PropTypes from 'prop-types';

import * as StyledPage from 'styles';
import { Flex } from 'styles/components';

import LabelAndText from 'components/LabelAndText';

const VendorInfo = ({ vendor = {} }) => {
  return (
    <StyledPage.Card title='거래처 정보'>
      <Flex>
        <LabelAndText label='거래처번호' text={vendor.vendorId} />
      </Flex>
      <Flex>
        <LabelAndText label='거래처 명' text={vendor.displayName} />
        <LabelAndText label='거래처 유형' text={vendor.vendorTypeMappings} />
      </Flex>
    </StyledPage.Card>
  );
};

VendorInfo.propTypes = {
  vendor: PropTypes.shape({
    vendorId: PropTypes.number,
    displayName: PropTypes.string,
    vendorTypeMappings: PropTypes.string,
  }),
};

export default memo(VendorInfo);
