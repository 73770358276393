import { useCallback, useEffect, useState } from 'react';

/**
 *
 * @param {*} asyncFunction
 * @param {*} options - {  manipulateFunc, initialExecute = true }
 * @returns - { data, execute, isLoading, setState }
 */
const useFetch = (asyncFunction, { manipulateFunc, initialExecute = true } = {}) => {
  const [state, setState] = useState({ isLoading: false });

  const execute = useCallback(
    async (...props) => {
      setState({ ...state, isLoading: true });
      const newState = { ...state, isLoading: false };
      try {
        const { response, ...rest } = await asyncFunction(...props);
        const data = manipulateFunc ? manipulateFunc(response?.data) : response?.data;
        newState.data = data || rest;
      } catch (error) {
        newState.error = error;
      } finally {
        setState(newState);
      }
      return newState;
    },
    [asyncFunction]
  );

  useEffect(() => {
    if (!initialExecute) {
      return;
    }

    execute();
  }, [initialExecute]);

  return { ...state, execute, setState };
};

export default useFetch;
