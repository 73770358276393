import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import downloadImageSvg from 'icons/svg/fileDownload.svg';

import { Flex } from 'styles/components';

import toastify from 'commons/toast';
import * as Styled from './styled';

const KB = 1024;
const MB = KB ** 2;

const ImageUploader = ({
  id,
  title,
  label,
  setState,
  imageName,
  imageId,
  onImageDownloadClick,
  disabled,
  required = true,
}) => {
  const [files, setFiles] = useState({ length: 0 });
  const [image, setImage] = useState({});

  useEffect(() => {
    setImage((prev) => ({ ...prev, name: imageName }));
  }, [imageName]);

  const handleFileChange = (e) => {
    const fileList = e.target.files;
    const file = fileList[0];
    if (!file) {
      return;
    }
    if (file.size > 10 * MB) {
      toastify('이미지는 10메가바이트 초과 할 수 없습니다.', { variant: 'error' });
      return;
    }
    const url = URL.createObjectURL(file);

    setFiles(fileList);
    setState((prev) => ({ ...prev, bankBookVendorImage: file }));
    setImage((prev) => ({ ...prev, url, name: file.name }));
  };

  return (
    <Flex $flexDirection='column' $gap='var(--space-m)'>
      <div>
        <Flex $flexDirection='column'>
          <Styled.Title>{title}</Styled.Title>
          <Styled.LabelWrapper $disabled={disabled}>
            <Styled.Label htmlFor={id}>{files[0]?.name || image.name || label}</Styled.Label>
            {imageId && (
              <Styled.ImageWrapper onClick={onImageDownloadClick}>
                <Styled.Image src={downloadImageSvg} />
              </Styled.ImageWrapper>
            )}
            {!disabled && (
              <Styled.Input
                required={required}
                id={id}
                onChange={handleFileChange}
                disabled={disabled}
                type='file'
                accept='.png,.jpeg,.jpg,.gif,.bmp'
              />
            )}
          </Styled.LabelWrapper>
        </Flex>
      </div>
    </Flex>
  );
};

ImageUploader.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  setState: PropTypes.func,
  imageName: PropTypes.string,
  imageId: PropTypes.number,
  onImageDownloadClick: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default ImageUploader;
