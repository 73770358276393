import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setUser } from 'commons/store/modules/user';
import { useInterval } from 'hooks/useInterval';
import { getTokens } from 'commons/token';
import { jwtDecode } from '@teampetfriends/utils';
import { useAuthInfo } from 'hooks/useSyncAuth';

import Header from '../Header';
import Sidebar from '../Sidebar';
import * as Styled from './styled';

const DefaultLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { storage, update } = useAuthInfo(['accessToken']);

  const handleSetUser = (userInfo) => dispatch(setUser(userInfo));

  useInterval(() => {
    const [accessToken] = getTokens(['accessToken']);

    const userInfo = accessToken ? jwtDecode(accessToken) : null;
    const storageInfo = storage ? jwtDecode(storage) : null;

    if (accessToken && storageInfo && userInfo?.user_id !== storageInfo.user_id) {
      handleSetUser({ accessToken });
      update({ accessToken });
    }
  }, 1000);

  return (
    <Styled.Wrapper>
      <Header />
      <Sidebar />
      <Styled.Main>{children}</Styled.Main>
    </Styled.Wrapper>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default DefaultLayout;
