/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getSettlementDetail, getSettlementList } from 'service/vendor/settlement';

import {
  SEARCH_FILTER_INIT_VALUES,
  SETTLEMENT_LIST,
} from 'pages/vendor/SettlementListPage/commons/constant';

const initialState = {
  [SETTLEMENT_LIST]: {
    searchFilter: SEARCH_FILTER_INIT_VALUES,
    tableData: [],
    totalElements: 0,
    totalPages: 0,
    selectedSettlementId: null,
    detailData: {},
  },
};

export const fetchSettlementList = createAsyncThunk(
  'vendorSettlement/fetchSettlementList',
  async (params, { getState }) => {
    const newParams = params
      ? {
          ...params,
          receiveCompleteDay:
            params.receiveCompleteDay && params.receiveCompleteDay.format('YYYY-MM-DD'),
        }
      : getState().vendorSettlement[SETTLEMENT_LIST].searchFilter;
    const response = await getSettlementList(newParams);

    return {
      searchFilter: newParams,
      data: response?.data?.data || response?.data,
      content: response?.data?.content,
      totalElements: response?.data?.totalElements,
      totalPages: response?.data?.totalPages,
    };
  }
);

export const fetchSettlementDetail = createAsyncThunk(
  'vendorSettlement/fetchSettlementDetail',
  async (params) => {
    const { selectedSettlementId } = params;
    const response = await getSettlementDetail(selectedSettlementId);
    return {
      selectedSettlementId,
      detailData: response?.data?.response.data || response?.data?.data || response?.data,
    };
  }
);

const slice = createSlice({
  name: 'vendorSettlement',
  initialState,
  reducers: {
    setPageFilter: (state, action) => {
      state[action.payload.path].searchFilter = action.payload.searchFilter;
    },
    setSelectedSettlementId: (state, action) => {
      state[action.payload.path].selectedSettlementId = action.payload.selectedSettlementId;
    },
  },
  extraReducers: (builder) => {
    // fetchSettlementList
    builder.addCase(fetchSettlementList.fulfilled, (state, action) => {
      state[SETTLEMENT_LIST].tableData = action.payload.content;
      state[SETTLEMENT_LIST].searchFilter = action.payload.searchFilter;
      state[SETTLEMENT_LIST].totalElements = action.payload.totalElements;
      state[SETTLEMENT_LIST].totalPages = action.payload.totalPages;
    });
    builder.addCase(fetchSettlementList.rejected, (state) => {
      state[SETTLEMENT_LIST].tableData = [];
      state[SETTLEMENT_LIST].totalElements = 0;
      state[SETTLEMENT_LIST].totalPages = 0;
    });
    // fetchSettlementDetail
    builder.addCase(fetchSettlementDetail.fulfilled, (state, action) => {
      state[SETTLEMENT_LIST].selectedSettlementId = action.payload.selectedSettlementId;
      state[SETTLEMENT_LIST].detailData = action.payload.detailData;
    });
  },
});

export const { setPageFilter, setSelectedSettlementId } = slice.actions;

export default slice.reducer;
