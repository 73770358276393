import { toast } from 'react-toastify';

// variant = success | info | error | warning | warn | dark | default
// position = 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right'

function toastify(
  message,
  {
    variant = 'info',
    position = 'top-center',
    autoClose = 3000,
    hideProgressBar = true,
    closeOnClick = true,
    pauseOnHover = true,
    draggable = true,
    pauseOnFocusLoss = true,
    newestOnTop = false,
    rtl = false,
    theme = 'dark',
  } = {}
) {
  const config = {
    position,
    autoClose,
    hideProgressBar,
    closeOnClick,
    pauseOnHover,
    draggable,
    pauseOnFocusLoss,
    newestOnTop,
    rtl,
    theme,
  };

  if (variant === 'default') {
    toast(message, config);
    return;
  }

  toast[variant](message, config);
}

export default toastify;
