import styled from 'styled-components';

export const NoticeTable = styled.table`
  width: 100%;

  thead th {
    color: #8b95a1;
    font-size: 12px;
    padding-bottom: 15px;
  }
`;
