import styled from 'styled-components';

const Wrapper = styled.button.attrs(({ $variant, $outline, $size }) => ({
  className: `btn ${$outline ? `btn-outline-${$variant}` : `btn-${$variant}`} 
    ${$size === 'large' && 'btn-lg'} ${$size === 'small' && 'btn-sm'}`,
}))`
  border-radius: var(--radius-m);
`;

export default { Wrapper };
